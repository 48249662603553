import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  height: 150px;
  z-index: 1;
`;

export const ModalContent = styled.div`
  border-radius: 24px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  width: 350px;
  height: 200px;
  overflow: auto;
  background-color: white;
  overflow: hidden;
`;

export const AbandoButton = styled.div`
  background-color: #c0d7d8;
  color: #015353;
  width: 125px;
  height: 27px;
  border-radius: 25px;
  margin: 0 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: karla;
  margin-left: 25px;
`;

export const CancelButton = styled.div`
  background-color: white;
  color: #4a4f7f;
  width: 125px;
  height: 27px;
  border: 1px solid #4a4f7f;
  border-radius: 25px;
  margin: 0 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: karla;
`;

export const ModalButtonsContainer = styled.div`
  display: flex;
  margin-top: 25px;
  margin-right: 15px;
  padding: 25px 0;
`;

export const ModalInnerContent = styled.div`
  font-size: 24px;
  height: 50px;
  width: 100%;
  background-color: #028a8a;
  padding-top: 10px;
  padding-left: 85px;
`;

export const ModalTextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
`;

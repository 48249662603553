import { Button } from '@mui/material';
import styled from 'styled-components';

export const CreateTaskDataContainer = styled.div`
  margin-top: 35px;
  height: 120px;
  width: 100%;
  font-family: karla;
`;

export const HeaderContainer = styled.div`
  display: flex;
`;

export const TextHeader = styled.div`
  margin-top: 31px;
  margin-left: 90px;
  font-family: karla;
  color: #015b5b;
  font-size: 30px;
  font-weight: bold;
`;

export const SectionHeader = styled.div`
  font-family: Lato;
  color: #0f0f0f;
  font-size: 14px;
  margin-bottom: 10px;
`;

export const DataColumn = styled.div`
  display: flex;
  margin-left: 85px;
  margin-top: 30px;
`;

export const SelectStyle = styled.select`
  width: 225px;
  height: 32px;
  border: 2px solid #e9e7e7;
  margin-top: -5px;
`;

export const TextAreaStyle = styled.textarea`
  border-radius: 10px;
  width: 1239px;
  height: 92px;
  border: 2px solid #e9e7e7;
  color: #0f145b;
  margin-top: 6px;
  margin-right: 20px;
`;

export const SubmitButtonContainer = styled.div`
  display: flex;
  padding: 25px 0;
  margin-left: 1075px;
`;

export const SubmitButton = styled(Button)`
  color: white;
  min-width: 125px;
  height: 27px;
  border-radius: 25px;
  margin: 0 7px;
  font-family: karla;

  &.Mui-disabled {
    background-color: #c8c8da;
    color: #ffffff;
  }
`;

export const CancelButton = styled.div`
  background-color: white;
  color: #4a4f7f;
  width: 125px;
  height: 27px;
  border: 1px solid #4a4f7f;
  border-radius: 25px;
  margin: 0 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: karla;
`;

export const CrumbBar = styled.div`
  display: flex;
  height: 80px;
  background-color: #c0d7d8;
  align-items: center;
  justify-content: space-evenly;
`;

export const CrumbBarData = styled.div`
  font-family: Open Sans;
  font-weight: bold;
`;

export const SpinnerButton = styled.div`
  background-color: #c0d7d8;
  color: white;
  width: 125px;
  height: 30px;
  border: 1px solid #4a4f7f;
  border-radius: 25px;
  font-family: Karla, sans-serif;
  outline: none;
  margin-left: 1075px;
`;

export const ErrorMessage = styled.div`
  color: red;
  font-family: Karla;
  margin-left: 85px;
`;

export const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
  margin: 8px;
  font-size: 14px;
`;

export const InfoColumn = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const InfoGroup = styled.div`
  display: flex;
  padding: 8px;
`;

export const InfoHeading = styled.div`
  font-family: lato;
  color: #0f0f0f;
  margin-right: 8px;
`;

export const InfoText = styled.div`
  font-family: Open Sans;
  color: #0f145b;
`;

export const CallContainer = styled.div`
  position: absolute;
  margin-left: 50%;
`;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ScreeningModuleStore } from '../ScreeningModuleStore';
import { Forms } from './forms';
import { InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import { ScreenMemberDataContainer, StyledSelect, TextHeader } from './styles';
import LoadingWrapper from 'Components/LoadingWrapper/LoadingWrapper';
import { screeningNames } from './config';
import { Alert } from '@mui/lab';

// TODO - remove prop-drilling once Task/Activity logic is refactored
const ScreeningForm = (props) => {
  const { taskGuid, userId } = useParams();
  const [loading, setLoading] = useState(true);
  const screeningStore = ScreeningModuleStore.Instance().Use();
  const FormComponent = Forms[screeningStore.ScreeningGuid] ?? (() => <></>);
  const availableScreenings = screeningStore.AvailableScreenings;
  const done = screeningStore.ScreeningIsDone;
  const isLoading = !FormComponent || !screeningStore.ScreeningGuid || loading;
  const { actions, setActions, setCurrentTab, setActionToDisplay, setValues, values } = props;

  const onComplete = (screeningResponseGuid) => {
    setActions({ ...actions, isActive: true, sourceGuid: screeningResponseGuid });
  };

  useEffect(() => {
    screeningStore.initialize({ userId, taskGuid, completeCallback: onComplete }).then(() => {
      setLoading(false);
    });
  }, [userId]);

  useEffect(() => {
    if (done && !loading) {
      setValues({ ...values, currentTab: '', actionToDisplay: '' });
      setActionToDisplay('');
      setCurrentTab('');
    }
  }, [done]);

  useEffect(() => {
    if (screeningStore.ScreeningGuid && !Forms[screeningStore.ScreeningGuid]) {
      screeningStore.cancel();
    }
  }, [screeningStore.ScreeningGuid, FormComponent]);

  return (
    <>
      <TextHeader>Screen Member</TextHeader>
      <ScreenMemberDataContainer>
        {!screeningStore.IsLoading && availableScreenings?.length == 0 && (
          <Alert severity="warning">No Screenings Available</Alert>
        )}
        <StyledSelect>
          <InputLabel variant="standard" id="screening-selection-label">
            Screening:
          </InputLabel>
          <Select
            id="screening-selection"
            labelId="screening-selection-label"
            value={screeningStore?.ScreeningGuid ?? ''}
            onChange={(e) => screeningStore.chooseScreening(e.target.value)}
            displayEmpty
          >
            {availableScreenings?.map((screeningGuid, index) => (
              <MenuItem key={index} name={screeningGuid} value={screeningGuid}>
                {screeningNames[screeningGuid]}
              </MenuItem>
            ))}
          </Select>
        </StyledSelect>
        <LoadingWrapper loading={isLoading}>
          <FormComponent />
        </LoadingWrapper>
      </ScreenMemberDataContainer>
    </>
  );
};

ScreeningForm.propTypes = {
  onCancel: PropTypes.func,
  setActionToDisplay: PropTypes.func,
  setCurrentTab: PropTypes.func,
  onChange: PropTypes.func,
  setActions: PropTypes.func,
  memberTaskInfo: PropTypes.object,
  actions: PropTypes.object,
  values: PropTypes.object,
  setValues: PropTypes.func,
};

export default ScreeningForm;

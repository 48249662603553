import { Radio, RadioGroup } from '@mui/material';
import React, { forwardRef } from 'react';
import { StyledFormLabel } from './styles';
import PropTypes from 'prop-types';

const OutcomeRadioGroup = forwardRef(
  ({ onChange, options, value, row, icon, checkedIcon, disabled, ...props }, ref) => {
    return (
      <RadioGroup {...props} onChange={onChange} row={row} ref={ref}>
        {options.map((option, index) => (
          <StyledFormLabel
            key={index}
            checked={value === option}
            value={option}
            label={option}
            disabled={disabled}
            control={<Radio icon={icon} checkedIcon={checkedIcon} />}
          />
        ))}
      </RadioGroup>
    );
  },
);

OutcomeRadioGroup.displayName = 'OutcomeRadioGroup';

OutcomeRadioGroup.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  row: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  checkedIcon: PropTypes.node,
};

export default OutcomeRadioGroup;

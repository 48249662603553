import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextHeader,
  HeaderContainer,
  Label,
  DataColumn,
  DataRow,
  SelectStyle,
  MemberTextContainer,
  InputStyle,
  HeaderStyle,
  TextTemplateList,
  PreviewContainer,
  SubmitButton,
  SpinnerButton,
  CancelButton,
  SubmissionRow,
  SubmissionContainer,
  SubmitButtonContainer,
  ErrorMessage,
} from './styles';
import { useForm, Controller } from 'react-hook-form';
import Radio from 'Tasking_Components/Radio/Radio';
import Spinner from 'Components/Spinner/Spinner';
import useGetNotifications from 'Tasking_Hooks/useGetNotifications';
import useSendMemberText from 'Tasking_Hooks/useSendMemberText';
import useSendPhoneText from 'Tasking_Hooks/useSendPhoneText';
import useSendNgmText from 'Tasking_Hooks/useSendNgmText';
import useGetLocales from 'Tasking_Hooks/useGetLocales';

const MemberText = ({
  setActions,
  setActionToDisplay,
  setCurrentTab,
  memberTaskInfo,
  ngm,
  memberInfo,
  values,
  setValues,
}) => {
  const { register, handleSubmit, control, clearErrors, formState } = useForm({
    defaultValues: {
      rbPhoneNumber: values?.memberTextData?.selectedPhoneNumber ?? '',
    },
  });

  const textNotificationTypes = [1, 3];
  const [textTemplates, setTextTemplates] = useState();
  const [currentTemplate, setCurrentTemplate] = useState();
  const { response: memberTextResponse, isPending: memberTextIsPending, sendMemberText } = useSendMemberText();
  const { response: phoneTextResponse, isPending: phoneTextIsPending, sendPhoneText } = useSendPhoneText();
  const { response: ngmTextResponse, isPending: ngmTextIsPending, sendNgmText } = useSendNgmText();
  const { locales } = useGetLocales();
  const { notifications, filterLanguage } = useGetNotifications();

  useEffect(() => {
    if (memberTextResponse || phoneTextResponse || ngmTextResponse) {
      setValues({ ...values, currentTab: '', actionToDisplay: '', memberTextData: undefined });
      setActionToDisplay('');
      setCurrentTab('');
    }
  }, [memberTextResponse, phoneTextResponse, ngmTextResponse]);

  const updateValue = (name, value) => {
    setValues({ ...values, memberTextData: { ...values?.memberTextData, [name]: value } });
  };

  function isValidOtherPhoneInput(input) {
    if (values?.memberTextData?.selectedPhoneNumber !== 'Other') return true;

    if (!input) return false;

    // remove non-numeric characters
    const rawInput = input.replace(/[^0-9]/g, '');
    if (rawInput.length < 10) return false;

    // input of only 0s is invalid
    if (Array.from(rawInput).every((c) => c === '0')) return false;

    return true;
  }

  const onSubmit = () => {
    setActions(true);
    if (ngm && values?.memberTextData?.selectedPhoneNumber === 'Preferred') {
      const textNgmMemberRequest = {
        sentByUserId: memberInfo?.userId,
        title: currentTemplate?.title,
        message: currentTemplate?.message,
        organizationId: memberTaskInfo?.organizationId,
        NGMIds: [memberTaskInfo?.id],
      };
      sendNgmText(textNgmMemberRequest);
    } else if (ngm && values?.memberTextData?.selectedPhoneNumber === 'Other') {
      const textPhoneRequest = {
        sentByUserId: memberInfo?.userId,
        notificationId: values?.memberTextData?.selectedTemplateId,
        title: currentTemplate?.title,
        message: currentTemplate?.message,
        notificationMethodId: currentTemplate?.id,
        organizationId: memberTaskInfo?.organizationId,
        phoneNumbers: [values?.memberTextData?.txtOther],
      };
      sendPhoneText(textPhoneRequest);
    } else if (!ngm) {
      const textMemberRequest = {
        sentByUserId: memberInfo?.userId,
        userIds: [memberTaskInfo?.userId],
        title: currentTemplate?.title,
        message: currentTemplate?.message,
        notificationMethodId: currentTemplate?.id,
      };
      sendMemberText(textMemberRequest);
    }
  };

  const onLanguageChange = (e) => {
    updateValue('selectedTemplateId', undefined);
    setTextTemplates();
    updateValue('language', e.currentTarget.value);
    filterLanguage(e.currentTarget.value);
  };

  useEffect(() => {
    if (values?.memberTextData?.selectedTemplateId && textTemplates) {
      //Check if the template is valid before assigning as current
      const validTemplate = textTemplates.find((x) => x.id === values?.memberTextData?.selectedTemplateId);
      validTemplate
        ? setCurrentTemplate(validTemplate.templates[0])
        : updateValue('selectedTemplateId', textTemplates[0].id);
    } else if (!values?.memberTextData?.selectedTemplateId && textTemplates) {
      updateValue('selectedTemplateId', textTemplates[0].id);
    }
  }, [values?.memberTextData?.selectedTemplateId, textTemplates]);

  useEffect(() => {
    if (notifications && notifications.length > 0) {
      setTextTemplates(
        notifications.filter((x) => x.templates.find((y) => textNotificationTypes.includes(y.notificationMethodId))),
      );
    }
  }, [notifications]);

  useEffect(() => {
    if (locales && locales.length > 0) {
      updateValue('language', values?.memberTextData?.language ?? locales[0].id);
      updateValue(
        'selectedPhoneNumber',
        memberTaskInfo?.preferredPhone || memberTaskInfo?.normalizedPhone ? 'Preferred' : 'Other',
      );
      filterLanguage(values?.memberTextData?.language ?? locales[0].id);
    }
  }, [locales]);

  const cancelSelected = () => {
    setValues({ ...values, currentTab: '', actionToDisplay: '', memberTextData: undefined });
    setActionToDisplay('');
    setCurrentTab('');
  };

  const getClass = (id) => {
    return values?.memberTextData?.selectedTemplateId === id ? 'active' : null;
  };

  const TextTemplateListItem = (props) => {
    return (
      <>
        <li>
          <button
            type="button"
            data-testid={`btnTemplate${props.index}`}
            className={getClass(props.template.id)}
            onClick={() => {
              updateValue('selectedTemplateId', props.template.id);
            }}
          >
            {props.template.name}
          </button>
        </li>
      </>
    );
  };

  TextTemplateListItem.propTypes = {
    template: PropTypes.object,
    index: PropTypes.number,
  };

  return (
    <>
      <HeaderContainer>
        <TextHeader>Text Member</TextHeader>
      </HeaderContainer>
      <form data-testid="ScreenMemberForm" onSubmit={handleSubmit(onSubmit)}>
        <MemberTextContainer data-testid="MemberText">
          <DataRow className="top-form">
            <DataColumn className="language">
              <Label htmlFor="ddLanguage">Language:</Label>
              <SelectStyle
                {...register('ddLanguage')}
                className="push-down"
                type="text"
                id="ddLanguage"
                data-testid="LanguageInput"
                value={values?.memberTextData?.language}
                onChange={(e) => onLanguageChange(e)}
              >
                {locales
                  ? locales.map((locale) => {
                      return (
                        <option data-testid={locale.language} value={locale.id} key={locale.id}>
                          {locale.language}
                        </option>
                      );
                    })
                  : null}
              </SelectStyle>
            </DataColumn>
            {ngm ? (
              <DataColumn className="flex-box">
                <Label htmlFor="rbPhoneNumber">Phone Number:</Label>
                {memberTaskInfo?.preferredPhone || memberTaskInfo?.normalizedPhone ? (
                  <Controller
                    control={control}
                    name="rbPhoneNumber"
                    render={({ field: { onChange, ...field }, ...fields }) => (
                      <Radio
                        {...field}
                        {...fields}
                        id="phoneNumberPreferred"
                        onChange={(e) => {
                          onChange(e);
                          updateValue('selectedPhoneNumber', 'Preferred');
                          clearErrors();
                        }}
                        name="rbPhoneNumber"
                        value="Preferred"
                        checked={values?.memberTextData?.selectedPhoneNumber === 'Preferred'}
                        data-testid="rbPhoneNumberPreferred"
                        dataTestId="rbPhoneNumberPreferredInput"
                      >
                        {memberTaskInfo?.preferredPhone
                          ? memberTaskInfo?.preferredPhone
                          : memberTaskInfo?.normalizedPhone}
                        <span className="break">{memberTaskInfo?.preferredPhone ? 'Preferred' : 'NGM Phone'}</span>
                      </Radio>
                    )}
                  />
                ) : null}
                <Controller
                  control={control}
                  name="rbPhoneNumber"
                  render={({ field: { onChange, ...field }, ...fields }) => (
                    <Radio
                      {...field}
                      {...fields}
                      id="phoneNumberOther"
                      onChange={(e) => {
                        onChange(e);
                        updateValue('selectedPhoneNumber', 'Other');
                      }}
                      name="rbPhoneNumber"
                      value="Other"
                      checked={values?.memberTextData?.selectedPhoneNumber === 'Other'}
                      data-testid="rbPhoneNumberOther"
                      dataTestId="rbPhoneNumberOtherInput"
                    >
                      Other
                    </Radio>
                  )}
                />

                <InputStyle
                  {...register('txtOther', {
                    validate: (v) => isValidOtherPhoneInput(v) || 'A valid phone number is required',
                  })}
                  hidden={values?.memberTextData?.selectedPhoneNumber !== 'Other'}
                  type="tel"
                  id="txtOther"
                  onChange={(e) => {
                    updateValue('txtOther', e.target.value);
                    clearErrors();
                  }}
                  data-testid="OtherPhone"
                  defaultValue={values?.memberTextData?.txtOther}
                />
                {formState.errors.txtOther && (
                  <ErrorMessage data-testid="NoPhoneError">{formState.errors.txtOther.message}</ErrorMessage>
                )}
              </DataColumn>
            ) : (
              <DataColumn />
            )}
          </DataRow>
        </MemberTextContainer>
        <MemberTextContainer>
          <DataRow className="text-template-row">
            <DataColumn className="text-list">
              <HeaderStyle>Text Template</HeaderStyle>
              {textTemplates ? (
                <TextTemplateList>
                  {textTemplates.map((textTemplate, i) => {
                    return <TextTemplateListItem key={`textTemplate${i}`} template={textTemplate} index={i} />;
                  })}
                </TextTemplateList>
              ) : (
                <div>loading...</div>
              )}
            </DataColumn>
            <DataColumn className="text-preview">
              <HeaderStyle className="preview-header">Preview Message</HeaderStyle>
              <PreviewContainer data-testid="previewContainer">
                {textTemplates && currentTemplate ? (
                  <>
                    <h4>{currentTemplate.title}</h4>
                    <p>{currentTemplate.message}</p>
                  </>
                ) : (
                  <div>loading...</div>
                )}
              </PreviewContainer>
            </DataColumn>
          </DataRow>
        </MemberTextContainer>
        <SubmissionRow>
          <SubmissionContainer>
            <SubmitButtonContainer>
              {memberTextIsPending || phoneTextIsPending || ngmTextIsPending ? (
                <SpinnerButton data-testid="SpinnerButton">
                  <Spinner />
                </SpinnerButton>
              ) : (
                <SubmitButton data-testid="SubmitButton" name={'submit'} value={'Send'} type="submit" />
              )}
              <CancelButton value="Cancel" type="reset" data-testid="CancelButton" onClick={cancelSelected}>
                Cancel
              </CancelButton>
            </SubmitButtonContainer>
          </SubmissionContainer>
        </SubmissionRow>
      </form>
    </>
  );
};

MemberText.propTypes = {
  setActionToDisplay: PropTypes.func,
  setCurrentTab: PropTypes.func,
  onChange: PropTypes.func,
  setActions: PropTypes.func,
  memberTaskInfo: PropTypes.object,
  memberInfo: PropTypes.object,
  ngm: PropTypes.bool,
  values: PropTypes.object,
  setValues: PropTypes.func,
};

export default MemberText;

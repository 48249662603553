import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './initialization/App';
import Redirect from 'Components/Redirect';

import { OidcProvider } from '@axa-fr/react-oidc';
import oidcConfiguration from './configuration';
import { RefreshTokenStorage } from './RefreshTokenStorage';
import GlobalStyle from './style';

createRoot(document.getElementById('root')).render(
  <OidcProvider callbackComponentOverride={Redirect} UserStore={RefreshTokenStorage} configuration={oidcConfiguration}>
    <GlobalStyle />
    <App />
  </OidcProvider>,
);

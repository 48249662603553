import React, { useState, useEffect, useContext } from 'react';
import { HeaderContainer, TextHeader, NewTaskButton, Loading } from './styles';
import Dashboard from 'Tasking_Components/Dashboard/Dashboard';
import CreateTask from 'Tasking_Components/CreateTask/CreateTask';
import { TaskSelectedContext } from 'Tasking_Contexts/TaskSelectedContext';
import PropTypes from 'prop-types';
import { Route, Routes, useParams } from 'react-router-dom';
import { MainIconBarContext } from 'Tasking_Contexts/MainIconBarContext';
import useTaskDetails from 'Tasking_Hooks/useTaskDetails';
import useGetEnrolledNgm from 'Tasking_Hooks/useGetEnrolledNgm';

const MemberTaskData = ({ memberInfo, ngm }) => {
  const [newTaskClicked, setNewTaskClicked] = useState(false);
  const [savedTaskClicked, setSavedTaskClicked] = useState();
  const [userTaskInNgmClicked, setUserTaskInNgmClicked] = useState();
  const { taskGuid, userId, ngmId, orgId } = useParams();
  const { taskDetail, isTaskDetailsPending, retrieveTask } = useTaskDetails(
    ngm ? memberInfo?.organizationId : memberInfo?.activeOrganizationId,
    taskGuid,
  );
  const { selectedTask, setSelectedTask } = useContext(TaskSelectedContext);
  const { setShowMainIcons } = useContext(MainIconBarContext);
  const { enrolledMemberData } = ngm ? useGetEnrolledNgm(memberInfo?.organizationId, memberInfo?.id, true) : '';

  useEffect(() => {
    if (taskGuid && memberInfo && !userTaskInNgmClicked) {
      setShowMainIcons(true);
      retrieveTask(ngm ? memberInfo?.organizationId : memberInfo?.activeOrganizationId, taskGuid);
    }
  }, [memberInfo]);

  useEffect(() => {
    if (taskGuid && taskDetail && !userTaskInNgmClicked) {
      setSavedTaskClicked(true);
      let updatedTask = { ...taskDetail, userId, ngmId, orgId, taskGuid };
      setSelectedTask(updatedTask);
    }
  }, [taskDetail]);

  useEffect(() => {
    if (
      (selectedTask || taskDetail) &&
      !userId &&
      ngmId &&
      (taskDetail?.userId || selectedTask?.userId || enrolledMemberData?.userId)
    ) {
      setUserTaskInNgmClicked(true);
    }
  }, [enrolledMemberData, taskDetail]);

  useEffect(() => {
    if (userTaskInNgmClicked) {
      setSavedTaskClicked(false);
      setShowMainIcons(true);
      setSelectedTask();
    }
  }, [userTaskInNgmClicked]);

  useEffect(() => {
    if (selectedTask && !userTaskInNgmClicked) {
      setSavedTaskClicked(true);
    }
  }, [selectedTask]);

  const displayTaskButton = () => {
    return !memberInfo || enrolledMemberData?.isGetPending ? null : (
      <NewTaskButton
        disabled={enrolledMemberData?.userId}
        title={enrolledMemberData?.userId ? 'Disabled. Go to enrolled profile.' : ''}
        data-testid={`CreateTaskButton`}
        onClick={() => {
          setNewTaskClicked(true);
          setSavedTaskClicked(false);
        }}
      >
        Create New Task
      </NewTaskButton>
    );
  };

  const returnTask = () => {
    if (newTaskClicked) {
      return (
        <div data-testid="CreateTaskOption" id="Dashboard">
          <CreateTask
            ngm={ngm}
            completed={false}
            memberTaskInfo={memberInfo}
            setSavedTaskClicked={setSavedTaskClicked}
            setNewTaskClicked={setNewTaskClicked}
            newTaskClicked={newTaskClicked}
            savedTaskClicked={savedTaskClicked}
          />
        </div>
      );
    }
    return (
      <>
        <HeaderContainer>
          <TextHeader>Tasks</TextHeader>
          {displayTaskButton()}
        </HeaderContainer>
        <Dashboard
          setSelectedTask={setSelectedTask}
          specificOrg={ngm ? memberInfo?.organizationId : memberInfo?.activeOrganizationId}
          specificProfile={ngm ? memberInfo?.id : memberInfo?.activeProfileId}
          ngm={ngm}
          profileSearch={true}
        />
      </>
    );
  };

  if (isTaskDetailsPending && taskGuid) {
    return <Loading data-testid="LoadingTask">Loading...</Loading>;
  }

  return (
    <Routes>
      <Route
        path=":taskGuid/*"
        element={
          <div data-testid="CreateTaskOption" id="Dashboard">
            <CreateTask
              ngm={ngm}
              completed={false}
              memberTaskInfo={memberInfo}
              setSavedTaskClicked={setSavedTaskClicked}
              setNewTaskClicked={setNewTaskClicked}
              newTaskClicked={newTaskClicked}
              isSavedTask={savedTaskClicked}
            />
          </div>
        }
      />
      <Route index element={returnTask()} />
    </Routes>
  );
};

MemberTaskData.propTypes = {
  memberInfo: PropTypes.object,
  ngm: PropTypes.bool,
};

export default MemberTaskData;

import styled from 'styled-components';

export const NonAppEnrollmentContainer = styled.div`
  margin-top: 35px;
  height: 120px;
  width: 100%;
`;

export const DataColumn = styled.div`
  display: flex;
  margin-left: 85px;
  margin-top: 30px;
`;

export const HeaderContainer = styled.div`
  display: flex;
`;

export const TextHeader = styled.div`
  margin-top: 31px;
  margin-left: 90px;
  font-family: karla;
  color: #015b5b;
  font-size: 34px;
  font-weight: bold;
`;

export const Label = styled.label`
  color: #0f0f0f;
  font-size: 14px;
  font-family: lato;
`;

export const InputStyle = styled.input`
  border-radius: 10px;
  width: 150px;
  height: 20px;
  border: 2px solid #e9e7e7;
  color: #0f145b;
  margin-top: 6px;
  margin-right: 40px;
`;

export const SubmitButtonContainer = styled.div`
  display: flex;
  padding: 25px 0;
`;

export const SubmitButton = styled.input`
  background-color: #4a4f7f;
  color: white;
  width: 150px;
  height: 27px;
  border: 1px solid #4a4f7f;
  border-radius: 25px;
  margin: 0 14px;
  cursor: pointer;
  font-family: karla;
  margin-left: 890px;
`;

export const CancelButton = styled.div`
  background-color: white;
  color: #4a4f7f;
  width: 125px;
  height: 27px;
  border: 1px solid #4a4f7f;
  border-radius: 25px;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: karla;
`;

export const SpinnerButton = styled.div`
  background-color: #4a4f7f;
  color: 'white';
  width: 148px;
  height: 25px;
  border: 1px solid #4a4f7f;
  border-radius: 25px;
  margin: 0;
  margin-right: 14px;
  margin-left: 890px;
  font-family: karla;
  outline: none;
`;

export const CustomError = styled.div`
  color: red;
  margin-left: 90px;
  margin-top: 25px;
`;

import styled from 'styled-components';

export const StyledErrorMessage = styled.div`
  color: red;
`;

export const StyledContentContainer = styled.div`
  padding: 28px;
  font-family: 'Karla';
`;

export const StyledProgressContainer = styled.span`
  display: flex;
  justify-content: center;
  padding: 100px;
`;

export const userMetaCategories = {
  GENERAL: 'GENERAL',
  YOUTH: 'YOUTH',
  CAREGIVER: 'CAREGIVER',
  GUARDIAN: 'GUARDIAN',
};

export const retrieveUserMetaCategories = (userMeta) => {
  let categories = [userMetaCategories.GENERAL];

  if (userMeta.age?.isYouth) categories.push(userMetaCategories.YOUTH);
  if (userMeta.caregiver?.isAdultCaregiver) categories.push(userMetaCategories.CAREGIVER);
  if (userMeta.caregiver?.isGuardian) categories.push(userMetaCategories.GUARDIAN);

  return categories;
};

import styled from '@emotion/styled';
import { Grid, Paper } from '@mui/material';

export const InfoContainer = styled(Grid)`
  min-height: 100px;
  border-radius: 10px;
  padding: 11px 10px;
  display: flex;
  flex-flow: wrap;
  row-gap: 20px;

  .MuiGrid-root {
    align-self: center;
  }

  .MuiButton-root {
    width: 125px;
    height: 30px;
  }
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  margin-left: 20px;
`;

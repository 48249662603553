import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { checkUserPermission } from 'Tasking_Helpers/checkUserPermission';
import { TabContainer, TabDescription, TabsContainer, Container } from './styles';
import tabsData, { memberTypes } from './ActivityTabOptions';

const ActivityTabs = ({ setActionToDisplay, setCurrentTab, currentTab, taskType, thriveStatus, ngm, disabled }) => {
  const [userHasGreetingCardPermission, setUserHasGreetingCardPermission] = useState(false);

  useEffect(() => {
    checkUserCardPermissions();
  }, []);

  const tabClicked = (data) => {
    if (disabled) return;
    setCurrentTab(data);
    setActionToDisplay(data);
  };

  const checkUserCardPermissions = async () => {
    setUserHasGreetingCardPermission(await checkUserPermission('resource.cards.admin'));
  };

  const checkIfTabShouldReturnTab = (tabMemberType, tabTaskType, ngm, taskType) => {
    if (tabTaskType === 'CARD' && taskType === 'CARD') {
      return userHasGreetingCardPermission && thriveStatus?.thriveStatus;
    } else if (tabMemberType === memberTypes.NGM && ngm) {
      return tabTaskType === 'ALL' || taskType === tabTaskType;
    } else if (tabMemberType === memberTypes.ENROLLED && !ngm) {
      return tabTaskType === 'ALL' || taskType === tabTaskType;
    } else {
      return tabMemberType === memberTypes.ALL && (tabTaskType === 'ALL' || taskType === tabTaskType);
    }
  };

  const displayTabIcons = () => {
    return tabsData.TABS.map((data, index) => {
      return checkIfTabShouldReturnTab(data?.memberType, data?.taskType, ngm, taskType) ? (
        <Container
          data-testid={`IconContainer${index}`}
          key={index}
          onClick={() => tabClicked(data.type)}
          disabled={disabled}
        >
          <TabContainer clicked={data.type === currentTab} disabled={disabled}>
            <i className={data.icon} />
          </TabContainer>
          <TabDescription disabled={disabled}>{data.description}</TabDescription>
        </Container>
      ) : null;
    });
  };

  return <TabsContainer>{displayTabIcons()}</TabsContainer>;
};

ActivityTabs.propTypes = {
  setActionToDisplay: PropTypes.func,
  setCurrentTab: PropTypes.func,
  currentTab: PropTypes.string,
  taskType: PropTypes.string,
  thriveStatus: PropTypes.object,
  ngm: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ActivityTabs;

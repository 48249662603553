import React from 'react';
import { FormLabel, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

const TextInput = ({ control, name, label }) => {
  return (
    <>
      <FormLabel id={`${name}-label`}>{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        defaultValue=""
        render={({ field }) => (
          <TextField {...field} aria-labelledby={`${name}-label`} inputProps={{ 'aria-labelledby': `${name}-label` }} />
        )}
      />
    </>
  );
};

TextInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
};

export default TextInput;

import React from 'react';
import PropTypes from 'prop-types';
import { DataGridContainer, DataTooltip } from './styles';
import GridDataItem from 'Components/ResultsGrid/GridDataItem/GridDataItem';

const TooltipDataGridItem = ({ data, ...props }) => {
  const isValid = !data?.userId;

  const getTooltipMessage = () => {
    if (data?.userId) {
      return 'This record is already associated with a member.';
    }
  };

  return (
    <DataTooltip title={getTooltipMessage()} placement="bottom" arrow>
      <DataGridContainer>
        <GridDataItem {...props} disabled={!isValid} />
      </DataGridContainer>
    </DataTooltip>
  );
};

TooltipDataGridItem.propTypes = {
  data: PropTypes.object,
};

export default TooltipDataGridItem;

import styled from 'styled-components';

export const CancelButton = styled.input`
  background-color: white;
  color: #4a4f7f;
  width: 125px;
  height: 27px;
  border: 1px solid #4a4f7f;
  border-radius: 25px;
  margin: 0 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: karla;
`;

export const ModalBody = styled.div`
  height: 20%;
  padding: 20px 0 0 35px;
  font-family: karla;
  margin-bottom: 30px;
`;

export const ModalButtonsContainer = styled.div`
  display: flex;
  margin-top: 25px;
  padding-top: 15px;
  align-items: center;
  justify-content: center;
  border-top-style: solid;
  border-width: 1px;
  border-color: #eaeaea;
  height: 25%;
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  height: 250px;
`;

export const ModalContent = styled.div`
  border-radius: 24px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  width: 350px;
  height: 250px;
  overflow: hidden;
  background-color: white;
`;

export const ModalHeader = styled.div`
  display: flex;
  padding: 20px 0 0 15px;
  background-color: #058b8b;
  font-family: karla;
  font-size: 24px;
  color: white;
  height: 18%;
`;

export const SelectStyle = styled.select`
  width: 275px;
  height: 25px;
  border-radius: 9px;
  border-color: #e9e7e7;
  cursor: pointer;
`;

export const SubmitButton = styled.input`
  background-color: #4a4f7f;
  color: white;
  width: 125px;
  height: 27px;
  border: 1px solid #4a4f7f;
  border-radius: 25px;
  margin: 0 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: karla;
`;

export const SpinnerButton = styled.div`
  background-color: #4a4f7f;
  color: white;
  width: 125px;
  height: 27px;
  border: 1px solid #4a4f7f;
  border-radius: 25px;
  margin: 0 7px;
  cursor: pointer;
  font-family: karla;
`;

import styled from '@emotion/styled';
import { FormControl } from '@mui/material';

export const StyledFormControl = styled(FormControl)`
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;

  .MuiFilledInput-input {
    background-color: white;
    border-radius: 5px;
    min-width: 120px;

    :hover,
    :focus {
      background-color: white;
      border-radius: 5px;
    }
  }

  .MuiFilledInput-input {
    padding: 5px;
  }
`;

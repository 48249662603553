import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DropDownInfoContainer, GroupContainer, Heading, Text } from './styles.js';
import { Grid } from '@mui/material';
import useGetMigration from 'Tasking_Hooks/useGetMigration.js';

const MigrationHistoryItem = ({ data }) => {
  const { migration, getMigration } = useGetMigration();

  useEffect(() => {
    getMigration(data?.guid);
  }, []);

  return (
    <DropDownInfoContainer data-testid="ScreeningDropDownInfo">
      <Grid container>
        <Grid item>
          <GroupContainer>
            <Heading>Created By:</Heading>
            <Text>{migration?.createdByFullName}</Text>
          </GroupContainer>
        </Grid>
        <Grid item>
          <GroupContainer>
            <Heading>Destination Organization:</Heading>
            <Text>{migration?.destinationOrganizationName}</Text>
          </GroupContainer>
        </Grid>
        <Grid item>
          <GroupContainer>
            <Heading>Source Organization:</Heading>
            <Text>{migration?.sourceOrganizationName}</Text>
          </GroupContainer>
        </Grid>
        <Grid item>
          <GroupContainer>
            <Heading>Created On:</Heading>
            <Text>{migration?.createdOnUtc}</Text>
          </GroupContainer>
        </Grid>
        <Grid item>
          <GroupContainer>
            <Heading>Destination Group:</Heading>
            <Text>{migration?.destinationGroupName}</Text>
          </GroupContainer>
        </Grid>
        <Grid item>
          <GroupContainer>
            <Heading>Source Group:</Heading>
            <Text>{migration?.sourceGroupName}</Text>
          </GroupContainer>
        </Grid>
        <Grid item>
          <GroupContainer>
            <Heading>Migration Status:</Heading>
            <Text>{migration?.migrationStatus}</Text>
          </GroupContainer>
        </Grid>
        <Grid item>
          <GroupContainer>
            <Heading>Destination Was Eligibility File:</Heading>
            <Text>{migration?.destinationWasEligabilityFile ? 'Yes' : 'No'}</Text>
          </GroupContainer>
        </Grid>
        <Grid item>
          <GroupContainer>
            <Heading>Source Was Eligibility File:</Heading>
            <Text>{migration?.sourceWasEligabilityFile ? 'Yes' : 'No'}</Text>
          </GroupContainer>
        </Grid>
        <Grid item xs={12}>
          <GroupContainer style={{ width: '100%' }}>
            <Heading>Last Migration Attempt Error Message:</Heading>
            <Text>{migration?.lastMigrationAttemptErrorMessage}</Text>
          </GroupContainer>
        </Grid>
      </Grid>
    </DropDownInfoContainer>
  );
};

MigrationHistoryItem.propTypes = {
  data: PropTypes.shape({
    guid: PropTypes.string,
  }),
};

export default MigrationHistoryItem;

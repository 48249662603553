import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Header, StyledLoadingButton } from './styles';
import { Alert, Grid, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import { useForm } from 'react-hook-form';
import { questionSections, statusTypes } from './MemberConnectionConfig';
import MemberConnectionSection from './MemberConnectionSection';
import useCreateMemberConnection from 'Tasking_Hooks/useCreateMemberConnection';
import useUpdateMemberConnection from 'Tasking_Hooks/useUpdateMemberConnection';
import useUpdateMemberConnectionStatus from 'Tasking_Hooks/useUpdateMemberConnectionStatus';
import { formatDateEditTask } from 'Tasking_Helpers/formatDate';

const MemberConnectionForm = ({
  memberConnectionGuid,
  memberConnectionData,
  cancelButtonCallback,
  memberInfo,
  printButtonCallback,
  displayStatus,
  onCreateMemberConnectionCallback,
  ngm,
  orgId,
  ngmData,
}) => {
  const [request, setRequest] = useState({ saved: {}, pending: {} });
  const {
    response: createMemberConnectionResponse,
    hasErrored: createMemberConnectionHasErrored,
    isLoading: createMemberConnectionIsLoading,
    createMemberConnectionError,
    createMemberConnection,
  } = useCreateMemberConnection();

  const {
    isLoading: updateMemberConnectionIsLoading,
    updateMemberConnectionError,
    updateMemberConnection,
  } = useUpdateMemberConnection(memberConnectionGuid, orgId);

  const { updateMemberConnectionStatus } = useUpdateMemberConnectionStatus(memberConnectionGuid, orgId);

  useEffect(() => {
    if (!updateMemberConnectionIsLoading && memberConnectionData) {
      setIsEditing(false);
      setRequest({ pending: {}, saved: request.pending });
    }
  }, [updateMemberConnectionIsLoading]);

  useEffect(() => {
    if (createMemberConnectionResponse) {
      setIsEditing(false);
      setRequest({ pending: {}, saved: request.pending });
      if (onCreateMemberConnectionCallback) {
        onCreateMemberConnectionCallback(createMemberConnectionResponse);
      }
    }
  }, [createMemberConnectionResponse]);

  useEffect(() => {
    if (createMemberConnectionError || updateMemberConnectionError) {
      setIsEditing(true);
      setRequest({ ...request, pending: {} });
    }
  }, [updateMemberConnectionError, createMemberConnectionError]);

  const getDefaultValues = () => {
    let defaultValues = {};
    if (memberConnectionData) {
      defaultValues = memberConnectionData;
      defaultValues['memberDOB'] = formatDateEditTask(defaultValues['memberDOB']);
    } else {
      let phoneNumber = null;
      if (ngm) {
        if (memberInfo?.phoneNumberPreference === 'HEALTHPLANPROVIDEDPHONENUMBER') {
          phoneNumber = memberInfo?.normalizedPhone;
        } else {
          phoneNumber = memberInfo?.preferredPhone ?? memberInfo?.normalizedPhone;
        }
      } else {
        phoneNumber = memberInfo?.preferredPhoneNumber ?? memberInfo?.phoneNumber;
      }
      // Birthday and healthPlanId should come from the ngm table if applicable (PYX-4904)
      defaultValues = {
        memberName: `${memberInfo?.firstName} ${memberInfo?.lastName}`,
        memberDOB: ngm
          ? formatDateEditTask(memberInfo?.birthDateUtc)
          : ngmData
          ? formatDateEditTask(ngmData?.birthDateUtc)
          : formatDateEditTask(memberInfo?.birthDay),
        memberPhoneNumber: phoneNumber,
        networkName: memberInfo?.networkName,
        healthPlanId: ngm ? memberInfo?.memberId : ngmData ? ngmData.memberId : memberInfo?.healthPlanId,
        memberLanguage: memberInfo?.language,
        groupName: memberInfo?.groupName,
        isUrgent: false,
        transferStatus: 'NO',
      };
    }
    return defaultValues;
  };

  const resetForm = () => {
    const memberConnectionInfo = { ...getDefaultValues(), ...request.saved };
    setFormValues(memberConnectionInfo);
    reset(memberConnectionInfo);
  };

  const [formValues, setFormValues] = useState(getDefaultValues());
  const [isEditing, setIsEditing] = useState(memberConnectionGuid == null);

  const { handleSubmit, control, setValue, getValues, reset } = useForm();

  const onChange = (updatedFields) => {
    updatedFields.forEach((field) => {
      setValue(field.fieldName, field.value);
    });
    let values = getValues();
    setFormValues(values);
  };

  const onCancel = () => {
    setIsEditing(false);
    resetForm();

    if (cancelButtonCallback) {
      cancelButtonCallback();
    }
  };

  const onPrint = () => {
    if (printButtonCallback) {
      printButtonCallback();
    }
  };

  const onSubmit = () => {
    let memberConnectionRequest = formValues;

    if (ngm) {
      memberConnectionRequest = {
        ...memberConnectionRequest,
        NgmId: memberInfo?.id,
        OrganizationId: memberInfo?.organizationId,
      };
    } else {
      memberConnectionRequest = {
        ...memberConnectionRequest,
        UserId: memberInfo?.userId,
        NetworkId: memberInfo?.primaryNetworkId,
        GroupId: memberInfo?.groupId,
        ProfileId: memberInfo?.activeProfileId,
        OrganizationId: memberInfo?.activeOrganizationId,
      };
    }
    setRequest({ ...request, pending: memberConnectionRequest });
    if (memberConnectionGuid) {
      updateMemberConnection(memberConnectionRequest);
    } else {
      createMemberConnection(memberConnectionRequest);
    }
  };

  const onStatusChange = (e) => {
    memberConnectionData['status'] = e.target.value;
    updateMemberConnectionStatus({ Status: e.target.value });
  };

  const isLoading = createMemberConnectionIsLoading || updateMemberConnectionIsLoading;

  return (
    <>
      <form data-testid="MemberDataForm" onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '-10px' }}>
        <Grid container padding="32px 58px">
          <Grid item xs={9} sx={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
            <Grid item xs={6}>
              <Header fontSize={32} fontWeight={700}>
                Member Connection
              </Header>
            </Grid>
            <Grid item xs={6}>
              {!isEditing && memberConnectionData && displayStatus && (
                <Select
                  value={memberConnectionData['status']}
                  onChange={onStatusChange}
                  disabled={isLoading || (formValues && memberConnectionData['status'] === statusTypes.SENT)}
                >
                  <MenuItem value={statusTypes.PENDING}>Pending Approval</MenuItem>
                  <MenuItem value={statusTypes.SENT}>Sent to Healthplan</MenuItem>
                </Select>
              )}
            </Grid>
          </Grid>
          {!isEditing ? (
            <Grid item xs={3} sx={{ display: 'flex' }}>
              <StyledLoadingButton
                disabled={isLoading || (memberConnectionData && memberConnectionData['status'] === statusTypes.SENT)}
                onClick={() => setIsEditing(true)}
              >
                <EditIcon fontSize="large" />
              </StyledLoadingButton>
              {printButtonCallback && (
                <StyledLoadingButton loading={isLoading} variant="contained" fullWidth onClick={onPrint}>
                  Print to PDF
                </StyledLoadingButton>
              )}
            </Grid>
          ) : (
            <Grid item xs={3} sx={{ display: 'flex' }}>
              <StyledLoadingButton disabled={isLoading} fullWidth onClick={onCancel}>
                Cancel
              </StyledLoadingButton>
              <StyledLoadingButton type="submit" loading={isLoading} variant="contained" fullWidth>
                {memberConnectionGuid ? 'Save' : 'Submit'}
              </StyledLoadingButton>
            </Grid>
          )}
          {!isLoading && createMemberConnectionHasErrored && (
            <Grid item xs={12}>
              <Alert severity="error" variant="filled">
                {createMemberConnectionError.message}
              </Alert>
            </Grid>
          )}
          <Grid container spacing={5}>
            {questionSections.map((section, index) => (
              <MemberConnectionSection
                key={index}
                control={control}
                section={section}
                index={index}
                onUpdate={onChange}
                isDisabled={createMemberConnectionIsLoading || !isEditing}
                values={formValues}
              />
            ))}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

MemberConnectionForm.propTypes = {
  memberInfo: PropTypes.object,
  memberConnectionData: PropTypes.object,
  cancelButtonCallback: PropTypes.func.isRequired,
  setMemberConnectionGuidCallback: PropTypes.func,
  printButtonCallback: PropTypes.func,
  onCreateMemberConnectionCallback: PropTypes.func,
  memberConnectionGuid: PropTypes.string,
  displayStatus: PropTypes.bool,
  ngm: PropTypes.bool,
  orgId: PropTypes.number,
  ngmData: PropTypes.object,
};

export default MemberConnectionForm;

import { atom, getDefaultStore, useAtom } from 'jotai';
import { InformationTypes } from 'Administrator_Pages/MigrationRequests/enumerations/enums';
import { IMigrationInformationModuleStore } from 'Administrator_Pages/MigrationRequests/IMigrationInformationModuleStore';
import { OnboardingApiDataService } from 'Administrator_Pages/MigrationRequests/services/OnboardingApiDataService';

export class CodeMigrationInformationModuleStore implements IMigrationInformationModuleStore {
  private atomStore = getDefaultStore();
  private apiService: OnboardingApiDataService;
  private infoAtom;
  private loadingAtom;
  private errorAtom;
  private searchResultsAtom;
  private clearCallback: () => void;
  private updateDestinationCallback: (destinationType) => void;

  constructor() {
    this.apiService = new OnboardingApiDataService();
    this.loadingAtom = atom(false);
    this.infoAtom = atom(null);
    this.errorAtom = atom(null);
    this.searchResultsAtom = atom(null);
  }

  public get IsLoading(): boolean {
    return this.atomStore.get(this.loadingAtom);
  }

  private set IsLoading(isLoading: boolean) {
    this.atomStore.set(this.loadingAtom, isLoading);
  }

  public get Info(): any {
    return this.atomStore.get(this.infoAtom);
  }

  public get Error(): string {
    return this.atomStore.get(this.errorAtom);
  }

  private set Error(message: any) {
    this.atomStore.set(this.errorAtom, message);
  }

  public get SearchResults(): boolean {
    return this.atomStore.get(this.searchResultsAtom);
  }

  public Use = () => {
    useAtom(this.loadingAtom);
    useAtom(this.infoAtom);
    useAtom(this.errorAtom);
    useAtom(this.searchResultsAtom);

    return this;
  };

  create(clearCallback: () => void, updateDestinationCallback: (destinationType) => void): this {
    this.clearCallback = clearCallback;
    this.updateDestinationCallback = updateDestinationCallback;
    this.clear();
    return this;
  }

  cancel() {
    this.atomStore.set(this.searchResultsAtom, null);
    this.atomStore.set(this.errorAtom, null);
    this.atomStore.set(this.loadingAtom, null);

    if (!this.Info) {
      this.clearCallback();
    }
  }

  clear() {
    this.atomStore.set(this.infoAtom, null);
    this.atomStore.set(this.searchResultsAtom, null);
    this.atomStore.set(this.errorAtom, null);
    this.atomStore.set(this.loadingAtom, null);
    this.updateDestinationCallback(null);
  }

  search = async () => {
    this.IsLoading = true;
    this.Error = null;

    await this.apiService.getCodes().then((response) => {
      this.atomStore.set(this.searchResultsAtom, response?.response);
      this.Error = response?.error;
      this.IsLoading = false;
    });
  }

  async addInfo(data: any) : Promise<boolean> {
    this.IsLoading = true;
    this.atomStore.set(this.infoAtom, data);
    this.atomStore.set(this.searchResultsAtom, null);
    this.updateDestinationCallback(InformationTypes.CODE);
    this.IsLoading = false;
    return true;
  }
}

import React, { useEffect } from 'react';
import { PeersCallModuleStore } from './PeersCallModuleStore';
import Button from 'Components/Button';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { StyledButton } from './styles';
import PropTypes from 'prop-types';

function PeersCallButton({ ngmId, userId, organizationId }) {
  const peersCallStore = PeersCallModuleStore.Instance().Use();

  useEffect(() => {
    peersCallStore.initialize({ userId, ngmId, organizationId });
  }, []);

  return (
    <>
      <StyledButton
        variant={Button.variant.TEXT}
        target="_blank"
        endIcon={<OpenInNewIcon />}
        href={peersCallStore.PeersLink}
        loading={peersCallStore.IsLoading}
      >
        Call
      </StyledButton>
    </>
  );
}

PeersCallButton.propTypes = {
  ngmId: PropTypes.number,
  userId: PropTypes.number,
  organizationId: PropTypes.number,
};

export default PeersCallButton;

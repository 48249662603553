import React from 'react';
import PropTypes from 'prop-types';
import { DataGridContainer, DataGridItem, LoadingGrid } from './styles';

function GridDataItem({ loading, children, ...props }) {
  return (
    <DataGridContainer>
      {loading && <LoadingGrid />}
      <DataGridItem {...props}>{children}</DataGridItem>
    </DataGridContainer>
  );
}

GridDataItem.displayName = 'GridDataItem';

GridDataItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.element, PropTypes.node]),
  loading: PropTypes.bool.isRequired,
};

export default GridDataItem;

import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'Tasking_Helpers/formatDate';

import { RowContainer, TitleText, ContentText } from './viewOnlyStyles';

const ActivityViewOnlyText = ({ selectedActivity }) => {
  return (
    <>
      <RowContainer baseline>
        <div>
          <RowContainer>
            <div>
              <TitleText style={{ marginLeft: '80px', marginTop: '5px' }}>Creation Date</TitleText>
              <ContentText>{formatDate(selectedActivity?.createdOnUtc)} </ContentText>
            </div>
          </RowContainer>
          <RowContainer>
            <div>
              <TitleText style={{ marginLeft: '80px', marginTop: '5px' }}>Completion Date</TitleText>
              <ContentText>{formatDate(selectedActivity?.createdOnUtc)} </ContentText>
            </div>
          </RowContainer>
        </div>
        <div>
          <RowContainer>
            <div>
              <TitleText style={{ marginLeft: '80px', marginTop: '5px' }}>Contact Outcome</TitleText>
              <ContentText notes>{selectedActivity?.contactOutcome || 'Spoke to Member'}</ContentText>
            </div>
          </RowContainer>
          <RowContainer>
            <div>
              <TitleText style={{ marginLeft: '80px', marginTop: '5px' }}>Does Member Need Followup?</TitleText>
              <ContentText data-testid="ActivityViewNeedsFollowUp" notes>
                {selectedActivity?.needFollowup ? 'Yes' : 'No'}
              </ContentText>
            </div>
          </RowContainer>
        </div>
        <div>
          <RowContainer>
            <div>
              <TitleText style={{ marginLeft: '80px', marginTop: '5px' }}>Contact Actions</TitleText>
              <ContentText notes>{selectedActivity?.actions?.join('\n')}</ContentText>
            </div>
          </RowContainer>
        </div>
        <div>
          <TitleText style={{ marginLeft: '80px', marginTop: '5px' }}>Notes</TitleText>
          <ContentText notes>{selectedActivity?.notes}</ContentText>
        </div>
      </RowContainer>
    </>
  );
};

ActivityViewOnlyText.propTypes = {
  setViewActivity: PropTypes.func,
  setStartActivity: PropTypes.func,
  assignOptions: PropTypes.object,
  listOfActivities: PropTypes.object,
  setSelectedActivity: PropTypes.func,
  selectedActivity: PropTypes.object,
};

export default ActivityViewOnlyText;

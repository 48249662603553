import React, { useEffect, useState } from 'react';
import Spinner from 'Components/Spinner/Spinner';
import { useThriveState } from 'Tasking_Contexts/ThriveContext.js';
import { StyledContainer, StyledParagraph, StyledBoldParagraph } from './styles';

function ThriveStatus() {
  const [enrollmentStatus, setEnrollmentStatus] = useState();
  const [currentWeek, setCurrentWeek] = useState();
  const [isPending, setIsPending] = useState(true);
  const thriveState = useThriveState();

  useEffect(() => {
    if (thriveState?.thriveStatus != null && thriveState?.thriveProgress != undefined) {
      switch (thriveState.thriveStatus?.thriveStatus) {
        case 'ACTIVE':
          setEnrollmentStatus('Enrolled');
          setCurrentWeek(`Week ${thriveState.thriveProgress.currentThriveWeek}`);
          break;
        case 'DROPOUT':
          setEnrollmentStatus('Dropped Out');
          setCurrentWeek('-');
          break;
        case 'GRADUATE':
          setEnrollmentStatus('Completed');
          setCurrentWeek('-');
          break;
        default:
          setEnrollmentStatus('No Thrive History');
          setCurrentWeek('-');
          break;
      }

      setIsPending(false);
    }
  }, [thriveState]);

  return (
    <div>
      {isPending ? (
        <Spinner />
      ) : (
        <>
          <StyledContainer>
            <StyledBoldParagraph>Thrive Status:</StyledBoldParagraph>
            <StyledParagraph data-testid="EnrollmentStatus"> {enrollmentStatus}</StyledParagraph>
          </StyledContainer>
          <StyledContainer>
            <StyledBoldParagraph>Current Week in Pathway:</StyledBoldParagraph>
            <StyledParagraph data-testid="CurrentWeek"> {currentWeek}</StyledParagraph>
          </StyledContainer>
        </>
      )}
    </div>
  );
}

export default ThriveStatus;

import React, { useEffect } from 'react';
import {
  CancelButton,
  DataColumn,
  ErrorMessage,
  HeaderContainer,
  ProvideAssistanceContainer,
  SectionHeader,
  SelectStyle,
  SubmitButton,
  SubmitButtonContainer,
  TextHeader,
} from './styles';

import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import useAssistanceProvided from 'Tasking_Hooks/useAssistanceProvided';
import useCreateAssistanceProvided from 'Tasking_Hooks/useCreateAssistanceProvided';
import useGetAssistanceProvided from 'Tasking_Hooks/useGetAssistanceProvided';
import useEditAssistanceProvided from 'Tasking_Hooks/useEditAssistanceProvided';
import { parseErrorMessage } from 'Helpers/parseApiErrorMessage';

const assistanceProvidedOptions = [
  {
    name: 'sentMemberConnection',
    display: 'Sent Member Connection',
    isProvidedResource: false,
    isMemberWarmTransferred: false,
    isAlwaysDisplayed: true,
  },
  {
    name: 'pcpProvider',
    display: 'PCP/Provider',
    isProvidedResource: false,
    isMemberWarmTransferred: true,
    isAlwaysDisplayed: false,
  },
  {
    name: 'andy',
    display: 'Andy',
    isProvidedResource: false,
    isMemberWarmTransferred: true,
    isAlwaysDisplayed: false,
  },
  {
    name: 'communityResource',
    display: 'Community Resource',
    isProvidedResource: true,
    isMemberWarmTransferred: false,
    isAlwaysDisplayed: false,
  },
  {
    name: 'communityResourceWarm',
    display: 'Community Resource',
    isProvidedResource: false,
    isMemberWarmTransferred: true,
    isAlwaysDisplayed: false,
  },
  {
    name: 'crisisLine',
    display: 'Crisis Line',
    isProvidedResource: false,
    isMemberWarmTransferred: true,
    isAlwaysDisplayed: false,
  },
  {
    name: 'nurseLine',
    display: 'NurseLine',
    isProvidedResource: false,
    isMemberWarmTransferred: true,
    isAlwaysDisplayed: false,
  },
  {
    name: 'memberServices',
    display: 'Member Services',
    isProvidedResource: false,
    isMemberWarmTransferred: true,
    isAlwaysDisplayed: false,
  },
  {
    name: 'careManagement',
    display: 'Care Management/Coordination',
    isProvidedResource: false,
    isMemberWarmTransferred: true,
    isAlwaysDisplayed: false,
  },
  {
    name: 'otherHealthPlanResource',
    display: 'Health Plan Resource',
    isProvidedResource: true,
    isMemberWarmTransferred: false,
    isAlwaysDisplayed: false,
  },
  {
    name: 'otherHealthPlanResourceWarm',
    display: 'Other Health Plan Resource',
    isProvidedResource: false,
    isMemberWarmTransferred: true,
    isAlwaysDisplayed: false,
  },
];

const ProvideAssistance = ({
  actions,
  setActions,
  setActionToDisplay,
  setCurrentTab,
  memberInfo,
  values,
  setValues,
}) => {
  const defaultValues = {
    organizationId: memberInfo?.activeOrganizationId,
    profileId: memberInfo?.activeProfileId,
    typeOfAssistanceProvided: '',
    communityResource: false,
    communityResourceWarm: false,
    pcpProvider: false,
    andy: false,
    crisisLine: false,
    nurseLine: false,
    memberServices: false,
    careManagement: false,
    otherHealthPlanResource: false,
    otherHealthPlanResourceWarm: false,
    sentMemberConnection: false,
  };

  const typeOfAssistanceProvided = {
    PROVIDEDRESOURCES: 'PROVIDEDRESOURCES',
    MEMBERWARMTRANSFERRED: 'MEMBERWARMTRANSFERRED',
    MEMBERDECLINEDWARMTRANSFER: 'MEMBERDECLINEDWARMTRANSFER',
  };

  const { register, handleSubmit } = useForm({
    shouldUnregister: false,
  });
  const { isPending, assistanceProvided } = useAssistanceProvided();
  const {
    assistanceProvidedGuid,
    createError,
    isCreatePending,
    assistanceProvidedErrorMessage,
    createAssistanceProvided,
  } = useCreateAssistanceProvided();
  const { existingAssistanceProvided, isGetPending, getAssistanceProvided, getAssistanceProvidedErrorMessage } =
    useGetAssistanceProvided();
  const { editAssistanceProvided, editResponse } = useEditAssistanceProvided();

  const updateValue = (name, value) => {
    setValues({ ...values, provideAssistance: { ...values?.provideAssistance, [name]: value } });
  };

  const displayRow = (array, addMargin) => {
    if (array) {
      return (
        <div style={{ display: 'flex', paddingTop: addMargin ? '20px' : '0' }}>
          {array.map((data, index) => {
            return (
              <div
                key={`div${values?.provideAssistance?.typeOfAssistanceProvided}${data.name}${index}`}
                style={{ width: '240px', marginRight: '44px' }}
              >
                <input
                  type="checkbox"
                  data-testid={`${values?.provideAssistance?.typeOfAssistanceProvided}${data.name}Input`}
                  id={`${values?.provideAssistance?.typeOfAssistanceProvided}${data.name}${index}`}
                  name={`${values?.provideAssistance?.typeOfAssistanceProvided}${data.name}${index}`}
                  onClick={() => {
                    updateValue(data.name, !values?.provideAssistance?.[data.name]);
                  }}
                  defaultChecked={values?.provideAssistance?.[data.name]}
                />
                <label htmlFor={`${values?.provideAssistance?.typeOfAssistanceProvided}${data.name}${index}`}>
                  <span style={{ marginLeft: '5px', color: '#0F145B' }}>
                    {data.display} <br />
                  </span>
                </label>
              </div>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  };

  const setExistingValues = () => {
    let resetValues = {
      ...defaultValues,
      ...existingAssistanceProvided,
    };

    if (resetValues.typeOfAssistanceProvided === typeOfAssistanceProvided.MEMBERWARMTRANSFERRED) {
      resetValues = {
        ...resetValues,
        communityResourceWarm: resetValues.communityResource,
        otherHealthPlanResourceWarm: resetValues.otherHealthPlanResource,
      };
    }

    setValues({ ...values, provideAssistance: { ...values?.provideAssistance, ...resetValues, init: false } });
  };

  useEffect(() => {
    if (!isPending && memberInfo && memberInfo.activeOrganizationId && actions && actions.assistanceProvidedGuid) {
      getAssistanceProvided(memberInfo?.activeOrganizationId, actions?.assistanceProvidedGuid);
    }
  }, [isPending]);

  useEffect(() => {
    if (existingAssistanceProvided && !(values?.provideAssistance || values?.provideAssistance?.init)) {
      setExistingValues();
    }
  }, [isGetPending]);

  useEffect(() => {
    if (assistanceProvided && !isPending && (!values?.provideAssistance || values?.provideAssistance?.initDropdown)) {
      if (existingAssistanceProvided) {
        updateValue('typeOfAssistanceProvided', existingAssistanceProvided.typeOfAssistanceProvided);
      } else if (!values?.provideAssistance?.typeOfAssistanceProvided) {
        updateValue('typeOfAssistanceProvided', assistanceProvided.TypeOfAssistanceProvided[0].code);
      }
      if (values?.provideAssistance?.initDropdown) {
        updateValue('initDropdown', false);
      }
    }
  }, [assistanceProvided, isGetPending]);

  useEffect(() => {
    if (!createError && !isCreatePending && assistanceProvidedGuid) {
      setActions({ ...actions, isActive: true, assistanceProvidedGuid: assistanceProvidedGuid });
      setActionToDisplay('');
      setCurrentTab('');
    }
  }, [isCreatePending]);

  useEffect(() => {
    if (editResponse !== null) {
      setActions({ ...actions, isActive: true, assistanceProvidedGuid: actions.assistanceProvidedGuid });
      setActionToDisplay('');
      setCurrentTab('');
    }
  }, [editResponse]);

  const onSubmit = () => {
    let assistanceProvidedData = {
      ...defaultValues,
      ...values?.provideAssistance,
    };

    assistanceProvidedData = {
      ...assistanceProvidedData,
      otherHealthPlanResource:
        values?.provideAssistance?.typeOfAssistanceProvided === typeOfAssistanceProvided.PROVIDEDRESOURCES
          ? values?.provideAssistance?.otherHealthPlanResource //getValues('otherHealthPlanResource')
          : values?.provideAssistance?.otherHealthPlanResourceWarm, //getValues('otherHealthPlanResourceWarm'),
      communityResource:
        values?.provideAssistance?.typeOfAssistanceProvided === typeOfAssistanceProvided.PROVIDEDRESOURCES
          ? values?.provideAssistance?.communityResource //getValues('communityResource')
          : values?.provideAssistance?.communityResourceWarm, //getValues('communityResourceWarm'),
    };

    delete assistanceProvidedData.otherHealthPlanResourceWarm;
    delete assistanceProvidedData.communityResourceWarm;

    if (actions && actions.assistanceProvidedGuid) {
      editAssistanceProvided(
        memberInfo?.activeOrganizationId,
        actions.assistanceProvidedGuid,
        assistanceProvidedData,
      ).then(() => {
        updateValue('init', true);
        updateValue('initDropdown', true);
      });
    } else {
      createAssistanceProvided(assistanceProvidedData).then(() => {
        updateValue('init', true);
        updateValue('initDropdown', true);
      });
    }
  };

  const cancelSelected = (e) => {
    e.preventDefault();
    if (existingAssistanceProvided) {
      let resetValues = {
        ...defaultValues,
        ...existingAssistanceProvided,
      };

      if (resetValues.typeOfAssistanceProvided === typeOfAssistanceProvided.MEMBERWARMTRANSFERRED) {
        resetValues = {
          ...resetValues,
          communityResourceWarm: resetValues.communityResource,
          otherHealthPlanResourceWarm: resetValues.otherHealthPlanResource,
        };
      }

      setValues({
        ...values,
        currentTab: '',
        actionToDisplay: '',
        provideAssistance: { ...values?.provideAssistance, ...resetValues, init: true, initDropdown: true },
      });
    } else {
      setValues({ ...values, currentTab: '', actionToDisplay: '', provideAssistance: undefined });
    }
    setActionToDisplay('');
    setCurrentTab('');
  };

  const displayButtons = () => {
    return (
      <SubmitButtonContainer>
        <SubmitButton data-testid="SubmitButton" name={'submit'} value={'Complete'} type="submit" />
        <CancelButton onClick={cancelSelected} type="reset" data-testid="CancelButton">
          Cancel
        </CancelButton>
      </SubmitButtonContainer>
    );
  };

  const displayAssistanceProvided = () => {
    let providedOptions = assistanceProvidedOptions.filter(
      (item) =>
        item.isAlwaysDisplayed ||
        (item.isProvidedResource &&
          values?.provideAssistance?.typeOfAssistanceProvided === typeOfAssistanceProvided.PROVIDEDRESOURCES) ||
        (item.isMemberWarmTransferred &&
          values?.provideAssistance?.typeOfAssistanceProvided === typeOfAssistanceProvided.MEMBERWARMTRANSFERRED),
    );

    const row1 = providedOptions.slice(0, 4);
    const row2 = providedOptions.length > 4 ? providedOptions.slice(4) : undefined;

    return (
      <DataColumn>
        <div>
          <SectionHeader>Assistance Provided:</SectionHeader>
          {displayRow(row1)}
          {displayRow(row2, true)}
        </div>
      </DataColumn>
    );
  };

  const parseError = (errorMessage) => {
    if (errorMessage.length > 0) {
      return parseErrorMessage(errorMessage);
    }
  };

  const onAssistanceChange = (data) => {
    if (data !== values?.provideAssistance?.typeOfAssistanceProvided) {
      setValues({
        ...values,
        provideAssistance: {
          ...values?.provideAssistance,
          ...defaultValues,
          typeOfAssistanceProvided: data,
        },
      });
    }
  };

  return (
    <>
      <HeaderContainer>
        <TextHeader>Assistance Provided</TextHeader>
      </HeaderContainer>

      <ProvideAssistanceContainer data-testid="ProvideAssistanceContainer">
        <form data-testid="TaskForm" onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '-10px' }}>
          <input
            {...register('profileId', { setValueAs: (value) => parseInt(value, 10) })}
            type="hidden"
            id="profileId"
            value={memberInfo?.activeProfileId}
          />
          <input
            {...register('organizationId', { setValueAs: (value) => parseInt(value, 10) })}
            type="hidden"
            id="organizationId"
            value={memberInfo?.activeOrganizationId}
          />
          <DataColumn top>
            <div>
              <SectionHeader language>Type of Assistance Provided:</SectionHeader>
              <SelectStyle
                {...register('typeOfAssistanceProvided')}
                assistance
                type="text"
                id="typeOfAssistanceProvided"
                data-testid="AssistanceInput"
                onChange={(e) => {
                  onAssistanceChange(e.target.value);
                }}
                value={values?.provideAssistance?.typeOfAssistanceProvided}
              >
                {assistanceProvided ? (
                  assistanceProvided.TypeOfAssistanceProvided.map((data) => {
                    return (
                      <option id={data.code} key={data.code} value={`${data.code}`}>{`${data.displayName}`}</option>
                    );
                  })
                ) : (
                  <option data-testid="AssistanceProvidedSubType" value={`Loading`}>{`Loading`}</option>
                )}
              </SelectStyle>
            </div>
          </DataColumn>

          {displayAssistanceProvided()}
          <DataColumn>
            <div>
              <div style={{ marginTop: '20px' }} />
            </div>
          </DataColumn>
          {displayButtons()}
          <ErrorMessage data-testid="ErrorMessage">
            {assistanceProvidedErrorMessage
              ? parseError(assistanceProvidedErrorMessage)
              : getAssistanceProvidedErrorMessage
              ? parseError(getAssistanceProvidedErrorMessage)
              : ''}
          </ErrorMessage>
        </form>
      </ProvideAssistanceContainer>
    </>
  );
};

ProvideAssistance.propTypes = {
  actions: PropTypes.object,
  setActionToDisplay: PropTypes.func,
  setCurrentTab: PropTypes.func,
  setActions: PropTypes.func,
  memberInfo: PropTypes.object,
  values: PropTypes.object,
  setValues: PropTypes.func,
};

export default ProvideAssistance;

import { Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { UncheckedRadio, CheckedRadio } from './styles';
import { Controller } from 'react-hook-form';
import { fieldTypes } from './MemberConnectionConfig';
import MemberConnectionsRadioGroup from '../MemberConnectionsRadioGroup/MemberConnectionsRadioGroup';
import MemberConnectionsCheckbox from '../MemberConnectionsCheckbox/MemberConnectionsCheckbox';

export default function MemberConnectionField({ formField, control, onUpdate, isDisabled, values }) {
  const {
    title,
    fieldName,
    dependencies,
    gridSize,
    fieldType,
    options,
    row,
    label,
    dependants,
    maxLength,
    editDisabled,
  } = formField;

  let defaultValue = '';
  if (fieldType === fieldTypes.CHECKBOX) {
    defaultValue = values ? values[fieldName] ?? false : false;
  } else {
    defaultValue = values ? values[fieldName] ?? '' : '';
  }

  // Currently this dependency check logic is designed only support checkbox dependencies
  const areDependenciesChecked = dependencies
    ? !values || Object.keys(dependencies).filter((x) => values[x])?.length > 0
    : true;
  const disabled = isDisabled || editDisabled || (dependencies && !areDependenciesChecked);
  const isChecked = fieldType !== fieldTypes.CHECKBOX || (values && !!values[fieldName]);

  return (
    <Grid item xs={gridSize}>
      {title && (
        <Typography fontSize={14} padding={'0px 0px 5px'}>
          {title}
        </Typography>
      )}
      <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, ...field }, ...fields }) => {
          const onAnswerChange = (e) => {
            const value = fieldType === fieldTypes.CHECKBOX ? e : e.target.value;
            onChange(e);
            const fieldsToUpdate = [{ fieldName, value }];
            if (fieldType === fieldTypes.CHECKBOX && !value && dependants) {
              dependants.forEach((d) => fieldsToUpdate.push({ fieldName: d, value: false }));
            }
            onUpdate(fieldsToUpdate);
          };
          return fieldType === fieldTypes.FREETEXT ? (
            <TextField
              {...field}
              {...fields}
              onChange={onAnswerChange}
              type="text"
              fullWidth
              disabled={disabled}
              inputProps={{
                maxLength: maxLength,
              }}
            />
          ) : fieldType === fieldTypes.DATE ? (
            <TextField {...field} {...fields} onChange={onAnswerChange} type="date" fullWidth disabled={disabled} />
          ) : fieldType === fieldTypes.CHECKBOX ? (
            <MemberConnectionsCheckbox
              {...field}
              {...fields}
              onChange={onAnswerChange}
              disabled={disabled}
              label={label}
              dependencies={dependencies}
              isChecked={isChecked}
            />
          ) : (
            <MemberConnectionsRadioGroup
              {...field}
              {...fields}
              onChange={onAnswerChange}
              options={options}
              disabled={disabled}
              row={row}
              icon={<UncheckedRadio disabled={false} />}
              checkedIcon={<CheckedRadio disabled={false} />}
            />
          );
        }}
      />
    </Grid>
  );
}

MemberConnectionField.propTypes = {
  control: PropTypes.object,
  formField: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  values: PropTypes.object,
};

import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import MemberConnectionHeader from 'Assets/images/member_connection_header.svg';
import MemberConnectionFooter from 'Assets/images/footer_member_connection.svg';
import { BodyContainer, FooterContainer, PrintContainer, PrintGridBody, PrintImage, PrintSection } from './styles';
import { Grid, Typography } from '@mui/material';
import { formatDate } from 'Tasking_Helpers/formatDate';
import { getMemberConnectionPrintConfig } from './MemberConnectionPrintConfig';
import PrintableInput from './InputType/PrintableInput';
import useAxiosSwrFetch from 'Tasking_Hooks/useAxiosSwrFetch';
import urlcat from 'urlcat';

const requestPath = '/v1/memberconnections/:orgId::memberConnectionGuid';

const PrintableMemberConnections = forwardRef(({ memberConnectionGuid, orgId }, ref) => {
  const { response: memberConnectionData, isLoading: isGetPending, fetch } = useAxiosSwrFetch({ fetchRequired: false });

  useEffect(() => {
    if (memberConnectionGuid && orgId) {
      fetch(urlcat(process.env.API_BASE_URL, requestPath, { orgId: orgId, memberConnectionGuid }));
    }
  }, []);
  const { sections, printGridProps } = getMemberConnectionPrintConfig(memberConnectionData);

  return (
    <>
      {!isGetPending && memberConnectionData && (
        <PrintContainer ref={ref}>
          <PrintImage src={MemberConnectionHeader} />
          <BodyContainer>
            <Typography fontSize={16}>
              <b>Member Connection:</b> {formatDate(memberConnectionData?.createdOnUtc)}
            </Typography>
            <PrintGridBody container {...printGridProps}>
              {sections.map(({ title, items, gridSize, containerWidth, sectionProps }, key) => {
                return title ? (
                  <PrintSection
                    container
                    key={key}
                    xs={gridSize ?? 12}
                    columnSpacing={3}
                    containerWidth={containerWidth}
                    className={`PrintSection PrintSection-${title?.replace(/\s|\/|\(|\)/g, '')}`}
                    {...sectionProps}
                  >
                    <Typography marginBottom={'7px'} width={'100%'} fontWeight={700} fontSize={14}>
                      {title}
                    </Typography>
                    {items.map((item, itemKey) => (
                      <PrintableInput key={itemKey} {...item} />
                    ))}
                  </PrintSection>
                ) : (
                  <Grid key={key} item xs={gridSize} />
                );
              })}
            </PrintGridBody>
          </BodyContainer>
          <FooterContainer>
            <PrintImage src={MemberConnectionFooter} style={{ width: '50%', marginLeft: '5%' }} />
          </FooterContainer>
        </PrintContainer>
      )}
    </>
  );
});

PrintableMemberConnections.displayName = 'PrintableMemberConnections';

PrintableMemberConnections.propTypes = {
  memberConnectionGuid: PropTypes.string,
  orgId: PropTypes.number,
};

export default PrintableMemberConnections;

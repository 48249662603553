import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

const getAssistanceProvidedUrl = 'v5/AssistanceProvided';
const options = {
  revalidateOnFocus: false,
};

const getAssistanceProvidedInternal = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useGetAssistanceProvided = () => {
  const [existingAssistanceProvided, setExistingAssistanceProvided] = useState();
  const [url, setUrl] = useState();
  const [isGetPending, setIsGetPending] = useState(true);
  const {
    data: assistanceProvidedResult,
    errorData,
    error: getError,
    mutate,
  } = useSWR(url, getAssistanceProvidedInternal, options);

  const getAssistanceProvided = (organizationId, assistanceProvidedGuid) => {
    setUrl(`${process.env.API_BASE_URL}/${getAssistanceProvidedUrl}/${organizationId}:${assistanceProvidedGuid}`);
    mutate(assistanceProvidedResult);
  };

  useEffect(() => {
    if (assistanceProvidedResult) {
      setExistingAssistanceProvided(assistanceProvidedResult.data);
    }

    setIsGetPending(!assistanceProvidedResult && !getError);
  }, [assistanceProvidedResult, getError]);

  return {
    existingAssistanceProvided,
    getError,
    getAssistanceProvidedErrorMessage: errorData,
    isGetPending,
    getAssistanceProvided,
  };
};

export default useGetAssistanceProvided;

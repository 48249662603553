import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';

const useThriveUpdate = () => {
  const [updateThriveData, setUpdateThriveData] = useState();
  const [url, setUrl] = useState();
  const { response, isLoading, hasErrored, errorData } = useAxiosSubmit(url, updateThriveData, 'put');

  const updateThriveUser = async (request) => {
    setUrl(`${process.env.API_BASE_URL}/v1/thrive/access/${request.userId}`);
    setUpdateThriveData(request);
  };

  return {
    updateResponse: response,
    updateError: hasErrored,
    isUpdatePending: isLoading,
    updateErrorMessage: errorData,
    updateThriveUser,
  };
};

export default useThriveUpdate;

import { atom, getDefaultStore, useAtom } from 'jotai';
import { IScreeningTakerModuleStore } from 'Tasking_Components/Screenings/IScreeningTakerModuleStore';
import { questions } from './config';

export class AdultNeedsScreeningModuleStore implements IScreeningTakerModuleStore {
  private atomStore = getDefaultStore();
  private answersAtom;
  private formValidAtom;
  private loadingAtom;
  private completeCallback: (answers) => Promise<void>;

  constructor() {
    this.answersAtom = atom(this.createDefaultAnswers());
    this.formValidAtom = atom(false);
    this.loadingAtom = atom(false);
  }

  private get Answers(): { [key: string]: any } {
    return this.atomStore.get(this.answersAtom);
  }

  public get IsValid(): boolean {
    return this.atomStore.get(this.formValidAtom);
  }

  public get IsLoading(): boolean {
    return this.atomStore.get(this.loadingAtom);
  }

  private set IsLoading(isLoading: boolean) {
    this.atomStore.set(this.loadingAtom, isLoading);
  }

  private createDefaultAnswers() {
    const defaultQuestions = {};
    questions.map((x) => (defaultQuestions[x.name] = undefined));
    return defaultQuestions;
  }

  private formatAnswer(answer) {
    const ans = { answerOptionGuid: answer, value: undefined };

    if (answer instanceof Object) {
      ans.answerOptionGuid = answer.name;
      ans.value = answer.value;
    }

    return ans;
  }

  public Use = () => {
    useAtom(this.answersAtom);
    useAtom(this.formValidAtom);
    useAtom(this.loadingAtom);

    return this;
  };

  create(completeCallback: (answers) => Promise<void>): this {
    this.completeCallback = completeCallback;
    return this;
  }

  updateAnswer(question, answer) {
    const currentAnswers = { ...this.Answers };
    currentAnswers[question] = answer;
    this.atomStore.set(this.answersAtom, currentAnswers);
    this.validate();
  }

  validate() {
    const missingAnswers = Object.keys(this.Answers).filter((x) => !this.Answers[x]);
    const isValid = missingAnswers.length === 0;
    this.atomStore.set(this.formValidAtom, isValid);
  }

  async complete() {
    this.IsLoading = true;

    this.validate();

    if (this.IsValid) {
      const answers = Object.keys(this.Answers).flatMap((x) => {
        if (this.Answers[x] instanceof Array) {
          return this.Answers[x].map((y) => this.formatAnswer(y));
        }

        return this.formatAnswer(this.Answers[x]);
      });

      this.completeCallback(answers).then(() => (this.IsLoading = false));
    } else {
      this.IsLoading = false;
    }
  }
}

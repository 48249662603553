import React, { useContext, useEffect, useState } from 'react';
import MemberData from 'Tasking_Components/MemberData/MemberData';
import MemberInfoTabs from 'Tasking_Components/MemberInfoTabs/MemberInfoTabs';
import MemberInfoData from 'Tasking_Components/MemberInfoData/MemberInfoData';
import MemberTaskData from 'Tasking_Components/MemberTaskData/MemberTaskData';
import MemberHistoryData from 'Tasking_Components/MemberHistoryData/MemberHistoryData';
import useMemberInfo from 'Tasking_Hooks/useMemberInfo';
import { MainIconBarContext } from 'Tasking_Contexts/MainIconBarContext';
import { Route, Routes, useParams, useLocation } from 'react-router-dom';
import withAppInsights from 'Helpers/appInsights';
import Thrive from 'Tasking_Components/Thrive/Thrive';
import MemberConnectionHistory from 'Tasking_Components/MemberConnections/MemberConnectionHistory';
import Redetermination from 'Tasking_Components/Redetermination/Redetermination';
import LoadingWrapper from 'Components/LoadingWrapper/LoadingWrapper';

const MemberInfo = () => {
  const location = useLocation();
  const { userId } = useParams();
  const [display, setDisplay] = useState('user');
  const { memberInfo } = useMemberInfo(userId);
  const { showMainIcons } = useContext(MainIconBarContext);

  useEffect(() => {
    if (location?.state?.showTaskScreen || location?.pathname?.includes('tasks')) {
      setDisplay('tasks');
    }
    if (
      location?.pathname?.substring(location?.pathname?.length - userId?.length, location?.pathname?.length) ===
      userId?.toString()
    ) {
      setDisplay('user');
    }
    if (location?.pathname?.includes('redetermination')) {
      setDisplay('redetermination');
    }
    if (location?.pathname?.includes('history')) {
      setDisplay('history');
    }
    if (location?.pathname?.includes('thrive')) {
      setDisplay('thrive');
    }
    if (location?.pathname?.includes('memberconnection')) {
      setDisplay('memberconnection');
    }
  }, [location?.pathname]);

  return (
    <div data-testid="MemberInfo">
      <MemberData memberInfo={memberInfo} ngm={false} />
      {showMainIcons && <MemberInfoTabs memberInfo={memberInfo} currentTab={display} />}
      <LoadingWrapper loading={!memberInfo}>
        <Routes>
          <Route path="tasks/*" element={<MemberTaskData memberInfo={memberInfo} />} />
          <Route path="redetermination" element={<Redetermination memberInfo={memberInfo} />} />
          <Route path="history" element={<MemberHistoryData memberInfo={memberInfo} />} />
          <Route path="thrive/*" element={<Thrive memberInfo={memberInfo} />} />
          <Route
            path="memberconnection/:memberConnectionGuid"
            element={<MemberConnectionHistory memberInfo={memberInfo} />}
          />
          <Route index element={<MemberInfoData memberInfo={memberInfo} />} />
        </Routes>
      </LoadingWrapper>
    </div>
  );
};

export default withAppInsights(MemberInfo);

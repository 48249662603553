import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

const assistanceProvidedUrl = '/v5/AssistanceProvided/enums';
const options = {
  revalidateOnFocus: false,
};

const getMemberInfo = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useAssistanceProvided = () => {
  const [isPending, setIsPending] = useState(true);
  const [assistanceProvided, setAssistanceProvided] = useState();
  const { data: assistanceProvidedResult, error } = useSWR(
    () => `${process.env.API_BASE_URL}${assistanceProvidedUrl}`,
    getMemberInfo,
    {
      ...options,
    },
  );

  useEffect(() => {
    if (assistanceProvidedResult) {
      setAssistanceProvided(assistanceProvidedResult.data);
    }

    setIsPending(!assistanceProvidedResult && !error);
  }, [assistanceProvidedResult, error]);

  return { assistanceProvided, error, isPending };
};

export default useAssistanceProvided;

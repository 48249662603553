import React, { useState } from 'react';
import { Paper, Tabs, Tab } from '@mui/material';
import { StyledTabContainer } from 'Tasking_Components/Thrive/ThriveMetrics/styles';
import BadgingAndProgress from './BadgingAndProgress/BadgingAndProgress';
import GreetingCards from './GreetingCards/GreetingCards';
import PositivePsychologyContent from './PositivePsychologyContent/PositivePsychologyContent';
import PyxPets from './PyxPets/PyxPets';
import ScreeningScore from './ScreeningScore/ScreeningScore';

const tabsConfig = {
  TABS: [
    { index: 0, label: 'Badging & Progress', content: BadgingAndProgress },
    { index: 1, label: 'Screening Score', content: ScreeningScore },
    { index: 2, label: 'Pyx Pets', content: PyxPets },
    { index: 3, label: 'Greeting Cards', content: GreetingCards },
    { index: 4, label: 'Positive Psychology Content', content: PositivePsychologyContent },
  ],
};

const ThriveMetrics = () => {
  const [currentTab, setCurrentTab] = useState(0);

  function handleChange(e, newTab) {
    setCurrentTab(newTab);
  }

  return (
    <Paper elevation={2}>
      <Tabs
        value={currentTab}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="Thrive Metrics tabs"
      >
        {tabsConfig.TABS.map((tab) => (
          <Tab key={tab.index} label={tab.label} />
        ))}
      </Tabs>
      <StyledTabContainer>{React.createElement(tabsConfig.TABS[currentTab].content)}</StyledTabContainer>
    </Paper>
  );
};

export default ThriveMetrics;

import { AuthService } from './AuthService';

export class Auth {
  static authService;

  static async buildAuthInstance() {
    let authService = new AuthService();
    await authService.init();
    return authService;
  }

  static get Instance() {
    return this.authService
      ? this.authService
      : this.buildAuthInstance().then((service) => {
          this.authService = service;
          return this.authService;
        });
  }
}

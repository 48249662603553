import styled from 'styled-components';
import { FormControl, FormControlLabel, RadioGroup } from '@mui/material';

export const StyledFormControl = styled(FormControl)`
  margin-left: 25px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

export const StyledFormLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledRadioGroup = styled(RadioGroup)`
  flex-wrap: nowrap;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  font-size: 0.875rem;
`;

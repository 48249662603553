import { Auth } from './authentication/AuthServiceWrapper';

export const useAuthentication = () => {
  const getAccessToken = async () => {
    const authService = await Auth.Instance;
    let accessToken = authService.getAccessToken();
    return accessToken;
  };

  const logout = async () => {
    const authService = await Auth.Instance;
    await authService.completeLogout();
  };

  return {
    getAccessToken,
    logout,
  };
};

import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { purple } from 'Styles/colors';

export const Header = styled(Typography)`
  color: #484d87;
`;

export const NumberAvatar = styled.div`
  width: 40px;
  height: 40px;
  background-color: #e9e7e7;
  border-radius: 100%;
  color: #605f76;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;

  p {
    position: absolute;
  }
`;

export const StyledLoadingButton = styled(LoadingButton)`
  height: 48px;

  &.MuiLoadingButton-loading {
    background-color: ${purple._900};

    .MuiLoadingButton-loadingIndicator {
      color: #fff;
    }
  }
`;

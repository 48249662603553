import { atom, getDefaultStore, useAtom } from 'jotai';
import { InformationTypes } from 'Administrator_Pages/MigrationRequests/enumerations/enums';
import { IMigrationInformationModuleStore } from 'Administrator_Pages/MigrationRequests/IMigrationInformationModuleStore';
import { calculateAge } from 'Util/dateFunctions';

export class HiFriendMigrationInformationModuleStore implements IMigrationInformationModuleStore {
  private atomStore = getDefaultStore();
  private infoAtom;
  private loadingAtom;
  private errorAtom;
  private searchResultsAtom;
  private clearCallback: () => void;
  private updateDestinationCallback: (destinationType) => void;

  constructor() {
    this.loadingAtom = atom(false);
    this.infoAtom = atom(null);
    this.errorAtom = atom(null);
    this.searchResultsAtom = atom(null);
  }

  public get IsLoading(): boolean {
    return this.atomStore.get(this.loadingAtom);
  }

  private set IsLoading(isLoading: boolean) {
    this.atomStore.set(this.loadingAtom, isLoading);
  }

  public get Info(): any {
    return this.atomStore.get(this.infoAtom);
  }

  public get Error(): string {
    return this.atomStore.get(this.errorAtom);
  }

  private set Error(message: any) {
    this.atomStore.set(this.errorAtom, message);
  }

  public get SearchResults(): boolean {
    return this.atomStore.get(this.searchResultsAtom);
  }

  public Use = () => {
    useAtom(this.loadingAtom);
    useAtom(this.infoAtom);
    useAtom(this.errorAtom);
    useAtom(this.searchResultsAtom);

    return this;
  };

  create(clearCallback: () => void, updateDestinationCallback: (destinationType) => void): this {
    this.clearCallback = clearCallback;
    this.updateDestinationCallback = updateDestinationCallback;
    this.clear();
    return this;
  }

  private isHiFriendYouth = (dob: string) => {
    const age = calculateAge(dob);
    return age >= 13 && age < 18;
  };

  cancel() {
    this.atomStore.set(this.searchResultsAtom, null);
    this.atomStore.set(this.errorAtom, null);
    this.atomStore.set(this.loadingAtom, null);

    if (!this.Info) {
      this.clearCallback();
    }
  }

  clear() {
    this.atomStore.set(this.infoAtom, null);
    this.atomStore.set(this.searchResultsAtom, null);
    this.atomStore.set(this.errorAtom, null);
    this.atomStore.set(this.loadingAtom, null);
    this.updateDestinationCallback(null);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  search = async () => {};

  async addInfo(birthDay: string): Promise<boolean> {
    this.IsLoading = true;
    this.atomStore.set(this.infoAtom, {
      organizationId: 2001,
      groupId: this.isHiFriendYouth(birthDay) ? 2003 : 2001,
    });
    this.atomStore.set(this.searchResultsAtom, null);
    this.updateDestinationCallback(InformationTypes.HIFRIEND);
    this.IsLoading = false;
    return true;
  }
}

import React, { useState } from 'react';
import { ScreeningModuleStore } from 'Tasking_Components/Screenings/ScreeningModuleStore';
import { Controller, useForm } from 'react-hook-form';
import { questions } from '../config';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import {
  ErrorMessage,
  StyledButton,
  StyledButtonContainer,
  StyledDialog,
  StyledForm,
  StyledRadioGroup,
} from './styles';
import { parseErrorMessage } from 'Helpers/parseApiErrorMessage';

function AdultPHQ4() {
  const screeningStore = ScreeningModuleStore.Instance().Use();
  const screeningTakerStore = screeningStore.ScreeningForm.Use();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const { control, handleSubmit } = useForm({ defaultValues: screeningTakerStore.Answers });
  const hasError = screeningStore.Error?.hasError;
  const errorMessage = screeningStore.Error?.error;

  const onAnswer = (question, answer) => screeningTakerStore.updateAnswer(question, answer);
  const onCompleteScreening = async () => {
    onCloseDialog();
    await screeningStore.startScreening().then(() => screeningTakerStore.complete());
  };
  const onFinish = () => setConfirmDialogOpen(true);
  const onCloseDialog = () => setConfirmDialogOpen(false);

  const formComplete = screeningTakerStore.IsValid;
  const isLoading = screeningTakerStore.IsLoading || screeningStore.IsLoading;

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onFinish)}>
        {questions.map(({ text: questionText, name: questionName, options }, questionIndex) => (
          <StyledRadioGroup key={questionIndex}>
            <FormLabel id={`radiogroup-label-${questionIndex}`}>{questionText}</FormLabel>
            <Controller
              control={control}
              name={questionName}
              defaultValue={''}
              render={({ field: { value, onChange, ...field } }) => (
                <RadioGroup
                  {...field}
                  aria-labelledby={`radiogroup-label-${questionIndex}`}
                  name={`radiogroup-${questionIndex}`}
                  value={value}
                  onChange={(e) => {
                    onAnswer(questionName, e?.target?.value);
                    onChange(e);
                  }}
                >
                  {options.map(({ text, name }, index) => (
                    <FormControlLabel key={index} name={name} value={name} control={<Radio />} label={text} />
                  ))}
                </RadioGroup>
              )}
            />
          </StyledRadioGroup>
        ))}
        <ErrorMessage>{hasError ? parseErrorMessage(errorMessage) : ''}</ErrorMessage>
      </StyledForm>
      <StyledButtonContainer>
        <StyledButton onClick={onFinish} disabled={!formComplete} loading={isLoading} elevation={0}>
          Complete
        </StyledButton>
        <StyledButton onClick={screeningStore.cancel} loading={isLoading} variant="outlined">
          Cancel
        </StyledButton>
      </StyledButtonContainer>
      <StyledDialog open={confirmDialogOpen} onClose={onCloseDialog}>
        <DialogTitle>Save Screening?</DialogTitle>
        <DialogContent dividers>
          <Typography>
            <strong>Warning!</strong> Once you save this screening, you will not be able to edit it afterwards.
          </Typography>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={onCloseDialog} loading={isLoading} variant="outlined">
            Cancel
          </StyledButton>
          <StyledButton onClick={onCompleteScreening} disabled={!formComplete} loading={isLoading} elevation={0}>
            Save
          </StyledButton>
        </DialogActions>
      </StyledDialog>
    </>
  );
}

export default AdultPHQ4;

import React from 'react';
import PropTypes from 'prop-types';
import { GroupContainer, Heading, Text } from '../../../ScreeningHistoryItem/styles.js';
import { questions } from '../config.js';

const YouthNeeds = ({ answers, scoreDetail }) => {
  return (
    <>
      <div>
        <GroupContainer>
          <Heading>Type</Heading>
          <Text data-testid={'ScreeningName'}>Youth Needs</Text>
        </GroupContainer>

        <GroupContainer>
          <Heading>Score</Heading>
          <Text>{scoreDetail[0]?.score}</Text>
        </GroupContainer>

        {questions.map((question, index) => {
          const questionAnswers = question.options.filter((o) => answers.find((x) => x.answerGuid === o.name));
          const answer = questionAnswers?.length ? questionAnswers[0] : questionAnswers;
          if (index < 3) {
            return (
              <GroupContainer key={`${index}`}>
                <Heading>{question.text}</Heading>
                <Text>{answer?.value ?? answer?.text}</Text>
              </GroupContainer>
            );
          }
        })}
      </div>

      <div style={{ marginLeft: '50px' }}>
        {questions.map((question, index) => {
          const questionAnswers = question.options.filter((o) => answers.find((x) => x.answerGuid === o.name));
          const answer = questionAnswers?.length ? questionAnswers[0] : questionAnswers;
          if (index > 2 && index < 7) {
            return (
              <GroupContainer key={`${index}`}>
                <Heading>{question.text}</Heading>
                <Text>{answer?.value ?? answer?.text}</Text>
              </GroupContainer>
            );
          }
        })}
      </div>

      <div style={{ marginLeft: '50px' }}>
        {questions.map((question, index) => {
          const questionAnswers = question.options.filter((o) => answers.find((x) => x.answerGuid === o.name));
          const answer = questionAnswers?.length ? questionAnswers[0] : questionAnswers;
          if (index > 6) {
            return (
              <GroupContainer key={`${index}`}>
                <Heading>{question.text}</Heading>
                <Text>{answer?.value ?? answer?.text}</Text>
              </GroupContainer>
            );
          }
        })}
      </div>
    </>
  );
};

YouthNeeds.propTypes = {
  answers: PropTypes.array,
  scoreDetail: PropTypes.array,
};

export default YouthNeeds;

import { ApiService } from 'Administrator_Helpers/ApiService';

export class PeersCallApiDataService {
  private static _instance: PeersCallApiDataService = new PeersCallApiDataService();
  private api: ApiService;
  constructor() {
    // Make sure we don't create more than one instance of this service
    if (PeersCallApiDataService._instance) {
      return PeersCallApiDataService._instance;
    }
    this.api = new ApiService();
  }
  public startCall = async (request: { userId: number, ngmId: number, organizationId: number }) => {
    if (!request) return;

    const { response, hasError } = await this.api.get(`/v1/integration/startcall/`, request);

    return { response, hasError };
  };
}

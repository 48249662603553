import React, { useCallback, useEffect, useState } from 'react';
import { Grid, IconButton, InputLabel, MenuItem, Typography } from '@mui/material';
import {
  ActionButton,
  DataGridFooter,
  HeaderContainer,
  SearchResultsGrid,
  StyledFormControl,
  StyledSelect,
} from './styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ViewMigration from './ViewMigration/ViewMigration';
import ResultsGrid from 'Components/ResultsGrid/ResultsGrid';
import { MigrationRequestModuleStore } from '../MigrationRequestModuleStore';
import { headers } from './config';
import { useNavigate } from 'react-router-dom';
import { MIGRATIONREQUESTS_NEW } from '../../../../initialization/Routes/RoutesConfig';

const fetchNumber = 10;

function MigrationList() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [statusSearch, setStatusSearch] = useState('');
  const [migrationRequest, setMigrationRequest] = useState();
  const migrationStore = MigrationRequestModuleStore.Instance().Use();

  const enums = [{ code: '', displayName: 'Open' }]
    .concat(migrationStore.MigrationEnums?.MigrationAttemptStatus)
    .filter((x) => !!x);
  const migrationRequests = migrationStore.MigrationResults;
  const nextAvailable = migrationRequests?.length >= fetchNumber;
  const isLoading = migrationStore.IsLoading;

  const onPageUpdate = (increment) => {
    if (!migrationStore.IsLoading && page + increment >= 0 && (page > page + increment || nextAvailable)) {
      setPage(page + increment);
    }
  };

  const onMigrationClick = (request) => setMigrationRequest(request);

  const onStatusChange = (e) => {
    setStatusSearch(e.target.value);
    setPage(0);
  };

  const onSearchUpdate = () => {
    setPage(0);
  };

  const onNewMigrationClick = async () => {
    migrationStore.ClearInformation();
    navigate(MIGRATIONREQUESTS_NEW);
  };

  const searchMigrations = useCallback(() => {
    migrationStore.SearchMigrations({
      status: statusSearch,
      fetch: fetchNumber,
      offset: page * fetchNumber,
    });
  }, [statusSearch, page]);

  useEffect(() => {
    searchMigrations();
  }, [searchMigrations]);

  useEffect(() => {
    if (!migrationRequests && page > 0) {
      setPage(page - 1);
    }
  }, [migrationRequests]);

  return (
    <>
      <SearchResultsGrid container>
        <HeaderContainer>
          <Grid item xs={12} style={{ padding: '15px' }}>
            <Typography component="h1" fontSize={24} fontWeight={700}>
              Member Migration
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <StyledFormControl>
              <InputLabel id="status_label">Status:</InputLabel>
              <StyledSelect
                id="status"
                labelId="status_label"
                value={statusSearch}
                defaultValue={statusSearch}
                onChange={onStatusChange}
                disabled={isLoading}
                displayEmpty
              >
                {enums?.map((item, index) => (
                  <MenuItem value={item.code} key={index}>
                    {item.displayName}
                  </MenuItem>
                ))}
              </StyledSelect>
            </StyledFormControl>
          </Grid>
          <Grid item xs={2}>
            <ActionButton variant="outlined" color="primary" fullWidth onClick={onNewMigrationClick}>
              New
            </ActionButton>
          </Grid>
        </HeaderContainer>
        <ResultsGrid
          fetchNumber={fetchNumber}
          loading={isLoading}
          headerMap={headers}
          data={migrationRequests}
          onDataClick={onMigrationClick}
        />
        <DataGridFooter item xs={9} />
        <DataGridFooter item xs={3}>
          <IconButton aria-label="back" onClick={() => onPageUpdate(-1)} disabled={page <= 0}>
            <ArrowBackIosIcon />
          </IconButton>
          Page {page + 1}
          <IconButton aria-label="next" onClick={() => onPageUpdate(1)} disabled={!nextAvailable}>
            <ArrowForwardIosIcon />
          </IconButton>
        </DataGridFooter>
      </SearchResultsGrid>
      <ViewMigration
        open={!!migrationRequest}
        onClose={() => setMigrationRequest()}
        onReset={onSearchUpdate}
        request={migrationRequest}
      />
    </>
  );
}

export default MigrationList;

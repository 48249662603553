import React, { forwardRef } from 'react';
import { Checkbox, FormGroup } from '@mui/material';
import { StyledLabel } from './styles';
import PropTypes from 'prop-types';

const CheckboxGroup = forwardRef(({ onChange, value, options, delimiter, ...props }, ref) => {
  const groupValue = value?.split(delimiter) ?? [];

  const onChecked = (option) => {
    const answerData = groupValue?.includes(option)
      ? groupValue.filter((el) => el != option)
      : groupValue.concat(option);

    value = answerData.join(delimiter);
    onChange(value);
  };

  return (
    <>
      <FormGroup row>
        {options.map((option, index) => (
          <StyledLabel
            key={index}
            label={option}
            ref={ref}
            control={
              <Checkbox
                {...props}
                name={option}
                checked={groupValue.includes(option)}
                onChange={() => {
                  onChecked(option);
                }}
              />
            }
          />
        ))}
      </FormGroup>
    </>
  );
});

CheckboxGroup.displayName = 'CheckboxGroup';

CheckboxGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  delimiter: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
};

CheckboxGroup.defaultProps = {
  delimiter: ',',
};

export default CheckboxGroup;

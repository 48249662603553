import { questionTypes } from '../enums';

export const nonAppGeneralConfig = {
  title: 'Non App Users - General',
  questions: [
    {
      title:
        'Has the Pyx Health program given you companionship? If YES, what part of the program gave you companionship?',
      guids: ['3d7e85a9-309a-40e1-97c2-b07895ba80eb'],
      types: [questionTypes.CLOSED],
      options: ['YES', 'NO'],
      typeOptions: {
        [questionTypes.CLOSED]: {
          YES: [
            {
              title: 'If YES, what services did you use?',
              guids: ['9fb63143-8c2e-456c-8eda-81c975aa7310'],
              types: [questionTypes.FREETEXT],
            },
          ],
        },
      },
    },
    {
      title:
        'On a scale of 1 to 5 (one being the least connected and five being the most), how connected to your healthcare team has the Pyx Health program made you feel?',
      guids: ['2e7c2f3f-b8b9-4e8d-b37e-fb4cd6327774'],
      types: [questionTypes.RATING],
      options: ['1', '2', '3', '4', '5'],
    },
    {
      title:
        'On a scale of 1 to 5 (one being the least satisfied and five being the most), how satisfied are you with your experience with Pyx Health?',
      guids: ['daaf2048-2039-4741-8854-630d53a872ba'],
      types: [questionTypes.RATING],
      options: ['1', '2', '3', '4', '5'],
    },
    {
      title:
        'Is there anything that you would like me to know that you like, dislike, or have suggestions about regarding the app or the program?',
      guids: ['8d4e7a3f-02f9-44fb-a478-1d965ab637f5'],
      types: [questionTypes.FREETEXT],
    },
    {
      title:
        'How likely is it that you would recommend Pyx to a friend or collegue? (Scale 1-10 [1 - Not likely to recommend, 10 - Extremely likely to recommend])',
      guids: ['45b0a19f-a0a0-4661-a94f-af9100f99f5d'],
      types: [questionTypes.RATING],
      options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    },
  ],
};

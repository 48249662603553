import styled from 'styled-components';

export const CreateMemberConcernsDataContainer = styled.div`
  margin-top: 35px;
  height: 120px;
  width: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
`;

export const TextHeader = styled.h2`
  margin-top: 31px;
  margin-left: 85px;
  font-family: karla;
  color: #015b5b;
  font-size: 32px;
  font-weight: bold;
`;

export const SectionHeader = styled.div`
  font-family: Lato;
  color: #0f0f0f;
  font-size: 14px;
  margin-bottom: 20px;
`;

export const DataColumn = styled.div`
  display: flex;
  margin-left: 85px;
  margin-top: 30px;
`;

export const TextAreaStyle = styled.textarea`
  border-radius: 10px;
  width: 1239px;
  height: 92px;
  border: 2px solid #e9e7e7;
  color: #0f145b;
  margin-top: 6px;
  margin-right: 20px;
`;

export const SubmitButtonContainer = styled.div`
  display: flex;
  padding: 75px 0;
`;

export const SubmitButton = styled.input`
  background-color: #4a4f7f;
  color: white;
  width: 125px;
  height: 27px;
  border: 1px solid #4a4f7f;
  border-radius: 25px;
  margin: 0 7px;
  cursor: pointer;
  font-family: karla;
  margin-left: 1110px;
`;

export const CancelButton = styled.div`
  background-color: white;
  color: #4a4f7f;
  width: 125px;
  height: 27px;
  border: 1px solid #4a4f7f;
  border-radius: 25px;
  margin: 0 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: karla;
`;

export const SpinnerButton = styled.div`
  background-color: #4a4f7f;
  color: white;
  width: 125px;
  height: 30px;
  border: 1px solid #4a4f7f;
  border-radius: 25px;
  margin: 0 7px;
  cursor: pointer;
  font-family: karla;
  margin-left: 1110px;
`;

export const ErrorMessage = styled.div`
  color: red;
  font-family: Karla;
  margin-left: 87px;
  margin-top: -50px;
`;

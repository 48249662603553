import React from 'react';
import PropTypes from 'prop-types';
import { GroupContainer, Heading, Text } from '../../../ScreeningHistoryItem/styles.js';
import { questions } from '../config.js';
import { Grid } from '@mui/material';

const AdultWellBeing = ({ answers, scoreDetail }) => {
  const questionGroupSplits = [0, 2].map((start, i, arr) => questions.slice(start, arr[i + 1]));
  return (
    <>
      <div style={{ width: '93%' }}>
        <Grid container direction="row">
          {questionGroupSplits.map((group, groupIndex) => (
            <Grid container direction="column" xs={4} key={groupIndex}>
              <>
                {groupIndex === 0 && (
                  <>
                    <Grid item>
                      <GroupContainer>
                        <Heading>Type</Heading>
                        <Text data-testid={'ScreeningName'}>Well-Being Assessment</Text>
                      </GroupContainer>
                    </Grid>

                    <Grid item>
                      <GroupContainer>
                        <Heading>Score</Heading>
                        <Text>{scoreDetail[0]?.score}</Text>
                      </GroupContainer>
                    </Grid>
                  </>
                )}
                {group.map((question, index) => {
                  const questionAnswers = question.options.filter((o) => answers.find((x) => x.answerGuid === o.name));
                  const answer = questionAnswers?.length ? questionAnswers[0] : questionAnswers;
                  const answerData = answers.find((x) => x.answerGuid === answer?.name);
                  const answerText = answerData?.value ?? questionAnswers.map((x) => x.text).join(', ');
                  return (
                    <Grid item key={`${index}`}>
                      <GroupContainer>
                        <Heading>{question.text}</Heading>
                        <Text>{answerData?.value ?? answerText}</Text>
                      </GroupContainer>
                    </Grid>
                  );
                })}
              </>
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};

AdultWellBeing.propTypes = {
  answers: PropTypes.array,
  scoreDetail: PropTypes.array,
};

export default AdultWellBeing;

import styled from '@emotion/styled';
import { FormControlLabel } from '@mui/material';

export const StyledLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 12px;
  }
`;

export const StyledWrapper = styled.div`
  padding-left: 20px;
`;

export const StyledSubQuestionWrapper = styled.div`
  padding-left: 50px;
`;

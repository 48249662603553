import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

const ngmProfileUrl = '/v5/members/ngm-profile';
const options = {
  revalidateOnFocus: false,
};

const getEnrolledNgm = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useGetEnrolledNgm = (organizationId, ngmId, init) => {
  const [isGetPending, setIsPending] = useState(true);
  const [enrolledMemberData, setEnrolledMemberData] = useState();

  const { data: enrolledResult, error } = useSWR(
    () => {
      if (!init || !organizationId || !ngmId) {
        return false;
      } else {
        return `${process.env.API_BASE_URL}${ngmProfileUrl}/${organizationId}:${ngmId}`;
      }
    },
    getEnrolledNgm,
    {
      ...options,
    },
  );

  useEffect(() => {
    if (enrolledResult) {
      setEnrolledMemberData(enrolledResult.data);
    }

    setIsPending(!enrolledResult && !error);
  }, [enrolledResult, error]);

  return { enrolledMemberData, error, isGetPending };
};

export default useGetEnrolledNgm;

import styled from 'styled-components';
import { Button, CircularProgress } from '@mui/material';

export const StyledDimensionHeader = styled.h2`
  margin: 20px 0 0;
`;

export const StyledForm = styled.form`
  max-width: 1000px;
  display: flex;
  flex-flow: column nowrap;
`;

export const StyledSubmitButton = styled(Button)`
  align-self: flex-end;
  margin: 20px 15px 0 0;
`;

export const StyledSaveSpinner = styled(CircularProgress)`
  margin-left: 8px;
  color: #fff;
`;

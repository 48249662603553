import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import useThriveEnroll from 'Tasking_Hooks/useThriveEnroll.js';
import useThriveUpdate from 'Tasking_Hooks/useThriveUpdate.js';
import { thriveMemberFunctionConfig } from './ThriveMemberFunctionConfig.js';
import { StyledForm, StyledErrorMessage, StyledMessageContainer, StyledSubmitButton } from './styles.js';
import { useThriveState } from 'Tasking_Contexts/ThriveContext.js';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, CircularProgress, MenuItem, TextField } from '@mui/material';

const ThriveMemberFunctions = ({ updateThriveStatus, memberInfo }) => {
  //state management
  const thriveState = useThriveState();
  const thriveStatus = thriveState?.thriveStatus?.thriveStatus;
  //component variables
  const { userId } = useParams();
  const [errorMessage, setErrorMessage] = useState(null);
  const { handleSubmit, control, setValue } = useForm();
  //API Hooks
  const [isPending, setIsPending] = useState(false);
  const { enrollResponse, enrollmentErrorMessage, enrollThriveUser } = useThriveEnroll();
  const { updateResponse, updateErrorMessage, updateThriveUser } = useThriveUpdate();

  useEffect(() => {
    if (enrollResponse || updateResponse !== null) {
      updateThriveStatus();
      setIsPending(false);
    } else if (enrollmentErrorMessage || updateErrorMessage) {
      setIsPending(false);
      setErrorMessage(enrollmentErrorMessage ?? updateErrorMessage);
    }
  }, [enrollResponse, updateResponse, enrollmentErrorMessage, updateErrorMessage]);

  const retrieveOptions = () => {
    switch (thriveMemberFunctionConfig[thriveStatus]?.optionType ?? 'ENROLLBUTTON') {
      case 'DROPDOWN':
        return retrieveDropdown();
      case 'NONE':
        return <></>;
      case 'ENROLLBUTTON':
      default:
        return <form onSubmit={handleSubmit(onSubmit)}>{retrieveButton()}</form>;
    }
  };

  const retrieveButton = () => {
    return isPending ? (
      <Button variant="outlined" color="primary">
        <CircularProgress size="24px" />
      </Button>
    ) : (
      <StyledSubmitButton variant="contained" color="primary" data-testid="SubmitButton" name="submit" type="submit">
        {thriveMemberFunctionConfig[thriveStatus]?.buttonValue ?? 'Enroll into Thrive'}
      </StyledSubmitButton>
    );
  };

  const retrieveDropdown = () => {
    return (
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="MemberFunctionDropdown"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field: { onChange, ...field } }, ...fields) => (
            <TextField
              {...field}
              {...fields}
              id="MemberFunctionDropdown"
              variant="standard"
              onChange={(e) => {
                onChange(e);
                setValue(e.target.value);
              }}
              label={thriveMemberFunctionConfig[thriveStatus].header}
              select
              helperText="Required"
              fullWidth
            >
              {thriveMemberFunctionConfig[thriveStatus].options.map((data, i) => (
                <MenuItem key={i} value={data}>
                  {data}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        {retrieveButton()}
      </StyledForm>
    );
  };

  const onSubmit = async (e) => {
    setIsPending(true);
    if (thriveStatus === 'ACTIVE') {
      const updateValues = {
        dropoutReason: e.MemberFunctionDropdown,
        userId: userId,
      };
      await updateThriveUser(updateValues);
    } else {
      const isMemberPendingRegistration = memberInfo?.userId && !memberInfo.activeProfileId;
      if (isMemberPendingRegistration || memberInfo?.deceased || memberInfo?.isTerminated) {
        setErrorMessage('User is not Eligible for Thrive. User is pending registration, deceased, or terminated');
        setIsPending(false);
      } else {
        const enrollValues = {
          userId: userId,
          enrollmentReason: e.MemberFunctionDropdown ?? thriveMemberFunctionConfig['NOTENROLLED'].reason,
        };
        await enrollThriveUser(enrollValues);
      }
    }
    setValue('MemberFunctionDropdown', '');
  };

  return (
    <>
      <StyledMessageContainer data-testid="MessageContainer">
        {thriveMemberFunctionConfig[thriveStatus]?.message ?? thriveMemberFunctionConfig['NOTENROLLED'].message}
      </StyledMessageContainer>
      {retrieveOptions()}
      <StyledErrorMessage data-testid="ErrorContainer">{errorMessage ?? ''}</StyledErrorMessage>
    </>
  );
};

ThriveMemberFunctions.propTypes = {
  updateThriveStatus: PropTypes.func,
  memberInfo: PropTypes.object.isRequired,
};

export default ThriveMemberFunctions;

import datediff from 'Tasking_Helpers/getDateDiff';
import React, { useState, useEffect } from 'react';
import CallTimer from './CallTimer/CallTimer';
import { useOidcUser } from '@axa-fr/react-oidc';
import { getNow } from 'Helpers/timeWarpHelper';
import PropTypes from 'prop-types';
import { convertLocalDateToUtcStr, convertUtcDateToLocalStr, removeTimeZoneStr } from 'Util/dateFunctions';
import { CallLogModuleStore } from './CallLogModuleStore';

function CallLog({
  taskDetail,
  selectedTask,
  taskSaved,
  organizationId,
  activityStarted,
  viewActivity,
  setSelectedTask,
  setCallLogInfo,
  callLogInfo,
}) {
  const { oidcUser } = useOidcUser();
  const [editClicked, setEditClicked] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [callLogFormData, setCallLogFormData] = useState({});

  const callLogStore = CallLogModuleStore.Instance().Use();
  const createError = callLogStore.CreateCallLogError;
  const getError = callLogStore.GetCallLogError;
  const updateError = callLogStore.UpdateCallLogError;

  const isGetPending = callLogStore.IsLoading;
  const isUpdatePending = callLogStore.IsUpdatePending;
  const isCreatePending = callLogStore.IsCreatePending;

  const callLogData = callLogStore.CallLogData;
  const callLogGuid = callLogStore.CallLogGuid;
  const callLogStatus = callLogStore.CallLogStatus;
  const callLogTaskGuid = callLogStore.TaskGuid;

  useEffect(() => {
    setCallLogFormData(callLogData);
    const callLogState = getTaskCallLogState();
    const callDataInfoState = { ...callLogInfo?.callLogState, ...callLogState };
    setCallLogInfo({ ...callLogInfo, callLogState: callDataInfoState });
  }, [callLogData]);

  useEffect(() => {
    let callLogGuids = selectedTask?.callLogGuids ? selectedTask.callLogGuids : [];
    if (callLogGuid && !callLogGuids?.includes(callLogGuid) && taskData.taskGuid == callLogTaskGuid) {
      callLogGuids.push(callLogGuid);
      setSelectedTask({ ...selectedTask, callLogGuids: callLogGuids });
      setCallLogInfo({ ...selectedTask, callLogGuids: callLogGuids });
    }
  }, [callLogGuid]);

  useEffect(() => {
    if (taskDetail && taskDetail?.callLogGuids?.length > 0) {
      callLogStore.getCallLog(taskDetail.callLogGuids[0], organizationId, taskData.taskGuid);
    } else if (taskDetail && taskDetail?.callLogGuids?.length < 1 && selectedTask?.callLogGuids?.length > 0) {
      callLogStore.getCallLog(selectedTask.callLogGuids[0], organizationId, taskData.taskGuid);
    } else {
      callLogStore.reset();
    }
  }, [taskDetail]);

  useEffect(() => {
    if (createError?.hasError || getError?.hasError || updateError?.hasError) {
      setErrorMessage(createError?.error ?? getError?.error ?? updateError?.error);
    }
  }, [createError, getError, updateError]);

  const taskData = {
    ...selectedTask,
    ...taskDetail,
    dueDateUtc: callLogInfo?.taskData?.dueDateUtc ?? selectedTask?.dueDateUtc ?? taskDetail?.dueDateUtc,
  };

  const getButtonText = () => {
    const defaultText = 'Start Timer';

    const buttonTextDataTask = {
      [CallLog.status.ENDED]: 'Timer Ended!',
      [CallLog.status.STARTED]: 'End Timer',
      [CallLog.status.NEW]: defaultText,
    };
    const taskTextData = buttonTextDataTask[callLogStatus] ?? defaultText;

    return taskTextData;
  };

  const startCall = () => {
    callLogStore.createCallLog(oidcUser?.sub, organizationId, taskData.taskGuid);
  };

  const endCall = () => {
    const endedOnUtc = convertLocalDateToUtcStr(getNow());

    const request = {
      AgentUserId: oidcUser?.sub,
      StartedOnUtc: callLogFormData?.startedOnUtc,
      EndedOnUtc: endedOnUtc,
    };
    const standardTimeUtc = removeTimeZoneStr(endedOnUtc);
    callData.endedOnUtc = standardTimeUtc;
    callLogStore.updateCallLog(callLogGuid, organizationId, taskData.taskGuid, request);
  };

  const onSave = () => {
    if (errorMessage) return;

    const request = {
      AgentUserId: oidcUser?.sub,
      StartedOnUtc: callLogFormData?.startedOnUtc,
      EndedOnUtc: callLogFormData?.endedOnUtc,
    };
    const success = callLogStore.updateCallLog(callLogGuid, organizationId, taskData.taskGuid, request);
    setEditClicked(!success);
  };

  const onCancel = () => {
    setEditClicked(false);
    setErrorMessage();
    setCallLogFormData(callLogData);
  };

  const onEdit = () => {
    setEditClicked(true);
  };

  const onEndedOnUtcInvalidDateEntry = (error) => {
    setErrorMessage(error);
  };

  const onStartedOnUtcInvalidDateEntry = (error) => {
    setErrorMessage(error);
  };

  const onChangeStartedOnUtc = (val) => {
    if (editClicked) {
      const valTime = removeTimeZoneStr(val.toISOString());
      const updatedCallLog = { ...callLogFormData, startedOnUtc: valTime };
      setCallLogFormData(updatedCallLog);
    }
  };

  const onChangeEndedOnUtc = (val) => {
    if (editClicked) {
      const valTime = removeTimeZoneStr(val.toISOString());
      const updatedCallLog = { ...callLogFormData, endedOnUtc: valTime };
      setCallLogFormData(updatedCallLog);
    }
  };

  let callData = { ...callLogData, ...callData, ...callLogInfo };

  const getTaskCallLogState = (currentCallLogChanges) => {
    const callDataInfo = { ...callLogData, ...currentCallLogChanges };

    // Call Log Status
    const callLogCompletionStatus = callDataInfo?.endedOnUtc
      ? CallLog.status.ENDED
      : callDataInfo?.startedOnUtc
      ? CallLog.status.STARTED
      : CallLog.status.NEW;

    // Task
    const isValidTaskType = taskData?.taskType === 'ANDY';

    const taskDueStatus =
      datediff(new Date(taskData?.dueDateUtc)) > 0 ? CallLog.taskStatus.FUTURE : CallLog.taskStatus.TODAY;

    const taskCompletionStatus =
      taskData?.status === 'COMPLETE'
        ? CallLog.taskStatus.COMPLETED
        : taskData?.status === 'NEW'
        ? CallLog.taskStatus.NEW
        : CallLog.taskStatus.EXISTING;

    const iconType =
      taskCompletionStatus === CallLog.taskStatus.COMPLETED && editClicked
        ? CallTimer.icons.SAVE
        : taskCompletionStatus === CallLog.taskStatus.COMPLETED
        ? CallTimer.icons.EDIT
        : activityStarted && callLogCompletionStatus === CallLog.status.NEW
        ? CallTimer.icons.INFO
        : '';

    const disableModule = taskDueStatus === CallLog.taskStatus.FUTURE;

    const addActivityButtonDisabled =
      taskDueStatus !== CallLog.taskStatus.FUTURE &&
      (taskData?.status === 'COMPLETE' || taskSaved) &&
      isValidTaskType &&
      callLogCompletionStatus === CallLog.status.NEW;

    // Call Log
    const buttonDisabled = disableModule || callLogCompletionStatus === CallLog.status.ENDED;

    const completeTaskButtonDisabled =
      isValidTaskType &&
      (taskDueStatus === CallLog.taskStatus.FUTURE ||
        (callLogCompletionStatus === CallLog.status.STARTED && taskDueStatus === CallLog.taskStatus.TODAY));

    const completeTaskToolTip =
      taskDueStatus === CallLog.taskStatus.FUTURE
        ? 'You must start the call timer to continue.'
        : callLogCompletionStatus === CallLog.status.STARTED && taskDueStatus === CallLog.taskStatus.TODAY
        ? 'You must end the call timer to continue'
        : '';

    // Call Log state
    let callLogState = {
      taskDueStatus: taskDueStatus,
      taskCompletionStatus: taskCompletionStatus,
      iconType: iconType,
      addActivityButtonDisabled: addActivityButtonDisabled,
      callLogCompletionStatus: callLogCompletionStatus,
      buttonDisabled: buttonDisabled,
      completeTaskButtonDisabled: completeTaskButtonDisabled,
      completeTaskToolTip: completeTaskToolTip,
      activityToolTip: addActivityButtonDisabled ? 'You must start the call timer to continue.' : '',
      disableActivityTabs: isValidTaskType && taskDueStatus === 'TODAY' && !callDataInfo?.startedOnUtc,
    };

    return callLogState;
  };

  const callLogState = getTaskCallLogState();

  const completedStyle = callLogInfo?.callLogState?.taskCompletionStatus === CallLog.taskStatus.COMPLETED && {
    minHeight: '170px',
  };

  const currentStyle = completedStyle ? completedStyle : {};

  const getIsUpdating =
    (!isGetPending &&
      callLogData &&
      (!callData || (!callLogData?.startedOnUtc && callLogState?.callLogCompletionStatus === CallLog.status.NEW))) ||
    (isGetPending && (taskDetail?.callLogGuids?.length > 0 || selectedTask?.callLogGuids?.length > 0));

  const callTimerIsPending = isUpdatePending || isCreatePending || getIsUpdating;

  return (
    <>
      {taskData?.taskType === 'ANDY' &&
        !viewActivity &&
        (!activityStarted || callLogInfo?.callLogState?.taskCompletionStatus !== CallLog.taskStatus.COMPLETED) &&
        oidcUser && (
          <>
            <CallTimer
              startedOnUtc={convertUtcDateToLocalStr(callLogFormData?.startedOnUtc)}
              endedOnUtc={convertUtcDateToLocalStr(callLogFormData?.endedOnUtc)}
              onClick={callLogState?.callLogCompletionStatus === CallLog.status.NEW ? startCall : endCall}
              onIconClick={callLogState?.iconType === CallTimer.icons.SAVE ? onSave : onEdit}
              disabled={callLogState?.taskDueStatus === CallLog.taskStatus.FUTURE}
              disableButton={callLogState?.buttonDisabled}
              buttonText={getButtonText()}
              hideButton={callLogState?.taskCompletionStatus === CallLog.taskStatus.COMPLETED}
              hideEndCall={callLogState?.callLogCompletionStatus === CallLog.status.NEW}
              style={currentStyle}
              icon={callLogState?.iconType}
              editTimeAllowed={editClicked}
              onChangeStartedOnUtc={onChangeStartedOnUtc}
              onChangeEndedOnUtc={onChangeEndedOnUtc}
              onCancel={onCancel}
              pending={callTimerIsPending}
              errorMessage={errorMessage}
              onStartedOnUtcInvalidDateEntry={onStartedOnUtcInvalidDateEntry}
              onEndedOnUtcInvalidDateEntry={onEndedOnUtcInvalidDateEntry}
            />
          </>
        )}
    </>
  );
}

CallLog.taskStatus = {
  PAST: 'PAST',
  TODAY: 'TODAY',
  FUTURE: 'FUTURE',
  STARTED: 'STARTED',
  ENDED: 'ENDED',
  NEW: 'NEW',
  EXISTING: 'EXISTING',
  COMPLETED: 'COMPLETED',
};

CallLog.status = { NEW: 'NEW', STARTED: 'STARTED', ENDED: 'ENDED' };

CallLog.propTypes = {
  taskDetail: PropTypes.object,
  selectedTask: PropTypes.object,
  taskSaved: PropTypes.bool,
  organizationId: PropTypes.number,
  activityStarted: PropTypes.bool,
  viewActivity: PropTypes.bool,
  setSelectedTask: PropTypes.func,
  setCallLogInfo: PropTypes.func,
  callLogInfo: PropTypes.object,
};

export default CallLog;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DropDownInfoContainer, GroupContainer, GreetingCardContainer, Heading, Text, StyledButton } from './styles';
import { formatDate } from 'Tasking_Helpers/formatDate';
import useTaskDetails from 'Tasking_Hooks/useTaskDetails';
import useGetPastScreening from 'Tasking_Hooks/useGetPastScreening';
import useGetMemberConcern from 'Tasking_Hooks/useGetMemberConcern';
import useGetAssistanceProvided from 'Tasking_Hooks/useGetAssistanceProvided';
import GreetingCard from 'Tasking_Components/GreetingCard/GreetingCard';
import { historyTypes, memberConnectionTypes } from './enums';
import { useNavigate } from 'react-router-dom';
import useAxiosSwrFetch from 'Tasking_Hooks/useAxiosSwrFetch';
import urlcat from 'urlcat';
import ScreeningHistoryItem from 'Tasking_Components/Screenings/ScreeningHistoryItem/ScreeningHistoryItem';
import MigrationHistoryItem from 'Tasking_Components/MemberMigrations/MigrationHistoryItem/MigrationHistoryItem';

const getMemberConnectionRequestPath = '/v1/memberconnections/:orgId::memberConnectionGuid';

const DropDownInfo = ({ type, data, ngm, memberInfo, values }) => {
  const navigate = useNavigate();
  const { taskDetail, retrieveTask } =
    type === historyTypes.TASK
      ? ngm
        ? useTaskDetails(memberInfo?.organizationId, data?.id)
        : useTaskDetails(memberInfo?.activeOrganizationId, data?.id)
      : '';
  const { memberScreeningDetail } = type.startsWith('Legacy')
    ? useGetPastScreening(memberInfo?.activeOrganizationId, data?.id)
    : '';
  const { memberConcernDetail } =
    type === historyTypes.MEMBERCONCERN ? useGetMemberConcern(memberInfo?.activeOrganizationId, data?.guid) : '';

  const orgId = ngm ? memberInfo.organizationId : memberInfo?.activeOrganizationId;
  const { response: memberConnectionData, fetch } = useAxiosSwrFetch({ fetchRequired: false });

  const { existingAssistanceProvided, getAssistanceProvided } = useGetAssistanceProvided();

  const onClickHistory = (path) => {
    navigate(path);
  };

  useEffect(() => {
    if (type === historyTypes.MEMBERCONNECTION && data?.guid && orgId) {
      fetch(
        urlcat(process.env.API_BASE_URL, getMemberConnectionRequestPath, {
          orgId: orgId,
          memberConnectionGuid: data?.guid,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (type === historyTypes.TASK) {
      const id = ngm ? memberInfo?.organizationId : memberInfo?.activeOrganizationId;
      retrieveTask(id, data?.guid);
    }
    if (type === historyTypes.ASSISTANCEPROVIDED) {
      getAssistanceProvided(memberInfo?.activeOrganizationId, data?.guid);
    }
  }, []);

  if (type === historyTypes.GREETINGCARD) {
    return (
      <GreetingCardContainer data-testid="GreetingCardContainer">
        <GreetingCard cardId={data?.id} cardFrom={data?.byUserName} maxSize="20%" />
      </GreetingCardContainer>
    );
  }
  if (type === historyTypes.MEMBERCONCERN)
    return (
      <>
        <DropDownInfoContainer data-testid="MemberConcernContainer">
          <div>
            <GroupContainer>
              <Heading id="AccessToCare">Access To Care:</Heading>
              <Text aria-labelledby="AccessToCare">{memberConcernDetail?.accesstocare ? 'Yes' : 'No'}</Text>
            </GroupContainer>
            <GroupContainer>
              <Heading>Behavioral Health Needs:</Heading>
              <Text>{memberConcernDetail?.behavioralhealthneeds ? 'Yes' : 'No'}</Text>
            </GroupContainer>
            <GroupContainer>
              <Heading>Benefit Questions:</Heading>
              <Text>{memberConcernDetail?.benefitquestions ? 'Yes' : 'No'}</Text>
            </GroupContainer>
            <GroupContainer>
              <Heading>Crisis:</Heading>
              <Text>{memberConcernDetail?.crisis ? 'Yes' : 'No'}</Text>
            </GroupContainer>
          </div>
          <div style={{ marginLeft: '50px' }}>
            <GroupContainer>
              <Heading>DME:</Heading>
              <Text>{memberConcernDetail?.dme ? 'Yes' : 'No'}</Text>
            </GroupContainer>
            <GroupContainer>
              <Heading>Employment:</Heading>
              <Text>{memberConcernDetail?.employment ? 'Yes' : 'No'}</Text>
            </GroupContainer>
            <GroupContainer>
              <Heading>Food:</Heading>
              <Text>{memberConcernDetail?.food ? 'Yes' : 'No'}</Text>
            </GroupContainer>
            <GroupContainer>
              <Heading>Housing:</Heading>
              <Text>{memberConcernDetail?.housing ? 'Yes' : 'No'}</Text>
            </GroupContainer>
          </div>
          <div style={{ marginLeft: '50px' }}>
            <GroupContainer>
              <Heading>Income:</Heading>
              <Text>{memberConcernDetail?.income ? 'Yes' : 'No'}</Text>
            </GroupContainer>
            <GroupContainer>
              <Heading>Other:</Heading>
              <Text>{memberConcernDetail?.othertext ? memberConcernDetail?.othertext : 'No'}</Text>
            </GroupContainer>
          </div>
        </DropDownInfoContainer>
      </>
    );
  if (type === historyTypes.ASSISTANCEPROVIDED)
    return (
      <>
        <DropDownInfoContainer data-testid="AssistanceProvidedContainer">
          <div>
            <GroupContainer>
              <Heading>Type Of Assistance Provided</Heading>
              <Text>
                {existingAssistanceProvided?.typeOfAssistanceProvided
                  ? existingAssistanceProvided?.typeOfAssistanceProvided === 'MEMBERWARMTRANSFERRED'
                    ? 'Member Warm Transferred'
                    : existingAssistanceProvided?.typeOfAssistanceProvided === 'PROVIDEDRESOURCES'
                    ? 'Provided Resources'
                    : existingAssistanceProvided?.typeOfAssistanceProvided === 'MEMBERDECLINEDWARMTRANSFER'
                    ? 'Member Declined Warm Transfer'
                    : ''
                  : ''}
              </Text>
            </GroupContainer>
            <GroupContainer>
              <Heading>Andy</Heading>
              <Text>{existingAssistanceProvided?.andy ? 'Yes' : 'No'}</Text>
            </GroupContainer>
            <GroupContainer>
              <Heading id="CareManagement">Care Management</Heading>
              <Text aria-labelledby="CareManagement">{existingAssistanceProvided?.careManagement ? 'Yes' : 'No'}</Text>
            </GroupContainer>
            <GroupContainer>
              <Heading>PCP Provider</Heading>
              <Text>{existingAssistanceProvided?.pcpProvider ? 'Yes' : 'No'}</Text>
            </GroupContainer>
          </div>

          <div style={{ marginLeft: '50px' }}>
            <GroupContainer>
              <Heading>Sent Member Connection</Heading>
              <Text>{existingAssistanceProvided?.sentMemberConnection ? 'Yes' : 'No'}</Text>
            </GroupContainer>
            <GroupContainer>
              <Heading>Community Resource</Heading>
              <Text>{existingAssistanceProvided?.communityResource ? 'Yes' : 'No'}</Text>
            </GroupContainer>
            <GroupContainer>
              <Heading>Crisis Line</Heading>
              <Text>{existingAssistanceProvided?.crisisLine ? 'Yes' : 'No'}</Text>
            </GroupContainer>
          </div>

          <div style={{ marginLeft: '50px' }}>
            <GroupContainer>
              <Heading>Member Services:</Heading>
              <Text>{existingAssistanceProvided?.memberServices ? 'Yes' : 'No'}</Text>
            </GroupContainer>
            <GroupContainer>
              <Heading>Nurse Line</Heading>
              <Text>{existingAssistanceProvided?.nurseLine ? 'Yes' : 'No'}</Text>
            </GroupContainer>
            <GroupContainer>
              <Heading>Other Health Plan Resource</Heading>
              <Text>{existingAssistanceProvided?.otherHealthPlanResource ? 'Yes' : 'No'}</Text>
            </GroupContainer>
          </div>
        </DropDownInfoContainer>
      </>
    );
  if (type === historyTypes.NOTIFICATION)
    return (
      <>
        <DropDownInfoContainer data-testid="NotificationContainer">
          <div>
            <GroupContainer>
              <Heading>Notifcation Sent</Heading>
              <Text>{data?.description}</Text>
            </GroupContainer>
          </div>
        </DropDownInfoContainer>
      </>
    );
  else if (type.startsWith('Legacy'))
    return (
      <>
        <DropDownInfoContainer data-testid="LegacyScreeningDropDownInfo">
          <div>
            <GroupContainer>
              <Heading>Type</Heading>
              <Text data-testid={'ScreeningName'}>{memberScreeningDetail?.screeningName}</Text>
            </GroupContainer>

            <GroupContainer>
              <Heading>Score</Heading>
              <Text>{memberScreeningDetail?.screeningScore}</Text>
            </GroupContainer>

            {memberScreeningDetail?.screeningAnswers.map((data, index) => {
              if (index < 3) {
                return (
                  <GroupContainer key={`${data}${index}`}>
                    <Heading>{data.question}</Heading>
                    <Text>{data.answer}</Text>
                  </GroupContainer>
                );
              }
            })}
          </div>
          <div style={{ marginLeft: '50px' }}>
            {memberScreeningDetail?.screeningAnswers.map((data, index) => {
              if (index > 2 && index < 7) {
                return (
                  <GroupContainer key={`${data}${index}`}>
                    <Heading>{data.question}</Heading>
                    <Text>{data.answer}</Text>
                  </GroupContainer>
                );
              }
            })}
          </div>

          <div style={{ marginLeft: '50px' }}>
            {memberScreeningDetail?.screeningAnswers.map((data, index) => {
              if (index > 6) {
                return (
                  <GroupContainer key={`${data}${index}`}>
                    <Heading>{data.question}</Heading>
                    <Text>{data.answer}</Text>
                  </GroupContainer>
                );
              }
            })}
          </div>
        </DropDownInfoContainer>
      </>
    );
  else if (type.startsWith('Screening'))
    return <ScreeningHistoryItem memberInfo={memberInfo} data={data} type={type} />;
  else if (type === historyTypes.TASK)
    return (
      <>
        <DropDownInfoContainer data-testid="TaskContainer">
          <div>
            <GroupContainer>
              <Heading>Task Type:</Heading>
              <Text>{taskDetail?.taskType}</Text>
            </GroupContainer>
            <GroupContainer>
              <Heading>Task Subtype:</Heading>
              <Text>{taskDetail?.taskSubType}</Text>
            </GroupContainer>
            <GroupContainer>
              <Heading>Notes:</Heading>
              <Text notes>{taskDetail?.notes}</Text>
            </GroupContainer>
          </div>
          <div style={{ marginLeft: '120px' }}>
            <GroupContainer>
              <Heading>Assigned To:</Heading>
              <Text>{taskDetail?.assignedToName}</Text>
            </GroupContainer>
            <GroupContainer>
              <Heading>Due Date:</Heading>
              <Text>{formatDate(taskDetail?.dueDateUtc)}</Text>
            </GroupContainer>
          </div>
          <div style={{ marginLeft: '120px' }}>
            <GroupContainer>
              <Heading>Task Status:</Heading>
              <Text>{taskDetail?.status}</Text>
            </GroupContainer>
            <GroupContainer>
              <Heading>Priority:</Heading>
              <Text>{taskDetail?.priority}</Text>
            </GroupContainer>
            {taskDetail?.status === 'COMPLETE' ? (
              <GroupContainer>
                <Heading>Close Reason:</Heading>
                <Text>{taskDetail?.closeReason}</Text>
              </GroupContainer>
            ) : null}
          </div>
        </DropDownInfoContainer>
      </>
    );
  else if (type === historyTypes.ACTIVITY)
    return (
      <>
        <DropDownInfoContainer data-testid="ActivityDropDownInfo">
          <div>
            <GroupContainer>
              <Heading>Task Type:</Heading>
              <Text>{values.taskType}</Text>
            </GroupContainer>

            {values.taskType === 'ANDY' ? (
              <GroupContainer>
                <Heading>Task Subtype:</Heading>
                <Text>{values.taskSubType}</Text>
              </GroupContainer>
            ) : (
              ''
            )}

            <GroupContainer>
              <Heading>Notes:</Heading>
              <Text notes>{values.notes}</Text>
            </GroupContainer>
          </div>
          <div style={{ marginLeft: '120px' }}>
            <GroupContainer>
              <Heading>Assigned To:</Heading>
              <Text>{values.assignedToName}</Text>
            </GroupContainer>

            <GroupContainer>
              <Heading>Due Date:</Heading>
              <Text>{formatDate(values.dueDateUtc)}</Text>
            </GroupContainer>
          </div>
          <div style={{ marginLeft: '120px' }}>
            <GroupContainer>
              <Heading>Task Status:</Heading>
              <Text>{values.status}</Text>
            </GroupContainer>
          </div>
        </DropDownInfoContainer>
      </>
    );
  else if (type === historyTypes.SELFREPORTEDOUTCOME)
    return (
      <>
        <DropDownInfoContainer data-testid="SelfReportedOutcomeContainer">
          <div>
            <GroupContainer>
              <Heading>Self Reported Outcome Submitted</Heading>
            </GroupContainer>
          </div>
        </DropDownInfoContainer>
      </>
    );
  else if (type === historyTypes.MEMBERMIGRATION) return <MigrationHistoryItem data={data} />;
  else if (type === historyTypes.MEMBERCONNECTION)
    return (
      <>
        <DropDownInfoContainer data-testid="MemberConnectionContainer">
          <div>
            <GroupContainer>
              <Heading>Created By:</Heading>
              <Text>{data?.byUserName}</Text>
            </GroupContainer>
            <GroupContainer>
              <Heading>Created On:</Heading>
              <Text>{formatDate(memberConnectionData?.createdOnUtc)}</Text>
            </GroupContainer>
          </div>
          <div style={{ marginLeft: '120px' }}>
            <GroupContainer>
              <Heading>Last Edited By:</Heading>
              <Text>{data?.modifiedByUserName}</Text>
            </GroupContainer>
            <GroupContainer>
              <Heading>Last Edit On:</Heading>
              <Text>{formatDate(memberConnectionData?.modifiedOnUtc)}</Text>
            </GroupContainer>
          </div>
          <div style={{ marginLeft: '120px', width: '218px' }}>
            <GroupContainer>
              <Heading>Status:</Heading>
              <Text>{memberConnectionTypes.STATUS[memberConnectionData?.status]}</Text>
            </GroupContainer>
            <GroupContainer>
              <Heading>Priority:</Heading>
              <Text>{memberConnectionData?.isUrgent ? 'URGENT' : 'NON-URGENT'}</Text>
            </GroupContainer>
          </div>
          <div style={{ margin: '20px' }}>
            <StyledButton
              onClick={() =>
                onClickHistory(
                  ngm
                    ? `/ngm/${memberInfo.organizationId}/${memberInfo.id}/memberconnection/${data?.guid}`
                    : `/user/${memberInfo.userId}/memberconnection/${data?.guid}`,
                )
              }
            >
              View
            </StyledButton>
          </div>
        </DropDownInfoContainer>
      </>
    );
};

DropDownInfo.propTypes = {
  type: PropTypes.string,
  ngm: PropTypes.bool,
  memberInfo: PropTypes.shape({
    id: PropTypes.number,
    organizationId: PropTypes.number,
    userId: PropTypes.number,
    activeOrganizationId: PropTypes.number,
  }),
  data: PropTypes.shape({
    id: PropTypes.number,
    guid: PropTypes.string,
    activeOrganizationId: PropTypes.number,
    description: PropTypes.string,
    question: PropTypes.string,
    answer: PropTypes.string,
    modifiedByUserName: PropTypes.string,
    byUserName: PropTypes.string,
  }),
  values: PropTypes.objectOf(
    PropTypes.shape({
      taskType: PropTypes.string,
      taskSubType: PropTypes.string,
      notes: PropTypes.string,
      assignedToName: PropTypes.string,
      status: PropTypes.string,
      dueDateUtc: PropTypes.string,
    }),
  ),
};

export default DropDownInfo;

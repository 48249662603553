import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

const ngmMemberInfoUrl = '/v5/Ngms';
const options = {
  revalidateOnFocus: false,
};

const getMemberInfo = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useMemberInfo = (data) => {
  const [isPending, setIsPending] = useState(true);
  const [ngmMemberInfo, setNgmMemberInfo] = useState();
  const [ngmIdData, setNgmIdData] = useState(data);
  const {
    data: ngmMemberInfoResult,
    error,
    mutate,
  } = useSWR(() => `${process.env.API_BASE_URL}${ngmMemberInfoUrl}/${ngmIdData[1]}:${ngmIdData[0]}`, getMemberInfo, {
    ...options,
  });

  useEffect(() => {
    if (ngmMemberInfoResult) {
      setNgmMemberInfo(ngmMemberInfoResult.data);
    }

    setIsPending(!ngmMemberInfoResult && !error);
  }, [ngmMemberInfoResult, error]);

  const retrieveNgmMemberInfo = (newNgmIdData) => {
    if (!newNgmIdData) return;
    setNgmIdData(newNgmIdData);
    mutate(ngmIdData);
  };

  return { ngmMemberInfo, error, isPending, retrieveNgmMemberInfo };
};

export default useMemberInfo;

import styled from '@emotion/styled';
import { Box, FormControlLabel, Grid, Typography } from '@mui/material';
import { purple } from 'Styles/colors';

export const PrintContainer = styled.div`
  padding: 5px 20px;

  @media print {
    display: block;

    html,
    body {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      bottom: 0px;
      margin: auto;
      margin-top: 0px !important;
      border: 1px solid;
      left: 0px;
      margin-left: 0px !important;

      width: 100vw;
      height: 100vh;
    }
    @page {
      size: portrait;
    }
  }
`;

export const PrintImage = styled.img`
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: block;
  margin-left: 25px;
`;

export const FooterContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 20px;
  left: 25px;
`;

export const BodyContainer = styled.div`
  margin-left: 25px;
  margin-top: -10px;
`;

export const PrintSection = styled(Grid)`
  padding: 13px 9px;
  display: flex;
  margin-left: 0px;
  padding-left: 0px;
  position: relative;

  padding: 10px 5px;

  &:not(:nth-child(1)):nth-child(odd) {
    padding-left: 10px;
  }

  &.MuiGrid-root.MuiGrid-root > .MuiGrid-item {
    padding-left: 5px;
  }

  &.MuiGrid-root::before {
    content: '';
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    width: ${(props) => (props?.containerWidth ? props.containerWidth : '99%')};
    height: 96%;
    position: absolute;
    margin-left: -7px;
    margin-top: -10px;
  }
`;

export const PrintSectionGroup = styled.div`
  border: 1px solid #f5f5f5;
  border-radius: 5px;
`;

export const PrintTextFieldLabel = styled(Typography)`
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
  margin-left: 2px;
  font-size: 10px;
  line-height: 0.5em;
  margin-top: 10px;
  font-family: 'Karla';
  margin-bottom: 7px;
`;

export const PrintTextField = styled(Box)`
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 0px;
  padding-left: 4px;
  margin-top: 5px;
  font-size: 7px;
  line-height: 1em;
  height: ${(props) => (props.rows ? props.rows : '1')}em;
  padding-left: 4px;
  color: rgba(0, 0, 0, 0.87);

  ${(props) =>
    props.rows <= 1 ||
    (!props.rows &&
      `
    padding-top: 4px;
    padding-bottom: 3px;
  `)}

  ${(props) =>
    props.rows > 1 &&
    `
    margin-bottom: 7px;
  `}
`;

export const PrintableCheckboxFormLabel = styled(FormControlLabel)`
  .MuiCheckbox-root {
    padding: 1px 10px;
    align-self: flex-start;
  }

  .MuiTypography-root {
    font-size: 12px;
    font-family: 'Karla';
  }

  .MuiSvgIcon-root {
    font-size: 16px;
    color: ${purple._900};
    margin-left: -1px;
  }

  &.Mui-disabled {
    .MuiTypography-root {
      color: black;
      font-family: 'Karla';
    }

    .MuiSvgIcon-root {
      background-color: #ededed;
    }

    .custom-unchecked-checkbox {
      background-color: #ededed;
      border: 1px solid #d6d6d6;
    }
  }
`;

export const PrintableRadioFormLabel = styled(FormControlLabel)`
  color: black;
  .MuiRadio-root {
    padding: 1px 10px;
  }

  .MuiTypography-root {
    font-size: 12px;
  }
`;

export const UncheckedRadio = styled.div`
  border-radius: 10px;
  border: 1px solid ${purple._900};
  opacity: 1;
  width: 10px;
  height: 10px;
`;

export const CheckedRadio = styled.div`
  height: 3px;
  width: 3px;
  border: 5px solid ${purple._900};
  border-radius: 50%;
`;

export const UncheckedCheckbox = styled.div`
  width: 10px;
  height: 10px;
  border: 1px solid #8c8d8d;
  border-radius: 2px;
  margin-top: 2px;
`;

export const PrintGridBody = styled(Grid)`
  width: 98%;
  margin-left: 3px;
  margin-top: 20px;

  .PrintSection-MemberInformation {
    justify-content: space-between;
    padding-right: 15px;
  }

  .PrintSection-ConnectionContactInformation {
    .PrintInputGrid-PrimaryMemberConnectionReason {
      max-width: 96% !important;
      margin-top: 8px;
    }

    .PrintInputGrid-SecondaryMemberConnectionReason {
      max-width: 96% !important;
      margin-top: -3px;
    }
  }

  .PrintSection-Medical {
    height: 200px;
    row-gap: 0px;

    .formGroup-Pharmacy {
      .label-0 {
        width: 103%;
      }
    }
    .formGroup-Dentistry {
      margin-top: -20px;
    }
    .formGroup-In-HomeCareAssistance {
      width: 113%;
      padding-left: 0px;
    }

    .PrintInputGrid-Pharmacy {
      margin-left: -35px;
    }

    .PrintInputGrid-In-HomeCareAssistance {
      margin-left: -5px;
    }

    .PrintInputGrid-DurableMedicalEquipmentDME {
      margin-top: -15px;
    }
  }

  .PrintSection-BehavioralHealth {
    row-gap: 0px;
    height: 85px;
    margin-top: -105px;
    padding-left: 10px;
  }

  .PrintSection-SDOH {
    row-gap: 0px;

    .PrintInputGrid-Housing {
      label-1 {
        width: 106%;
      }
    }
  }

  .PrintSection-ConnectionDestination {
    padding-left: 10px;
    height: 105px;
    row-gap: 0px;
    .formGroup-Community-BasedOrg {
      width: 103%;
      marginleft: -10px;
    }
  }

  .PrintSection-Benefits {
    row-gap: 0px;
    margin-top: -215px;
    height: 105px;
    padding-left: 10px;
  }

  .PrintSection-TransfertoPlanRecommended {
    row-gap: 0px;
    margin-top: -260px;
    height: 120px;
  }

  .PrintSection-DetailsAdditionalInformation {
    row-gap: 0px;
    margin-top: -135px;
    padding-left: 10px;
  }
`;

import React, { useEffect, useState } from 'react';
import useGetPositivePsychology from 'Tasking_Hooks/useGetPositivePsychology';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { psychologyContentConfig } from './psychologyContentConfig';
import { useParams } from 'react-router-dom';
import { formatDate } from 'Tasking_Helpers/formatDate';
import { StyledCircularProgress, StyledErrorMessage, StyledProgressContainer } from './styles';
import useUserMeta from 'Tasking_Hooks/useUserMeta';
import { retrieveUserMetaCategories } from 'Tasking_Helpers/userMetaHelpers';
import { mergeObjectsByMetaCategory } from './psychologyContentUtils';

const PositivePsychologyContent = () => {
  const { userId } = useParams();
  const {
    positivePsychology,
    isPending: isPositivePsychologyPending,
    error: positivePsychologyError,
    getPositivePsychology,
  } = useGetPositivePsychology();
  const { userMeta, isPending: isMetaPending, error: metaError } = useUserMeta(userId);
  let isPending = isPositivePsychologyPending || isMetaPending;
  let errorMessage = positivePsychologyError?.message || metaError?.message;
  const [metaPsychologyContent, setPsychContent] = useState(null);

  useEffect(() => {
    getPositivePsychology(userId);
  }, []);

  useEffect(() => {
    if (!isMetaPending && metaPsychologyContent == null) {
      const categories = retrieveUserMetaCategories(userMeta);
      setPsychContent(mergeObjectsByMetaCategory(psychologyContentConfig, categories));
    }
  }, [isMetaPending]);

  const buildTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table variant="outlined" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Dimension</TableCell>
              <TableCell>Content Title</TableCell>
              <TableCell>Last Accessed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{mapTableRows()}</TableBody>
        </Table>
      </TableContainer>
    );
  };

  const mapTableRows = () => {
    return metaPsychologyContent?.map((dimensions) => {
      return dimensions.items.map((item, j) => {
        return (
          <TableRow data-testid={`contentRow_${item.contentId}`} key={item.contentId}>
            {j === 0 ? (
              <TableCell data-testid={`dimensionCell_${dimensions.dimension}`} rowSpan={dimensions.items.length}>
                {dimensions.dimension}
              </TableCell>
            ) : null}
            <TableCell data-testid={`contentCellTitle_${item.contentId}`}>{item.contentTitle}</TableCell>
            <TableCell data-testid={`contentCellAccessed_${item.contentId}`}>
              {getLastAccessedDate(item.contentId)}
            </TableCell>
          </TableRow>
        );
      });
    });
  };

  const getLastAccessedDate = (contentId) => {
    let relevantInteractions = positivePsychology?.contentInteractions?.filter((interactions) => {
      return interactions.contentId === contentId;
    });

    if (relevantInteractions?.length > 0) {
      return formatDate(relevantInteractions[0].lastInteractionDateUtc);
    } else {
      return null;
    }
  };

  return isPending ? (
    <StyledProgressContainer>
      <StyledCircularProgress data-testid="circularSpinner" />
    </StyledProgressContainer>
  ) : errorMessage ? (
    <StyledErrorMessage data-testid="errorMessageContainer">{errorMessage}</StyledErrorMessage>
  ) : (
    <>
      <h1>Positive Psychology Content - Status</h1>
      <br />
      {buildTable()}
    </>
  );
};

export default PositivePsychologyContent;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MemberConnectionForm from './MemberConnectionForm/MemberConnectionForm';
import { Alert, Grid } from '@mui/material';
import useAxiosSwrFetch from 'Tasking_Hooks/useAxiosSwrFetch';
import urlcat from 'urlcat';

const getMemberConnectionPath = '/v1/memberconnections/:orgId::memberConnectionGuid';
const getNGMRequestPath = '/v5/ngms/:orgId::ngmId';

const MemberConnectionActivity = ({
  actions,
  setActions,
  setActionToDisplay,
  setCurrentTab,
  memberInfo,
  values,
  setValues,
  ngm,
}) => {
  const memberConnectionGuid = actions?.memberConnectionGuid;
  const orgId = ngm ? memberInfo.organizationId : memberInfo?.activeOrganizationId;

  const { response: ngmData, fetch: fetchNgm } = useAxiosSwrFetch({ fetchRequired: false });

  useEffect(() => {
    if (!ngm && memberInfo?.ngmId && orgId && !memberConnectionGuid) {
      fetchNgm(urlcat(process.env.API_BASE_URL, getNGMRequestPath, { orgId: orgId, ngmId: memberInfo?.ngmId }));
    }
  }, []);

  const {
    response: memberConnectionData,
    error: getMemberConnectionError,
    isLoading: isGetPending,
    fetch,
  } = useAxiosSwrFetch({ fetchRequired: false });

  useEffect(() => {
    if (memberConnectionGuid && orgId) {
      fetch(urlcat(process.env.API_BASE_URL, getMemberConnectionPath, { orgId: orgId, memberConnectionGuid }));
    }
  }, []);

  const cancelButtonCallback = () => {
    setValues({ ...values, currentTab: '', actionToDisplay: '' });
    setActionToDisplay('');
    setCurrentTab('');
  };

  const onCreateMemberConnectionCallback = (guid) => {
    setActions({ ...actions, isActive: true, memberConnectionGuid: guid });
    setActionToDisplay('');
    setCurrentTab('');
  };

  const isLoading =
    (memberConnectionGuid && !memberConnectionData) ||
    (!ngm && memberInfo?.ngmId && orgId && !memberConnectionGuid && !ngmData);

  return (
    <>
      {isLoading ? (
        <Grid item xs={12}>
          <Alert severity="info" variant="filled">
            loading member connection...
          </Alert>
        </Grid>
      ) : !isGetPending && memberConnectionGuid && getMemberConnectionError ? (
        <Grid item xs={12}>
          <Alert severity="error" variant="filled">
            {getMemberConnectionError.message ?? 'Error: Something went wrong.'}
          </Alert>
        </Grid>
      ) : (
        <MemberConnectionForm
          memberInfo={memberInfo}
          ngm={ngm}
          orgId={orgId}
          ngmData={ngmData}
          memberConnectionGuid={memberConnectionGuid}
          memberConnectionData={memberConnectionData}
          onCreateMemberConnectionCallback={onCreateMemberConnectionCallback}
          cancelButtonCallback={cancelButtonCallback}
        />
      )}
    </>
  );
};

MemberConnectionActivity.propTypes = {
  setActionToDisplay: PropTypes.func,
  setCurrentTab: PropTypes.func,
  actions: PropTypes.object,
  setActions: PropTypes.func,
  memberInfo: PropTypes.object,
  values: PropTypes.object,
  setValues: PropTypes.func,
  ngm: PropTypes.bool,
};

export default MemberConnectionActivity;

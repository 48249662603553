import React from 'react';
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { formatDate } from 'Tasking_Helpers/formatDate';
import { StyledTableContainer } from 'Tasking_Components/Thrive/ThriveMetrics/styles';
import { StyledActionsContainer } from './styles';
import { useParams } from 'react-router-dom';
import urlcat from 'urlcat';
import useGetCardHistory from 'Tasking_Hooks/useGetCardHistory';
import CenteredCircularProgress from 'Components/CenteredCircularProgress/CenteredCircularProgress';

const GreetingCards = () => {
  const { userId } = useParams();
  const { cardHistory, isGetCardHistoryPending } = useGetCardHistory(userId);

  function openGreetingCardHistoryWindow() {
    const path = `/user/${userId}/history?filter=greetingCard`;
    const target = urlcat(process.env.BASE_URL, path);
    window.open(target);
  }

  if (isGetCardHistoryPending) {
    return <CenteredCircularProgress />;
  }

  return (
    <>
      <h2>Current Greeting Card</h2>
      <StyledTableContainer component={Paper} variant="outlined">
        <Table size="small" data-testid="GreetingCardsTable">
          <TableHead>
            <TableRow>
              <TableCell>Total Greeting Cards Sent</TableCell>
              <TableCell>Last Sent Greeting Card</TableCell>
              <TableCell>Total Greeting Cards Received</TableCell>
              <TableCell>Last Received Greeting Card</TableCell>
              <TableCell>Unopened Greeting Cards?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{cardHistory?.totalCardsSent}</TableCell>
              <TableCell>{formatDate(cardHistory?.lastCardSentUtc)}</TableCell>
              <TableCell>{cardHistory?.totalCardsReceived}</TableCell>
              <TableCell>{formatDate(cardHistory?.lastCardReceivedUtc)}</TableCell>
              <TableCell>{cardHistory?.totalCardsUnopened > 0 ? 'Yes' : 'No'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </StyledTableContainer>
      <StyledActionsContainer>
        <Button variant="contained" color="primary" onClick={openGreetingCardHistoryWindow}>
          View Greeting Card History
        </Button>
      </StyledActionsContainer>
    </>
  );
};

export default GreetingCards;

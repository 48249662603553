export const memberConnectionTypes = {
  STATUS: {
    SENTTOPLANPARTNER: 'Sent To Plan Partner',
    PENDINGAPPROVAL: 'Pending Approval',
  },
};

export const historyTypes = {
  SELFREPORTEDOUTCOME: 'Self Reported Outcome',
  MEMBERCONNECTION: 'Member Connection',
  TASK: 'Task',
  NOTIFICATION: 'Notification',
  ASSISTANCEPROVIDED: 'Assistance Provided',
  MEMBERCONCERN: 'Member Concern',
  GREETINGCARD: 'Greeting Card',
  ACTIVITY: 'Activity',
  MEMBERMIGRATION: 'Member Migration',
};

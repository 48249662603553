import { questionTypes } from '../enums';

export const nonAppCaregiverConfig = {
  title: 'Non App Users - Caregiver',
  questions: [
    {
      title:
        'Has the Pyx Health program given you companionship? If YES, what part of the program gave you companionship?',
      guids: ['eaeb7357-3d67-42e3-a870-51ad30769f4d'],
      types: [questionTypes.CLOSED],
      options: ['YES', 'NO'],
      typeOptions: {
        [questionTypes.CLOSED]: {
          YES: [
            {
              title: 'If YES, what services did you use?',
              guids: ['98496104-2ebd-42f6-987d-770e8f383c1e'],
              types: [questionTypes.FREETEXT],
            },
          ],
        },
      },
    },
    {
      title:
        'On a scale of 1 to 5 (one being the least satisfied and five being the most), how satisfied are you with your experience on the Pyx Health program?',
      guids: ['5db7324e-d591-4277-af2b-b3e92cce3338'],
      types: [questionTypes.RATING],
      options: ['1', '2', '3', '4', '5'],
    },
    {
      title: 'Did the services provided by Pyx help you be a better caregiver/guardian?',
      guids: ['4c5d20a2-27f4-415c-8d9d-0e345bee4b5b'],
      types: [questionTypes.CLOSED],
      options: ['YES', 'NO'],
      typeOptions: {
        [questionTypes.CLOSED]: {
          YES: [
            {
              title: 'If so, what were the most valuable aspects of the program?',
              guids: ['74b42015-41b9-40a3-a8a4-f235a86aadbf'],
              types: [questionTypes.FREETEXT],
            },
          ],
        },
      },
    },
    {
      title:
        'Would having additional benefits for yourself through the health plan that covers the person in your care (such as training and support programs) be an incentive for you to support continued enrollment in this health plan?',
      guids: ['89bbf9f0-1e33-4688-8bf7-c1beae10edce'],
      types: [questionTypes.RATING],
      options: ['YES', 'NO'],
    },
    {
      title: 'As a caregiver/guardian - the most important resource I receive or need is...',
      guids: ['ce7c55e4-412d-4284-95fe-a00c12dccc5d'],
      types: [questionTypes.FREETEXT],
    },
    {
      title:
        'Is there anything that you would like me to know that you like, dislike, or have suggestions about regarding the app or the program?',
      guids: ['144bdb1f-8176-43b3-9960-62e70704d26f'],
      types: [questionTypes.FREETEXT],
    },
    {
      title:
        'How likely is it that you would recommend Pyx to a friend or collegue? (Scale 1-10 [1 - Not likely to recommend, 10 - Extremely likely to recommend])',
      guids: ['a23e8355-ebbd-4e2b-ac37-0b3f81558e27'],
      types: [questionTypes.RATING],
      options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    },
  ],
};

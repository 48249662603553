import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import { Paper, Snackbar } from '@mui/material';

export const StyledLoadingButton = styled(LoadingButton)`
  height: 48px;
  font-size: 16px;
  float: right;

  &:hover {
    background-image: linear-gradient(rgb(0 0 0/40%) 0 0);
  }

  &.MuiLoadingButton-loading {
    .MuiLoadingButton-loadingIndicator {
      color: #fff;
    }
  }
`;

export const StyledSnackbar = styled(Snackbar)`
  &.MuiSnackbar-root {
    position: relative;
    margin-top: 50px;
  }
`;

export const StyledContainer = styled(Paper)`
  padding: 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: max-content;
  background-color: rgba(0, 0, 0, 0.05);
  margin: 30px;
`;

export const StyledRedeterminationContainer = styled.div`
  display: inline;
  justify-content: space-between;
`;

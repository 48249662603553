import React from 'react';
import { useThriveState } from 'Tasking_Contexts/ThriveContext';
import { StyledNavButton, StyledNavContainer } from './styles';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import urlcat from 'urlcat';
import {
  ENROLLED_THRIVE,
  ENROLLED_THRIVE_METRICS,
  ENROLLED_THRIVE_PSYCHOLOGYCONTENT,
  ENROLLED_THRIVE_SCREENING,
} from '../../../../initialization/Routes/RoutesConfig';

function ThriveNavigation() {
  const thriveState = useThriveState();
  const navigate = useNavigate();
  const { userId } = useParams();
  const location = useLocation();

  const thriveConfig = {
    TABS: [
      {
        name: 'Member Functions',
        key: 'member-functions',
        component: 'ThriveMemberFunctions',
        route: urlcat(ENROLLED_THRIVE, { userId: userId }),
        default: true,
        thriveStates: ['ACTIVE', 'GRADUATE', 'DROPOUT'],
      },
      {
        name: 'Screening',
        key: 'screening',
        component: 'ThriveScreening',
        route: urlcat(ENROLLED_THRIVE_SCREENING, { userId: userId }),
        thriveStates: ['ACTIVE', 'DROPOUT'],
      },
      {
        name: 'Positive Psychology Content',
        key: 'psychology-content',
        component: 'PsychologyContent',
        route: urlcat(ENROLLED_THRIVE_PSYCHOLOGYCONTENT, { userId: userId }),
        thriveStates: ['ACTIVE', 'DROPOUT'],
      },
      {
        name: 'Thrive Metrics',
        key: 'thrive-metrics',
        component: 'ThriveMetrics',
        route: urlcat(ENROLLED_THRIVE_METRICS, { userId: userId }),
        thriveStates: ['ACTIVE', 'GRADUATE', 'DROPOUT'],
      },
    ],
  };

  function getTabs() {
    if (thriveState?.thriveStatus?.thriveStatus) {
      return thriveConfig.TABS.filter((t) => t.thriveStates.includes(thriveState?.thriveStatus?.thriveStatus));
    } else {
      return thriveConfig.TABS.filter((t) => t.default);
    }
  }

  const handleButtonClick = (t) => {
    if (t.key === 'thrive-metrics') {
      navigate(urlcat(ENROLLED_THRIVE_METRICS, { userId: userId }));
    }
    if (t.key === 'screening') {
      navigate(urlcat(ENROLLED_THRIVE_SCREENING, { userId: userId }));
    }
    if (t.key === 'psychology-content') {
      navigate(urlcat(ENROLLED_THRIVE_PSYCHOLOGYCONTENT, { userId: userId }));
    }
    if (t.key === 'member-functions') {
      navigate(urlcat(ENROLLED_THRIVE, { userId: userId }));
    }
  };

  return (
    <StyledNavContainer>
      {getTabs().map((t) => (
        <StyledNavButton
          key={t.key}
          variant="contained"
          active={location.pathname === t.route}
          onClick={() => handleButtonClick(t)}
        >
          {t.name}
        </StyledNavButton>
      ))}
    </StyledNavContainer>
  );
}

export default ThriveNavigation;

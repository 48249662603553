import React from 'react';
import {
  ModalContainer,
  ModalContent,
  SubmitButton,
  CancelButton,
  ModalButtonsContainer,
  ModalInnerContent,
  ModalTextContent,
  SelectStyle,
  SectionHeader,
  SpinnerButton,
} from './styles';

import Spinner from 'Components/Spinner/Spinner';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

const Modal = ({ display, setModal, isCompleteTaskPending, enums, closeReasonPicked }) => {
  const { register, handleSubmit } = useForm();

  const onSubmit = (e) => {
    closeReasonPicked(e);
  };

  const displayData = () => {
    if (display)
      return (
        <ModalContent data-testid="ModalContent">
          <ModalInnerContent>
            <span style={{ color: 'white' }}>Complete Task</span>
          </ModalInnerContent>
          <ModalTextContent>
            <div style={{ marginTop: '10px', width: '80%' }}>
              <form data-testid="TaskForm" onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '-10px' }}>
                <div style={{ marginTop: '10px' }} />
                <SectionHeader id="CloseReasonLabel">Close Reason:</SectionHeader>
                <div style={{ marginTop: '15px' }} />

                <SelectStyle
                  {...register('CloseReason')}
                  type="text"
                  id="CloseReason"
                  aria-labelledby="CloseReasonLabel"
                >
                  {enums?.TaskCloseReason.map((data) => {
                    return <option key={data.code} value={`${data.code}`}>{`${data.displayName}`}</option>;
                  })}
                </SelectStyle>

                <ModalButtonsContainer style={{ marginTop: '-3px' }}>
                  {isCompleteTaskPending ? (
                    <SpinnerButton data-testid="Spinner" submitSpinner>
                      <Spinner />
                    </SpinnerButton>
                  ) : (
                    <SubmitButton data-testid="SubmitButton" name={'submit'} value={'Complete'} type="submit" />
                  )}

                  <CancelButton
                    onClick={() => {
                      setModal(false);
                    }}
                    value="Clear"
                    type="reset"
                    data-testid="CancelButton"
                  >
                    Cancel
                  </CancelButton>
                </ModalButtonsContainer>
              </form>
            </div>
          </ModalTextContent>
        </ModalContent>
      );
  };

  return <ModalContainer data-testid="ModalContainer">{displayData()}</ModalContainer>;
};

Modal.propTypes = {
  display: PropTypes.bool,
  setModal: PropTypes.func,
  setNewTaskClicked: PropTypes.func,
  setSavedTaskClicked: PropTypes.func,
  enums: PropTypes.object,
  closeReasonPicked: PropTypes.func,
  isCompleteTaskPending: PropTypes.bool,
};

export default Modal;

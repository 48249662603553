import styled from 'styled-components';

export const SearchBoxContainer = styled.div`
  width: 100%;
  height: auto;
  background-color: white;
`;

export const SearchFormContainer = styled.div`
  padding-top: 16px;
`;

export const PageTitle = styled.h2`
  color: #484d87;
  margin-bottom: 39px;
  font-size: 32px;
  font-weight: bold;
  font-family: Karla;
  margin-left: 95px;
`;

export const SearchRowOne = styled.div`
  display: flex;
  margin-left: 95px;
`;

export const SearchRowTwo = styled.div`
  display: flex;
  margin-top: 14px;
  margin-left: 95px;
`;

export const Label = styled.label`
  color: #0f0f0f;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  height: min-content;
`;

export const InputStyle = styled.input`
  width: ${(props) => (props.DOB ? '145px' : '231px')};
  height: 32px;
  margin-right: 72px;
  border-radius: 9px;
  border: 1px solid #e9e7e7;
`;

export const SelectStyle = styled.select`
  width: 256px;
  height: 37px;
  margin-right: 15px;
  border-radius: 9px;
  border-color: #e9e7e7;
  margin-right: 72px;
`;

export const SearchRowThree = styled.div`
  display: flex;
  margin-top: 32px;
  align-items: center;
  margin-left: ${(props) => (props.error ? '530px' : '925px')};
`;

export const FormButton = styled.input`
  background-color: ${(props) => (props.clear ? 'white' : '#4a4f7f')};
  color: ${(props) => (props.clear ? '#4a4f7f' : 'white')};
  width: 125px;
  height: 30px;
  border: 1px solid #4a4f7f;
  border-radius: 25px;
  font-family: Karla, sans-serif;
  cursor: pointer;
  outline: none;
  margin-left: 14px;
`;

export const SpinnerButton = styled.div`
  background-color: ${(props) => (props.clear ? 'white' : '#4a4f7f')};
  color: ${(props) => (props.clear ? '#4a4f7f' : 'white')};
  width: 125px;
  height: 30px;
  border: 1px solid #4a4f7f;
  border-radius: 25px;
  font-family: Karla, sans-serif;
  outline: none;
  margin-left: 14px;
`;

export const Divder = styled.div`
  background-color: #f2f2f2;
  height: 3px;
  width: 100%;
  margin-top: 32px;
`;

export const CustomError = styled.div`
  color: red;
`;

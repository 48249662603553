import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

const userMetricsUrl = 'v1/internalusermetrics';
const options = {
  revalidateOnFocus: false,
};

const getUserMetrics = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useUserMetrics = () => {
  const [isPending, setIsPending] = useState(true);
  const [userMetrics, setUserMetrics] = useState();
  const currentDate = new Date();
  const [startDate] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
  const [endDate] = useState(new Date());
  const { data: userMetricsResult, error } = useSWR(
    () =>
      `${
        process.env.API_BASE_URL
      }/${userMetricsUrl}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
    getUserMetrics,
    {
      ...options,
    },
  );

  useEffect(() => {
    if (userMetricsResult) {
      let totalEnrollmentBonus = 0.0;
      userMetricsResult.data.enrollment.forEach((item) => {
        totalEnrollmentBonus += item.enrollmentBonus;
      });

      setUserMetrics({ totalEnrollmentBonus: totalEnrollmentBonus });
    }

    setIsPending(!userMetricsResult && !error);
  }, [userMetricsResult, error]);

  return { userMetrics: userMetrics, error, isPending };
};

export default useUserMetrics;

import { ApiService } from 'Administrator_Helpers/ApiService';

export class MigrationRequestsApiDataService {
  private static _instance: MigrationRequestsApiDataService = new MigrationRequestsApiDataService();
  private api: ApiService;

  constructor() {
    // Make sure we don't create more than one instance of this service
    if (MigrationRequestsApiDataService._instance) {
      return MigrationRequestsApiDataService._instance;
    }
    this.api = new ApiService();
  }

  public getMigrationEnums = async () => {
    const { response, error } = await this.api.get('/v1/migrationrequests/enums', null);
    return { response, error };
  };

  public retryMigrationRequest = async (request: { migrationGuid: string }) => {
    const { response, error } = await this.api.put('/v1/migrationrequests/:migrationGuid/retry', request, null);
    return { response, error };
  };

  public searchMigrationRequests = async (request: { status: string; fetch: number; offset: number }) => {
    const { response, error } = await this.api.post('/v1/migrationrequests/search', null, request);
    return { response, error };
  };

  public createMigrationRequest = async (request: {
    userId: number;
    sourceNgmId: number;
    sourceOrganizationId: number;
    sourceGroupId: number;
    destinationOrganizationId: number;
    destinationGroupId: number;
    destinationNgmId?: number;
    profileMigrationType: string;
    dataMigrationType: string;
  }) => {
    const { response, error, success } = await this.api.post('/v1/migrationrequests', null, request);
    return { response, error, success };
  };

  public deleteMigrationRequest = async (request: { migrationGuid: string }) => {
    const { response, error } = await this.api.delete('/v1/migrationrequests/:migrationGuid', request);
    return { response, error };
  };
}

import styled from '@emotion/styled';
import { Button, Skeleton } from '@mui/material';

export const DataGridItem = styled(Button)`
  display: flex;
  padding: 10px;
  border-bottom: 0.75px solid rgb(228, 230, 227);
  color: rgb(15, 20, 91);
  width: 100%;
  font-size: 16px;
  text-align: left;
  padding-left: 15px;
`;

export const DataGridContainer = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-flow: column;
  position: relative;
`;

export const LoadingGrid = styled(Skeleton)`
  width: 100%;
  position: absolute;
  height: 100%;
  transform: scale(1);
  border-radius: 0;
  pointer-events: none;
  cursor: wait;
  pointer-events: auto;
  z-index: 1;
`;

import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';

const useUpdateMemberConnectionStatus = (guid, orgId) => {
  const [request, setRequest] = useState();
  const url = `${process.env.API_BASE_URL}/v1/memberconnections/${orgId}:${guid}/status`;
  const { response, isLoading, hasErrored, errorData } = useAxiosSubmit(url, request, 'put');

  const updateMemberConnectionStatus = async (request) => {
    setRequest(request);
  };

  return { response, hasErrored, isLoading, updateStatusError: errorData, updateMemberConnectionStatus };
};

export default useUpdateMemberConnectionStatus;

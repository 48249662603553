import { ApiService } from 'Tasking_Helpers/ApiService';
import { retryRequest } from 'Helpers/retryRequest';

export class CallLogApiService {
  private static _instance: CallLogApiService = new CallLogApiService();
  private api: ApiService;

  constructor() {
    // Make sure we don't create more than one instance of this service
    if (CallLogApiService._instance) {
      return CallLogApiService._instance;
    }

    this.api = new ApiService();
  }

  public getCallLog = async (organizationId: number, callLogGuid, retries = 1) => {
    const { response, error } = await retryRequest(
      () => this.api.get('/v1/calllogs/:organizationId::callLogGuid', { organizationId, callLogGuid }),
      retries,
    );
    return { response, error };
  };

  public createCallLog = async (request: { AgentUserId; StartedOnUtc; OrganizationId }) => {
    const { response, error } = await retryRequest(() => this.api.post('/v1/calllogs', null, request));
    return { response, error };
  };

  public updateCallLog = async (
    organizationId: number,
    callLogGuid,
    request: { AgentUserId; StartedOnUtc; OrganizationId; EndedOnUtc },
  ) => {
    const { response, error } = await retryRequest(() =>
      this.api.put('/v1/calllogs/:organizationId::callLogGuid', { organizationId, callLogGuid }, request),
    );
    return { response, error };
  };
}

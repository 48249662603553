import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';

const useNgmSearch = () => {
  const [ngmNembersSearchData, setNgmMembersSearchData] = useState();
  const [url, setUrl] = useState();
  const { response, isLoading, hasErrored } = useAxiosSubmit(url, ngmNembersSearchData);

  const ngmSearch = async (request) => {
    setUrl(`${process.env.API_BASE_URL}/v5/ngms/search`);
    setNgmMembersSearchData(request);
  };

  return { ngmList: response, error: hasErrored, ngmIsPending: isLoading, ngmSearch };
};

export default useNgmSearch;

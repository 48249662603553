export const questions = [
  {
    name: '754dc683-9c20-42d5-93af-81ae850b16e9',
    text: 'Over the last 2 weeks, how often have you been bothered by any of the following problems? \nFeeling nervous, anxious or on edge',
    options: [
      {
        name: '7904d60b-dcd0-4bf1-b072-b2dc47cc9e57',
        text: 'Not at all',
      },
      {
        name: '514b73d7-64c3-47e3-b180-220b1747fe2a',
        text: 'Several Days',
      },
      {
        name: 'b1465b7d-6236-4479-b2fb-ca19b885f082',
        text: 'More than half the days',
      },
      {
        name: '046f44e9-9ec0-4cc6-b306-65f0e2903e35',
        text: 'Nearly every day',
      },
    ],
  },
  {
    name: '35428c17-ed8b-4d80-8b92-536a0baf61c5',
    text: 'Over the last 2 weeks, how often have you been bothered by any of the following problems? \nNot being able to stop or control worrying',
    options: [
      {
        name: '0da0aaa7-baa2-43ee-a202-6705f1e2a2d5',
        text: 'Not at all',
      },
      {
        name: 'c3415c30-3581-44b1-8a39-a2050e799232',
        text: 'Several Days',
      },
      {
        name: 'dee6badb-ed75-4112-9458-4ee46cf98f0c',
        text: 'More than half the days',
      },
      {
        name: '3cfe4bf4-4406-4d3a-ace0-75cff972702a',
        text: 'Nearly every day',
      },
    ],
  },
  {
    name: 'a7186c55-f43e-4b87-8c4a-910ceee2e89e',
    text: 'Over the last 2 weeks, how often have you been bothered by any of the following problems? \nLittle interest or pleasure in doing things',
    options: [
      {
        name: 'e2200bce-fe3b-4d4b-a5f5-095658fde69f',
        text: 'Not at all',
      },
      {
        name: '8e6ab95b-c1c3-4a70-8669-52b8aa7da689',
        text: 'Several Days',
      },
      {
        name: 'edd7daf9-5a8a-4ddf-8876-7b292968402b',
        text: 'More than half the days',
      },
      {
        name: '80d003ea-09b9-42ec-871c-0b7f39194539',
        text: 'Nearly every day',
      },
    ],
  },
  {
    name: 'a5d4716a-9447-485f-8f32-5ddd4c52996c',
    text: 'Over the last 2 weeks, how often have you been bothered by any of the following problems? \nFeeling down, depressed, or hopeless',
    options: [
      {
        name: '04c17da6-c85c-4037-94d4-1d6f94f0f66a',
        text: 'Not at all',
      },
      {
        name: '38f251fd-e919-42f6-bb97-d36c8c5abc79',
        text: 'Several Days',
      },
      {
        name: 'ef050dc7-7333-4990-8169-0d792db680f1',
        text: 'More than half the days',
      },
      {
        name: '5066f3fe-5bb5-438f-a8ba-4a2223581a12',
        text: 'Nearly every day',
      },
    ],
  },
];

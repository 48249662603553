import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import { Typography, Paper, FormControlLabel } from '@mui/material';
import { purple } from 'Styles/colors';

export const Header = styled(Typography)`
  color: #484d87;
`;

export const SectionHeader = styled(Typography)`
  color: black;
`;

export const StyledLoadingButton = styled(LoadingButton)`
  height: 48px;

  &.MuiLoadingButton-loading {
    background-color: ${purple._900};

    .MuiLoadingButton-loadingIndicator {
      color: #fff;
    }
  }
`;

export const StyledFormLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 12px;
    color: ${(props) => (props.disabled ? '#8A8A8A' : '#605f76')};
  }
`;

export const StyledPaper = styled(Paper)`
  padding: 32px 16px;
  border-radius: 5px;
  height: 85%;
`;

export const UncheckedRadio = styled.div`
  border-radius: 10px;
  border: 1px solid ${(props) => (props.disabled ? '#8A8A8A' : purple._900)};
  opacity: 1;
  width: 18px;
  height: 18px;
`;

export const CheckedRadio = styled.div`
  border-radius: 10px;
  opacity: 1;
  width: 20px;
  height: 20px;
  background: radial-gradient(circle at 50% 50%, #fff 4px, transparent 5px),
    ${(props) => (props.disabled ? '#8A8A8A' : purple._900)};
`;

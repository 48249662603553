import React, { useState, useEffect } from 'react';
import {
  TextHeader,
  HeaderContainer,
  PageContentContainer,
  GalleryContainer,
  NavIcon,
  NavButton,
  GalleryOptionContainer,
  CardGalleryContainer,
  GalleryPreviewContainer,
  GalleryHeader,
  ButtonContainer,
  CancelButton,
  SubmitButton,
  SpinnerContainer,
  ErrorMessageContainer,
  FooterContainer,
} from './styles';
import PropTypes from 'prop-types';
import useGetPremadeCards from 'Tasking_Hooks/useGetPremadeCards';
import GreetingCard from 'Tasking_Components/GreetingCard/GreetingCard';
import useSendCard from 'Tasking_Hooks/useSendCard';
import useCreateCard from 'Tasking_Hooks/useCreateCard';
import Spinner from 'Components/Spinner/Spinner';
import { parseErrorMessage } from 'Helpers/parseApiErrorMessage';

const CardGallery = ({ setActions, setActionToDisplay, setCurrentTab, actions, memberTaskInfo, setValues, values }) => {
  const { cards, isSearchCardsPending, searchCardsError, searchPremadeCards } = useGetPremadeCards();
  const { cardInformation, cardIsPending, cardError, createCard } = useCreateCard();
  const { cardSendInformation, cardSendIsPending, cardSendError, sendCard } = useSendCard();
  const [currentPage, setCurrentPage] = useState(values?.greetingCard?.currentPage ?? 1);
  const [init, setInit] = useState(false);

  const PAGE_SIZE = 2;

  useEffect(() => {
    if (currentPage !== values?.greetingCard?.currentPage) {
      setInit(true);
      updateValue('currentPage', currentPage);
      searchPremadeCards({
        Offset: (currentPage - 1) * PAGE_SIZE,
        Fetch: PAGE_SIZE * 2,
      });
    }
  }, [currentPage]);

  useEffect(() => {
    if (cards && (init || !values?.greetingCard)) {
      setValues({
        ...values,
        greetingCard: {
          ...values?.greetingCard,
          cards: cards.slice(0, PAGE_SIZE),
          nextCards: cards.slice(PAGE_SIZE, PAGE_SIZE * 2),
        },
      });
      setInit(false);
    }
  }, [cards, currentPage]);

  useEffect(() => {
    if (cardInformation) {
      updateValue('greetingCardId', cardInformation);
      sendCard(cardInformation, { SentToUserId: memberTaskInfo?.userId });
    }
  }, [cardIsPending]);

  useEffect(() => {
    if (cardSendInformation !== null) {
      setActions({ ...actions, isActive: true, greetingCardId: values?.greetingCard?.greetingCardId });
      setActionToDisplay('');
      setCurrentTab('');
    }
  }, [cardSendIsPending]);

  const updateValue = (name, value) => {
    setValues({ ...values, greetingCard: { ...values?.greetingCard, [name]: value } });
  };

  const backwardPageButton = (e) => {
    e.preventDefault();
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const forwardPageButton = (e) => {
    e.preventDefault();
    if (currentPage !== Math.ceil(values?.greetingCard?.cards?.count / PAGE_SIZE)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const setSelectedCard = (card) => {
    updateValue('selectedCard', card);
  };

  const cancelSelected = (e) => {
    e.preventDefault();
    setValues({ ...values, currentTab: '', actionToDisplay: '', greetingCard: undefined });
    setActionToDisplay('');
    setCurrentTab('');
  };

  const cancelSelectedCard = () => {
    updateValue('selectedCard', undefined);
  };

  const isPreview = () => {
    return actions?.greetingCardId || values?.greetingCard?.selectedCard;
  };

  const onSendClick = () => {
    if (values?.greetingCard?.selectedCard && memberTaskInfo?.userId) {
      createCard({
        MessageId: values?.greetingCard?.selectedCard?.cardMessageId,
        ImageId: values?.greetingCard?.selectedCard?.cardImageId,
      });
    }
  };

  const showGallery = () => {
    return (
      <>
        {(!values?.greetingCard && isSearchCardsPending) || cardIsPending || cardSendIsPending ? (
          <SpinnerContainer data-testid={'Spinner'}>
            <Spinner marginLeft={'0'} height={'auto'} width={'auto'} />
          </SpinnerContainer>
        ) : values?.greetingCard?.cards ? (
          <>
            <GalleryContainer>
              <GalleryOptionContainer>
                <NavButton data-testid={'nav_back'} disabled={currentPage === 1} onClick={backwardPageButton}>
                  <NavIcon className="fas fa-chevron-left" />
                </NavButton>
              </GalleryOptionContainer>
              <GalleryOptionContainer style={{ flex: 1 }}>
                <GalleryHeader>Select a Card</GalleryHeader>
                <CardGalleryContainer>
                  {values?.greetingCard?.cards?.map((data, i) => {
                    return (
                      <GalleryPreviewContainer
                        data-testid={`GreetingCard_${i}`}
                        key={`GreetingCard_${i}`}
                        onClick={() => setSelectedCard(data)}
                      >
                        <GreetingCard cardId={data?.cardId} maxSize={'200px'} cardFrom={'Andy'} />
                      </GalleryPreviewContainer>
                    );
                  })}
                </CardGalleryContainer>
              </GalleryOptionContainer>
              <GalleryOptionContainer>
                <NavButton
                  data-testid={'nav_forward'}
                  disabled={values?.greetingCard?.nextCards?.length < 1}
                  onClick={forwardPageButton}
                >
                  <NavIcon className="fas fa-chevron-right" />
                </NavButton>
              </GalleryOptionContainer>
            </GalleryContainer>
          </>
        ) : null}
      </>
    );
  };

  const showPreview = () => {
    const cardId = actions?.greetingCardId ? actions?.greetingCardId : values?.greetingCard?.selectedCard?.cardId;
    return (
      <>
        <PageContentContainer preview>
          <GreetingCard cardId={cardId} maxSize={'275px'} cardFrom={'Andy'} />
        </PageContentContainer>
      </>
    );
  };

  const showButtons = () => {
    if (isPreview()) {
      return actions?.greetingCardId ? (
        <SubmitButton type="reset" onClick={cancelSelected}>
          Done
        </SubmitButton>
      ) : (
        <>
          <CancelButton type="reset" onClick={cancelSelectedCard}>
            Cancel
          </CancelButton>
          <SubmitButton onClick={onSendClick}>Send</SubmitButton>{' '}
        </>
      );
    } else {
      return (
        <CancelButton type="reset" onClick={cancelSelected}>
          Cancel
        </CancelButton>
      );
    }
  };

  return (
    <>
      <HeaderContainer>
        <TextHeader>{isPreview() ? 'Card Preview' : 'Card Gallery'}</TextHeader>
      </HeaderContainer>
      <PageContentContainer preview={isPreview()}>{isPreview() ? showPreview() : showGallery()}</PageContentContainer>
      <FooterContainer preview={isPreview()}>
        <ButtonContainer preview={isPreview()}>{showButtons()}</ButtonContainer>
        <ErrorMessageContainer preview={isPreview()}>
          {searchCardsError || cardError || cardSendError
            ? parseErrorMessage(searchCardsError ? searchCardsError : cardError ? cardError : cardSendError)
            : ''}
        </ErrorMessageContainer>
      </FooterContainer>
    </>
  );
};

CardGallery.propTypes = {
  setActionToDisplay: PropTypes.func,
  setCurrentTab: PropTypes.func,
  setActions: PropTypes.func,
  actions: PropTypes.object,
  memberTaskInfo: PropTypes.object,
  values: PropTypes.object,
  setValues: PropTypes.func,
};

export default CardGallery;

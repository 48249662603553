import styled from 'styled-components';
import { Button } from '@mui/material';
import { Check } from '@mui/icons-material';

export const StyledButton = styled(Button)`
  border-radius: 25px;
  width: 150px;
`;

export const StyledSaveButton = styled(Button)`
  border-radius: 25px;
  width: 150px;
  margin: 20px 0;
  float: right;
`;

export const StyledOptionContainer = styled.div`
  margin-left: 50px;
`;

export const StyledDimensionContainer = styled.div`
  display: grid;
  grid-template-columns: 300px 300px;
  grid-auto-rows: auto;
  margin-left: 25px;
`;

export const StyledContentColumn = styled.p`
  grid-column: 1;
`;

export const StyledOptionColumn = styled.div`
  grid-column: 2;
`;

export const StyledDimensionSubheader = styled.p`
  font-weight: bold;
`;

export const StyledCheck = styled(Check)`
  padding-top: 8px;
  padding-left: 5px;
`;

export const StyledInputContainer = styled.div`
  display: block;
  width: 80%;
`;

import { appCaregiverConfig } from './App/appCaregiverConfig';
import { appGeneralConfig } from './App/appGeneralConfig';
import { nonAppCaregiverConfig } from './NonApp/nonAppCaregiverConfig';
import { nonAppGeneralConfig } from './NonApp/nonAppGeneralConfig';
import { populationTypes, userTypes } from './enums';

export const selfReportedOutcomeConfig = {
  [userTypes.APP]: {
    [populationTypes.GENERAL]: appGeneralConfig,
    [populationTypes.CAREGIVER]: appCaregiverConfig,
  },
  [userTypes.NONAPP]: {
    [populationTypes.GENERAL]: nonAppGeneralConfig,
    [populationTypes.CAREGIVER]: nonAppCaregiverConfig,
  },
};

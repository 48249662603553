import { useEffect, useReducer } from 'react';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case 'SUBMIT_INIT':
      return { ...state, isLoading: true, isError: false };
    case 'SUBMIT_SUCCESS':
      return {
        ...state,
        isLoading: false,
        hasErrored: false,
        errorMessage: '',
        response: action.payload,
        requestCompleted: true,
      };
    case 'SUBMIT_FAILURE':
      return {
        ...state,
        isLoading: false,
        hasErrored: true,
        errorData: action.payload,
        errorMessage: 'Data Submission Failed',
      };
  }
};

const useAxiosSubmit = (url, data, option) => {
  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    hasErrored: false,
    errorMessage: '',
    response: null,
  });

  useEffect(() => {
    let didCancel = false;

    const submitData = async () => {
      dispatch({ type: 'SUBMIT_INIT' });

      try {
        const authorizedApi = await getAuthClient();
        let response;
        option === 'put'
          ? (response = await authorizedApi.put(url, data))
          : (response = await authorizedApi.post(url, data));

        if (!didCancel) {
          dispatch({ type: 'SUBMIT_SUCCESS', payload: response.data });
        }
      } catch (err) {
        if (!didCancel) {
          dispatch({ type: 'SUBMIT_FAILURE', payload: err?.response?.data });
        }
      }
    };

    if (data) {
      submitData();
    }

    return () => {
      didCancel = true;
    };
  }, [data]);

  return { ...state };
};

export default useAxiosSubmit;

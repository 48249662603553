import { Radio, RadioGroup } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { PrintableRadioFormLabel, UncheckedRadio, CheckedRadio } from '../../styles';

function PrintableRadioGroup({ value, options, ...props }) {
  return (
    <RadioGroup value={value} {...props} className={value}>
      {options.map((option, key) => (
        <PrintableRadioFormLabel
          value={option?.value}
          key={key}
          label={option?.name}
          control={
            <Radio
              className={`Radio-${key}`}
              checked={value === option?.value}
              icon={<UncheckedRadio />}
              checkedIcon={<CheckedRadio />}
            />
          }
        />
      ))}
    </RadioGroup>
  );
}

PrintableRadioGroup.displayName = 'PrintableRadioGroup';

PrintableRadioGroup.propTypes = {
  value: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.object),
};

export default PrintableRadioGroup;

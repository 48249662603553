import { Grid, List, ListItem, ListItemAvatar } from '@mui/material';
import React from 'react';
import OutcomeAnswer from '../OutcomeAnswer/OutcomeAnswer';
import OutcomeQuestion from '../OutcomeQuestion/OutcomeQuestion';
import { questionTypes } from '../Configuration/enums';
import { NumberAvatar } from '../styles';
import PropTypes from 'prop-types';

function OutcomeItem({ control, question, onChange, values, index }) {
  const { guids, typeOptions, types, title, options, questionDependencies } = question;

  const getLetterIndex = (letterIndex) => {
    return String.fromCharCode(((letterIndex + 1 - 1) % 26) + 65);
  };

  const questionDisabled =
    questionDependencies &&
    (!values || Object.keys(questionDependencies).filter((x) => questionDependencies[x] !== values[x])?.length > 0);

  const firstType = types[0];
  const currentOption = values && values[guids[0]];

  return (
    <Grid item xs={12}>
      <List>
        <ListItem sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <ListItemAvatar sx={{ alignSelf: 'flex-start' }}>
            <NumberAvatar>{index + 1}</NumberAvatar>
          </ListItemAvatar>
          <OutcomeQuestion title={title} disabled={questionDisabled} type={OutcomeQuestion.type.PRIMARY}>
            <OutcomeAnswer
              options={options}
              type={firstType}
              control={control}
              name={guids[0]}
              onUpdate={onChange}
              disabled={questionDisabled}
            />
            {firstType === questionTypes.CLOSED && (
              <>
                {options?.map((option, questionIndex) => {
                  const isDisabled = questionDisabled || currentOption !== option;
                  const currentSubQuestions = typeOptions[firstType][option];
                  return currentSubQuestions?.map(
                    (
                      { title: subTitle, guids: subGuids, typeOptions: subTypeOptions, types: subTypes },
                      subQuestionIndex,
                    ) => (
                      <OutcomeQuestion
                        key={subQuestionIndex}
                        disabled={isDisabled}
                        title={`${index + 1}.${getLetterIndex(questionIndex + subQuestionIndex)} ${subTitle}`}
                      >
                        {subTypes?.map((type, subQuestionTypeIndex) => (
                          <OutcomeAnswer
                            key={subQuestionTypeIndex}
                            name={subGuids[subQuestionTypeIndex]}
                            control={control}
                            onUpdate={onChange}
                            type={type}
                            disabled={isDisabled}
                            options={subTypeOptions ? subTypeOptions[type]?.options : undefined}
                          />
                        ))}
                      </OutcomeQuestion>
                    ),
                  );
                })}
              </>
            )}
          </OutcomeQuestion>
        </ListItem>
      </List>
    </Grid>
  );
}

OutcomeItem.propTypes = {
  control: PropTypes.object,
  question: PropTypes.object,
  onChange: PropTypes.func,
  values: PropTypes.object,
  index: PropTypes.number,
};

export default OutcomeItem;

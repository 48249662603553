import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';

const useSendNgmText = () => {
  const [ngmTextData, setNgmTextData] = useState();
  const [url, setUrl] = useState();
  const { response, isLoading, hasErrored } = useAxiosSubmit(url, ngmTextData);

  const sendNgmText = async (request) => {
    setUrl(`${process.env.API_BASE_URL}/v1/notification-requests/ngm`);
    setNgmTextData(request);
  };

  return { response, error: hasErrored, isPending: isLoading, sendNgmText };
};

export default useSendNgmText;

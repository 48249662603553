import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TextHeader,
  CreateMemberConcernsDataContainer,
  DataColumn,
  SectionHeader,
  HeaderContainer,
  TextAreaStyle,
  SubmitButtonContainer,
  SubmitButton,
  SpinnerButton,
  CancelButton,
  ErrorMessage,
} from './styles';

import Spinner from 'Components/Spinner/Spinner';
import { useForm } from 'react-hook-form';
import useCreateMemberConcern from 'Tasking_Hooks/useCreateMemberConcern';
import useGetMemberConcern from 'Tasking_Hooks/useGetMemberConcern';
import useEditMemberConcern from 'Tasking_Hooks/useEditMemberConcern';
import { parseErrorMessage } from 'Helpers/parseApiErrorMessage';

const memberConcernOptionsRow1 = ['Food', 'Income', 'Access to Care', 'DME', 'Benefit Questions'];
const memberConcernOptionsRow2 = ['Housing', 'Employment', 'Crisis', 'Behavioral Health Needs', 'Other'];

const MemberConcerns = ({
  actions,
  setActions,
  setActionToDisplay,
  setCurrentTab,
  memberTaskInfo,
  values,
  setValues,
}) => {
  const { register, handleSubmit } = useForm();
  const { memberConcernGuid, isCreatePending, memberConcernErrorMessage, createMemberConcern } =
    useCreateMemberConcern();
  const { editError, isEditPending, editConcernErrorMessage, editMemberConcern } = useEditMemberConcern();
  const { memberConcernDetail } = useGetMemberConcern(memberTaskInfo?.activeOrganizationId, actions?.concernGuid);

  useEffect(() => {
    if (memberConcernGuid && !isCreatePending) {
      setActions({ ...actions, isActive: true, concernGuid: memberConcernGuid });
      setActionToDisplay('');
      setCurrentTab('');
    }
  }, [isCreatePending]);

  useEffect(() => {
    if (memberConcernDetail && !isEditPending && !editError) {
      setActionToDisplay('');
      setCurrentTab('');
    }
  }, [isEditPending]);

  useEffect(() => {
    if (memberConcernDetail) {
      setNewValues();
    }
  }, [memberConcernDetail]);

  const updateValue = (name, value) => {
    setValues({ ...values, memberConcerns: { ...values?.memberConcerns, [name]: value } });
  };

  const parseError = (errorMessage) => {
    if (errorMessage.length > 0) {
      return parseErrorMessage(errorMessage);
    }
  };

  const onSubmit = () => {
    let valuesForSubmit = {
      Food: values?.memberConcerns?.Food,
      Income: values?.memberConcerns?.Income,
      AccessToCare: values?.memberConcerns?.AccesstoCare,
      Dme: values?.memberConcerns?.DME,
      BenefitQuestions: values?.memberConcerns?.BenefitQuestions,
      Housing: values?.memberConcerns?.Housing,
      Employment: values?.memberConcerns?.Employment,
      Crisis: values?.memberConcerns?.Crisis,
      BehavioralHealthNeeds: values?.memberConcerns?.BehavioralHealthNeeds,
      Other: values?.memberConcerns?.Other,
      OtherText: values?.memberConcerns?.othertext,
    };

    if (actions?.concernGuid) {
      let endUrl = `${memberTaskInfo?.activeOrganizationId}:${actions?.concernGuid}`;
      editMemberConcern(valuesForSubmit, endUrl);
    } else {
      valuesForSubmit = {
        ...valuesForSubmit,
        OrganizationId: memberTaskInfo?.activeOrganizationId,
        ProfileId: memberTaskInfo?.activeProfileId,
      };
      createMemberConcern(valuesForSubmit);
    }
  };

  const setNewValues = () => {
    setDefaultValues(memberConcernOptionsRow1, memberConcernDetail);
    setDefaultValues(memberConcernOptionsRow2, memberConcernDetail);

    if (memberConcernDetail.other) {
      updateValue('Other', true);
      updateValue('othertext', memberConcernDetail['othertext']);
    }
  };

  const setDefaultValues = (arr, obj) => {
    for (let i = 0; i < arr.length; i++) {
      let cleanName = arr[i].replace(/\s/g, '');
      updateValue(cleanName, obj[cleanName.toLowerCase()]);
    }
  };

  const cancelSelected = (e) => {
    e.preventDefault();
    if (memberConcernDetail) {
      setValues({
        ...values,
        currentTab: '',
        actionToDisplay: '',
        memberConcerns: {
          ...values?.memberConcerns,
          AccesstoCare: memberConcernDetail?.accesstocare,
          BehavioralHealthNeeds: memberConcernDetail?.behavioralhealthneeds,
          BenefitQuestions: memberConcernDetail?.benefitquestions,
          Crisis: memberConcernDetail?.crisis,
          DME: memberConcernDetail?.dme,
          Employment: memberConcernDetail?.employment,
          Food: memberConcernDetail?.food,
          Housing: memberConcernDetail?.housing,
          Income: memberConcernDetail?.income,
          Other: memberConcernDetail?.other,
          othertext: memberConcernDetail?.othertext,
        },
      });
    } else {
      setValues({ ...values, currentTab: '', actionToDisplay: '', memberConcerns: undefined });
    }
    setActionToDisplay('');
    setCurrentTab('');
  };

  const updateCheckBox = (name, value) => {
    if (name === 'Other' && !value) {
      setValues({ ...values, memberConcerns: { ...values?.memberConcerns, [name]: value, othertext: undefined } });
    } else {
      updateValue(name, value);
    }
  };

  const displayCheckboxes = (arr) => {
    return arr.map((data) => {
      let cleanName = data.replace(/\s/g, '');
      return (
        <div key={data} style={{ width: '240px', marginRight: '44px' }}>
          <input
            {...register(cleanName)}
            onClick={() => {
              updateCheckBox(cleanName, !values?.memberConcerns?.[cleanName]);
            }}
            type="checkbox"
            data-testid={cleanName + 'Checkbox'}
            id={cleanName}
            defaultChecked={values?.memberConcerns?.[cleanName]}
          />
          <label htmlFor={data}>
            <span style={{ marginLeft: '5px', color: '#0F145B' }}>
              {data} <br />
            </span>
          </label>
        </div>
      );
    });
  };

  const displayButtons = () => {
    return (
      <SubmitButtonContainer data-testid="ButtonContainer">
        {isCreatePending || isEditPending ? (
          <SpinnerButton data-testid="SpinnerButton">
            <Spinner />
          </SpinnerButton>
        ) : (
          <SubmitButton data-testid="SubmitButton" name={'submit'} value={'Complete'} type="submit" />
        )}
        <CancelButton clear value="Clear" type="reset" data-testid="CancelButton" onClick={cancelSelected}>
          Cancel
        </CancelButton>
      </SubmitButtonContainer>
    );
  };

  return (
    <>
      <HeaderContainer>
        <TextHeader>Member Concerns</TextHeader>
      </HeaderContainer>

      <CreateMemberConcernsDataContainer data-testid="CreateMemberConcernsDataContainer">
        <form data-testid="ConcernsForm" onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '-10px' }}>
          <DataColumn>
            <div>
              <SectionHeader>Member Concerns:</SectionHeader>
              <div style={{ display: 'flex' }}>{displayCheckboxes(memberConcernOptionsRow1)}</div>
              <div style={{ marginTop: '20px' }} />
              <div style={{ display: 'flex' }}>{displayCheckboxes(memberConcernOptionsRow2)}</div>
            </div>
          </DataColumn>
          {values?.memberConcerns?.Other ? (
            <DataColumn>
              <div>
                <SectionHeader>Other Member Concerns:</SectionHeader>
                <TextAreaStyle
                  {...register('Concerns')}
                  type="textarea"
                  id="Concerns"
                  data-testid="ConcernsInput"
                  onChange={(e) => {
                    updateValue('othertext', e.target.value);
                  }}
                  defaultValue={values?.memberConcerns?.othertext}
                ></TextAreaStyle>
              </div>
            </DataColumn>
          ) : null}

          {displayButtons()}
          <ErrorMessage data-testid="ErrorMessage">
            {memberConcernErrorMessage || editConcernErrorMessage
              ? parseError(memberConcernErrorMessage ? memberConcernErrorMessage : editConcernErrorMessage)
              : ''}
          </ErrorMessage>
        </form>
      </CreateMemberConcernsDataContainer>
    </>
  );
};

MemberConcerns.propTypes = {
  setActionToDisplay: PropTypes.func,
  setCurrentTab: PropTypes.func,
  actions: PropTypes.object,
  setActions: PropTypes.func,
  memberTaskInfo: PropTypes.object,
  values: PropTypes.object,
  setValues: PropTypes.func,
};

export default MemberConcerns;

import styled from 'styled-components';

export const Customers = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  box-shadow: 1px 2px 11px 0px rgba(0, 0, 0, 0.25);
  width: 1274px;
  margin-top: 38px;
  margin-left: 85px;
`;

export const TableHeader = styled.h2`
  margin-top: 38px;
  margin-left: 85px;
  font-family: Karla;
  letter-spacing: 0.25px;
  font-size: 32px;
  font-weight: bold;
  color: #484d87;
  margin-bottom: -20px;
`;

export const CustomerData = styled.td`
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 0.75px solid #e4e6e3;
  color: #0f145b;
  font-family: Open Sans;
  border-bottom: ${(props) => (props.num === 9 ? 'None' : '')};
  padding-left: ${(props) => (props.org ? '15px' : '5px')};
`;

export const CustomerDataHeader = styled.th`
  font-family: Karla;
  letter-spacing: 0.25px;
  text-align: left;
  color: #015353;
  background-color: #f2f2f2;
  padding: 10px 5px;
  padding-left: ${(props) => (props.org ? '15px' : '')};
  width: ${(props) =>
    props.firstName ? '175px' : props.lastName ? '193px' : props.org ? '268px' : props.dob ? '193px' : '0px'};
  border: none;
`;

export const Pagination = styled.th`
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: Karla;
  letter-spacing: 0.25px;
  text-align: left;
  color: #015353;
  background-color: #f2f2f2;
  font-size: '16px';
  width: '200px';
`;

export const CustomerTableSections = styled.tr`
  &:hover {
    background-color: #d9f7f7;
  }
`;

export const NoDataMessage = styled.div`
  margin-top: 38px;
  margin-left: 85px;
  font-family: Karla;
  font-size: 18px;
  color: red;
  margin-bottom: -20px;
`;

import { AnswerTypes } from '../../enums';
import Checkbox from './Checkbox/Checkbox';
import Radio from './Radio/Radio';
import Slider from './Slider/Slider';

export const answerMap = {
  [AnswerTypes.RADIO]: Radio,
  [AnswerTypes.CHECKBOX]: Checkbox,
  [AnswerTypes.SLIDER]: Slider,
};

import styled from 'styled-components';

export const OtpCodeButton = styled.div`
  padding: 4px 10px 4px 8px;
  background-color: #4a4f7f;
  color: white;
  font-size: 12px;
  border: solid 1px #4a4f7f;
  border-radius: 18px;
  cursor: pointer;
`;

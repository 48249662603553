import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Tasking_Helpers/getAuthClient';
import urlcat from 'urlcat';

const options = {
  revalidateOnFocus: false,
};

const getCardHistory = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useGetCardHistory = (userId) => {
  const [isPending, setIsPending] = useState(true);
  const [cardHistoryResult, setCardHistoryResult] = useState();
  const getCardUrl = `v1/cards/${userId}/history`;
  const { data: cardHistoryResultData, error } = useSWR(
    () => urlcat(process.env.API_BASE_URL, getCardUrl),
    getCardHistory,
    {
      ...options,
    },
  );

  useEffect(() => {
    if (cardHistoryResultData) {
      setCardHistoryResult(cardHistoryResultData.data);
    }

    setIsPending(!cardHistoryResultData && !error);
  }, [cardHistoryResultData, error]);

  return { cardHistory: cardHistoryResult, getCardHistoryError: error, isGetCardHistoryPending: isPending };
};

export default useGetCardHistory;

import { ApiService } from 'Administrator_Helpers/ApiService';

export class OnboardingApiDataService {
  private static _instance: OnboardingApiDataService = new OnboardingApiDataService();
  private api: ApiService;

  constructor() {
    // Make sure we don't create more than one instance of this service
    if (OnboardingApiDataService._instance) {
      return OnboardingApiDataService._instance;
    }
    this.api = new ApiService();
  }

  public getCodes = async () => {
    const { response, error } = await this.api.get(`/v5/onboarding/codes/search`, null);
    return { response, error };
  };
}

import React from 'react';
import { Grid } from '@mui/material';
import { MigrationRequestModuleStore } from 'Administrator_Pages/MigrationRequests/MigrationRequestModuleStore';
import { InfoContainer, StyledPaper } from './styles';
import Button from 'Components/Button';
import { InformationTypes } from 'Administrator_Pages/MigrationRequests/enumerations/enums';
import { formatApiDate } from 'Util/dateFunctions';

const EligibilityInformation = () => {
  const migrationStore = MigrationRequestModuleStore.Instance().Use();
  const infoStore = migrationStore.Information.Use();
  const destinationType = migrationStore.DestinationType;
  const data = infoStore.Info;

  const onClear = () => {
    infoStore.clear();
    migrationStore.ClearInformation();
  };

  if (destinationType !== InformationTypes.ELIGIBILITY || !data) {
    return <></>;
  }

  return (
    <Grid container item xs={7}>
      <StyledPaper elevation={2}>
        <InfoContainer container>
          <Grid item xs={6}>
            Name: {data?.firstName} {data?.lastName}
          </Grid>
          <Grid item xs={6}>
            Organization: {data?.organizationName}
          </Grid>
          <Grid item xs={6}>
            DOB: {formatApiDate(data?.birthDateUtc)}
          </Grid>
          <Grid item xs={6}>
            HPID: {data?.memberId}
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Button onClick={onClear} variant={Button.variant.OUTLINED}>
              Clear
            </Button>
          </Grid>
        </InfoContainer>
      </StyledPaper>
    </Grid>
  );
};

export default EligibilityInformation;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyledContainer, StyledLoadingButton, StyledRedeterminationContainer, StyledSnackbar } from './styles';
import { Alert, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import useLogRedeterminationAttempt from 'Tasking_Hooks/useLogRedeterminationAttempt';
import { parseErrorMessage } from 'Helpers/parseApiErrorMessage';
import { OUTCOMES, States } from './config';

function Redetermination({ ngm, memberInfo }) {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(States.NONE);
  const [outcome, setOutcome] = useState(States.NONE);

  const { response, hasErrored, isLoading, attemptError, logAttempt } = useLogRedeterminationAttempt();

  const states = {
    [States.ERROR]: { severity: 'error', text: parseErrorMessage(attemptError) },
    [States.SUCCESS]: { severity: 'success', text: 'Submission was successful' },
  };

  useEffect(() => {
    if (!isLoading) {
      const redeterminationState = hasErrored ? States.ERROR : response && !isLoading ? States.SUCCESS : States.NONE;
      setState(redeterminationState);
      setOpen([States.ERROR, States.SUCCESS].includes(redeterminationState));
    }
  }, [isLoading, response, hasErrored]);

  const handleOnChange = (event) => {
    setOutcome(event.target.value);
  };

  const logRedetermination = () => {
    let request = { outcome };

    if (ngm) {
      request = {
        ...request,
        NgmId: memberInfo?.id,
        OrganizationId: memberInfo?.organizationId,
      };
    } else {
      request = {
        ...request,
        UserId: memberInfo?.userId,
      };
    }

    logAttempt(request);
  };

  return (
    <>
      <StyledContainer elevation={4}>
        <Typography fontWeight={700} fontSize={24} color="rgba(0,0,0,0.75)">
          Log Redetermination Attempt
        </Typography>
        <StyledRedeterminationContainer>
          <RadioGroup
            aria-labelledby="redetermination-outcome-radio-group"
            name="redetermination-outcome"
            onChange={handleOnChange}
          >
            {OUTCOMES.map(({ value, label }, index) => (
              <FormControlLabel
                key={index}
                name={value}
                value={value}
                control={<Radio />}
                label={label}
                disabled={state === States.SUCCESS}
              />
            ))}
          </RadioGroup>

          <StyledLoadingButton
            sx={{ mt: 1, mr: 1 }}
            disabled={!outcome || state === States.SUCCESS}
            type="submit"
            variant="contained"
            onClick={logRedetermination}
            loading={isLoading}
          >
            Save
          </StyledLoadingButton>
        </StyledRedeterminationContainer>
      </StyledContainer>
      <StyledSnackbar open={open}>
        <Alert severity={states[state]?.severity}>{states[state]?.text}</Alert>
      </StyledSnackbar>
    </>
  );
}

Redetermination.propTypes = {
  memberInfo: PropTypes.object.isRequired,
  ngm: PropTypes.bool,
};

export default Redetermination;

import styled from '@emotion/styled';

export const DropDownInfoContainer = styled.div`
  display: flex;
`;

export const GroupContainer = styled.div`
  padding-top: 21px;
  margin-left: 25px;
  font-size: 14px;
  margin-bottom: 10px;
  width: 300px;
`;

export const Heading = styled.div`
  font-family: lato;
  color: #0f0f0f;
`;

export const Text = styled.div`
  font-family: Open Sans;
  color: #0f145b;
  padding-top: 11px;
  white-space: ${(props) => (props.notes ? 'pre-line' : 'normal')};
`;

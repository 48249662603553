import React, { useEffect } from 'react';
import { StyledTableContainer } from './styles';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import usePetsHistory from 'Tasking_Hooks/usePetsHistory';
import { useParams } from 'react-router-dom';
import { lowerCaseAllWordsExceptFirstLetters } from 'Util/stringFunctions';
import { formatDate } from 'Tasking_Helpers/formatDate';
import CenteredCircularProgress from 'Components/CenteredCircularProgress/CenteredCircularProgress';

const PyxPets = () => {
  const { userId } = useParams();
  const { petHistory, getPetHistory, isPending } = usePetsHistory();

  useEffect(() => {
    getPetHistory(userId);
  }, []);

  if (isPending) {
    return <CenteredCircularProgress />;
  }

  return (
    <section>
      <h2>Current Pyx Pet</h2>
      <StyledTableContainer component={Paper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Pet Type</TableCell>
              <TableCell>Pet Name</TableCell>
              <TableCell>Last Activity Date</TableCell>
              <TableCell>Last Activity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{lowerCaseAllWordsExceptFirstLetters(petHistory?.petType ?? '')}</TableCell>
              <TableCell>{petHistory?.name}</TableCell>
              <TableCell>{formatDate(petHistory?.lastInteractionDateUtc ?? '')}</TableCell>
              <TableCell>{lowerCaseAllWordsExceptFirstLetters(petHistory?.interactionType ?? '')}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </StyledTableContainer>
    </section>
  );
};

export default PyxPets;

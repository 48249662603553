import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const StyledModalHeader = styled.div`
  padding: 20px 10px 10px;
  border-radius: 20px 20px 0px 0px;
`;

export const StyledModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  min-width: 350px;
  text-align: center;
  border-radius: 20px;
`;

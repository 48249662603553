import { Grid } from '@mui/material';
import React from 'react';
import { inputConfig } from './inputConfig';
import PropTypes from 'prop-types';

function PrintableInput({ inputType, gridSize, ...props }) {
  const Input = inputConfig[inputType];
  const classNameLabel = (props?.label ?? props?.name)?.replace(/\s|\/|\(|\)/g, '');
  return (
    <Grid item xs={gridSize} className={`PrintInputGrid-${classNameLabel}`}>
      {Input && <Input className={`PrintInput-${classNameLabel}`} {...props} />}
    </Grid>
  );
}

PrintableInput.displayName = 'PrintableInput';

PrintableInput.propTypes = {
  gridSize: PropTypes.number,
  inputType: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
};

export default PrintableInput;

import { Screenings } from '../enumerations/enums';

export const screeningNames = {
  [Screenings.YOUTH_PHQ2]: 'Youth PHQ-2',
  [Screenings.YOUTH_NEEDS]: 'Youth Needs',
  [Screenings.YOUTH_UCLA3]: 'Youth UCLA-3',
  [Screenings.ADULT_PHQ4]: 'PHQ-4 Screening',
  [Screenings.ADULT_NEEDS]: 'Social Determinants of Health',
  [Screenings.ADULT_PRAPARE]: 'Social Determinants of Health - PRAPARE',
  [Screenings.ADULT_UCLA3]: 'UCLA-3 Screening',
  [Screenings.ADULT_WELLBEING]: 'Well-Being Assessment',
  [Screenings.CAHPS_PYXPLUS]: 'Pyx Plus Survey - CAHPS',
};

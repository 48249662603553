import { questionTypes } from '../enums';

export const appGeneralConfig = {
  title: 'App Users - General',
  questions: [
    {
      title:
        'Has the Pyx Health program given you companionship? If YES, what part of the program gave you companionship?',
      guids: ['ced9d785-421e-4735-bac3-a308197c0f4d'],
      types: [questionTypes.CLOSED],
      options: ['YES', 'NO'],
      typeOptions: {
        [questionTypes.CLOSED]: {
          YES: [
            {
              title: 'If YES, what services did you use?',
              guids: ['70d4a89e-1475-404a-a906-0737f3578555'],
              types: [questionTypes.FREETEXT],
            },
          ],
        },
      },
    },
    {
      title:
        'On a scale of 1 to 5 (one being the least connected and five being the most), how connected to your healthcare team has the Pyx Health program made you feel?',
      guids: ['b7aebd85-8dcb-4050-bcc5-cf6d241f1968'],
      types: [questionTypes.RATING],
      options: ['1', '2', '3', '4', '5'],
    },
    {
      title:
        'On a scale of 1 to 5 (one being the least satisfied and five being the most), how satisfied are you with your experience on the Pyx Health platform?',
      guids: ['bb8701fc-cd49-469a-9fb1-f6c745543305'],
      types: [questionTypes.RATING],
      options: ['1', '2', '3', '4', '5'],
    },
    {
      title:
        'Is there anything that you would like me to know that you like, dislike, or have suggestions about regarding the app or the program?',
      guids: ['7542efc7-5482-46cb-bb5f-fc5c4f53a4a3'],
      types: [questionTypes.FREETEXT],
    },
    {
      title:
        'How likely is it that you would recommend Pyx to a friend or collegue? (Scale 1-10 [1 - Not likely to recommend, 10 - Extremely likely to recommend])',
      guids: ['5b6c16a5-6b57-4c50-b25c-badec7963ad6'],
      types: [questionTypes.RATING],
      options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    },
  ],
};

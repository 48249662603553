import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const LoadingWrapper = ({ loading, children }) => {
  return (
    <>
      {loading ? (
        <Typography fontSize={18} color="red">
          Loading...
        </Typography>
      ) : (
        children
      )}
    </>
  );
};

LoadingWrapper.displayName = 'LoadingWrapper';

LoadingWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.element]),
  loading: PropTypes.bool.isRequired,
};

export default LoadingWrapper;

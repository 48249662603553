import getUserProfileInformation from 'Tasking_Helpers/getUserProfileInformation';

const checkUserPermission = async (permission) => {
  const profile = await getUserProfileInformation();
  if (profile?.permission?.includes(permission)) {
    return true;
  }
  return false;
};

export { checkUserPermission };

import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';

const useCreateTask = () => {
  const [createTaskhData, setCreateTaskhData] = useState();
  const [url, setUrl] = useState();
  const { response, isLoading, hasErrored, errorData } = useAxiosSubmit(url, createTaskhData, 'put');

  const editTask = async (request, orgId, taskGuid) => {
    setUrl(`${process.env.API_BASE_URL}/v5/Tasks/${orgId}:${taskGuid}`);
    setCreateTaskhData(request);
  };

  return { editedTask: response, error: hasErrored, errorMessage: errorData, isEditTaskPending: isLoading, editTask };
};

export default useCreateTask;

import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@mui/material';

const ScoreTableFormattedCell = ({ children }) => {
  const score = +children;
  let color = 'black';
  let backgroundColor = 'white';
  if (score === 4) {
    color = '#214522';
    backgroundColor = '#acdcac';
  } else if (score === 3) {
    color = '#663000';
    backgroundColor = '#f9dd90';
  } else if (score === 2 || score === 1) {
    color = '#501611';
    backgroundColor = '#e07f76';
  }

  return <TableCell style={{ color: color, backgroundColor: backgroundColor }}>{children}</TableCell>;
};
ScoreTableFormattedCell.propTypes = {
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ScoreTableFormattedCell;

export class DefaultBrowser {
  showWindow(url) {
    const openWindow = window.open(url, '_self');
    if (openWindow) {
      openWindow.addEventListener('beforeupload', () => this.onCloseFunction());
    }
  }

  closeWindow() {
    window.close();
  }

  browserCloseListener(closeBrowserEvent) {
    this.onCloseFunction = closeBrowserEvent;
  }
}

import { FormControlLabel, InputLabel } from '@mui/material';
import Button from 'Components/Button';
import styled from 'styled-components';

export const OtpDataContainer = styled.div`
  height: 120px;
  width: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
`;

export const TextHeader = styled.h2`
  margin-top: 20px;
  margin-left: 85px;
  font-family: karla;
  color: #015b5b;
  font-size: 32px;
  font-weight: bold;
`;

export const SectionHeader = styled.div`
  margin-top: 31px;
  font-family: Lato;
  color: #0f0f0f;
  font-size: 14px;
`;

export const SubmitButton = styled(Button)`
  background-color: #c0d7d8;
  color: #015353;
  border-radius: 25px;
  width: 120px;
  height: 25px;
  margin: 20px 88px 0px;

  &:hover {
    background-color: #c0d7d8;
  }

  &.MuiLoadingButton-loading {
    background-color: #c0d7d8;
  }
`;

export const OtpCodeHeader = styled.div`
  margin-top: 31px;
  margin-left: 90px;
  font-family: Lato;
  color: #0f0f0f;
  font-size: 14px;
`;

export const OtpCoode = styled.div`
  margin-left: 90px;
  font-family: Lato;
  color: #0f0f0f;
  font-size: 14px;
  margin-top: 10px;
`;

export const DoneButton = styled.div`
  background-color: #4a4f7f;
  color: white;
  width: 125px;
  height: 27px;
  border: 1px solid white;
  border-radius: 25px;
  margin: 40px 0 0 320px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: karla;
`;

export const CustomError = styled.div`
  color: red;
  margin-left: 90px;
  margin-top: 25px;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  white-space: pre-wrap;
  flex: 1;

  .MuiOutlinedInput-input {
    padding: 5px 5px;
    width: 150px;
  }
`;

export const StyledRadioContainer = styled.div`
  margin-left: 20px;
  margin-top: 15px;
  max-width: 500px;
  display: flex;
  flex-wrap: wrap;
`;

export const StyledInputLabel = styled(InputLabel)`
  color: black;
  font-size: 16px;
  line-height: 2;
  display: inline-flex;
  padding-right: 15px;
`;

export const StyleLabeledFormControlLabel = styled(StyledFormControlLabel)`
  .MuiFormControlLabel-label {
    display: inline-flex;
  }
`;

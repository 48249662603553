import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Tasking_Helpers/getAuthClient';
import useMemberSearch from './useMemberSearch';

const memberOtpCodeDataUrl = 'v5/members';
const options = {
  revalidateOnFocus: false,
};

const getMemberOtpCode = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useMemberOtpCode = () => {
  const [otpCode, setOtpCode] = useState();
  const [url, setUrl] = useState();
  const [error, setError] = useState();
  const [isPending, setIsPending] = useState();
  const [otpType, setOtpType] = useState();
  const { membersList, error: memberSearchError, isPending: memberSearchIsPending, membersSearch } = useMemberSearch();
  const {
    data: otpCodeResult,
    error: otpError,
    isValidating,
    mutate,
  } = useSWR(() => url, getMemberOtpCode, {
    ...options,
  });

  useEffect(() => {
    if (otpCodeResult) {
      setOtpCode(otpCodeResult.data);
    }
  }, [otpCodeResult]);

  useEffect(() => {
    setIsPending(isValidating || memberSearchIsPending);
  }, [isValidating, memberSearchIsPending]);

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      retrieveMemberOtpCode(membersList[0]?.userId);
    }
  }, [membersList]);

  useEffect(() => {
    if (memberSearchError || otpError) {
      setUrl();
      setOtpCode();
      setError(true);
    }
  }, [memberSearchError, otpError]);

  const retrieveMemberOtpCode = (userId, type) => {
    setUrl(`${process.env.API_BASE_URL}/${memberOtpCodeDataUrl}/${userId}/otp?otpType=${type ?? otpType}`);
    mutate(otpCodeResult);
    setError();
  };

  const retrieveMemberOtpCodeByMemberSearch = (request, type) => {
    membersSearch(request);
    setOtpType(type);
    setOtpCode();
    setError();
  };

  const isError = error || otpError || memberSearchError || (membersList && membersList?.length < 1);

  return { otpCode, error: isError, isPending, retrieveMemberOtpCode, retrieveMemberOtpCodeByMemberSearch };
};

export default useMemberOtpCode;

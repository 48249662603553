import React from 'react';
import { FormHelperText, Grid, IconButton, Tooltip } from '@mui/material';
import { CallTimerContainer, SubmitButton, TimerEntryContainer, StyledIconButtonContainer } from '../styles';
import TimerEntry from '../TimerEntry/TimerEntry';
import PropTypes from 'prop-types';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

function CallTimer({
  startedOnUtc,
  endedOnUtc,
  buttonText,
  onClick,
  disabled,
  disableButton,
  hideEndCall,
  hideButton,
  onIconClick,
  editTimeAllowed,
  onChangeStartedOnUtc,
  onChangeEndedOnUtc,
  onStartedOnUtcInvalidDateEntry,
  onEndedOnUtcInvalidDateEntry,
  onCancel,
  icon,
  style,
  pending,
  errorMessage,
}) {
  const renderIcon = () => {
    if (icon === CallTimer.icons.INFO) {
      return (
        <Tooltip arrow placement="top" title="You must start the call timer to continue.">
          <IconButton size="small" aria-label="Info" disabled={disabled}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      );
    } else if (icon === CallTimer.icons.EDIT) {
      return (
        <IconButton size="small" title="Edit" onClick={onIconClick} disabled={disabled}>
          <EditIcon />
        </IconButton>
      );
    } else if (icon === CallTimer.icons.SAVE) {
      return (
        <>
          <IconButton size="small" title="Cancel" onClick={onCancel} disabled={disabled}>
            <CancelIcon />
          </IconButton>
          <IconButton size="small" title="Save" onClick={onIconClick} disabled={disabled}>
            <SaveIcon />
          </IconButton>
        </>
      );
    }
  };

  return (
    <CallTimerContainer disabled={disabled} style={style}>
      <StyledIconButtonContainer>{renderIcon()}</StyledIconButtonContainer>
      <TimerEntryContainer container columnSpacing={1} rowSpacing={1}>
        <TimerEntry
          label={'Call started at:'}
          timeUtc={startedOnUtc}
          onTimeChange={onChangeStartedOnUtc}
          onInvalidDateEntry={onStartedOnUtcInvalidDateEntry}
          disabled={disabled}
          error={!!errorMessage}
          editTimeAllowed={editTimeAllowed}
        />
        {!hideEndCall && (
          <TimerEntry
            label={'Call ended at:'}
            timeUtc={endedOnUtc}
            onTimeChange={onChangeEndedOnUtc}
            onInvalidDateEntry={onEndedOnUtcInvalidDateEntry}
            disabled={disabled}
            editTimeAllowed={editTimeAllowed}
            error={!!errorMessage}
          />
        )}
        {hideButton && !!errorMessage && (
          <Grid item xs={12}>
            <FormHelperText error={!!errorMessage}>{errorMessage}</FormHelperText>
          </Grid>
        )}
        <Grid item xs={12}>
          {!hideButton && (
            <SubmitButton
              onClick={onClick}
              disabled={disableButton || disabled}
              variant="contained"
              loading={pending}
              fullWidth
              disableElevation
            >
              {pending ? '' : buttonText}
            </SubmitButton>
          )}
        </Grid>
      </TimerEntryContainer>
    </CallTimerContainer>
  );
}

CallTimer.icons = { INFO: 'INFO', EDIT: 'EDIT', SAVE: 'SAVE' };

CallTimer.propTypes = {
  startedOnUtc: PropTypes.string,
  endedOnUtc: PropTypes.string,
  onClick: PropTypes.func,
  onChangeStartedOnUtc: PropTypes.func,
  onChangeEndedOnUtc: PropTypes.func,
  onStartedOnUtcInvalidDateEntry: PropTypes.func,
  onEndedOnUtcInvalidDateEntry: PropTypes.func,
  onCancel: PropTypes.func,
  onIconClick: PropTypes.func,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  disableButton: PropTypes.bool,
  hideEndCall: PropTypes.bool,
  hideButton: PropTypes.bool,
  editTimeAllowed: PropTypes.bool,
  pending: PropTypes.bool,
  icon: PropTypes.string,
  style: PropTypes.object,
  errorMessage: PropTypes.string,
};

export default CallTimer;

import styled from 'styled-components';
import { CircularProgress } from '@mui/material';

export const StyledProgressContainer = styled.div`
  text-align: center;
`;

export const StyledCircularProgress = styled(CircularProgress)`
  margin: auto;
`;

export const StyledErrorMessage = styled.div`
  color: red;
`;

import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from '../helpers/getAuthClient';

const organizationUrl = '/v1/Organizations';
const options = {
  revalidateOnFocus: false,
};

const getOrganizations = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useGetOrganizations = () => {
  const [isPending, setIsPending] = useState(true);
  const [organizations, setOrganizations] = useState();
  const { data: organizationsResult, error } = useSWR(
    () => `${process.env.API_BASE_URL}${organizationUrl}`,
    getOrganizations,
    {
      ...options,
    },
  );

  useEffect(() => {
    if (organizationsResult) {
      setOrganizations(organizationsResult.data);
    }

    setIsPending(!organizationsResult && !error);
  }, [organizationsResult, error]);

  return { organizations, error, isPending };
};

export default useGetOrganizations;

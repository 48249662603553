import React, { useState } from 'react';
import DropDown from './DropDown/DropDown';
import { useNavigate } from 'react-router-dom';
import useUserMetrics from 'Tasking_Hooks/useUserMetrics';
import { NavBarContainer, Left, Right, IconContainer } from './styles';
import Logo from 'Assets/images/Logo.svg';
import { HOME } from '../../../initialization/Routes/RoutesConfig';

const NavBar = () => {
  const [iconUserClicked, setIconUserClicked] = useState(false);
  const [moneyIconClicked, setMoneyIconClicked] = useState(false);
  const navigate = useNavigate();

  const { userMetrics, isPending } = useUserMetrics();

  let moneyDetails;

  if (!isPending) {
    moneyDetails = {
      enrollmentBonus: userMetrics?.totalEnrollmentBonus,
      currentDateForEnrollement: new Date(),
    };
  }

  return (
    <NavBarContainer id="NavBar" data-testid="NavBar">
      <Left>
        <img
          style={{ height: 'auto', minHeight: '80px', width: '250px', cursor: 'pointer' }}
          src={Logo}
          onClick={() => {
            navigate(HOME);
          }}
          data-testid="Logo"
        />
      </Left>
      <Right>
        <IconContainer
          data-testid="IconContainerPig"
          picked={moneyIconClicked}
          onClick={() => {
            setIconUserClicked(false);
            moneyIconClicked ? setMoneyIconClicked(false) : setMoneyIconClicked(true);
          }}
          className="fas fa-piggy-bank"
        />
        {moneyIconClicked ? <DropDown moneyIcon moneyDetails={moneyDetails}></DropDown> : ''}
        <IconContainer
          onClick={() => {
            navigate(HOME);
          }}
          data-testid="IconContainerHome"
          home
          className="fa fa-home fa-lg"
        />
        <IconContainer
          data-testid="IconContainerUser"
          picked={iconUserClicked}
          onClick={() => {
            setMoneyIconClicked(false);
            iconUserClicked ? setIconUserClicked(false) : setIconUserClicked(true);
          }}
          user
          className="fas fa-user"
        />
        {iconUserClicked ? <DropDown userIcon></DropDown> : ''}
      </Right>
    </NavBarContainer>
  );
};

export default NavBar;

import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { purple } from 'Styles/colors';

export const UncheckedRadio = styled.div`
  border-radius: 10px;
  border: 1px solid ${(props) => (props.disabled ? '#8A8A8A' : purple._900)};
  opacity: 1;
  width: 18px;
  height: 18px;
`;

export const CheckedRadio = styled.div`
  border-radius: 10px;
  opacity: 1;
  width: 20px;
  height: 20px;
  background: radial-gradient(circle at 50% 50%, #fff 4px, transparent 5px),
    ${(props) => (props.disabled ? '#8A8A8A' : purple._900)};
`;

export const StyledTextBox = styled(TextField)`
  .MuiOutlinedInput-root {
    background-color: #fff;
    padding: 11px 14px;
    font-size: 14px;
    color: #272b30;
    margin: 15px 0px 0px;

    &.Mui-disabled {
      background-color: #fcfcfc;
    }
  }
`;

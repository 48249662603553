import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import ScoreTableFormattedCell from './ScoreTableFormattedCell';
import { StyledTableContainer } from 'Tasking_Components/Thrive/ThriveMetrics/ScreeningScore/styles';

const ScoreOverviewTable = ({ name, scores }) => {
  const week1 = scores.find((s) => s.term === 'WEEK1');
  const week8 = scores.find((s) => s.term === 'WEEK8');
  const display = (value) => value || 'N/A';

  return (
    <StyledTableContainer component={Paper} variant="outlined" data-testid={`OverviewTable_${name}`}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Week</TableCell>
            <TableCell>Screening Date</TableCell>
            <TableCell>Score</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Week 1</TableCell>
            <TableCell>{display(week1?.date)}</TableCell>
            <ScoreTableFormattedCell>{display(week1?.score)}</ScoreTableFormattedCell>
          </TableRow>
          <TableRow>
            <TableCell>Week 8</TableCell>
            <TableCell>{display(week8?.date)}</TableCell>
            <ScoreTableFormattedCell>{display(week8?.score)}</ScoreTableFormattedCell>
          </TableRow>
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};
ScoreOverviewTable.propTypes = {
  name: PropTypes.string.isRequired,
  scores: PropTypes.arrayOf(
    PropTypes.shape({
      week: PropTypes.string,
      date: PropTypes.string,
      score: PropTypes.number,
    }),
  ).isRequired,
};

export default ScoreOverviewTable;

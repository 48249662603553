import React, { useEffect, useState } from 'react';
import {
  DropDownContainer,
  Arrow,
  MoneyDropDownTitle,
  MoneyDropDownAmount,
  MoneyDropDownDate,
  UserDropDownOption,
  UserDropDownInfoTitle,
  UserDropDownInfoText,
  Divider,
  UserDropDownInfoContainer,
  UserDropDownInfoColumn,
  UserDropDownDefaultIcon,
  StyledDialog,
  StyledDateTimePicker,
  StyledButtonContainer,
  StyledButton,
} from './styles';
import PropTypes from 'prop-types';
import { useOidcUser } from '@axa-fr/react-oidc';
import { useAuthentication } from 'Tasking_Helpers/useAuthentication';
import { useNavigate } from 'react-router-dom';
import { MigrationRequestModuleStore } from 'Administrator_Pages/MigrationRequests/MigrationRequestModuleStore';
import { MIGRATIONREQUESTS } from '../../../../initialization/Routes/RoutesConfig';

const DropDown = ({ moneyIcon, moneyDetails }) => {
  const { oidcUser } = useOidcUser();
  const { logout } = useAuthentication();
  const navigate = useNavigate();
  const migrationStore = MigrationRequestModuleStore.Instance().Use();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dateTimeValue, setDateTimeValue] = useState(new Date().toISOString().split('.')[0]);
  const STORAGE_KEY = 'dateTimeOverride';
  const isProduction = process.env.ENVIRONMENT === 'production';

  const renderCalendar = () => {
    if (!isProduction) {
      return (
        <UserDropDownOption data-testid="TimeWarpSelector" onClick={() => setDialogOpen(true)}>
          Time Warp
        </UserDropDownOption>
      );
    }
    return null;
  };

  const setLocalStorageDateTime = () => {
    localStorage.setItem(STORAGE_KEY, `${dateTimeValue}Z`);
    setDialogOpen(false);
  };

  const clearDateTimeOverride = () => {
    localStorage.removeItem(STORAGE_KEY);
    setDialogOpen(false);
  };

  const onClickLogout = async () => {
    await logout();
  };

  useEffect(() => {
    migrationStore.initialize({ userId: oidcUser?.sub });
  }, [oidcUser]);

  if (moneyIcon) {
    const numberFormatter = new Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'USD',
    });
    return (
      <DropDownContainer data-testid="DropDownPig">
        <Arrow />
        <MoneyDropDownTitle>Monthly Bonus</MoneyDropDownTitle>
        <MoneyDropDownAmount>{numberFormatter.format(moneyDetails.enrollmentBonus)}</MoneyDropDownAmount>
        <MoneyDropDownDate>
          {moneyDetails.currentDateForEnrollement.toLocaleString('default', { month: 'long' }).toUpperCase()}{' '}
          {moneyDetails.currentDateForEnrollement.getFullYear()}
        </MoneyDropDownDate>
      </DropDownContainer>
    );
  }
  return (
    <DropDownContainer data-testid="DropDownUser" user isProduction={isProduction}>
      <Arrow user />
      <UserDropDownInfoContainer>
        <UserDropDownDefaultIcon className="fas fa-user" />
        <UserDropDownInfoColumn>
          <UserDropDownInfoTitle>
            {oidcUser?.given_name} {oidcUser?.family_name}
          </UserDropDownInfoTitle>
          <UserDropDownInfoText>{oidcUser?.email}</UserDropDownInfoText>
          <Divider />
          {migrationStore.HasPermissions && (
            <UserDropDownOption data-testid="MigrateUser" onClick={() => navigate(MIGRATIONREQUESTS)}>
              Migration Requests
            </UserDropDownOption>
          )}
          {renderCalendar()}
          <UserDropDownOption data-testid="LogOutUser" onClick={onClickLogout}>
            Logout
          </UserDropDownOption>
        </UserDropDownInfoColumn>
      </UserDropDownInfoContainer>
      <StyledDialog data-testid="DatTimeDialog" open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <StyledDateTimePicker
          id="datetime-local"
          label="Date Picker"
          type="datetime-local"
          variant="standard"
          data-testid="DateTimePicker"
          value={dateTimeValue}
          onChange={(evt) => setDateTimeValue(evt.target.value)}
        />
        <StyledButtonContainer>
          <StyledButton
            onClick={() => setDialogOpen(false)}
            data-testid="CancelOverride"
            variant="contained"
            color="primary"
          >
            Cancel
          </StyledButton>
          <StyledButton onClick={clearDateTimeOverride} data-testid="ClearOverride" variant="contained" color="primary">
            Clear Override
          </StyledButton>
          <StyledButton
            onClick={setLocalStorageDateTime}
            data-testid="ApplyOverride"
            variant="contained"
            color="primary"
          >
            Apply Override
          </StyledButton>
        </StyledButtonContainer>
      </StyledDialog>
    </DropDownContainer>
  );
};

DropDown.propTypes = {
  moneyIcon: PropTypes.bool,
  moneyDetails: PropTypes.any,
};

export default DropDown;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, FormControlLabel, Checkbox as MuiCheckbox, FormControl, FormGroup } from '@mui/material';

const Checkbox = forwardRef(({ index, options, onChange, ...props }, ref) => {
  const extraInputProps = { 'aria-labelledby': props['aria-labelledby'] };
  const checkedAnswers = props?.value instanceof Array ? props?.value : [];

  const onCheckboxChange = (e) => {
    const isDeselected = checkedAnswers?.includes(e.target.value);

    const answers = isDeselected
      ? checkedAnswers.filter((x) => x !== e.target.value)
      : [...checkedAnswers, e.target.value];

    if (onChange) {
      onChange(e, answers);
    }
  };

  return (
    <FormControl>
      <FormGroup aria-labelledby={props['aria-labelledby']} {...extraInputProps}>
        <RadioGroup ref={ref} name={`checkboxgroup-${index}`} {...props}>
          {options.map(({ text, name }) => {
            return (
              <FormControlLabel
                key={`id-${name}`}
                value={name}
                checked={checkedAnswers?.includes(name)}
                control={<MuiCheckbox />}
                label={text}
                onChange={onCheckboxChange}
              />
            );
          })}
        </RadioGroup>
      </FormGroup>
    </FormControl>
  );
});

Checkbox.displayName = `Checkbox`;

Checkbox.propTypes = {
  index: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  ref: PropTypes.object,
  onChange: PropTypes.func,
  ['aria-labelledby']: PropTypes.string,
};

export default Checkbox;

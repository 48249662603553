import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

const notificationsUrl = 'v5/notifications';
const options = {
  revalidateOnFocus: false,
};

const getNotifications = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useGetNotifications = () => {
  const [notifications, setNotifications] = useState();
  const [url, setUrl] = useState();
  const {
    data: notificationsResults,
    error,
    isPending,
    mutate,
  } = useSWR(() => url, getNotifications, {
    ...options,
  });

  useEffect(() => {
    if (notificationsResults) {
      setNotifications(notificationsResults.data);
    }
  }, [notificationsResults]);

  const filterLanguage = (localeId) => {
    setUrl(`${process.env.API_BASE_URL}/${notificationsUrl}?localeId=${localeId}&notificationCategoryId=2`);
    mutate(notificationsResults);
  };

  return { notifications, error, isPending, filterLanguage };
};

export default useGetNotifications;

import React, { useState } from 'react';
import { ScreeningModuleStore } from 'Tasking_Components/Screenings/ScreeningModuleStore';
import { useForm } from 'react-hook-form';
import { questions } from '../config';
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import {
  ErrorMessage,
  StyledButton,
  StyledButtonContainer,
  StyledDialog,
  StyledForm,
  StyledRadioGroup,
  SubQuestionWrapper,
} from './styles';
import { parseErrorMessage } from 'Helpers/parseApiErrorMessage';
import { AnswerTypes } from '../enums';
import Question from './FormAnswer/Question';

const transformedQuestions = questions
  .filter((x) => !x.dependencies)
  .map((x) => {
    const answerGuids = x.options.map((x) => x.name);
    x.subQuestions = questions.filter((x) => x.dependencies?.find((y) => answerGuids.includes(y)));
    return x;
  });

function CAHPSPyxPlus() {
  const screeningStore = ScreeningModuleStore.Instance().Use();
  const screeningTakerStore = screeningStore.ScreeningForm.Use();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const { control, handleSubmit } = useForm({ defaultValues: screeningTakerStore.Answers });
  const screeningAnswers = screeningTakerStore.Answers;
  const hasError = screeningStore.Error?.hasError;
  const errorMessage = screeningStore.Error?.error;
  const answers = Object.values(screeningAnswers)
    .filter((x) => !!x)
    .map((x) => x?.name || x);

  const getLetterIndex = (letterIndex) => {
    return String.fromCharCode(((letterIndex + 1 - 1) % 26) + 65);
  };

  const onAnswer = (question, answer, subQuestions) => {
    screeningTakerStore.updateAnswer(question, answer);

    subQuestions?.forEach((x) => {
      if (!x?.dependencies?.includes(answer)) {
        screeningTakerStore.clearSubAnswer(x.name);
      }
    });
  };

  const onCompleteScreening = async () => {
    onCloseDialog();
    await screeningStore.startScreening().then(() => screeningTakerStore.complete());
  };
  const onFinish = () => setConfirmDialogOpen(true);
  const onCloseDialog = () => setConfirmDialogOpen(false);

  const formComplete = screeningTakerStore.IsValid;
  const isLoading = screeningTakerStore.IsLoading || screeningStore.IsLoading;

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onFinish)}>
        {transformedQuestions.map(
          ({ name: questionName, text: questionText, answerType, subQuestions, ...question }, questionIndex) => (
            <StyledRadioGroup key={questionIndex}>
              <Question
                control={control}
                index={questionIndex}
                answerType={answerType ?? AnswerTypes.RADIO}
                value={screeningAnswers[questionName]}
                name={questionName}
                text={`${questionIndex + 1}. ${questionText}`}
                onChange={(e, vals) => onAnswer(questionName, vals ?? e.target?.name, subQuestions)}
                {...question}
              />
              {subQuestions && (
                <SubQuestionWrapper>
                  {subQuestions?.map(
                    (
                      {
                        name: subQuestionName,
                        text: subQuestionText,
                        answerType: subQuestionAnswerType,
                        dependencies,
                        ...subQuestion
                      },
                      subQuestionIndex,
                    ) => (
                      <Question
                        control={control}
                        index={`${questionIndex}-${subQuestionIndex}`}
                        key={`${questionIndex}-${subQuestionIndex}`}
                        answerType={subQuestionAnswerType ?? AnswerTypes.RADIO}
                        value={screeningAnswers[subQuestionName]}
                        name={subQuestionName}
                        text={`${questionIndex + 1}.${getLetterIndex(subQuestionIndex)}.  ${subQuestionText}`}
                        disabled={!dependencies?.find((x) => answers.includes(x))}
                        onChange={(e, vals) => onAnswer(subQuestionName, vals ?? e.target?.name)}
                        {...subQuestion}
                      />
                    ),
                  )}
                </SubQuestionWrapper>
              )}
            </StyledRadioGroup>
          ),
        )}
        <ErrorMessage>{hasError ? parseErrorMessage(errorMessage) : ''}</ErrorMessage>
      </StyledForm>
      <StyledButtonContainer>
        <StyledButton onClick={onFinish} disabled={!formComplete} loading={isLoading} elevation={0}>
          Complete
        </StyledButton>
        <StyledButton onClick={screeningStore.cancel} loading={isLoading} variant="outlined">
          Cancel
        </StyledButton>
      </StyledButtonContainer>
      <StyledDialog open={confirmDialogOpen} onClose={onCloseDialog}>
        <DialogTitle>Save Screening?</DialogTitle>
        <DialogContent dividers>
          <Typography>
            <strong>Warning!</strong> Once you save this screening, you will not be able to edit it afterwards.
          </Typography>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={onCloseDialog} loading={isLoading} variant="outlined">
            Cancel
          </StyledButton>
          <StyledButton onClick={onCompleteScreening} disabled={!formComplete} loading={isLoading} elevation={0}>
            Save
          </StyledButton>
        </DialogActions>
      </StyledDialog>
    </>
  );
}

export default CAHPSPyxPlus;

import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';

const useThriveEnroll = () => {
  const [enrollThriveData, setEnrollThriveData] = useState();
  const [url, setUrl] = useState();
  const { response, isLoading, hasErrored, errorData } = useAxiosSubmit(url, enrollThriveData);

  const enrollThriveUser = async (request) => {
    setUrl(`${process.env.API_BASE_URL}/v1/thrive/access`);
    setEnrollThriveData(request);
  };

  return {
    enrollResponse: response,
    enrollError: hasErrored,
    isEnrollPending: isLoading,
    enrollmentErrorMessage: errorData,
    enrollThriveUser,
  };
};

export default useThriveEnroll;

import { ApiService } from 'Administrator_Helpers/ApiService';

export class MembersApiDataService {
  private static _instance: MembersApiDataService = new MembersApiDataService();
  private api: ApiService;

  constructor() {
    // Make sure we don't create more than one instance of this service
    if (MembersApiDataService._instance) {
      return MembersApiDataService._instance;
    }
    this.api = new ApiService();
  }

  public getMember = async (request: { userId: number }) => {
    const { response, error } = await this.api.get(`/v5/members/:userId`, request);
    return { response, error };
  };


  public searchMembers = async (request: { username: string, birthDay: string, phoneNumber: string, firstName: string, lastName: string, organizationId: number }) => {
    const { response, error } = await this.api.post(`/v5/members/search`, null, request);
    return { response, error };
  };
}

import getAuthClient from './getAuthClient';

const USERINFO_RESPONSE_KEY = 'userinfo_response';

const getUserProfileInformation = async () => {
  const userInfo = localStorage.getItem(USERINFO_RESPONSE_KEY);
  if (userInfo) {
    try {
      return JSON.parse(userInfo);
      // eslint-disable-next-line no-empty
    } catch {}
  }

  const authorizedApiClient = await getAuthClient();
  try {
    const { data } = await authorizedApiClient.post(`${process.env.AUTHORITY}/connect/userinfo`);

    if (data) {
      // Filtering out personal details from storage
      // eslint-disable-next-line no-unused-vars
      const { family_name, given_name, phone_number, ...filteredData } = data;
      localStorage.setItem(USERINFO_RESPONSE_KEY, JSON.stringify(filteredData));

      return data;
    }
  } catch (error) {
    throw new Error('Failed to get user information');
  }
};

export default getUserProfileInformation;

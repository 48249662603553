import React from 'react';
import PropTypes from 'prop-types';
import useThriveAssessmentDetails from 'Tasking_Hooks/useThriveAssessmentDetails';
import { CircularProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import ScoreTableFormattedCell from './ScoreTableFormattedCell';
import { StyledProgressContainer, StyledTableContainer } from './styles';
import { lowerCaseAllWordsExceptFirstLetters } from 'Util/stringFunctions';

const ScoreDetailTable = ({ orgId, week1ScoreId, week8ScoreId }) => {
  const { response: w1Response, isLoading: w1Loading } = useThriveAssessmentDetails(orgId, week1ScoreId);
  const { response: w8Response, isLoading: w8Loading } = useThriveAssessmentDetails(orgId, week8ScoreId);

  const getScoreByQuestion = (answers, questionText) =>
    answers?.find((a) => a.questionText === questionText)?.answerValue || null;

  const mapDetails = () => {
    if (w1Response) {
      return w1Response.answers.map((a) => ({
        dimension: lowerCaseAllWordsExceptFirstLetters(a.dimension),
        question: a.questionText,
        week1: a.answerValue,
        week8: getScoreByQuestion(w8Response?.answers, a.questionText),
      }));
    }

    if (w8Response) {
      return w8Response.answers.map((a) => ({
        dimension: lowerCaseAllWordsExceptFirstLetters(a.dimension),
        question: a.questionText,
        week1: null,
        week8: a.answerValue,
      }));
    }

    return [];
  };

  if (w1Loading || w8Loading) {
    return (
      <StyledProgressContainer>
        <CircularProgress size={100} />
      </StyledProgressContainer>
    );
  }

  return (
    <section>
      <h2>Detailed Scores by Question</h2>
      <StyledTableContainer component={Paper} variant="outlined" data-testid="ScoreTable">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2}>Dimension</TableCell>
              <TableCell rowSpan={2}>Question</TableCell>
              <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                Scores
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Week 1</TableCell>
              <TableCell>Week 8</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mapDetails().map((s, i) => (
              <TableRow key={i}>
                <TableCell>{s.dimension}</TableCell>
                <TableCell>{s.question}</TableCell>
                <ScoreTableFormattedCell>{s.week1}</ScoreTableFormattedCell>
                <ScoreTableFormattedCell>{s.week8}</ScoreTableFormattedCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </section>
  );
};
ScoreDetailTable.propTypes = {
  week1ScoreId: PropTypes.number,
  week8ScoreId: PropTypes.number,
  orgId: PropTypes.number,
};

export default ScoreDetailTable;

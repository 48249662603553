import React from 'react';
import { Customers, CustomerData, CustomerDataHeader, CustomerTableSections } from './styles';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import urlcat from 'urlcat';
import {
  ENROLLED_TASKS_EDIT_ACTIVITY_VIEW,
  NONENROLLED_TASKS_EDIT_ACTIVITY_VIEW,
} from '../../../initialization/Routes/RoutesConfig';

const HeaderTransform = {
  CreationDate: 'Creation Date',
  PerformedBy: 'Performed By',
  Outcome: 'Contact Outcome',
  Actions: 'Contact Actions',
};

const ActivitesList = ({ assignOptions, setViewActivity, listOfActivities, setSelectedActivity, ngm }) => {
  const { userId, ngmId, taskGuid, orgId } = useParams();
  const navigate = useNavigate();

  const renderTableData = () => {
    return listOfActivities?.map((activity, i) => {
      const { createdOnUtc, createdByUserId, actions, contactOutcome } = activity;

      const showCreatedByName = (createdByUserId) => {
        if (assignOptions) {
          const keys = Object.keys(assignOptions);
          for (let i in keys) {
            if (assignOptions[i]['userId'] === createdByUserId) {
              return assignOptions[i]['name'];
            }
          }
        }
      };

      return (
        <CustomerTableSections
          onClick={() => {
            navigate(
              ngm
                ? urlcat(NONENROLLED_TASKS_EDIT_ACTIVITY_VIEW, {
                    orgId: orgId,
                    ngmId: ngmId,
                    taskGuid: taskGuid,
                    activityGuid: activity?.guid,
                  })
                : urlcat(ENROLLED_TASKS_EDIT_ACTIVITY_VIEW, {
                    userId: userId,
                    taskGuid: taskGuid,
                    activityGuid: activity?.guid,
                  }),
              { showActivity: true },
            );
            setViewActivity(true);
            setSelectedActivity(activity);
          }}
          data-testid={`TableSection${i}`}
          key={i}
        >
          <CustomerData date createdOnUtc num={i}>
            {createdOnUtc.substring(0, 10)}
          </CustomerData>
          <CustomerData num={i}>{showCreatedByName(createdByUserId)}</CustomerData>
          <CustomerData num={i}>{contactOutcome || 'Spoke to Member'}</CustomerData>
          <CustomerData num={i}>{actions?.join(', ')}</CustomerData>
        </CustomerTableSections>
      );
    });
  };

  const renderTableHeader = () => {
    const header = Object.keys({ CreationDate: 1, PerformedBy: 1, Outcome: 1, Actions: 1 });
    return header.map((key, index) => {
      return (
        <CustomerDataHeader
          key={index}
          CreationDate={key === 'CreationDate'}
          PerformedBy={key === 'PerformedBy'}
          Outcome={key === 'Outcome'}
          Action={key === 'Actions'}
        >
          {HeaderTransform[key]}
        </CustomerDataHeader>
      );
    });
  };

  const checkIfTableShouldRender = () => {
    return (
      <>
        <Customers data-testid={'ActivityList'}>
          <tbody>
            {<tr id="TableHeader">{renderTableHeader()}</tr>}
            {renderTableData()}
          </tbody>
        </Customers>
      </>
    );
  };

  const returnTask = () => {
    return <div>{checkIfTableShouldRender()}</div>;
  };

  return <>{returnTask()}</>;
};

ActivitesList.propTypes = {
  setViewActivity: PropTypes.func,
  assignOptions: PropTypes.array,
  listOfActivities: PropTypes.array,
  setSelectedActivity: PropTypes.func,
  ngm: PropTypes.bool,
};

export default ActivitesList;

import { useEffect, useState } from 'react';
import useSWR from 'swr';
import urlcat from 'urlcat';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

const requestPath = 'v1/thrive/access/:userId';

const retrieveApiStatus = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

function useThriveStatus() {
  const [isPending, setIsPending] = useState(true);
  const [status, setStatus] = useState();
  const [url, setUrl] = useState();

  const {
    data: response,
    error,
    mutate,
  } = useSWR(() => url, retrieveApiStatus, {
    revalidateOnFocus: false,
  });

  const getThriveStatus = (userId) => {
    if (userId) {
      setUrl(urlcat(process.env.API_BASE_URL, requestPath, { userId }));
      mutate(response);
    }
  };

  useEffect(() => {
    if (response) {
      setStatus(response.data);
    }

    setIsPending(!response && !error);
  }, [response, error]);

  return { status, error, isPending, getThriveStatus };
}

export default useThriveStatus;

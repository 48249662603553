import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import NumberFormat from 'react-number-format';

const NullableTextbox = forwardRef(({ inputProps, options, onChange, validate, ...props }, ref) => {
  const extraInputProps = {
    'aria-describedby': props['aria-describedby'],
    'aria-labelledby': props['aria-labelledby'],
  };
  const { name: filledName, ...filledAnswerOptions } = options[0];
  const { name: unfilledName, ...unfilledAnswerOptions } = options[1];
  const [answer, setAnswer] = useState(props?.value ?? { name: unfilledName, value: '' } ?? {});
  const [error, setError] = useState(!!answer.value ? validate(answer.value) : '');
  const answerOptions = filledName === props?.value?.name ? filledAnswerOptions : unfilledAnswerOptions;
  const answerValue = !isNaN(answer.value) ? parseInt(answer.value) : answer.value;

  const onValueChange = (e) => {
    const val = e.target.value;
    let ans = { name: unfilledName };

    if (!val && val !== 0) {
      ans = undefined;
    } else if (val != 0) {
      ans.value = val;
      ans.name = filledName;
    }

    setAnswer(ans ?? {});

    if (validate) {
      setError(validate(val));
    }

    if (onChange) {
      onChange(e, ans);
    }
  };

  return (
    <NumberFormat
      {...props}
      className="answer-textbox"
      inputProps={{ ...inputProps, ...extraInputProps }}
      onChange={onValueChange}
      allowLeadingZeros
      allowNegative={false}
      decimalSeparator=""
      ref={ref}
      {...answerOptions}
      error={error}
      value={answerValue}
      customInput={TextField}
      helperText={error}
    />
  );
});

NullableTextbox.displayName = 'NullableTextbox';

NullableTextbox.propTypes = {
  inputProps: PropTypes.object,
  ref: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.any,
  validate: PropTypes.func,
  ['aria-describedby']: PropTypes.string,
  ['aria-labelledby']: PropTypes.string,
};

export default NullableTextbox;

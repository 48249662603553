import { AnswerTypes } from './enums';

export const questions = [
  {
    name: '05c68f54-0af3-4b6d-8c08-d4fbf8aca390',
    text: 'Are you Hispanic or Latino?',
    options: [
      {
        name: 'd5b13dd2-623f-443d-a221-f1f538f17da8',
        text: 'Yes',
      },
      {
        name: '0f6a4539-36d8-4a99-9e85-02c8c6e13502',
        text: 'No',
      },
      {
        name: 'cfc5cf09-db3a-453d-a062-395aae8ec830',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
  {
    name: 'ec55df0d-0769-43a8-9699-4331a23d1aef',
    text: 'Which race(s) are you?  Check all that apply.',
    answerType: AnswerTypes.CHECKBOX,
    options: [
      {
        name: '8f1154f3-bd22-4ce5-a744-0f93c79709e4',
        text: 'American Indian or Alaskan Native',
      },
      {
        name: '8e0cfbab-7259-400f-a435-fd9f7a195c38',
        text: 'Asian',
      },
      {
        name: '80d61807-a873-41b9-b874-c3340d01e044',
        text: 'Black or African American',
      },
      {
        name: '6c94da53-969f-402b-b508-059a97d0a1d7',
        text: 'Pacific Islander',
      },
      {
        name: '92e22d96-f310-42dd-9410-6d997d49f16d',
        text: 'White',
      },
      {
        name: '9505e487-55a1-4fa9-826a-edca756ae548',
        text: 'Other',
      },
      {
        name: '3e7a167b-51fb-438f-a5f7-aa310b7bbc09',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
  {
    name: '7adf5691-4a1b-4607-a954-e0b2d76f555a',
    text: "At any point in the past 2 years, has seasonal or migrant farm work been you or your family's main source of income?",
    options: [
      {
        name: 'd16f1d9c-26c6-4fb5-b1b5-d0bf6bff67c4',
        text: 'Yes',
      },
      {
        name: '1c8f2f18-15dd-489c-9174-f473e2a7c256',
        text: 'No',
      },
      {
        name: '3f437663-077d-4b4d-ab27-aa608973f9fc',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
  {
    name: 'be40d46d-6bc6-4fb4-a831-0015b930aa7c',
    text: 'Have you been discharged from the armed forces of the United States?',
    options: [
      {
        name: '61d05aed-cd40-4e9a-9005-8a67a0ada187',
        text: 'Yes',
      },
      {
        name: '076d0a45-082a-4c82-a054-f1e8944778d5',
        text: 'No',
      },
      {
        name: '3c0e5c68-27fd-4435-b1cd-89d4e5130c1f',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
  {
    name: '06c25395-0d2a-48d2-8f40-c5c8ca2c3863',
    text: 'What languages are you most comfortable speaking?',
    options: [
      {
        name: 'f9c55b2d-b5ac-471f-a297-ee9b7e1e82be',
        text: 'English',
      },
      {
        name: '63e802d3-3e0b-40e5-beb1-e93af5b42523',
        text: 'Other',
      },
      {
        name: '72051b02-e104-4f7e-aefe-1bb7b83be3ae',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
  {
    name: '1d7b1e1e-5316-4c8d-ab59-4e461d079fb8',
    text: 'How many family members, including yourself, do you currently live with?',
    options: [
      {
        name: '66b7e321-c0c8-4d75-8f3f-4adf0ff5d378',
        text: '1',
      },
      {
        name: '20cfea03-7f59-44c5-996b-09a62caa497b',
        text: '2',
      },
      {
        name: '6ad98b18-6d21-4fae-95b6-a767657aeb3d',
        text: '3',
      },
      {
        name: 'e97bc1a6-a3dd-4de9-a338-01eef4196a07',
        text: '4',
      },
      {
        name: '518c2850-bdb8-498b-859d-e55928e4069a',
        text: '5',
      },
      {
        name: 'f3fff67e-8a62-4a50-8ea4-61e533895954',
        text: '6',
      },
      {
        name: '48697f15-1f4a-4a49-af64-9cc7c3ef423d',
        text: '7',
      },
      {
        name: 'ee37b8ed-6db3-4d23-8f05-1244d15e1dd3',
        text: '8',
      },
      {
        name: 'a5c5ceb4-dd94-4654-9486-94965b6cc063',
        text: '9',
      },
      {
        name: '243a1aa6-db41-43fa-ae53-68efcfeebb5c',
        text: '10+',
      },
      {
        name: '5abc3255-cdcb-4757-826d-28813a5e6d7e',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
  {
    name: '26a09be7-6924-40ac-93a2-d97e501b4361',
    text: 'What is your housing situation today?',
    options: [
      {
        name: '8a559f30-88d4-45e0-a919-984a792ebff0',
        text: 'Have housing',
      },
      {
        name: 'bfbb3eb7-3c5d-4f8f-9ca7-5baa73536ec1',
        text: 'Do not have housing',
      },
      {
        name: '9ee9d73e-c050-4aee-ae86-09712ecb31de',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
  {
    name: '785d17dc-166d-4513-bc18-f0aa9ab2c04d',
    text: 'Are you worried about losing your housing?',
    options: [
      {
        name: '4804bfdd-605e-444e-878e-6b70a6d6a829',
        text: 'Yes',
      },
      {
        name: '6154afa3-5c49-4346-818b-89fa31030b63',
        text: 'No',
      },
      {
        name: '2d710217-bc6e-484d-9a60-f854dec807f8',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
  {
    name: '59d7fdef-d4d6-419f-951e-4da7913fab4e',
    text: 'What is the highest level of school that you have finished?',
    options: [
      {
        name: 'efaf9ae1-2b6e-47fe-89fa-9f50a7f98170',
        text: 'Less than high school',
      },
      {
        name: '5d03e240-fd49-4a2e-aaf6-e9d0d05d630e',
        text: 'High school or GED',
      },
      {
        name: '2213679d-6844-4e56-bf39-cbb731547bc7',
        text: 'More than high school',
      },
      {
        name: '20631249-392c-4253-8fac-d617b636ad33',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
  {
    name: '1873b467-3f37-4569-861d-df63fa1ac141',
    text: 'What is your current work situation?',
    options: [
      {
        name: '04ce10e1-eb6f-4ff3-8c1c-29fa9a48d08a',
        text: 'Unemployed',
      },
      {
        name: 'ec8a8fcc-147e-4614-8bd2-38511297afe6',
        text: 'Part time or temporary',
      },
      {
        name: '1486a999-18f3-41ce-b227-f1ccca0696c2',
        text: 'Full time',
      },
      {
        name: 'b917fd58-e154-4536-91f2-11e6c2adeaaf',
        text: 'Not seeking work',
      },
      {
        name: '249f87b3-6b26-4d94-bea3-2db4f473b9ef',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
  {
    name: '1bd482d8-c840-4b0f-99bf-5d8c3881a817',
    text: 'What is your main insurance?',
    options: [
      {
        name: '4afcf6ac-6bd6-4536-97b4-0556986ec91c',
        text: 'None/uninsured',
      },
      {
        name: '905b8514-2ea0-4b60-8789-88491fcb2759',
        text: 'Medicaid',
      },
      {
        name: '07f11a08-c654-4bfd-ac05-8bb8549a1185',
        text: 'CHIP Medicaid',
      },
      {
        name: '5c3794c0-79e6-4a57-b8f3-db9900568ddf',
        text: 'Medicare',
      },
      {
        name: 'f51b5edb-5848-4df5-b4c0-f41c812dd708',
        text: 'Non-CHIP public',
      },
      {
        name: 'f37983f8-6e2c-4dfc-87cb-8ba292f0169f',
        text: 'CHIP public',
      },
      {
        name: '91abe9b1-4634-4f3d-8698-c74cf3af6c2b',
        text: 'Private insurance',
      },
      {
        name: '1f487c1b-c104-4127-80e4-c9ab5594b18e',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
  {
    name: '04c869bb-69d1-4a9a-a25b-98b6e3238dac',
    text: 'During the past year, what was the total combined income for you and the family members you live with? \nThis information will help us determine if you are eligible for any benefits.',
    answerType: AnswerTypes.SLIDER,
    options: [
      {
        name: 'b1c1e877-d601-4e74-9886-544d7d42cb58',
        text: 'Slider from $0-$250k',
        step: 1000,
        min: 0,
        max: 250000,
      },
      {
        name: 'c52804fd-c03b-476a-9d2f-c62b3494f0f1',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
  {
    name: 'f466317a-bb05-47eb-92cf-5cc6dec355fd',
    text: 'In the past year, have you or any family members you live with been unable to get any of the following when it was really needed?  Check all that apply. \nHelp text: \nHealth Care Details \n*Medical \n*Dental \n*Mental Health \n*Vision',
    answerType: AnswerTypes.CHECKBOX,
    options: [
      {
        name: 'c6174e90-b3a7-4bfd-b999-193f0d4043c1',
        text: 'Food',
      },
      {
        name: 'a6cca5ad-4fb7-4f39-b707-f89aa8363ccf',
        text: 'Clothing',
      },
      {
        name: 'c88aa925-8e2c-4c1f-a9be-ca0b483b1d01',
        text: 'Utilities',
      },
      {
        name: 'bef670cd-97c3-4ef1-9c3f-66f44da1f55f',
        text: 'Childcare',
      },
      {
        name: 'e645c727-a643-4381-a582-c76b134ea5e4',
        text: 'Medicine or any health care',
      },
      {
        name: 'f2059d25-768f-4586-973f-0a64f8f6aacf',
        text: 'Phone',
      },
      {
        name: 'b9bf4483-ae3d-4786-b053-2d9c3d2cd303',
        text: 'Other',
      },
      {
        name: '5535d468-1aa6-4147-8189-36f11d7b8855',
        text: 'No',
      },
      {
        name: 'e4f05c84-5649-4df0-b7e1-1db22e014fde',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
  {
    name: 'be51576b-1524-4cba-bc03-73a1a1453fa8',
    text: 'Has lack of transportation kept you from medical appointments, meetings, work, or from getting things needed for daily living?  Check all that apply.',
    answerType: AnswerTypes.CHECKBOX,
    options: [
      {
        name: '8da24acf-1e98-4906-a3e0-06686e3ef967',
        text: 'Yes, medical appointments',
      },
      {
        name: 'c0c05d70-586a-4f69-8d6f-c9c48392b3d6',
        text: 'Yes, for non-medical',
      },
      {
        name: 'abbaf5ed-ad8a-4274-8846-f54138da71de',
        text: 'No',
      },
      {
        name: 'e18ee7fc-85f7-4917-9a9e-007e8ee67ef7',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
  {
    name: '0c8594dc-9387-47d1-96f0-ceff0f018c5e',
    text: 'How often do you see or talk to people that you care about and feel close to? (For example: talking to friends on the phone, visiting friends or family, attending clubs)',
    options: [
      {
        name: 'aa725391-141f-46b3-a593-9c431813a9da',
        text: 'Less than once a week',
      },
      {
        name: '1c9c4c0c-d5ad-42b7-8240-a1837d1b6419',
        text: '1 or 2 times a week',
      },
      {
        name: 'd52f503f-888f-413a-97fd-057baee94078',
        text: '3 to 5 times a week',
      },
      {
        name: '80e2d173-2438-4344-a1e1-73f305e55d31',
        text: '6 or more times a week',
      },
      {
        name: '3a12fa1f-28c1-4248-9170-fc13429eea7e',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
  {
    name: 'd1365a6a-aa1b-4a1b-89ce-0bc451379235',
    text: "Stress is when someone feels tense, nervous, anxious, or can't sleep at night because their mind is troubled.  How stressed are you?",
    options: [
      {
        name: '373ea2c6-0af4-4ee5-bee1-0fb5991440c9',
        text: 'Not at all',
      },
      {
        name: 'a82898c6-52ea-4ee8-8e54-5b5fcc1725ce',
        text: 'A little bit',
      },
      {
        name: '85925762-5774-4684-9335-65603bf5e8c6',
        text: 'Somewhat',
      },
      {
        name: '98dc7e6d-bc01-4835-b2e0-f118b286b77e',
        text: 'Quite a bit',
      },
      {
        name: '2434a501-c0c9-491c-a5e1-122cfe2d82b1',
        text: 'Very much',
      },
      {
        name: '0dadd26d-dad9-4a6a-9120-bc703f74434c',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
  {
    name: '4980e9c3-0900-4de5-a0c8-0a8955f441fe',
    text: 'In the past year, have you spent more than 2 nights in a row in jail, prison, detention center, or juvenile correctional facility?',
    options: [
      {
        name: 'ab609c5d-4dad-49a6-be99-15b2f9b3f431',
        text: 'Yes',
      },
      {
        name: 'f8aba760-c20e-4567-8f5a-c80f99d846f3',
        text: 'No',
      },
      {
        name: '17e3ed77-d078-48c5-8c29-f80a6a9acb34',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
  {
    name: '0e8a09f8-1005-441b-88e8-638d24083e96',
    text: 'Are you a refugee?',
    options: [
      {
        name: '0097cb0c-3fee-48bd-9ff4-20f4729c4f3e',
        text: 'Yes',
      },
      {
        name: '095f2e64-8c0d-4368-b8ae-9f51b0d393d0',
        text: 'No',
      },
      {
        name: '2fd32351-2ef7-4d9e-9387-2f70924c8fea',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
  {
    name: '3c7855c9-ad2c-4c77-846b-056742ccabc2',
    text: 'Do you feel physically and emotionally safe where you currently live?',
    options: [
      {
        name: '0bdfb022-0c02-4f60-8fc8-680183b67129',
        text: 'Yes',
      },
      {
        name: '8dcee727-aa14-481a-82c6-86856543e1f1',
        text: 'No',
      },
      {
        name: '9c5cbb92-0374-4dd1-bace-4614b4da52ad',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
  {
    name: 'fed898da-baeb-4c58-92cd-fdba6430a2ad',
    text: 'In the past year, have you been afraid of your partner or ex-partner?',
    options: [
      {
        name: 'c97cad89-2dc5-4536-86aa-82f166710158',
        text: 'Yes',
      },
      {
        name: 'f642c37a-cb27-4cfc-b8bb-c708a5210a5d',
        text: 'No',
      },
      {
        name: '190533eb-76b7-43a5-b8a4-e778133f3fdb',
        text: 'Unsure',
      },
      {
        name: 'd19fad57-cc0b-4f40-a79c-889c67506054',
        text: 'Have not had a partner',
      },
      {
        name: '016e5130-f298-453a-b85e-e830affc514b',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
];

import styled from '@emotion/styled';
import { Dialog, Grid, Tooltip, Typography } from '@mui/material';
import Button from 'Components/Button';

export const Header = styled.div`
  display: flex;
  width: -webkit-fill-available;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0 5px 15px;
  background-color: rgb(2, 138, 138);
  color: white;

  .MuiIconButton-root {
    color: white;
    padding: 0;
    padding-right: 5px;

    .MuiSvgIcon-root {
      font-size: 45px;
    }
  }
`;

export const MigrationErrorMessage = styled(Typography)`
word-break: break-word;
  overflow: clip;
  display: inline-block;
  max-height: 70px;
  display: inherit;

  &:hover {
    overflow: visible;
    background-color: white;
    height: fit-content;
    max-height: fit-content;
    width: 100%;
    z-index: 1;
    box-shadow: 0px 12px 6px rgba(0,0,0,0.2);
    }
  }
`;

export const MigrationActionButton = styled(Button)`
  height: 35px;
  width: 60px;
`;

export const MigrationActionsContainer = styled(Grid)`
  align-self: end;
  display: flex;
  justify-content: space-evenly;
`;

export const ErrorContainer = styled.div`
  display: flex;
  max-height: 70px;
`;

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    min-width: 300px;
    max-width: 450px;
    border-radius: 20px;
  }
`;

export const InfoLabel = styled(Grid)`
  text-align: right;
  align-self: center;
  font-size: 14px;
`;

export const InfoData = styled(Grid)`
  padding-left: 10px;
  font-size: 14px;
`;

export const MigrationInfoContainer = styled(Grid)`
  padding: 20px 9px 25px 35px;
  row-gap: 15px;
  justify-content: center;
`;

export const DataLinkButton = styled(Button)`
  padding: 0;
  height: auto;
  line-height: inherit;
  min-width: 30px;
`;

export const ActionTooltip = styled(Tooltip)`
  &.Mui-disabled {
    pointer-events: inherit;
  }
`;

import React, { useEffect, useState, useContext } from 'react';
import SearchBox from 'Tasking_Components/SearchBox/SearchBox';
import SearchResults from 'Tasking_Components/SearchResults/SearchResults';
import Dashboard from 'Tasking_Components/Dashboard/Dashboard';
import { TaskSelectedContext } from 'Tasking_Contexts/TaskSelectedContext';
import { MainIconBarContext } from 'Tasking_Contexts/MainIconBarContext';
import { ActivitySelectedContext } from 'Tasking_Contexts/ActivitySelectedContext';
import withAppInsights from 'Helpers/appInsights';

const HomePage = () => {
  const [showResults, setShowResults] = useState(false);
  const [background, setBackground] = useState('white');
  const [searchResultsData, setSearchResultsData] = useState();
  const [ngmSearchResultsData, setNgmSearchResultsData] = useState();
  const { setSelectedTask } = useContext(TaskSelectedContext);
  const { setSelectedActivity } = useContext(ActivitySelectedContext);
  const { setShowMainIcons } = useContext(MainIconBarContext);

  let currentUrlParams = new URLSearchParams(window.location.search);
  const valuesForSearch = {};
  currentUrlParams.forEach((value, key) => {
    valuesForSearch[key] = value;
  });

  const showSearchResults = () => {
    setShowResults(true);
  };

  useEffect(() => {
    setSelectedTask();
    setSelectedActivity();
    setShowMainIcons(true);
  }, []);

  useEffect(() => {
    showResults ? setBackground('#F9F9F9') : setBackground('white');
  }, [showResults]);

  return (
    <div style={{ backgroundColor: background }} id="HomePage">
      <SearchBox
        showResults={showResults}
        valuesForSearch={valuesForSearch}
        showSearchResults={showSearchResults}
        setSearchResultsData={setSearchResultsData}
        setNgmSearchResultsData={setNgmSearchResultsData}
      />
      {showResults ? (
        <SearchResults searchResultsData={searchResultsData} ngmSearchResultsData={ngmSearchResultsData} />
      ) : (
        <Dashboard />
      )}
    </div>
  );
};

export default withAppInsights(HomePage);

import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';

const useEditAssistanceProvided = () => {
  const [editAssistanceProvidedData, setEditAssistanceProvidedData] = useState();
  const [url, setUrl] = useState();
  const { response, isLoading, hasErrored } = useAxiosSubmit(url, editAssistanceProvidedData, 'put');

  const editAssistanceProvided = async (organizationId, assistanceProvidedGuid, request) => {
    setUrl(`${process.env.API_BASE_URL}/v5/AssistanceProvided/${organizationId}:${assistanceProvidedGuid}`);
    setEditAssistanceProvidedData(request);
  };

  return { editResponse: response, editError: hasErrored, isEditPending: isLoading, editAssistanceProvided };
};

export default useEditAssistanceProvided;

import React, { useState, useEffect } from 'react';
import {
  TextHeader,
  SubmitButton,
  OtpDataContainer,
  SectionHeader,
  HeaderContainer,
  OtpCodeHeader,
  OtpCoode,
  DoneButton,
  CustomError,
  StyledFormControlLabel,
  StyledRadioContainer,
  StyledInputLabel,
  StyleLabeledFormControlLabel,
} from './styles';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import useMemberOtpCode from 'Tasking_Hooks/useMemberOtpCode';
import { OtpTypes } from 'Tasking_Helpers/otpTypes';
import { RadioGroup, Radio, TextField } from '@mui/material';
import { contactOptions } from './memberOtpCodeConfig';

const MemberOtpCode = ({ setActions, setActionToDisplay, setCurrentTab, memberInfo, ngm, setValues, values }) => {
  const [customError, setCustomError] = useState(false);
  const { otpCode, isPending, error, retrieveMemberOtpCode, retrieveMemberOtpCodeByMemberSearch } = useMemberOtpCode();
  const { setValue, handleSubmit, control } = useForm({
    defaultValues: { OtpOptions: '' },
  });

  const updateValue = (name, value) => {
    setValues({ ...values, memberOtpCodeData: { ...values?.memberOtpCodeData, [name]: value } });
  };

  const onSubmit = () => {
    setActions(true);
    if (!ngm) {
      retrieveMemberOtpCode(memberInfo?.userId, OtpTypes.PASSWORD);
    } else {
      const contactType = values?.memberOtpCodeData?.contactType;

      const contactConfig = {
        [contactOptions.PREFERRED_PHONE]: memberInfo?.preferredPhone,
        [contactOptions.OTHER_PHONE]: values?.memberOtpCodeData?.contactValue ?? defaultOtherPhoneText,
        [contactOptions.EMAIL]: values?.memberOtpCodeData?.contactValue,
      };

      const contactValue = contactConfig[contactType];

      let request = {};
      let isValid = true;
      let otpType = null;

      if (contactType === contactOptions.EMAIL) {
        request.email = contactValue;
        otpType = OtpTypes.EMAIL;
      } else {
        request.phoneNumber = contactValue;
        otpType = OtpTypes.PHONE;

        if (contactValue?.length < 10) {
          isValid = false;
        }
      }

      if (!isValid || !otpType) {
        setCustomError(true);
      } else {
        retrieveMemberOtpCodeByMemberSearch(request, otpType);
      }
    }
  };

  const doneSelected = () => {
    setValues({ ...values, currentTab: '', actionToDisplay: '', memberOtpCodeData: undefined });
    setActionToDisplay('');
    setCurrentTab('');
  };

  useEffect(() => {
    if (otpCode) {
      updateValue('otpCode', otpCode.code);
    }
  }, [otpCode]);

  const onContactChange = (contactType) => {
    updateValue('contactType', contactType);
  };

  const onContactTextChange = (e, contactOption) => {
    if (contactOption) {
      setValue('contactType', contactOption);
    }

    const text = e.target.value;

    const textData = {
      contactValue: text,
      contactType: contactOption,
      [contactOption]: text,
    };

    setValues({
      ...values,
      memberOtpCodeData: { ...values?.memberOtpCodeData, ...textData },
    });
  };

  const defaultOtherPhoneText =
    values?.memberOtpCodeData?.[contactOptions.OTHER_PHONE] ?? memberInfo?.normalizedPhone ?? '';
  const defaultEmailText = values?.memberOtpCodeData?.[contactOptions.EMAIL] ?? '';
  const isError = !otpCode && (!!error || customError);

  return (
    <>
      <HeaderContainer>
        <TextHeader>OTP Code</TextHeader>
      </HeaderContainer>
      <OtpDataContainer data-testid="OtpCode">
        <form data-testid="OtpCodeForm" onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '-10px' }}>
          {ngm && (
            <Controller
              name={'contactType'}
              control={control}
              defaultValue={values?.memberOtpCodeData?.contactType ?? ' '}
              render={({ field: { onChange, ...field } }, ...fields) => {
                const onContactOptionChange = (e, contactOption) => {
                  onChange(e);
                  onContactChange(contactOption);
                };
                return (
                  <RadioGroup {...field} {...fields} onChange={onContactOptionChange} sx={{ marginLeft: '90px' }}>
                    <SectionHeader>Phone Number:</SectionHeader>
                    <StyledRadioContainer>
                      {memberInfo?.preferredPhone && (
                        <StyledFormControlLabel
                          label={`${memberInfo?.preferredPhone}\nPreferred`}
                          value={contactOptions.PREFERRED_PHONE}
                          checked={field?.value === contactOptions.PREFERRED_PHONE}
                          control={<Radio />}
                        />
                      )}
                      <StyleLabeledFormControlLabel
                        label={
                          <>
                            <StyledInputLabel htmlFor="otherPhone">Other</StyledInputLabel>
                            <TextField
                              id="otherPhone"
                              defaultValue={defaultOtherPhoneText}
                              onChange={(e) => onContactTextChange(e, contactOptions.OTHER_PHONE)}
                              inputProps={{ 'aria-label': 'Other Phone' }}
                            />
                          </>
                        }
                        value={contactOptions.OTHER_PHONE}
                        checked={field?.value === contactOptions.OTHER_PHONE}
                        control={<Radio />}
                      />
                    </StyledRadioContainer>
                    <SectionHeader>Email:</SectionHeader>
                    <StyledRadioContainer>
                      <StyledFormControlLabel
                        label={
                          <TextField
                            defaultValue={defaultEmailText}
                            onChange={(e) => onContactTextChange(e, contactOptions.EMAIL)}
                            inputProps={{ 'aria-label': 'Email' }}
                          />
                        }
                        value={contactOptions.EMAIL}
                        checked={field?.value === contactOptions.EMAIL}
                        control={<Radio />}
                      />
                    </StyledRadioContainer>
                  </RadioGroup>
                );
              }}
            />
          )}
          <SubmitButton type="submit" loading={isPending} disableElevation>
            Get Code
          </SubmitButton>
        </form>
        {isError && <CustomError data-testid="CustomError">Failed to retrieve OTP Code</CustomError>}
        <OtpCodeHeader>OTP Code:</OtpCodeHeader>
        <OtpCoode data-testid="OtpCoode" style={{ color: '#0F145B' }}>
          {values?.memberOtpCodeData?.otpCode ?? '000000'}
        </OtpCoode>
        <DoneButton data-testid="DoneButton" onClick={doneSelected}>
          Done
        </DoneButton>
      </OtpDataContainer>
    </>
  );
};

MemberOtpCode.propTypes = {
  setActionToDisplay: PropTypes.func,
  setCurrentTab: PropTypes.func,
  onChange: PropTypes.func,
  setActions: PropTypes.func,
  value: PropTypes.string,
  memberInfo: PropTypes.object,
  ngm: PropTypes.bool,
  values: PropTypes.object,
  setValues: PropTypes.func,
};

export default MemberOtpCode;

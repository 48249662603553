import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

const assignOptionsUrl = '/v5/Tasks/users';
const options = {
  revalidateOnFocus: false,
};

const getAssignOptions = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useAssignOptions = () => {
  const [isAssignableUsersPending, setIsPending] = useState(true);
  const [assignOptions, setAssignOptions] = useState();
  const { data: assignResult, error } = useSWR(
    () => `${process.env.API_BASE_URL}${assignOptionsUrl}`,
    getAssignOptions,
    {
      ...options,
    },
  );

  useEffect(() => {
    if (assignResult) {
      setAssignOptions(assignResult.data);
    }

    setIsPending(!assignResult && !error);
  }, [assignResult, error]);

  return { assignOptions, error, isAssignableUsersPending };
};

export default useAssignOptions;

import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';

const useCreateCard = () => {
  const [cardRequest, setCardRequest] = useState();
  const [url, setUrl] = useState();
  const { response, isLoading, hasErrored } = useAxiosSubmit(url, cardRequest, 0, 1);

  const createCard = async (request) => {
    setUrl(`${process.env.API_BASE_URL}/v1/cards`);
    setCardRequest(request);
  };

  return { cardInformation: response, cardError: hasErrored, cardIsPending: isLoading, createCard };
};

export default useCreateCard;

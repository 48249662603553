export const questions = [
  {
    name: '51cf8f1b-7677-438e-9595-a15807bed575',
    text: 'How often do you feel that you lack companionship?',
    options: [
      {
        name: 'f0db37fe-66c5-4238-8955-89ba7f4a6758',
        text: 'Hardly ever or never',
      },
      {
        name: 'a379b070-5c1d-4dbd-a6a5-43056c9b25eb',
        text: 'Some of the time',
      },
      {
        name: '863c244e-0914-4f2d-a795-35963b84201d',
        text: 'Often',
      },
    ],
  },
  {
    name: '255ea238-b3ab-4799-aa82-ec886be9da86',
    text: 'How often do you feel left out?',
    options: [
      {
        name: 'f44d66cc-ce07-416d-88e7-bae59abce2d6',
        text: 'Hardly ever or never',
      },
      {
        name: '7538b3bf-02c8-4989-8ae4-503a1837357c',
        text: 'Some of the time',
      },
      {
        name: '663ab25e-fdba-48cd-8135-51b4c8afc8d8',
        text: 'Often',
      },
    ],
  },
  {
    name: 'dac97d9a-0471-4ba0-a0ff-60fc98601b4b',
    text: 'How often do you feel isolated from others?',
    options: [
      {
        name: 'b5fca7cf-ef1c-4432-88f9-9586bd466b5b',
        text: 'Hardly ever or never',
      },
      {
        name: 'b2620eb0-02d7-4b5e-afdd-58f0df859cf1',
        text: 'Some of the time',
      },
      {
        name: 'e79c41c9-b652-48a4-ba2e-671575794315',
        text: 'Often',
      },
    ],
  },
];

import React from 'react';
import PropTypes from 'prop-types';
import loadingGif from 'Assets/images/loading.gif';

const Spinner = ({ height, width, marginLeft, marginTop, marginBottom }) => {
  return (
    <img
      data-testid="Spinner"
      style={{
        width,
        height,
        marginLeft,
        marginTop,
        marginBottom,
      }}
      src={loadingGif}
    />
  );
};
Spinner.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  marginLeft: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
};
Spinner.defaultProps = {
  height: '15px',
  width: '15px',
  marginLeft: '50px',
  marginTop: '8px',
  marginBottom: '0',
};

export default Spinner;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { TextField } from '@mui/material';

const CodeInput = forwardRef(({ onChange, error, errorMessage, ...props }, ref) => {
  return (
    <NumberFormat
      getInputRef={ref}
      type={'tel'}
      customInput={TextField}
      placeholder="OTP Code"
      format="######"
      mask="_"
      helperText={error && errorMessage}
      error={error}
      onValueChange={(v) => onChange(v.value)}
      {...props}
    />
  );
});

CodeInput.displayName = 'CodeInput';

CodeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  error: PropTypes.bool,
};

export default CodeInput;

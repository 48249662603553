export function mergeObjectsByMetaCategory(obj, metaArr) {
  let mergedObj = [];

  metaArr.forEach((category) => {
    obj[category].forEach((section) => {
      //identify if the section already exists in the merged object
      const existingPosition = mergedObj.findIndex((mo) => mo.dimension == section.dimension);
      if (existingPosition > -1) {
        //filter out dupes and append unique items
        const filteredItems = section.items.filter(
          (x) => !mergedObj[existingPosition].items.some((i) => i.contentId == x.contentId),
        );
        mergedObj[existingPosition].items.push(...filteredItems);
      } else {
        //add the whole section if it does not exist
        mergedObj.push(section);
      }
    });
  });

  return mergedObj;
}

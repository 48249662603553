import 'regenerator-runtime/runtime.js';
import { useState, useEffect } from 'react';
import useAxiosSubmit from './useAxiosSubmit';
import useEnumsList from './useEnumLists';

const SEARCH_URL = `${process.env.API_BASE_URL}/v5/Tasks/search`;

const useGetTaskList = () => {
  const { enums } = useEnumsList();
  const [otherRequest, setOtherRequest] = useState();
  const [taskListDataAndyRequest, setTaskListDataAndyRequest] = useState();
  const [taskListDataNewRequest, setTaskListDataNewRequest] = useState();
  const [taskListDataOtherRequest, setTaskListDataOtherRequest] = useState();
  const [taskList, setTaskList] = useState();
  const [isTaskListPending, setIsTaskListPending] = useState(false);
  const [searchUrl, setSearchUrl] = useState();

  const {
    response: taskListDataAndy,
    isLoading: isLoadingAndy,
    hasErrored: hasErroredAndy,
  } = useAxiosSubmit(searchUrl, taskListDataAndyRequest);
  const {
    response: taskListDataNew,
    isLoading: isLoadingNew,
    hasErrored: hasErroredNew,
  } = useAxiosSubmit(searchUrl, taskListDataNewRequest);
  const {
    response: taskListDataOther,
    isLoading: isLoadingOther,
    hasErrored: hasErroredOther,
  } = useAxiosSubmit(searchUrl, taskListDataOtherRequest);

  useEffect(() => {
    setIsTaskListPending(isLoadingAndy || isLoadingNew || isLoadingOther);
  }, [isLoadingAndy, isLoadingNew, isLoadingOther]);

  useEffect(() => {
    if (taskListDataAndy) {
      setTaskList(taskListDataAndy);
    }
  }, [taskListDataAndy]);

  useEffect(() => {
    if (taskListDataNew) {
      setTaskList(taskListDataNew);
    }
  }, [taskListDataNew]);

  useEffect(() => {
    if (taskListDataOther) {
      setTaskList(taskListDataOther);
    }
  }, [taskListDataOther]);

  useEffect(() => {
    if (enums && otherRequest) {
      getTaskListOther(otherRequest);
    }
  }, [enums, otherRequest]);

  const getTaskList = (request, type) => {
    setSearchUrl(SEARCH_URL);
    switch (type) {
      case 'ENROLLMENT':
        getTaskListNewEnrollment(request);
        break;
      case 'ANDY':
        getTaskListAndy(request);
        break;
      case 'ALL':
        setOtherRequest(request);
        break;
      default:
        setOtherRequest(request);
        getTaskListAndy(request);
        getTaskListNewEnrollment(request);
    }
  };

  const getTaskListAndy = (request) => {
    const andyRequest = { ...request, taskType: 'ANDY' };
    setTaskListDataAndyRequest(andyRequest);
  };

  const getTaskListNewEnrollment = (request) => {
    const newRequest = { ...request, taskType: 'ENROLLMENT' };
    setTaskListDataNewRequest(newRequest);
  };

  const getTaskListOther = (request) => {
    const taskTypes = [];
    enums.TaskType.forEach((taskType) => {
      if (taskType.code !== 'ANDY' && taskType.code !== 'ENROLLMENT') {
        taskTypes.push(taskType.code);
      }
    });
    const otherRequest = { ...request, taskType: taskTypes.join() };
    setTaskListDataOtherRequest(otherRequest);
  };

  return {
    taskList,
    errorAndy: hasErroredAndy,
    errorNew: hasErroredNew,
    errorOther: hasErroredOther,
    isTaskListPending,
    getTaskList,
  };
};

export default useGetTaskList;

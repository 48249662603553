import { LoadingButton } from '@mui/lab';
import { Grid, TextField } from '@mui/material';
import styled from 'styled-components';

export const CallTimerContainer = styled(Grid)`
  border: 1px solid ${(props) => (props.disabled ? '#F8F7F7' : '#e9e7e7')};
  border-radius: 10px;
  width: 325px;
  color: ${(props) => (props.disabled ? '#BCBCBC' : '#0f0f0f')};
  font-size: 14px;
  min-height: 229px;
  padding: 15px;

  & .MuiGrid-root {
    justify-content: center;
  }
`;

export const TimerEntryContainer = styled(Grid)`
  padding-top: 5px;
`;

export const StyledGridTimerPicker = styled(Grid)`
  & .MuiPaper-root > div > div:last-child {
    box-shadow: none;
    width: 100%;
  }

  & .MuiPaper-root {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-radius: 20px;

    & .MuiClockPicker-arrowSwitcher {
      display: none;
    }

    & .MuiSvgIcon-root {
      display: none;
    }

    & .PrivatePickersToolbar-root {
      background-color: #f6f6f6;
      padding: 12px;
      border-radius: 20px 20px 0px 0px;

      & .MuiGrid-root {
        & .PrivateTimePickerToolbar-ampmSelection {
          margin-right: 0px;
          background-color: #e9e9e9;
          border-radius: 10px;
          margin-left: 6px;

          & .MuiTypography-root {
            font-size: 14px;
            border-radius: 10px;
          }

          *:first-child .MuiTypography-root {
            padding: 5px 6px 0px 6px;
            &.Mui-selected {
              background-color: #b6b6c6;
              border-radius: 10px 10px 0px 0px;
            }
          }

          *:last-child .MuiTypography-root {
            padding: 0px 6px 5px 6px;
            &.Mui-selected {
              background-color: #b6b6c6;
              border-radius: 0px 0px 10px 10px;
            }
          }
        }

        & .PrivateTimePickerToolbar-hourMinuteLabel {
          & .MuiTypography-root {
            background-color: #e9e9e9;
            padding: 0px 5px;
            border-radius: 10px;
          }

          & .Mui-selected {
            background-color: #b6b6c6;
            color: #1f194b;
            padding: 0px 5px;
            border-radius: 10px;
          }

          & .PrivateTimePickerToolbar-separator {
            background-color: #f6f6f6;
            padding: 0px;
          }
        }
      }
    }

    & .MuiIconButton-root {
      display: none;
    }
  }
`;

export const StyledTextField = styled(TextField)`
  width: 100%;

  & .MuiOutlinedInput-root {
    &:hover fieldset,
    & fieldset {
      border-color: #e9e7e7;
      border-radius: 8px;
    }

    &.Mui-focused fieldset {
      border-color: #e9e7e7;
      border-width: 1px;
      border-radius: 8px;
    }
  }

  & .MuiInputBase-root.Mui-disabled {
    & > fieldset,
    &.Mui-focused fieldset,
    &:hover fieldset {
      border-color: #f8f7f7;
      border-radius: 8px;
    }
  }

  & .MuiInputBase-input {
    border: 1px solid #e9e7e7;
    padding: 5px;
    border-radius: 8px;
  }
`;

export const CallTimerField = styled(TextField)`
  width: 100%;

  input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
  input[type='time']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  & .MuiInputBase-root.Mui-disabled {
    & > fieldset,
    &.Mui-focused fieldset,
    &:hover fieldset {
      border-color: #f8f7f7;
      border-radius: 8px;
    }
  }

  & .MuiInputBase-input {
    border: 1px solid #e9e7e7;
    padding: 5px;
    border-radius: 8px;
  }

  & .MuiOutlinedInput-root {
    &:hover fieldset,
    & fieldset {
      border-color: #e9e7e7;
      border-radius: 8px;
    }
    &.Mui-focused fieldset {
      border-color: #e9e7e7;
      border-width: 1px;
      border-radius: 8px;
    }
  }
`;

export const StyledIconButtonContainer = styled.div`
  position: absolute;
  width: 93%;
  justify-content: right;
  padding: 0px;
  margin: 0px;
  height: 11px;
  display: flex;
  color: #898e95;
`;

export const SubmitButton = styled(LoadingButton)`
  border-radius: 25px;
  height: 27px;
  margin-top: 5px;
  position: absolute;
  width: 90%;
  top: 170px;

  &.Mui-disabled {
    background-color: #c8c8da;
    color: #ffffff;
  }
`;

import styled from 'styled-components';

export const StyledContainer = styled.div`
  margin-bottom: 28px;
`;

export const StyledBoldParagraph = styled.p`
  font-weight: bold;
  display: inline;
`;

export const StyledParagraph = styled.p`
  display: inline;
  white-space: nowrap;
`;

export enum Screenings {
  YOUTH_NEEDS = 'f78d6304-cb3d-4781-997a-50ad50ce685e',
  YOUTH_PHQ2 = '4d68c997-e574-4b79-8f28-7676b1cd6276',
  YOUTH_UCLA3 = 'b670ccd4-d2d7-4757-b78e-4184fafd81bf',
  ADULT_UCLA3 = '334b56f2-205f-4b18-9c31-39b2c1cd7253',
  ADULT_PHQ4 = 'e5620a7c-6f01-4d3e-a7f5-c5ecbb6e196d',
  ADULT_NEEDS = '167a68b6-66a9-4016-bddb-4fa9df65a6ad',
  ADULT_PRAPARE = '14d9dbb5-de3d-40fd-9aec-7e04c8720a46',
  ADULT_WELLBEING = 'f7690741-32cc-45a1-b73b-c9694a12bb65',
  CAHPS_PYXPLUS = '6a5d194e-a9c0-4c75-a60b-f4c9e759a5d2',
}

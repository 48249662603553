/* istanbul ignore file */

export const activityTypes = {
  CARD: 'CARD',
  ASSISTANCE: 'ASSISTANCE',
  FEEDBACK: 'FEEDBACK',
  NONAPPENROLL: 'NONAPPENROLL',
  OTP: 'OTP',
  CONCERNS: 'CONCERNS',
  TEXT: 'TEXT',
  SCREEN: 'SCREEN',
  MEMBERCONNECTION: 'MEMBERCONNECTION',
};

export const memberTypes = {
  ENROLLED: 'ENROLLED',
  NGM: 'NGM',
  ALL: 'ALL',
};

export default {
  TABS: [
    {
      type: activityTypes.CARD,
      description: 'SEND CARD',
      icon: 'fas fa-envelope',
      memberType: memberTypes.ENROLLED,
      taskType: 'CARD',
    },
    {
      type: activityTypes.MEMBERCONNECTION,
      description: 'MEMBER CONNECTION',
      icon: 'fas fa-user',
      memberType: memberTypes.ALL,
      taskType: 'ALL',
    },
    {
      type: activityTypes.ASSISTANCE,
      description: 'ASSISTANCE',
      icon: 'fas fa-clipboard-check',
      memberType: memberTypes.ENROLLED,
      taskType: 'ALL',
    },
    {
      type: activityTypes.FEEDBACK,
      description: 'FEEDBACK',
      icon: 'fas fa-user',
      memberType: memberTypes.ENROLLED,
      taskType: 'FEEDBACK',
    },
    {
      type: activityTypes.NONAPPENROLL,
      description: 'NON-APP ENROLL',
      icon: 'fas fa-user-plus',
      memberType: memberTypes.NGM,
      taskType: 'ENROLLMENT',
    },
    {
      type: activityTypes.OTP,
      description: 'OTP CODE',
      icon: 'fas fa-shield-check',
      memberType: memberTypes.ALL,
      taskType: 'ALL',
    },
    {
      type: activityTypes.CONCERNS,
      description: 'CONCERNS',
      icon: 'fas fa-question-circle',
      memberType: memberTypes.ENROLLED,
      taskType: 'ALL',
    },
    {
      type: activityTypes.TEXT,
      description: 'TEXT',
      icon: 'fas fa-comment-alt-lines',
      memberType: memberTypes.ALL,
      taskType: 'ALL',
    },
    {
      type: activityTypes.SCREEN,
      description: 'SCREEN',
      icon: 'fas fa-clipboard-list-check',
      memberType: memberTypes.ENROLLED,
      taskType: 'ALL',
    },
  ],
};

export const headers = [
  { header: 'Member', name: 'userFullName' },
  { header: 'Migration Status', name: 'migrationStatus' },
  { header: 'Source', name: 'sourceOrganizationName' },
  { header: 'Destination', name: 'destinationOrganizationName' },
];

export const getViewMigrationFields = (req) => {
  return [
    {
      title: 'Destination Org',
      value: req?.destinationOrganizationName,
    },
    {
      title: 'Destination Group',
      value: req?.destinationGroupName,
    },
    {
      title: 'Destination HPID',
      value: req?.destinationHealthPlanId,
    },
    {
      title: 'Source Org',
      value: req?.sourceOrganizationName,
    },
    {
      title: 'Source Group',
      value: req?.sourceGroupName,
    },
    {
      title: 'Created By',
      value: req?.createdByName,
    },
    {
      title: 'Created On',
      value: req?.createdOnUtc,
    },
    {
      title: 'Profile Migration',
      value: req?.profileMigrationType,
    },
    {
      title: 'Data Migration',
      value: req?.dataMigrationType,
    },
  ];
};

import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, InputLabel, MenuItem, Select } from '@mui/material';
import { StyledFormControl } from './styles';
import { defaultOptionPropConfig, searchTypes } from './selectConfig';

const SearchSelect = ({ name, title, type, options, value, loading, show, optionProps, ...props }) => {
  const selectProps = { id: title, name: title };

  const transformOptions = (optionData) => {
    let transformedOptions = [];
    const validOptions = optionData?.filter((x) => x);
    transformedOptions.length = validOptions?.length;

    validOptions?.map((data, index) => {
      let transformedOptionProps = {};
      Object.keys(optionProps).map((x) => (transformedOptionProps[x] = data[optionProps[x]]));
      transformedOptions[index] = transformedOptionProps;
    });

    return transformedOptions;
  };

  const transformValue = (selected) => {
    if (type === searchTypes.SINGLE) {
      const exactDisplayValue = optionsData?.find((x) => x.name === selected)?.text;
      const displayValue = exactDisplayValue ? exactDisplayValue : optionsData?.find((x) => x.name == selected)?.text;
      return displayValue ?? title;
    }

    if (selected.length === 0) {
      return title;
    }

    let selectedDisplayNames = [];

    selected?.forEach((y) => {
      const displayValue = optionsData?.find((x) => x.name == y)?.text;
      selectedDisplayNames.push(displayValue);
    });

    return selectedDisplayNames.join(', ');
  };

  const isPending = loading || !options;
  const optionsData = !isPending ? transformOptions(options) : [];

  return (
    show && (
      <StyledFormControl variant="filled">
        <InputLabel id={`${name}label`} sx={{ display: 'none' }}>
          {title}
        </InputLabel>
        <Select
          {...selectProps}
          id={name}
          labelId={`${name}label`}
          multiple={type === searchTypes.MULTIPLE}
          displayEmpty
          value={value}
          renderValue={transformValue}
          MenuProps={{ PaperProps: { style: { maxHeight: '300px' } } }}
          {...props}
        >
          {title && (
            <MenuItem disabled value="">
              {title}
            </MenuItem>
          )}
          {!isPending &&
            optionsData?.map((data) => {
              const { name, text } = data;
              return (
                <MenuItem key={name} name={name} value={name}>
                  {type === searchTypes.MULTIPLE && <Checkbox checked={value?.indexOf(name) > -1} />}
                  {text}
                </MenuItem>
              );
            })}
        </Select>
      </StyledFormControl>
    )
  );
};

SearchSelect.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  optionProps: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  loading: PropTypes.bool,
  show: PropTypes.bool,
};

SearchSelect.defaultProps = {
  type: searchTypes.SINGLE,
  optionProps: defaultOptionPropConfig,
  show: true,
};

export default SearchSelect;

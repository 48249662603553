import React, { forwardRef } from 'react';
import { Checkbox } from '@mui/material';
import { StyledLabel, StyledWrapper, StyledSubQuestionWrapper } from './styles';
import PropTypes from 'prop-types';

const MemberConnectionsCheckbox = forwardRef(
  ({ onChange, name, label, disabled, dependencies, isChecked, ...props }, ref) => {
    const onChecked = (e) => {
      onChange(e.target.checked);
    };

    const Component = dependencies ? StyledSubQuestionWrapper : StyledWrapper;

    return (
      <Component>
        <StyledLabel
          label={label}
          ref={ref}
          control={
            <Checkbox
              {...props}
              name={name}
              disabled={disabled}
              checked={isChecked}
              onChange={(e) => {
                onChecked(e);
              }}
            />
          }
        />
      </Component>
    );
  },
);

MemberConnectionsCheckbox.displayName = 'MemberConnectionsCheckbox';

MemberConnectionsCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  dependencies: PropTypes.object,
  disabled: PropTypes.bool,
  isChecked: PropTypes.bool,
};

export default MemberConnectionsCheckbox;

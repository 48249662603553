import React, { forwardRef } from 'react';
import { RadioGroup, FormControlLabel, Radio as MuiRadio } from '@mui/material';
import PropTypes from 'prop-types';

const Radio = forwardRef(({ index, options, ...props }, ref) => {
  return (
    <RadioGroup aria-labelledby={`radiogroup-label-${index}`} name={`radiogroup-${index}`} ref={ref} {...props}>
      {options.map(({ text, name }, optionIndex) => (
        <FormControlLabel
          key={optionIndex}
          name={name}
          value={name}
          checked={props?.value === name}
          control={<MuiRadio />}
          label={text}
        />
      ))}
    </RadioGroup>
  );
});

Radio.displayName = 'Radio';

Radio.propTypes = {
  index: PropTypes.string,
  options: PropTypes.array,
  props: PropTypes.object,
  ref: PropTypes.object,
  value: PropTypes.string,
};

export default Radio;

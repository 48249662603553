import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

const enumsUrl = 'v5/Tasks/enums';
const options = {
  revalidateOnFocus: false,
};

const getTasksEnums = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useEnumsList = () => {
  const [isPending, setIsPending] = useState(true);
  const [enums, setEnums] = useState();
  const { data: enumsResult, error } = useSWR(() => `${process.env.API_BASE_URL}/${enumsUrl}`, getTasksEnums, {
    ...options,
  });

  useEffect(() => {
    if (enumsResult) {
      setEnums(enumsResult.data);
    }

    setIsPending(!enumsResult && !error);
  }, [enumsResult, error]);

  return { enums, error, isPending };
};

export default useEnumsList;

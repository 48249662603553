import { questionTypes } from '../Configuration/enums';

export const displayTypes = {
  RADIO: 'RADIO',
  CHECKBOX: 'CHECKBOX',
  FREETEXT: 'FREETEXT',
};

export const questionDisplayTypes = {
  [questionTypes.CLOSED]: displayTypes.RADIO,
  [questionTypes.RATING]: displayTypes.RADIO,
  [questionTypes.CHECKBOX]: displayTypes.CHECKBOX,
  [questionTypes.FREETEXT]: displayTypes.FREETEXT,
};

import { Grid, Typography } from '@mui/material';
import Button from 'Components/Button';
import React, { useEffect } from 'react';
import {
  ContentContainer,
  DestinationContainer,
  DetectedMigrationTypeItem,
  DetectedMigrationTypesContainer,
  ErrorMessage,
  Footer,
  MemberTypeChooseButton,
  NewMigrationFormContainer,
} from './styles';
import { MigrationRequestModuleStore } from '../MigrationRequestModuleStore';
import { DataMigrationType, InformationTypes, ProfileMigrationType } from '../enumerations/enums';
import MemberInformation from './MemberInformation/MemberInformation';
import EligibilityInformation from './EligibilityInformation/EligibilityInformation';
import CodeInformation from './CodeInformation/CodeInformation';
import { useNavigate } from 'react-router-dom';
import urlcat from 'urlcat';
import HiFriendInformation from './HiFriendInformation/HiFriendInformation';
import { MIGRATIONREQUESTS, MIGRATIONREQUESTS_NEW_INFO } from '../../../../initialization/Routes/RoutesConfig';

function NewMigration() {
  const navigate = useNavigate();
  const migrationStore = MigrationRequestModuleStore.Instance().Use();
  const memberStore = migrationStore.Member.Use();
  const MigrationError = migrationStore.Error;
  const formComplete = !!memberStore.Info && !!migrationStore.DestinationType && !!migrationStore.Information.Info;
  const isLoading = migrationStore.IsLoading;
  const destinationType = migrationStore.DestinationType;
  const detectedRule = migrationStore.DetectedRule;
  const memberInformation = memberStore.Info;
  const enums = migrationStore.MigrationEnums;

  const profileMigrationType = enums?.ProfileMigrationType?.find(
    (x) => x.code == ProfileMigrationType[migrationStore.ProfileMigrationType],
  )?.displayName;

  const dataMigrationType = enums?.DataMigrationType?.find(
    (x) => x.code == DataMigrationType[migrationStore.DataMigrationType],
  )?.displayName;

  const onSubmit = () =>
    migrationStore.SubmitMigrationRequest().then((success) => {
      if (success) {
        navigate(MIGRATIONREQUESTS);
      }
    });

  const onCancel = () => {
    migrationStore.ClearMigrationRequest();
    navigate(MIGRATIONREQUESTS);
  };

  useEffect(() => {
    if (formComplete && !migrationStore.DataMigrationType && !migrationStore.ProfileMigrationType) {
      migrationStore.DetectMigrationTypes();
    }
  }, [formComplete]);

  return (
    <>
      <NewMigrationFormContainer>
        <Typography component="h1" fontSize={24} fontWeight={700}>
          New Migration
        </Typography>
        <ContentContainer container>
          <Grid item xs={4}>
            <Typography textAlign={'right'} paddingRight={'10px'}>
              Choose Member:
            </Typography>
          </Grid>
          {!!memberInformation ? (
            <MemberInformation />
          ) : (
            <DestinationContainer item xs={7}>
              <MemberTypeChooseButton
                variant={Button.variant.OUTLINED}
                onClick={() =>
                  navigate(urlcat(MIGRATIONREQUESTS_NEW_INFO, { informationSearchType: InformationTypes.MEMBER }))
                }
              >
                Find Member
              </MemberTypeChooseButton>
            </DestinationContainer>
          )}
          <Grid item xs={4}>
            <Typography textAlign={'right'} paddingRight={'10px'}>
              Choose Destination:
            </Typography>
          </Grid>
          {destinationType !== null ? (
            <>
              <CodeInformation />
              <EligibilityInformation />
              <HiFriendInformation />
            </>
          ) : (
            <DestinationContainer item xs={7}>
              <MemberTypeChooseButton
                variant={Button.variant.OUTLINED}
                onClick={() =>
                  navigate(urlcat(MIGRATIONREQUESTS_NEW_INFO, { informationSearchType: InformationTypes.CODE }))
                }
              >
                Code
              </MemberTypeChooseButton>
              <Typography>or</Typography>
              <MemberTypeChooseButton
                variant={Button.variant.OUTLINED}
                onClick={() =>
                  navigate(urlcat(MIGRATIONREQUESTS_NEW_INFO, { informationSearchType: InformationTypes.ELIGIBILITY }))
                }
              >
                Eligibility File
              </MemberTypeChooseButton>
              <Typography>or</Typography>
              <MemberTypeChooseButton
                variant={Button.variant.OUTLINED}
                onClick={() =>
                  navigate(urlcat(MIGRATIONREQUESTS_NEW_INFO, { informationSearchType: InformationTypes.HIFRIEND }))
                }
              >
                HiFriend
              </MemberTypeChooseButton>
            </DestinationContainer>
          )}
          <Grid item xs={10}>
            <Typography>Verify Migration Type:</Typography>
            <DetectedMigrationTypesContainer>
              <DetectedMigrationTypeItem item xs={4}>
                Detected Rule:
              </DetectedMigrationTypeItem>
              <Grid item xs={6}>
                {migrationStore.DetectedRule}
              </Grid>
              <DetectedMigrationTypeItem item xs={4}>
                Profile:
              </DetectedMigrationTypeItem>
              <Grid item xs={6}>
                {profileMigrationType}
              </Grid>
              <DetectedMigrationTypeItem item xs={4}>
                Data:
              </DetectedMigrationTypeItem>
              <Grid item xs={6}>
                {dataMigrationType}
              </Grid>
            </DetectedMigrationTypesContainer>
          </Grid>
        </ContentContainer>
        <Footer>
          <Button onClick={onCancel} variant={Button.variant.OUTLINED}>
            Cancel
          </Button>
          <Button disabled={!formComplete || !detectedRule} onClick={onSubmit} loading={isLoading}>
            Save
          </Button>
        </Footer>
        <ErrorMessage>{MigrationError}</ErrorMessage>
      </NewMigrationFormContainer>
    </>
  );
}

export default NewMigration;

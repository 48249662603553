import React, { useEffect, useState } from 'react';
import withAppInsights from 'Helpers/appInsights';
import { MigrationRequestModuleStore } from './MigrationRequestModuleStore';
import { useOidcUser } from '@axa-fr/react-oidc';
import urlcat from 'urlcat';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { InformationTypes } from './enumerations/enums';
import { Typography } from '@mui/material';
import NewMigration from './NewMigration/NewMigration';
import MigrationList from './MigrationList/MigrationList';
import MemberSearch from './InformationImplementations/MemberSearch/MemberSearch';
import CodeSearch from './InformationImplementations/CodeSearch/CodeSearch';
import EligibilitySearch from './InformationImplementations/EligibilitySearch/EligibilitySearch';
import HiFriend from './InformationImplementations/HiFriend/HiFriend';
import { HOME } from '../../../initialization/Routes/RoutesConfig';

function MemberMigration() {
  const migrationStore = MigrationRequestModuleStore.Instance().Use();
  const { oidcUser } = useOidcUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const hasPermissions = migrationStore.HasPermissions;

  useEffect(() => {
    if (oidcUser && loading) {
      migrationStore.initialize({ userId: oidcUser?.sub }).then(() => setLoading(false));
    }
  }, [oidcUser]);

  useEffect(() => {
    if (hasPermissions === false) {
      navigate(HOME);
    }
  }, [hasPermissions]);

  if (loading || !hasPermissions) {
    return (
      <Typography fontSize={18} color="red">
        Loading main...
      </Typography>
    );
  }

  return (
    <Routes>
      <Route path={'new'} element={<NewMigration />} />
      <Route
        path={urlcat('new/:informationSearchType', { informationSearchType: InformationTypes.MEMBER })}
        element={<MemberSearch />}
      />
      <Route
        path={urlcat('new/:informationSearchType', { informationSearchType: InformationTypes.ELIGIBILITY })}
        element={<EligibilitySearch />}
      />
      <Route
        exact
        path={urlcat('new/:informationSearchType', { informationSearchType: InformationTypes.CODE })}
        element={<CodeSearch />}
      />
      <Route
        path={urlcat('new/:informationSearchType', { informationSearchType: InformationTypes.HIFRIEND })}
        element={<HiFriend />}
      />
      <Route index element={<MigrationList />} />
    </Routes>
  );
}

export default withAppInsights(MemberMigration);

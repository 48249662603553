import React, { useState, useEffect } from 'react';
import useAxiosFetch from 'Tasking_Hooks/useAxiosFetch';
import {
  Customers,
  CustomerData,
  CustomerDataHeader,
  Pagination,
  TableHeader,
  CustomerTableSections,
  NoDataMessage,
} from './styles';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { formatApiDate } from 'Util/dateFunctions';
import urlcat from 'urlcat';
import { ENROLLED, NONENROLLED } from '../../../initialization/Routes/RoutesConfig';

const HeaderTransform = {
  Organization: 'Organization',
  DOB: 'DOB',
  FirstName: 'First Name',
  LastName: 'Last Name',
  Phone: 'Phone',
};

const SearchResults = ({ searchResultsData, ngmSearchResultsData }) => {
  const [notEnrolledcurrentPage, setNotEnrolledCurrentPage] = useState(1);
  const [notEnrolledlistRange, setNotEnrolledListRange] = useState([0, 10]);
  const [enrolledCurrentPage, setEnrolledCurrentPage] = useState(1);
  const [enrolledlistRange, setEnrolledListRange] = useState([0, 10]);
  const navigate = useNavigate();
  const { data } = useAxiosFetch('customer.mock.json');

  useEffect(() => {
    setEnrolledCurrentPage(1);
    setNotEnrolledCurrentPage(1);
    setEnrolledListRange([0, 10]);
    setNotEnrolledListRange([0, 10]);
  }, [searchResultsData, ngmSearchResultsData]);

  const orgNameReturn = (orgName) => {
    if (orgName === 'Banner HealthCare Group') {
      return 'Banner Healthcare';
    } else {
      return orgName;
    }
  };

  const addDashes = (num, ngm) => {
    if (!num) return '';

    if (ngm) {
      if (num?.length < 12) {
        return num?.slice(0, 3) + '-' + num?.slice(3, 6) + '-' + num?.slice(6);
      } else if (num?.length < 13) {
        return num?.slice(2, 5) + '-' + num?.slice(5, 8) + '-' + num?.slice(8);
      } else {
        return num?.slice(3, 6) + '-' + num?.slice(6, 9) + '-' + num?.slice(9);
      }
    }
    return num?.slice(1, 4) + '-' + num?.slice(4, 7) + '-' + num?.slice(7);
  };

  const navigateToCustomerPage = (userId) => {
    navigate(urlcat(ENROLLED, { userId: userId }));
  };

  const navigateToNgmPage = (organizationId, networkGroupMemberId) => {
    navigate(urlcat(NONENROLLED, { orgId: organizationId, ngmId: networkGroupMemberId }));
  };

  const renderTableData = (type) => {
    let userList;
    if (type == 'enrolled') {
      userList = searchResultsData.slice(enrolledlistRange[0], enrolledlistRange[1]);
      return userList.map((searchResultsData, i) => {
        const { userId, organizationName, birthDay, firstName, lastName, phoneNumber } = searchResultsData;
        return (
          <CustomerTableSections
            onClick={() => navigateToCustomerPage(userId)}
            data-testid={`TableSections${i}`}
            id="TableSections"
            key={i}
          >
            <CustomerData org num={i}>
              {orgNameReturn(organizationName)}
            </CustomerData>
            <CustomerData num={i}>{formatApiDate(birthDay?.slice(0, 10))}</CustomerData>
            <CustomerData num={i}>{firstName}</CustomerData>
            <CustomerData num={i}>{lastName}</CustomerData>
            <CustomerData data-testid="EnrolledPhone" num={i}>
              {addDashes(phoneNumber?.slice(1, 13))}
            </CustomerData>
          </CustomerTableSections>
        );
      });
    }

    if (type == 'notEnrolled') {
      userList = ngmSearchResultsData?.slice(notEnrolledlistRange[0], notEnrolledlistRange[1]);
      return userList?.map((ngmSearchResultsData, i) => {
        const {
          // eslint-disable-next-line no-unused-vars
          userId,
          organizationId,
          networkGroupMemberId,
          organizationName,
          birthDay,
          firstName,
          lastName,
          phoneNumber,
        } = ngmSearchResultsData;

        return (
          <CustomerTableSections
            onClick={() => navigateToNgmPage(organizationId, networkGroupMemberId)}
            data-testid={`NgmTableSections${i}`}
            id="NgmTableSections"
            key={i}
          >
            <CustomerData org num={i}>
              {orgNameReturn(organizationName)}
            </CustomerData>
            <CustomerData num={i}>{formatApiDate(birthDay?.slice(0, 10))}</CustomerData>
            <CustomerData num={i}>{firstName}</CustomerData>
            <CustomerData num={i}>{lastName}</CustomerData>

            <CustomerData data-testid="NotEnrolledPhone" num={i}>
              {phoneNumber ? addDashes(phoneNumber.slice(0, 13), true) : ''}
            </CustomerData>
          </CustomerTableSections>
        );
      });
    }
  };

  const renderTableHeader = () => {
    const header = Object.keys(data.notEnrolled.customers[0]);
    return header.map((key, index) => {
      return (
        <CustomerDataHeader
          lastName={key === 'LastName'}
          firstName={key === 'FirstName'}
          org={key === 'Organization'}
          dob={key === 'DOB'}
          phone={key === 'Phone'}
          key={index}
        >
          {HeaderTransform[key]}
        </CustomerDataHeader>
      );
    });
  };

  const backwardPageButton = (tableType) => {
    if (tableType === 'enrolled') {
      if (enrolledCurrentPage !== 1) {
        setEnrolledListRange([enrolledlistRange[0] - 10, enrolledlistRange[1] - 10]);
        setEnrolledCurrentPage(enrolledCurrentPage - 1);
      }
    } else {
      if (notEnrolledcurrentPage !== 1) {
        setNotEnrolledListRange([notEnrolledlistRange[0] - 10, notEnrolledlistRange[1] - 10]);
        setNotEnrolledCurrentPage(notEnrolledcurrentPage - 1);
      }
    }
  };

  const forwardPageButton = (tableType) => {
    if (tableType === 'enrolled') {
      if (enrolledCurrentPage !== Math.ceil(searchResultsData.length / 10)) {
        setEnrolledListRange([enrolledlistRange[0] + 10, enrolledlistRange[1] + 10]);
        setEnrolledCurrentPage(enrolledCurrentPage + 1);
      }
    } else {
      if (notEnrolledcurrentPage !== Math.ceil(ngmSearchResultsData.length / 10)) {
        setNotEnrolledListRange([notEnrolledlistRange[0] + 10, notEnrolledlistRange[1] + 10]);
        setNotEnrolledCurrentPage(notEnrolledcurrentPage + 1);
      }
    }
  };

  const renderTableFooter = (tableType) => {
    const header = Object.keys(data.notEnrolled.customers[0]);
    return header.map((key, index) => {
      return (
        <Pagination key={index} index={index}>
          {index === 4 ? (
            <div style={{ fontSize: '16px', width: '120px', paddingLeft: '310px' }}>
              <span
                data-testid="backwardPageButton"
                style={{ cursor: 'pointer' }}
                onClick={() => backwardPageButton(tableType)}
              >
                {'<  '}
              </span>
              <span>
                {`Page ${tableType === 'enrolled' ? enrolledCurrentPage : notEnrolledcurrentPage} of ${
                  tableType === 'enrolled'
                    ? Math.ceil(searchResultsData.length / 10) || 1
                    : Math.ceil(ngmSearchResultsData.length / 10)
                }`}
              </span>
              <span
                data-testid="forwardPageButton"
                style={{ cursor: 'pointer' }}
                onClick={() => forwardPageButton(tableType)}
              >
                {'  >'}
              </span>
            </div>
          ) : (
            ''
          )}
        </Pagination>
      );
    });
  };

  const checkIfTableShouldRender = () => {
    if (data) {
      return (
        <>
          <TableHeader>Enrolled Members</TableHeader>
          {searchResultsData.length === 0 ? (
            <NoDataMessage> No Members fit the search criteria</NoDataMessage>
          ) : (
            <Customers>
              <tbody>
                {<tr id="TableHeader">{renderTableHeader()}</tr>}
                {renderTableData('enrolled')}
                {<tr id="TableFooter">{renderTableFooter('enrolled')}</tr>}
              </tbody>
            </Customers>
          )}

          <TableHeader>Not Enrolled Members</TableHeader>
          {ngmSearchResultsData.length === 0 ? (
            <NoDataMessage> No Members fit the search criteria</NoDataMessage>
          ) : (
            <Customers>
              <tbody>
                {<tr id="TableHeader">{renderTableHeader()}</tr>}
                {renderTableData('notEnrolled')}
                {<tr id="TableFooter">{renderTableFooter('notEnrolled')}</tr>}
              </tbody>
            </Customers>
          )}
        </>
      );
    }
  };

  return (
    <div style={{ paddingBottom: '40px' }} id="SearchResults">
      {checkIfTableShouldRender()}
    </div>
  );
};

SearchResults.propTypes = {
  searchResultsData: PropTypes.array,
  ngmSearchResultsData: PropTypes.array,
};

export default SearchResults;

import { AnswerTypes } from '../../enums';
import Checkbox from './Checkbox/Checkbox';
import Radio from './Radio/Radio';
import Textbox from './Textbox/Textbox';

export const answerMap = {
  [AnswerTypes.RADIO]: Radio,
  [AnswerTypes.CHECKBOX]: Checkbox,
  [AnswerTypes.TEXT]: Textbox,
};

import React from 'react';
import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  BackButton,
  ButtonContainer,
  ErrorMessage,
  Footer,
  HeaderContainer,
  InputFieldContainer,
  SearchFormContainer,
  SearchHeaderContainer,
  StyledButton,
} from './styles';
import TextInput from 'Administrator_Pages/MigrationRequests/components/TextInput/TextInput';
import SelectInput from 'Administrator_Pages/MigrationRequests/components/SelectInput/SelectInput';
import ResultsGrid from 'Components/ResultsGrid/ResultsGrid';
import useGetOrganizations from 'Administrator_Hooks/useGetOrganizations';
import { MigrationRequestModuleStore } from 'Administrator_Pages/MigrationRequests/MigrationRequestModuleStore';
import Button from 'Components/Button';
import { fields, headers } from './config';
import TooltipDataGridItem from './TooltipDataGridItem/TooltipDataGridItem';
import { useNavigate } from 'react-router-dom';
import withInformation from '../withInformation';
import { MIGRATIONREQUESTS_NEW } from '../../../../../initialization/Routes/RoutesConfig';
import { formatApiDate } from 'Util/dateFunctions';

const EligibilitySearch = () => {
  const { handleSubmit, reset, control } = useForm({});
  const migrationStore = MigrationRequestModuleStore.Instance().Use();
  const infoStore = migrationStore.Information.Use();
  const navigate = useNavigate();
  const { organizations } = useGetOrganizations();
  const mappedOrganizations = organizations
    ?.map((x) => ({ name: x.id, text: x.name }))
    .concat({ name: '', text: 'All Organizations' });
  const isLoading = migrationStore.IsLoading || infoStore.IsLoading;
  const error = infoStore.Error;
  const searchResults = infoStore.SearchResults?.map((x) => ({ ...x, birthDay: formatApiDate(x.birthDay) }));

  const onDataClick = (item) =>
    infoStore.addInfo(item).then((response) => {
      if (response) {
        navigate(MIGRATIONREQUESTS_NEW);
      }
    });

  const onBack = () => {
    navigate(MIGRATIONREQUESTS_NEW);
    infoStore.cancel();
  };

  const onSubmit = (data) => {
    infoStore.search({
      birthDay: data?.dob,
      phoneNumber: data?.phone,
      firstName: data?.firstname,
      lastName: data?.lastname,
      organizationId: data?.organization,
    });
  };

  return (
    <>
      <SearchHeaderContainer>
        <BackButton variant={Button.variant.TEXT} onClick={onBack}>
          {'< Back'}
        </BackButton>
      </SearchHeaderContainer>
      <SearchFormContainer>
        <Typography component="h1" fontSize={24} fontWeight={700}>
          Choose Eligibility Record
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <HeaderContainer container>
            <InputFieldContainer item xs={3}>
              <SelectInput
                label="Organization:"
                name="organization"
                control={control}
                placeholder="- Select One -"
                options={mappedOrganizations}
              />
            </InputFieldContainer>
            {fields?.map((field, index) => {
              return (
                <InputFieldContainer item xs={3} key={index}>
                  <TextInput {...field} control={control} />
                </InputFieldContainer>
              );
            })}
            <ButtonContainer item xs={9}>
              <StyledButton variant={Button.variant.OUTLINED} onClick={() => reset()}>
                Clear
              </StyledButton>
              <StyledButton type="submit" loading={isLoading}>
                Search
              </StyledButton>
            </ButtonContainer>
            <ErrorMessage>{error}</ErrorMessage>
          </HeaderContainer>
        </form>
        <ResultsGrid
          fetchNumber={10}
          loading={isLoading}
          headerMap={headers}
          data={searchResults}
          onDataClick={onDataClick}
          dataComponent={TooltipDataGridItem}
        />
        <Footer />
      </SearchFormContainer>
    </>
  );
};

export default withInformation(EligibilitySearch);

import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

const memberInfoUrl = '/v5/members';
const options = {
  revalidateOnFocus: false,
};

const getMemberInfo = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useMemberInfo = (initUserId) => {
  const [isPending, setIsPending] = useState(true);
  const [memberInfo, setMemberInfo] = useState();
  const [userId, setUserId] = useState(initUserId);
  const {
    data: memberInfoResult,
    error,
    mutate,
  } = useSWR(() => (userId ? `${process.env.API_BASE_URL}${memberInfoUrl}/${userId}` : ''), getMemberInfo, {
    ...options,
  });

  useEffect(() => {
    if (memberInfoResult) {
      setMemberInfo(memberInfoResult.data);
    }

    setIsPending(!memberInfoResult && !error);
  }, [memberInfoResult, error]);

  const retrieveMemberInfo = (newUserId) => {
    if (!newUserId) return;
    setUserId(newUserId);
    mutate(userId);
  };

  return { memberInfo, error, isPending, retrieveMemberInfo };
};

export default useMemberInfo;

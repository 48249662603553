import React from 'react';
import OtpCodeButton from '../MemberOtpCodeButton/MemberOtpCodeButton';
import PropTypes from 'prop-types';
import { ENROLLED } from '../../../initialization/Routes/RoutesConfig';
import { MemberDataContainer, DataColumn, TextInfo, TextLink, OtpCodeButtonContainer } from './styles';
import { formatDate } from 'Tasking_Helpers/formatDate';
import { useNavigate } from 'react-router-dom';
import useGetEnrolledNgm from 'Tasking_Hooks/useGetEnrolledNgm';
import { OtpTypes } from 'Tasking_Helpers/otpTypes';
import { phoneNumberTypes } from 'Tasking_Components/MemberInfoData/enums';
import { calculateAge } from 'Util/dateFunctions';
import urlcat from 'urlcat';

const MemberInfo = ({ memberInfo, ngm }) => {
  const navigate = useNavigate();
  const { enrolledMemberData } = ngm ? useGetEnrolledNgm(memberInfo?.organizationId, memberInfo?.id, true) : '';
  const isMemberPendingRegistration = memberInfo?.userId && !memberInfo.activeProfileId;
  const otpType = memberInfo?.landlinePhoneNumber ? OtpTypes.EMAIL : OtpTypes.PHONE;
  const isUnderAge = calculateAge(memberInfo?.birthDateUtc) < 13;

  const getPhoneNumber = () => {
    if (ngm) {
      if (memberInfo?.phoneNumberPreference === phoneNumberTypes.HEALTHPLANPROVIDEDPHONENUMBER) {
        return memberInfo?.normalizedPhone;
      } else {
        return memberInfo?.preferredPhone ?? memberInfo?.normalizedPhone;
      }
    } else {
      return memberInfo?.preferredPhoneNumber ?? memberInfo?.phoneNumber;
    }
  };

  return (
    <MemberDataContainer
      deceased={memberInfo?.deceased}
      deleted={memberInfo?.deleted}
      declined={memberInfo?.declined}
      doNotCall={memberInfo?.doNotCall}
      notEnrolled={memberInfo?.notEnrolled}
      enrolled={memberInfo?.enrolledOn}
      underage={isUnderAge}
      data-testid="MemberInfo"
      ngm={ngm}
    >
      <DataColumn colOne>
        <TextInfo nameCol isName>
          {memberInfo?.firstName || 'Loading'} {memberInfo?.lastName}
        </TextInfo>
        <TextInfo data-testid="EnrolledData" nameCol>
          {memberInfo?.deceased & memberInfo?.declined
            ? 'DECEASED, DECLINED'
            : memberInfo?.deceased
            ? 'DECEASED'
            : memberInfo?.deleted
            ? 'DELETED'
            : memberInfo?.declined && memberInfo?.doNotCall
            ? 'DECLINED, DO NOT CALL'
            : memberInfo?.doNotCall
            ? 'DO NOT CALL'
            : memberInfo?.declined
            ? 'DECLINED'
            : memberInfo?.isTerminated
            ? `MEMBER TERMINATED FROM NETWORK ON ${formatDate(memberInfo?.terminationUtc)}`
            : isUnderAge
            ? 'UNDER AGE'
            : !memberInfo?.userId
            ? 'NOT ENROLLED'
            : memberInfo?.isNonAppUser
            ? 'ENROLLED NON-APP'
            : 'ENROLLED'}
        </TextInfo>
        {isMemberPendingRegistration && (
          <OtpCodeButtonContainer>
            <OtpCodeButton userId={memberInfo?.userId} otpType={otpType} />
          </OtpCodeButtonContainer>
        )}
      </DataColumn>
      <DataColumn colTwo>
        <TextInfo> TEL: {getPhoneNumber()?.slice(-10)} </TextInfo>
        <TextInfo> DOB: {ngm ? formatDate(memberInfo?.birthDateUtc) : formatDate(memberInfo?.birthDay)} </TextInfo>
        {ngm || isMemberPendingRegistration ? '' : <TextInfo> USERNAME: {memberInfo?.username}</TextInfo>}
      </DataColumn>
      <DataColumn colThree>
        <TextInfo> ORGANIZATION: {memberInfo?.organizationName} </TextInfo>
        <TextInfo> NETWORK: {memberInfo?.networkName} </TextInfo>
        <TextInfo> GROUP: {memberInfo?.groupName} </TextInfo>
      </DataColumn>
      <DataColumn colFour>
        <TextInfo> HPID: {ngm ? memberInfo?.memberId : memberInfo?.healthPlanId} </TextInfo>
        <TextInfo> HEALTH HOME: {memberInfo?.healthHomeFacility}</TextInfo>

        {enrolledMemberData?.userId ? (
          <TextLink
            data-testid="EnrolledLink"
            onClick={() => {
              navigate(urlcat(ENROLLED, { userId: enrolledMemberData?.userId }));
            }}
          >
            GO TO ENROLLED PROFILE
          </TextLink>
        ) : (
          ''
        )}
      </DataColumn>
    </MemberDataContainer>
  );
};

MemberInfo.propTypes = {
  memberInfo: PropTypes.object,
  ngm: PropTypes.bool,
};

export default MemberInfo;

import React from 'react';
import { Grid } from '@mui/material';
import { MigrationRequestModuleStore } from 'Administrator_Pages/MigrationRequests/MigrationRequestModuleStore';
import { InfoContainer, StyledPaper } from './styles';
import Button from 'Components/Button';
import { formatApiDate } from 'Util/dateFunctions';

const MemberInformation = () => {
  const migrationStore = MigrationRequestModuleStore.Instance().Use();
  const memberStore = migrationStore.Member.Use();
  const data = memberStore.Info;

  const onClear = () => {
    memberStore.clear();
    migrationStore.ClearRules();
  };

  return (
    <Grid container item xs={7}>
      <StyledPaper elevation={2}>
        <InfoContainer container>
          <Grid item xs={6}>
            Name: {data?.firstName} {data?.lastName}
          </Grid>
          <Grid item xs={6}>
            Organization: {data?.organizationName}
          </Grid>
          <Grid item xs={6}>
            DOB: {formatApiDate(data?.birthDay)}
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Button onClick={onClear} variant={Button.variant.OUTLINED}>
              Clear
            </Button>
          </Grid>
        </InfoContainer>
      </StyledPaper>
    </Grid>
  );
};

export default MemberInformation;

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  SearchBoxContainer,
  SearchFormContainer,
  SearchRowOne,
  SearchRowTwo,
  SearchRowThree,
  InputStyle,
  SelectStyle,
  FormButton,
  Label,
  PageTitle,
  Divder,
  SpinnerButton,
  CustomError,
} from './styles';
import { useForm } from 'react-hook-form';
import useMemberSearch from 'Tasking_Hooks/useMemberSearch';
import useNgmMemberInfo from 'Tasking_Hooks/useNgmSearch';
import { useNavigate } from 'react-router-dom';

import Spinner from 'Components/Spinner/Spinner';
import useGetOrganizations from 'Tasking_Hooks/useGetOrganizations';
import { birthDayReformat } from 'Util/dateFunctions';

const SearchBox = ({
  showSearchResults,
  setSearchResultsData,
  setNgmSearchResultsData,
  valuesForSearch,
  showResults,
}) => {
  const { register, handleSubmit } = useForm({ defaultValues: valuesForSearch });
  const { membersList, isPending, membersSearch } = useMemberSearch();
  const { ngmList, ngmIsPending, ngmSearch } = useNgmMemberInfo();
  const { organizations } = useGetOrganizations();
  const [customError, setCustomError] = useState();
  const navigate = useNavigate();
  const inputEl = useRef(null);

  useEffect(() => {
    if (!showResults && Object.keys(valuesForSearch).length !== 0 && organizations) {
      setTimeout(function () {
        inputEl.current?.click();
      }, 1000);
    }
  }, [showResults, valuesForSearch, organizations]);

  const hasRequiredFieldsEntered = (fields) => {
    const enteredFields = Object.values(fields).filter((item) => item !== '');
    return enteredFields.length > 1;
  };

  const onSubmit = (data) => {
    let currentUrlParams = new URLSearchParams(window.location.search);

    // needed to clear out previous search data in url query
    for (const property in data) {
      currentUrlParams.delete(property);
    }

    for (const property in data) {
      currentUrlParams.set(property, data[property]);
    }

    const cleanData = {};
    for (const property in data) {
      if (data[property] && data[property].length < 3) {
        setCustomError('Please include at least 3 character in your chosen search fields');
        return null;
      } else if (property === 'organizationId' && !hasRequiredFieldsEntered(data)) {
        setCustomError('Search must include at least one criterion (a selected organization does not count)');
        return null;
      } else if (property === 'organizationId' && data[property] === 0) {
        setCustomError('Please select an organization');
        return null;
      } else {
        if (data[property]) {
          data[property] = typeof data[property] === 'string' ? data[property].trim() : data[property];
          if (data[property][data[property].length - 1] === '.') {
            data[property] = data[property].slice(0, -1);
          }
          if (property === 'BirthDay') {
            let birthDayResult = birthDayReformat(data[property]);
            if (!Date.parse(birthDayResult)) {
              setCustomError('Invalid Date Format');
              return null;
            }
            birthDayResult = new Date(birthDayResult);
            birthDayResult.setUTCHours(0, 0, 0, 0);
            data[property] = birthDayResult;
          }
          cleanData[property] = data[property];
        }
      }
    }

    navigate(window.location.pathname + '?' + currentUrlParams.toString());

    setCustomError();
    membersSearch(cleanData);
    ngmSearch(filterNgmData(cleanData));
  };

  useEffect(() => {
    if (membersList || ngmList) {
      setNgmSearchResultsData(ngmList || []);
      setSearchResultsData(membersList || []);
      showSearchResults(true);
    }
  }, [isPending, ngmIsPending]);

  const returnOrganization = (data) => {
    let orgName = '';
    if (valuesForSearch && valuesForSearch.organizationId) {
      if (organizations) {
        orgName = organizations.filter((data) => {
          return data.id === parseInt(valuesForSearch.organizationId);
        });
      }
    }

    if (orgName[0] && orgName[0].name === data.name) {
      return orgName[0].name;
    }
  };

  //Clears the url, search results, and search form
  const clearFields = () => {
    navigate(window.location.pathname);
    window.location.reload();
  };

  const filterNgmData = (cleanObj) => {
    const filteredKeys = Object.keys(cleanObj).filter((key) => {
      return key !== 'organizationId' && key !== 'UserName';
    });

    if (filteredKeys < 1) {
      return null;
    }

    return Object.keys(cleanObj).reduce((accumulator, key) => {
      if (key !== 'UserName') {
        accumulator[key] = cleanObj[key];
      }
      return accumulator;
    }, {});
  };

  const returnProfileForm = () => {
    return (
      <SearchFormContainer id="SearchFormContainer">
        <PageTitle>Member Search</PageTitle>
        <form data-testid="SearchForm" onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '-10px' }}>
          <SearchRowOne>
            <div>
              <Label htmlFor="UserName">User Name:</Label>
              <br />
              <InputStyle
                {...register('UserName')}
                defaultValue={valuesForSearch.UserName ? valuesForSearch.UserName : ''}
                type="text"
                id="UserName"
                data-testid="UserNameInput"
              />
            </div>

            <div>
              <Label htmlFor="organizationId">Organization:</Label>
              <br />
              <SelectStyle
                {...register('organizationId', {
                  setValueAs: (value) => (value === '' ? undefined : parseInt(value, 10)),
                })}
                type="text"
                id="organizationId"
                data-testid="OrganizationInput"
              >
                <option value={0}>{`- Select One -`}</option>
                {organizations ? (
                  organizations.map((data) => {
                    return (
                      <option
                        selected={returnOrganization(data)}
                        key={data.id}
                        value={`${data.id}`}
                      >{`${data.name}`}</option>
                    );
                  })
                ) : (
                  <option data-testid="OrganizationInputOption" value={`Loading`}>{`Loading`}</option>
                )}
                <option selected={valuesForSearch.organizationId === 'undefined'} value="">
                  {`All Organizations`}
                </option>
              </SelectStyle>
            </div>

            <div>
              <Label htmlFor="BirthDay">DOB:</Label>
              <br />
              <InputStyle
                {...register('BirthDay')}
                DOB
                defaultValue={valuesForSearch.BirthDay ? valuesForSearch.BirthDay : ''}
                type="text"
                id="BirthDay"
                data-testid="BirthDayInput"
                placeholder={'MM/DD/YYYY'}
              />
            </div>

            <div>
              <Label htmlFor="PhoneNumber">Phone:</Label>
              <br />
              <InputStyle
                {...register('PhoneNumber')}
                defaultValue={valuesForSearch.PhoneNumber ? valuesForSearch.PhoneNumber : ''}
                type="text"
                id="PhoneNumber"
                data-testid="PhoneInput"
              />
            </div>
          </SearchRowOne>

          <SearchRowTwo>
            <div>
              <Label htmlFor="FirstName">First Name:</Label>
              <br />
              <InputStyle
                {...register('FirstName')}
                defaultValue={valuesForSearch.FirstName ? valuesForSearch.FirstName : ''}
                type="text"
                id="FirstName"
                data-testid="FirstNameInput"
              />
            </div>

            <div>
              <Label htmlFor="FirstName">Last Name:</Label>
              <br />
              <InputStyle
                {...register('LastName')}
                defaultValue={valuesForSearch.LastName ? valuesForSearch.LastName : ''}
                type="text"
                id="LastName"
                data-testid="LastNameInput"
              />
            </div>
          </SearchRowTwo>

          <SearchRowThree error={customError}>
            {customError ? <CustomError data-testid="CustomError">{customError}</CustomError> : null}

            {isPending || ngmIsPending ? (
              <SpinnerButton>
                <Spinner />
              </SpinnerButton>
            ) : (
              <FormButton ref={inputEl} data-testid="SubmitButton" value={'Submit'} type="submit" />
            )}
            <FormButton onClick={() => clearFields()} clear value="Clear" type="reset" />
          </SearchRowThree>
        </form>
        <Divder />
      </SearchFormContainer>
    );
  };

  return <SearchBoxContainer id="SearchBoxContainer">{returnProfileForm()}</SearchBoxContainer>;
};

SearchBox.propTypes = {
  showSearchResults: PropTypes.func,
  setSearchResultsData: PropTypes.func,
  setNgmSearchResultsData: PropTypes.func,
  showResults: PropTypes.bool,
  valuesForSearch: PropTypes.object,
};

export default SearchBox;

import { Radio, RadioGroup } from '@mui/material';
import React, { forwardRef } from 'react';
import { StyledFormLabel } from './styles';
import PropTypes from 'prop-types';

const MemberConnectionsRadioGroup = forwardRef(
  ({ options, value, row, icon, checkedIcon, disabled, ...props }, ref) => {
    return (
      <RadioGroup {...props} row={row} ref={ref}>
        {options.map((option, index) => (
          <StyledFormLabel
            key={index}
            checked={String(value) === String(option.value)}
            value={option.value}
            label={option.label}
            disabled={disabled}
            control={<Radio icon={icon} checkedIcon={checkedIcon} />}
          />
        ))}
      </RadioGroup>
    );
  },
);

MemberConnectionsRadioGroup.displayName = 'MemberConnectionsRadioGroup';

MemberConnectionsRadioGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.any,
  row: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  checkedIcon: PropTypes.node,
};

export default MemberConnectionsRadioGroup;

export const fieldTypes = {
  RADIO: 'RADIO',
  FREETEXT: 'FREETEXT',
  CHECKBOX: 'CHECKBOX',
  DATE: 'DATE',
};

export const statusTypes = {
  PENDING: 'PENDINGAPPROVAL',
  SENT: 'SENTTOPLANPARTNER',
};

export const questionSections = [
  {
    title: 'Member Information',
    gridSize: 12,
    formFields: [
      {
        title: 'Full Name:',
        fieldName: 'memberName',
        gridSize: 3,
        fieldType: fieldTypes.FREETEXT,
        maxLength: 256,
        editDisabled: true,
      },
      {
        title: 'Phone Number:',
        fieldName: 'memberPhoneNumber',
        gridSize: 3,
        fieldType: fieldTypes.FREETEXT,
      },
      {
        title: 'Network Name:',
        fieldName: 'networkName',
        gridSize: 3,
        fieldType: fieldTypes.FREETEXT,
        maxLength: 50,
        editDisabled: true,
      },
      {
        title: 'Date of Birth:',
        fieldName: 'memberDOB',
        gridSize: 3,
        fieldType: fieldTypes.DATE,
        editDisabled: true,
      },
      {
        title: 'Healthplan ID:',
        fieldName: 'healthPlanId',
        gridSize: 3,
        fieldType: fieldTypes.FREETEXT,
        maxLength: 450,
        editDisabled: true,
      },
      {
        title: 'Language:',
        fieldName: 'memberLanguage',
        gridSize: 3,
        fieldType: fieldTypes.FREETEXT,
        maxLength: 20,
      },
      {
        title: 'Group Name:',
        fieldName: 'groupName',
        gridSize: 3,
        fieldType: fieldTypes.FREETEXT,
        maxLength: 50,
        editDisabled: true,
      },
    ],
  },
  {
    title: 'Connection Contact Information',
    gridSize: 6,
    formFields: [
      {
        fieldName: 'isUrgent',
        gridSize: 12,
        fieldType: fieldTypes.RADIO,
        defaultValue: false,
        row: true,
        options: [
          { label: 'URGENT MEMBER CONNECTION', value: true },
          { label: 'NON-URGENT MEMBER CONNECTION', value: false },
        ],
      },
      {
        title: 'Primary Member Connection Reason:',
        fieldName: 'primaryReason',
        gridSize: 12,
        fieldType: fieldTypes.FREETEXT,
        maxLength: 300,
      },
      {
        title: 'Secondary Member Connection Reason:',
        fieldName: 'secondaryReason',
        gridSize: 12,
        fieldType: fieldTypes.FREETEXT,
        maxLength: 300,
      },
    ],
  },
  {
    title: 'Connection Destination',
    gridSize: 6,
    formFields: [
      {
        label: 'MEMBER SERVICES',
        fieldName: 'destinationMemberServices',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
      },
      {
        label: 'COMMUNITY-BASED ORG.',
        fieldName: 'destinationCommunityOrg',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
      },
      {
        label: 'CRISIS LINE',
        fieldName: 'destinationCrisisLine',
        gridSize: 12,
        fieldType: fieldTypes.CHECKBOX,
      },
      {
        label: 'NURSE LINE',
        fieldName: 'destinationNurseLine',
        gridSize: 12,
        fieldType: fieldTypes.CHECKBOX,
      },
    ],
  },
  {
    title: 'Medical',
    gridSize: 6,
    formFields: [
      {
        label: 'ACCESS TO CARE',
        fieldName: 'medicalAccessToCare',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
        dependants: ['medicalAccessToPhysician', 'medicalAccessToBehaviorHealth'],
      },
      {
        label: 'PHARMACY',
        fieldName: 'medicalPharmacy',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
        dependants: ['medicalPharmacySideEffects', 'medicalPharmacyRefill', 'medicalPharmacyOther'],
      },
      {
        label: 'BEHAVIORAL HEALTH',
        fieldName: 'medicalAccessToBehaviorHealth',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
        dependencies: { medicalAccessToCare: true },
      },
      {
        label: 'SIDE EFFECTS',
        fieldName: 'medicalPharmacySideEffects',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
        dependencies: { medicalPharmacy: true },
      },
      {
        label: 'MD/PHYSICIAN',
        fieldName: 'medicalAccessToPhysician',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
        dependencies: { medicalAccessToCare: true },
      },
      {
        label: 'REFILL',
        fieldName: 'medicalPharmacyRefill',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
        dependencies: { medicalPharmacy: true },
      },
      {
        label: 'DENTISTRY',
        fieldName: 'medicalDentistry',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
      },
      {
        label: 'OTHERS',
        fieldName: 'medicalPharmacyOther',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
        dependencies: { medicalPharmacy: true },
      },
      {
        label: 'DURABLE MEDICAL EQUIPMENT (DME)',
        fieldName: 'medicalDME',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
      },
      {
        label: 'IN-HOME CARE ASSISTANCE',
        fieldName: 'medicalInHomeCare',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
      },
      {
        label: 'ACTIVITIES FOR DAILY LIVING',
        fieldName: 'medicalActivities',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
      },
    ],
  },
  {
    title: 'Behavioral Health',
    gridSize: 6,
    formFields: [
      {
        label: 'FIND A PROVIDER',
        fieldName: 'behaviorFindProvider',
        gridSize: 12,
        fieldType: fieldTypes.CHECKBOX,
      },
      {
        label: 'WELFARE CHECK',
        fieldName: 'behaviorWelfareCheck',
        gridSize: 12,
        fieldType: fieldTypes.CHECKBOX,
      },
    ],
  },
  {
    title: 'SDOH',
    gridSize: 6,
    formFields: [
      {
        label: 'FOOD',
        fieldName: 'sdohFood',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
      },
      {
        label: 'EMPLOYMENT',
        fieldName: 'sdohEmployment',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
      },
      {
        label: 'HOUSING',
        fieldName: 'sdohHousing',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
        dependants: ['sdohHousingHUD', 'sdohHousingUrgentShelter'],
      },
      {
        label: 'SAFETY',
        fieldName: 'sdohSafety',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
        dependants: ['sdohSafetyEnvironment', 'sdohSafetyPhysical'],
      },
      {
        label: 'HUD',
        fieldName: 'sdohHousingHUD',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
        dependencies: { sdohHousing: true },
      },
      {
        label: 'ENVIRONMENTAL',
        fieldName: 'sdohSafetyEnvironment',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
        dependencies: { sdohSafety: true },
      },
      {
        label: 'URGENT SHELTER',
        fieldName: 'sdohHousingUrgentShelter',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
        dependencies: { sdohHousing: true },
      },
      {
        label: 'PHYSICAL',
        fieldName: 'sdohSafetyPhysical',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
        dependencies: { sdohSafety: true },
      },
      {
        label: 'TRANSPORTATION',
        fieldName: 'sdohTransportation',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
      },
      {
        label: 'UTILITY',
        fieldName: 'sdohUtility',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
      },
      {
        label: 'INCOME',
        fieldName: 'sdohIncome',
        gridSize: 6,
        fieldType: fieldTypes.CHECKBOX,
      },
    ],
  },
  {
    title: 'Benefits',
    gridSize: 6,
    formFields: [
      {
        label: 'COVERED SERVICES',
        fieldName: 'benefitsCoveredServices',
        gridSize: 12,
        fieldType: fieldTypes.CHECKBOX,
      },
      {
        label: 'NEED A CARD',
        fieldName: 'benefitsNeedACard',
        gridSize: 12,
        fieldType: fieldTypes.CHECKBOX,
      },
      {
        label: 'PRIOR AUTHORIZATION',
        fieldName: 'benefitsPriorAuthorization',
        gridSize: 12,
        fieldType: fieldTypes.CHECKBOX,
      },
    ],
  },
  {
    title: 'Details / Additional Information',
    gridSize: 6,
    formFields: [
      {
        title: 'Action Taken:',
        fieldName: 'actionTaken',
        gridSize: 12,
        fieldType: fieldTypes.FREETEXT,
        maxLength: 200,
      },
      {
        title: 'Follow Up:',
        fieldName: 'followUp',
        gridSize: 12,
        fieldType: fieldTypes.FREETEXT,
        maxLength: 200,
      },
      {
        title: 'Additional Notes:',
        fieldName: 'additionalNotes',
        gridSize: 12,
        fieldType: fieldTypes.FREETEXT,
        maxLength: 200,
      },
    ],
  },
  {
    title: 'Transfer To Plan Recommended',
    gridSize: 6,
    formFields: [
      {
        fieldName: 'transferStatus',
        gridSize: 12,
        fieldType: fieldTypes.RADIO,
        row: false,
        defaultValue: 'NO',
        options: [
          { label: 'NO', value: 'NO' },
          { label: 'DECLINED TRANSFER', value: 'DECLINED' },
          { label: 'SUCCESSFUL TRANSFER', value: 'SUCCESSFUL' },
        ],
      },
    ],
  },
];

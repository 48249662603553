export const historyTypeFilterConfig = {
  all: 'All',
  greetingCard: 'Greeting Card',
  notification: 'Notification',
  task: 'Task',
  legacyScreening: 'Legacy Screening',
  screening: 'Screening',
  assistanceProvided: 'Assistance Provided',
  memberConcerns: 'Member Concern',
  memberConnection: 'Member Connection',
  memberMigration: 'Member Migration',
};

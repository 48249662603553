import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';

const useSendPhoneText = () => {
  const [memberTextData, setMemberTextData] = useState();
  const [url, setUrl] = useState();
  const { response, isLoading, hasErrored } = useAxiosSubmit(url, memberTextData);

  const sendPhoneText = async (request) => {
    setUrl(`${process.env.API_BASE_URL}/v1/notification-requests/phone`);
    setMemberTextData(request);
  };

  return { response, error: hasErrored, isPending: isLoading, sendPhoneText };
};

export default useSendPhoneText;

import getAuthClient from 'Tasking_Helpers/getAuthClient';
import urlcat from 'urlcat';
import useSWR from 'swr';
import { useEffect, useState } from 'react';

async function getPendingAssessment(url) {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
}

const usePendingAssessment = (userId) => {
  const [isPending, setIsPending] = useState(true);
  const [result, setResult] = useState();
  const {
    data: response,
    error,
    mutate,
  } = useSWR(
    () => urlcat(process.env.API_BASE_URL, 'v1/thrive/assessments/scores/pending', { userId: userId }),
    getPendingAssessment,
    {
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (response) {
      setResult(response.data);
    }

    setIsPending(!response && !error);
  }, [response, error]);

  const refresh = () => mutate();

  return { result, error, isPending, refresh };
};

export default usePendingAssessment;

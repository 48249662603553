import React, { useState } from 'react';
import { ScreeningModuleStore } from 'Tasking_Components/Screenings/ScreeningModuleStore';
import { useForm } from 'react-hook-form';
import { questions } from '../config';
import { DialogActions, DialogContent, DialogTitle, FormLabel, Typography } from '@mui/material';
import {
  ErrorMessage,
  StyledButton,
  StyledButtonContainer,
  StyledDialog,
  StyledForm,
  StyledRadioGroup,
} from './styles';
import { parseErrorMessage } from 'Helpers/parseApiErrorMessage';
import SkippableFormAnswer from './FormAnswer/SkippableFormAnswer';
import { AnswerTypes } from '../enums';

function AdultPRAPARE() {
  const screeningStore = ScreeningModuleStore.Instance().Use();
  const screeningTakerStore = screeningStore.ScreeningForm.Use();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const { control, handleSubmit } = useForm({ defaultValues: screeningTakerStore.Answers });
  const screeningAnswers = screeningTakerStore.Answers;
  const hasError = screeningStore.Error?.hasError;
  const errorMessage = screeningStore.Error?.error;

  const onAnswer = (question, answer) => screeningTakerStore.updateAnswer(question, answer);

  const onCompleteScreening = async () => {
    onCloseDialog();
    await screeningStore.startScreening().then(() => screeningTakerStore.complete());
  };
  const onFinish = () => setConfirmDialogOpen(true);
  const onCloseDialog = () => setConfirmDialogOpen(false);

  const formComplete = screeningTakerStore.IsValid;
  const isLoading = screeningTakerStore.IsLoading || screeningStore.IsLoading;

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onFinish)}>
        {questions.map(
          ({ text: questionText, name: questionName, answerType, options, ...question }, questionIndex) => (
            <StyledRadioGroup key={questionIndex}>
              <FormLabel id={`radiogroup-label-${questionIndex}`}>{questionText}</FormLabel>
              <SkippableFormAnswer
                control={control}
                answerType={answerType ?? AnswerTypes.RADIO}
                options={options}
                text={questionText}
                value={screeningAnswers[questionName]}
                name={questionName}
                onChange={(e, vals) => onAnswer(questionName, vals ?? e.target?.name)}
                {...question}
              />
            </StyledRadioGroup>
          ),
        )}
        <ErrorMessage>{hasError ? parseErrorMessage(errorMessage) : ''}</ErrorMessage>
      </StyledForm>
      <StyledButtonContainer>
        <StyledButton onClick={onFinish} disabled={!formComplete} loading={isLoading} elevation={0}>
          Complete
        </StyledButton>
        <StyledButton onClick={screeningStore.cancel} loading={isLoading} variant="outlined">
          Cancel
        </StyledButton>
      </StyledButtonContainer>
      <StyledDialog open={confirmDialogOpen} onClose={onCloseDialog}>
        <DialogTitle>Save Screening?</DialogTitle>
        <DialogContent dividers>
          <Typography>
            <strong>Warning!</strong> Once you save this screening, you will not be able to edit it afterwards.
          </Typography>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={onCloseDialog} loading={isLoading} variant="outlined">
            Cancel
          </StyledButton>
          <StyledButton onClick={onCompleteScreening} disabled={!formComplete} loading={isLoading} elevation={0}>
            Save
          </StyledButton>
        </DialogActions>
      </StyledDialog>
    </>
  );
}

export default AdultPRAPARE;

import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';

const useMemberSearch = () => {
  const [membersSearchData, setMembersSearchData] = useState();
  const [url, setUrl] = useState();
  const { response, isLoading, hasErrored } = useAxiosSubmit(url, membersSearchData);

  const membersSearch = async (request) => {
    setUrl(`${process.env.API_BASE_URL}/v5/members/search`);
    setMembersSearchData(request);
  };

  return { membersList: response, error: hasErrored, isPending: isLoading, membersSearch };
};

export default useMemberSearch;

import React, { useEffect } from 'react';
import { StyledInteractionDatesContainer, StyledBoldSpan } from './styles';
import { Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { StyledTableContainer } from 'Tasking_Components/Thrive/ThriveMetrics/styles';
import useThriveAccessHistory from 'Tasking_Hooks/useThriveAccessHistory';
import { useParams } from 'react-router-dom';
import useThriveProgress from 'Tasking_Hooks/useThriveProgress';
import { formatDate } from 'Tasking_Helpers/formatDate';

const displayName = {
  ENROLLED: 'Enrolled',
  GRADUATE: 'Graduate',
  DROPOUT: 'Drop out',
  NONE: 'Null',
  ANDYCALL: 'Andy Call',
  SCREENING: 'Assessment',
  PYXIR: 'Pyxir chat',
  MYTOOLS: 'My Tools',
};

const thriveEnums = {
  thriveStatus: {
    ENROLLED: 'ENROLLED',
    DROPOUT: 'DROPOUT',
    GRADUATE: 'GRADUATE',
    NONE: 'NONE',
  },
  badgeStatus: {
    COMPLETE: 'COMPLETE',
    NOTSTARTED: 'NOTSTARTED',
    MISSED: 'MISSED',
  },
};

const BadgingAndProgress = () => {
  const { userId } = useParams();
  const { history: thriveHistory, getThriveAccessHistory } = useThriveAccessHistory();
  const { progress: thriveProgress, getThriveProgress } = useThriveProgress();

  useEffect(() => {
    updateThriveHistory();
  }, []);

  const updateThriveHistory = () => {
    getThriveAccessHistory(userId);
    getThriveProgress(userId);
  };

  const showHistory = (h) => {
    let rows = [];
    if (h.enrollmentReason) {
      rows.push(
        <TableRow key={h.id + thriveEnums.thriveStatus.ENROLLED}>
          <TableCell>{displayName[thriveEnums.thriveStatus.ENROLLED]}</TableCell>
          <TableCell>{h.enrollmentReason}</TableCell>
          <TableCell>{formatDate(h.startDateUtc)}</TableCell>
        </TableRow>,
      );
    }
    if (h.dropoutReason || h.dropoutDateUtc) {
      rows.push(
        <TableRow key={h.id + thriveEnums.thriveStatus.DROPOUT}>
          <TableCell>{displayName[thriveEnums.thriveStatus.DROPOUT]}</TableCell>
          <TableCell>{h.dropoutReason}</TableCell>
          <TableCell>{formatDate(h.dropoutDateUtc)}</TableCell>
        </TableRow>,
      );
    }
    if (h.graduationDateUtc) {
      rows.push(
        <TableRow key={h.id + thriveEnums.thriveStatus.GRADUATE}>
          <TableCell>{displayName[thriveEnums.thriveStatus.GRADUATE]}</TableCell>
          <TableCell></TableCell>
          <TableCell>{formatDate(h.graduationDateUtc)}</TableCell>
        </TableRow>,
      );
    }

    return rows;
  };

  const showProgressQuickView = (r, i) => {
    const activities = r.badges.map((x) => displayName[x.badgeType]).join(', ');

    const activitiesCompleted = r.badges
      .filter((x) => x.badgeStatus == thriveEnums.badgeStatus.COMPLETE)
      .map((x) => displayName[x.badgeType])
      .join(', ');

    const activitiesToDo = r.badges
      .filter(
        (x) => x.badgeStatus == thriveEnums.badgeStatus.NOTSTARTED || x.badgeStatus == thriveEnums.badgeStatus.MISSED,
      )
      .map((x) => displayName[x.badgeType])
      .join(', ');

    return (
      <TableRow key={i}>
        <TableCell>{`Week ${r.thriveWeek}`}</TableCell>
        <TableCell>{activities}</TableCell>
        <TableCell>{activitiesCompleted}</TableCell>
        <TableCell>{activitiesToDo}</TableCell>
      </TableRow>
    );
  };

  const showBadgesEarned = (b, i) => {
    const badgesEarned = b.badges.filter((x) => x.badgeStatus == thriveEnums.badgeStatus.COMPLETE);

    return badgesEarned.map((badge, r) => {
      return (
        <TableRow key={`${i}${r}`}>
          <TableCell>{`Week ${b.thriveWeek}`}</TableCell>
          <TableCell>{displayName[badge.badgeType]}</TableCell>
          <TableCell>{formatDate(badge.completionDateUtc)}</TableCell>
        </TableRow>
      );
    });
  };

  return (
    <>
      <section>
        <h2>Thrive History</h2>
        <StyledInteractionDatesContainer>
          <span>
            <StyledBoldSpan>Last Activity Interaction Date: </StyledBoldSpan>
            <span>{formatDate(thriveHistory?.lastBadgeCompletionDateUtc)}</span>
          </span>
          <span>
            <StyledBoldSpan>Last Voice-to-Voice with Andy Date: </StyledBoldSpan>
            <span>{formatDate(thriveHistory?.lastAndyCallDateUtc)}</span>
          </span>
        </StyledInteractionDatesContainer>
        <StyledTableContainer component={Paper} variant="outlined">
          <Table size="small" data-testid="HistoryTable">
            <TableHead>
              <TableRow>
                <TableCell>Status History</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell>Action Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {thriveHistory?.enrollmentHistory
                ?.sort((a, b) => (a.startDateUtc > b.startDateUtc ? 1 : -1))
                ?.map((h) => {
                  return showHistory(h);
                })}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </section>

      <section>
        <h2>Thrive Progress Report - Quick View</h2>
        <StyledTableContainer component={Paper} variant="outlined">
          <Table size="small" data-testid="ProgressReportTable">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '60px' }}>Week</TableCell>
                <TableCell>Activities in the Week</TableCell>
                <TableCell>Completed Activities</TableCell>
                <TableCell>To Do Activities</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {thriveProgress?.thriveWeeks?.map((r, i) => {
                return showProgressQuickView(r, i);
              })}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </section>

      <section>
        <h2>Badges Earned</h2>
        <StyledTableContainer component={Paper} variant="outlined">
          <Table size="small" data-testid="BadgesEarnedTable">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '60px' }}>Week</TableCell>
                <TableCell align="left">Badge Type</TableCell>
                <TableCell>Earned Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {thriveProgress?.thriveWeeks?.map((b, i) => {
                return showBadgesEarned(b, i);
              })}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </section>
    </>
  );
};

export default BadgingAndProgress;

import React, { useState, useContext, useEffect } from 'react';
import MemberData from 'Tasking_Components/MemberData/MemberData';
import MemberInfoTabs from 'Tasking_Components/MemberInfoTabs/MemberInfoTabs';
import MemberInfoData from 'Tasking_Components/MemberInfoData/MemberInfoData';
import MemberTaskData from 'Tasking_Components/MemberTaskData/MemberTaskData';
import MemberHistoryData from 'Tasking_Components/MemberHistoryData/MemberHistoryData';
import useNgmMemberInfo from 'Tasking_Hooks/useNgmMemberInfo';
import { MainIconBarContext } from 'Tasking_Contexts/MainIconBarContext';
import { useParams, Route, Routes, useLocation } from 'react-router-dom';
import withAppInsights from 'Helpers/appInsights';
import MemberConnectionHistory from 'Tasking_Components/MemberConnections/MemberConnectionHistory';
import Redetermination from 'Tasking_Components/Redetermination/Redetermination';
import LoadingWrapper from 'Components/LoadingWrapper/LoadingWrapper';

const NgmInfo = () => {
  const location = useLocation();
  const { orgId, ngmId } = useParams();
  const [display, setDisplay] = useState(location?.pathname?.includes('tasks') ? 'tasks' : 'user');
  const { ngmMemberInfo } = useNgmMemberInfo([ngmId, orgId]);
  const { showMainIcons } = useContext(MainIconBarContext);

  useEffect(() => {
    if (location?.state?.showTaskScreen || location?.pathname?.includes('tasks')) {
      setDisplay('tasks');
    }
    if (
      location?.pathname?.substring(location?.pathname?.length - ngmId?.length, location?.pathname?.length) ===
      ngmId?.toString()
    ) {
      setDisplay('user');
    }
    if (location?.pathname?.includes('redetermination')) {
      setDisplay('redetermination');
    }
    if (location?.pathname?.includes('history')) {
      setDisplay('history');
    }
    if (location?.pathname?.includes('memberconnection')) {
      setDisplay('memberconnection');
    }
  }, [location?.pathname]);

  return (
    <div data-testid="MemberInfo">
      <MemberData memberInfo={ngmMemberInfo} ngm />
      {showMainIcons && (
        <MemberInfoTabs
          showTask={location?.state?.showTaskScreen || location?.pathname?.includes('tasks')}
          ngm
          memberInfo={ngmMemberInfo}
          setDisplay={setDisplay}
          currentTab={display}
        />
      )}
      <LoadingWrapper loading={!ngmMemberInfo}>
        <Routes>
          <Route path="tasks/*" element={<MemberTaskData memberInfo={ngmMemberInfo} ngm />} />
          <Route path="redetermination" element={<Redetermination memberInfo={ngmMemberInfo} ngm />} />
          <Route path="history" element={<MemberHistoryData memberInfo={ngmMemberInfo} ngm />} />
          <Route
            path="memberconnection/:memberConnectionGuid"
            element={<MemberConnectionHistory memberInfo={ngmMemberInfo} ngm />}
          />
          <Route index element={<MemberInfoData memberInfo={ngmMemberInfo} ngm />} />
        </Routes>
      </LoadingWrapper>
    </div>
  );
};

export default withAppInsights(NgmInfo);

import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1360px;
  min-width: 880px;
  margin: 0 85px;

  @media (max-width: 1050px) {
    margin: 0;
  }
`;

export const TextHeader = styled.h2`
  margin-top: 28px;
  font-family: karla;
  color: #015b5b;
  font-size: 32px;
  font-weight: bold;
  width: fit-content;
`;

export const NewTaskButton = styled.button`
  font-family: karla;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 28px;
  border: 1px solid #015353;
  cursor: pointer;
  color: #015353;
  font-size: 14px;
  padding: 0px 12px;
  align-self: center;
  height: 30px;

  &:disabled {
    background: #808080;
    border: 1px none #303030;
    color: white;
    cursor: auto;
  }
`;

export const Loading = styled.div`
  padding: 12px;
  font-family: karla;
  color: red;
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
`;

export const OUTCOMES = [
  { value: 'BADNUMBER', label: 'Bad Number' },
  { value: 'DECLINEDALREADYCOMPLETED', label: 'Declined - Already Completed' },
  { value: 'DECLINEDOTHER', label: 'Declined - Other' },
  { value: 'LANGUAGEBARRIER', label: 'Language Barrier' },
  { value: 'LEFTMESSAGE', label: 'Left Message' },
  { value: 'NOANSWER', label: 'No Answer' },
  { value: 'RENEWALCOMPLETED', label: 'Renewal Completed' },
  { value: 'RENEWALPENDING', label: 'Renewal Pending' },
  { value: 'TERMHEALTHPLAN', label: 'Term Health Plan' },
  { value: 'DECEASED', label: 'Deceased' },
];

export const States = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  NONE: 'NONE',
};

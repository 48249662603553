import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';

export const DataTooltip = styled(Tooltip)`
  &.Mui-disabled {
    pointer-events: inherit;
  }
`;

export const DataGridContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

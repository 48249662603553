import React from 'react';
import { Box, Checkbox, FormGroup } from '@mui/material';
import PropTypes from 'prop-types';
import { PrintableCheckboxFormLabel, UncheckedCheckbox } from '../../styles';

function PrintableCheckBox({ name, checked, options, ...props }) {
  return (
    <FormGroup className={`formGroup-${name?.replace(/\s/g, '')}`}>
      <PrintableCheckboxFormLabel
        label={name}
        className={name}
        control={
          <Checkbox
            checked={!!checked}
            disabled={!checked}
            className={`label-${name}`}
            icon={<UncheckedCheckbox className="custom-unchecked-checkbox" />}
          />
        }
        {...props}
      />
      {options && (
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
          {options.map(({ checked: optionChecked, name: checkboxName, ...option }, key) => (
            <PrintableCheckboxFormLabel
              key={key}
              label={checkboxName}
              className={`label-${key}`}
              control={
                <Checkbox
                  checked={!!optionChecked && !!checked}
                  disabled={!optionChecked && !checked}
                  className={`checkbox-${key}`}
                  icon={<UncheckedCheckbox className="custom-unchecked-checkbox" />}
                />
              }
              {...option}
            />
          ))}
        </Box>
      )}
    </FormGroup>
  );
}

PrintableCheckBox.displayName = 'PrintableCheckBox';

PrintableCheckBox.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
};

export default PrintableCheckBox;

import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';

const useCreateAssistanceProvided = () => {
  const assistanceProvidedUrl = '/v5/AssistanceProvided';

  const [createAssistanceProvidedData, setCreateAssistanceProvidedData] = useState();
  const [url, setUrl] = useState();
  const { response, isLoading, hasErrored, errorData } = useAxiosSubmit(url, createAssistanceProvidedData);

  const createAssistanceProvided = async (request) => {
    setUrl(`${process.env.API_BASE_URL}${assistanceProvidedUrl}`);
    setCreateAssistanceProvidedData(request);
  };

  return {
    assistanceProvidedErrorMessage: errorData,
    assistanceProvidedGuid: response,
    error: hasErrored,
    isCreatePending: isLoading,
    createAssistanceProvided,
  };
};

export default useCreateAssistanceProvided;

import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

const getMigrationUrl = 'v1/migrationrequests';
const options = {
  revalidateOnFocus: false,
};

const getMigrationInternal = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useGetMigration = () => {
  const [migration, setMigration] = useState();
  const [url, setUrl] = useState();
  const [isGetPending, setIsGetPending] = useState(true);
  const { data: migrationResult, error, mutate } = useSWR(url, getMigrationInternal, options);

  const getMigration = (migrationRequestGuid) => {
    setUrl(`${process.env.API_BASE_URL}/${getMigrationUrl}/${migrationRequestGuid}`);
    mutate(migrationResult);
  };

  useEffect(() => {
    if (migrationResult) {
      setMigration(migrationResult.data);
    }

    setIsGetPending(!migrationResult && !error);
  }, [migrationResult, error]);

  return {
    migration,
    error,
    isGetPending,
    getMigration,
  };
};

export default useGetMigration;

import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

async function deleter(url, data, token) {
  const authorizedApiClient = await getAuthClient();
  if (!url) return;
  if (authorizedApiClient) {
    const headers = {
      'Content-Type': 'application/json',
    };
    return await authorizedApiClient.delete(url, { data, headers, cancelToken: token });
  }
}

const useAxiosDelete = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const [response, setResponse] = useState();

  const source = useMemo(() => axios.CancelToken.source(), []);

  async function executeDelete(url, data) {
    deleter(url, data)
      .then((result) => setResponse(result))
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setError(error);
        }
      });
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      const validResponse = !!response?.status;
      setIsLoading(!validResponse && !error);
    }

    return () => {
      source.cancel();
      isMounted = false;
    };
  }, [response, error]);

  return { response, error, isLoading, executeDelete };
};

export default useAxiosDelete;

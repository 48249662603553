import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Grid, Typography } from '@mui/material';
import { selfReportedOutcomeConfig } from './Configuration/selfReportedOutcomeConfig';
import { populationTypes, questionTypes, userTypes } from './Configuration/enums';
import { Header, StyledLoadingButton } from './styles';
import OutcomeItem from './OutcomeItem/OutcomeItem';
import { useForm } from 'react-hook-form';
import useSubmitSelfReportedOutcomes from 'Tasking_Hooks/useSubmitSelfReportedOutcomes';
import SubmissionModal from './SubmissionModal/SubmissionModal';
import PropTypes from 'prop-types';
import { black } from 'Styles/colors';
import useUserMeta from 'Tasking_Hooks/useUserMeta';
import { retrieveUserMetaCategories } from 'Tasking_Helpers/userMetaHelpers';

function SelfReportedOutcome({
  actions,
  setActions,
  setActionToDisplay,
  setCurrentTab,
  memberInfo,
  values,
  setValues,
  completedFeedback,
}) {
  const { response, hasErrored, isLoading, selfReportedOutcomeError, submitSelfReportedOutcome } =
    useSubmitSelfReportedOutcomes();
  const { userId } = useParams();
  const { userMeta, isPending: isMetaPending } = useUserMeta(userId);
  const [isCaregiverGuardian, setIsCaregiverGuardian] = useState();
  const [openModal, setOpenModal] = useState(false);
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {},
  });

  const memberType = memberInfo?.isNonAppUser ? userTypes.NONAPP : userTypes.APP;
  const populationType = isCaregiverGuardian ? populationTypes.CAREGIVER : populationTypes.GENERAL;
  const config = selfReportedOutcomeConfig[memberType][populationType];
  const questions = config?.questions;

  useEffect(() => {
    if (response && !isLoading && !hasErrored) {
      setActions({ ...actions, isActive: true, outcomeGuid: response });
      setActionToDisplay('');
      setCurrentTab('');
    }
  }, [response]);

  useEffect(() => {
    if (!isMetaPending) {
      const userMetaCategory = retrieveUserMetaCategories(userMeta);
      setIsCaregiverGuardian(userMetaCategory.includes('CAREGIVER' || 'GUARDIAN'));
    }
  }, [isMetaPending]);

  const onSubmit = (e) => {
    setOpenModal(false);

    const request = {
      profileId: memberInfo.activeProfileId,
      organizationId: memberInfo.activeOrganizationId,
      answers: Object.keys(e)
        .filter((d) => e[d])
        .map((d) => ({ questionGuid: d, answerValue: e[d] })),
    };

    submitSelfReportedOutcome(request);
  };

  const onCancel = () => {
    setValues({ ...values, currentTab: '', actionToDisplay: '', selfReportedOutcome: undefined });
    setActionToDisplay('');
    setCurrentTab('');
  };

  const resetQuestionValues = (vals, question) => {
    question.guids.forEach((questionGuid, index) => {
      if (vals && vals[questionGuid]) {
        const defaultVal = question?.types[index] === questionTypes.FREETEXT ? '' : undefined;
        delete vals[questionGuid];
        setValue(questionGuid, defaultVal);
      }
    });
    return vals;
  };

  const onChange = ({ name, value }) => {
    const currentQuestion = questions.find((el) => el.guids.includes(name));
    let vals = values.selfReportedOutcome;

    if (!vals) {
      updateValue(name, value);
      return;
    }

    if (currentQuestion) {
      const dependentQuestions = questions.filter((el) => el.questionDependencies && el.questionDependencies[name]);

      dependentQuestions?.forEach((x) => {
        x?.options?.forEach(
          (o) =>
            x?.typeOptions &&
            x?.typeOptions[questionTypes.CLOSED][o]?.forEach((q) => {
              vals = resetQuestionValues(vals, q);
            }),
        );

        vals = resetQuestionValues(vals, x);
      });
    }

    if (currentQuestion?.types[0] === questionTypes.CLOSED) {
      const oppositeClosed = currentQuestion?.options?.find((e) => e !== value);
      currentQuestion?.typeOptions[questionTypes.CLOSED][oppositeClosed]?.forEach((q) => {
        vals = resetQuestionValues(vals, q);
      });
    }

    vals[name] = value;
    setValues({ ...values, selfReportedOutcome: vals });
  };

  const updateValue = (name, value) => {
    setValues({ ...values, selfReportedOutcome: { ...values?.selfReportedOutcome, [name]: value } });
  };

  const wasSubmitted = !!actions?.outcomeGuid || completedFeedback;

  return (
    <Grid container padding="32px 58px">
      <Grid item xs={9} sx={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
        <Header fontSize={32} fontWeight={700}>
          Self-Reported Outcome
        </Header>
        <Header fontSize={22} fontWeight={300}>
          {`/ ${config?.title}`}
        </Header>
      </Grid>
      <Grid item xs={3} sx={{ display: 'flex' }}>
        <StyledLoadingButton disabled={isLoading} fullWidth onClick={onCancel}>
          Cancel
        </StyledLoadingButton>
        {!wasSubmitted && (
          <StyledLoadingButton loading={isLoading} variant="contained" fullWidth onClick={() => setOpenModal(true)}>
            Save
          </StyledLoadingButton>
        )}
      </Grid>
      {!wasSubmitted && !isLoading && hasErrored && (
        <Grid item xs={12}>
          <Alert severity="error" variant="filled">
            {selfReportedOutcomeError}
          </Alert>
        </Grid>
      )}
      {wasSubmitted ? (
        <Grid item xs={12}>
          <Typography fontSize={24} color={black._87} padding="64px 0px">
            Report Submitted
          </Typography>
        </Grid>
      ) : (
        questions.map((question, index) => (
          <OutcomeItem
            key={index}
            question={question}
            index={index}
            control={control}
            onChange={onChange}
            values={values?.selfReportedOutcome}
          />
        ))
      )}
      <SubmissionModal open={openModal} onClose={() => setOpenModal(false)} onSubmit={handleSubmit(onSubmit)} />
    </Grid>
  );
}

SelfReportedOutcome.propTypes = {
  setActionToDisplay: PropTypes.func,
  setCurrentTab: PropTypes.func,
  actions: PropTypes.object,
  setActions: PropTypes.func,
  memberInfo: PropTypes.object,
  values: PropTypes.object,
  setValues: PropTypes.func,
  completedFeedback: PropTypes.bool,
};

export default SelfReportedOutcome;

import styled from 'styled-components';
import { TableContainer } from '@mui/material';

export const StyledTabContainer = styled.div`
  padding: 30px;
  font-size: 0.875rem;
`;

export const StyledTableContainer = styled(TableContainer)`
  margin: 20px 0 40px 0;

  th {
    font-weight: bold;
    white-space: nowrap;
  }
`;

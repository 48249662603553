export const HOME = '/';
export const ENROLLED = '/user/:userId';
export const ENROLLED_REDETERMINATION = '/user/:userId/redetermination';
export const ENROLLED_HISTORY = '/user/:userId/history';
export const ENROLLED_MEMBERCONNECTION = '/user/:userId/memberconnection/:memberConnectionGuid';
export const ENROLLED_THRIVE = '/user/:userId/thrive';
export const ENROLLED_THRIVE_METRICS = '/user/:userId/thrive/metrics';
export const ENROLLED_THRIVE_SCREENING = '/user/:userId/thrive/screening';
export const ENROLLED_THRIVE_PSYCHOLOGYCONTENT = '/user/:userId/thrive/psychology-content';
export const ENROLLED_TASKS = '/user/:userId/tasks';
export const ENROLLED_TASKS_EDIT = '/user/:userId/tasks/:taskGuid';
export const ENROLLED_TASKS_EDIT_ACTIVITY = '/user/:userId/tasks/:taskGuid/activity';
export const ENROLLED_TASKS_EDIT_ACTIVITY_VIEW = '/user/:userId/tasks/:taskGuid/activity/:activityGuid';
export const NONENROLLED = '/ngm/:orgId/:ngmId';
export const NONENROLLED_REDETERMINATION = '/ngm/:orgId/:ngmId/redetermination';
export const NONENROLLED_HISTORY = '/ngm/:orgId/:ngmId/history';
export const NONENROLLED_THRIVE = '/ngm/:orgId/:ngmId/memberconnection/:memberConnectionGuid';
export const NONENROLLED_TASKS = '/ngm/:orgId/:ngmId/tasks';
export const NONENROLLED_TASKS_EDIT = '/ngm/:orgId/:ngmId/tasks/:taskGuid';
export const NONENROLLED_TASKS_EDIT_ACTIVITY = '/ngm/:orgId/:ngmId/tasks/:taskGuid/activity';
export const NONENROLLED_TASKS_EDIT_ACTIVITY_VIEW = '/ngm/:orgId/:ngmId/tasks/:taskGuid/activity/:activityGuid';
export const MIGRATIONREQUESTS = '/migrationrequests';
export const MIGRATIONREQUESTS_NEW = '/migrationrequests/new';
export const MIGRATIONREQUESTS_NEW_INFO = '/migrationrequests/new/:informationSearchType';

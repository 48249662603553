import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

const memberConcernUrl = '/v5/memberconcerns';
const options = {
  revalidateOnFocus: false,
};

const getMemberConcern = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const keysToLowerCase = (obj) => {
  var key,
    keys = Object.keys(obj);
  let n = keys.length;
  let newObj = {};
  while (n--) {
    key = keys[n];
    newObj[key.toLowerCase()] = obj[key];
  }
  return newObj;
};

const useGetMemberConcern = (organizationId, concernGuid) => {
  const [isGetPending, setIsPending] = useState(true);
  const [memberConcernDetail, setMemberConcern] = useState();

  if (!concernGuid) {
    return { memberConcernDetail, error, isGetPending };
  }

  const { data: memberConcernResult, error } = useSWR(
    () => `${process.env.API_BASE_URL}${memberConcernUrl}/${organizationId}:${concernGuid}`,
    getMemberConcern,
    {
      ...options,
    },
  );

  useEffect(() => {
    if (memberConcernResult) {
      let cleanObj = keysToLowerCase(memberConcernResult.data);
      setMemberConcern(cleanObj);
    }

    setIsPending(!memberConcernResult && !error);
  }, [memberConcernResult, error]);

  return { memberConcernDetail, error, isGetPending };
};

export default useGetMemberConcern;

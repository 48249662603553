import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

const localesUrl = '/v1/locales';
const options = {
  revalidateOnFocus: false,
};

const getLocales = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useGetLocales = () => {
  const [isPending, setIsPending] = useState(true);
  const [locales, setLocales] = useState();
  const { data: localeResults, error } = useSWR(() => `${process.env.API_BASE_URL}${localesUrl}`, getLocales, {
    ...options,
  });

  useEffect(() => {
    if (localeResults) {
      setLocales(localeResults.data);
    }

    setIsPending(!localeResults && !error);
  }, [localeResults, error]);

  return { locales, error, isPending };
};

export default useGetLocales;

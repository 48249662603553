import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';

const useCreateTask = () => {
  const [completeTaskData, setCompleteTaskData] = useState();
  const [url, setUrl] = useState();
  const { response, isLoading, hasErrored, requestCompleted, errorData } = useAxiosSubmit(url, completeTaskData, 'put');

  const completeTask = async (request, orgId, taskGuid) => {
    setUrl(`${process.env.API_BASE_URL}/v5/Tasks/${orgId}:${taskGuid}/complete`);
    setCompleteTaskData(request);
  };

  return {
    taskCompletedRequestCompleted: requestCompleted,
    completedTask: response,
    error: hasErrored,
    isCompleteTaskPending: isLoading,
    taskErrorMessage: errorData,
    completeTask,
  };
};

export default useCreateTask;

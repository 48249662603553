import styled from 'styled-components';

export const StyledInteractionDatesContainer = styled.div`
  display: flex;

  span {
    flex: 1 1 0;
  }
`;

export const StyledBoldSpan = styled.span`
  font-weight: bold;
`;

import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';

const useEditMemberConcern = () => {
  const [editMemberConcernData, setMemberConcernData] = useState();
  const [url, setUrl] = useState();
  const { response, isLoading, hasErrored, errorData } = useAxiosSubmit(url, editMemberConcernData, 'put');

  const editMemberConcern = async (request, endUrl) => {
    setUrl(`${process.env.API_BASE_URL}/v5/memberconcerns/${endUrl}`);
    setMemberConcernData(request);
  };

  return {
    editResponse: response,
    editConcernErrorMessage: errorData,
    editError: hasErrored,
    isEditPending: isLoading,
    editMemberConcern,
  };
};

export default useEditMemberConcern;

import styled from 'styled-components';

const colors = {
  deceased: '#4c4c4c',
  deleted: '#ECECEC',
  declined: '#e76a5f',
  ngm: '#6fa7a8',
  underage: '#ff955c',
  default: '#88be71',
};

export const MemberDataContainer = styled.div`
  background-color: ${(props) =>
    props.deceased
      ? colors.deceased
      : props.deleted
      ? colors.deleted
      : props.declined || props.doNotCall
      ? colors.declined
      : props.underage
      ? colors.underage
      : props.ngm
      ? colors.ngm
      : colors.default};
  display: flex;
  height: 76px;
  justify-content: space-evenly;
  font-family: karla;
`;

export const TabsContainer = styled.div`
  margin-top: 13px;
  display: flex;
  font-size: 22px;
`;

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.isHistoryTab
      ? 'white'
      : props.clicked
      ? 'white'
      : props.deceased
      ? colors.deceased
      : props.deleted
      ? colors.deleted
      : props.declined || props.doNotCall
      ? colors.declined
      : props.underage
      ? colors.underage
      : props.ngm
      ? colors.ngm
      : colors.default};
  color: ${(props) =>
    props.isHistoryTab
      ? colors.default
      : props.clicked
      ? props.deceased
        ? colors.deceased
        : props.deleted
        ? colors.deleted
        : props.declined || props.doNotCall
        ? colors.declined
        : props.underage
        ? colors.underage
        : props.ngm
        ? colors.ngm
        : colors.default
      : 'white'};
  border-width: 2px;
  border-style: solid;
  border-color: white;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  vertical-align: middle;
  padding: 10px;
  margin-left: 2.5vw;
`;

export const TabDescription = styled.div`
  color: white;
  font-size: 16px;
  margin-top: 15px;
  margin-left: 10px;
`;

export const Container = styled.div`
  display: flex;
  cursor: pointer;
  margin-right: 30px;
`;

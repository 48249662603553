import { Screenings } from '../enumerations/enums';
import { ApiService } from 'Tasking_Helpers/ApiService';
import { retryRequest } from 'Helpers/retryRequest';

export class AvailableScreeningsApiService {
  private static _instance: AvailableScreeningsApiService = new AvailableScreeningsApiService();
  private api: ApiService;

  constructor() {
    // Make sure we don't create more than one instance of this service
    if (AvailableScreeningsApiService._instance) {
      return AvailableScreeningsApiService._instance;
    }

    this.api = new ApiService();
  }

  public getAvailableScreenings = async (userId: number, retries = 1) => {
    const { response, error } = await retryRequest(
      () => this.api.get(`/v1/screeningschedule/${userId}/allavailable`, null),
      retries,
    );
    return { response, error };
  };
}

import React from 'react';

export function parseErrorMessage(errorMessage) {
  // The test environment gives a full detailed debugging message. The qa/prod environment returns specific error messages
  return errorMessage;
}

// Below will format a 400 error code's multiple error messages appropriately
// Ex.
// {
//   "errors": {
//     "UserName": ["UserName is required", "Username must be 6 characters or longer"],
//     "PhoneNumber": ["PhoneNumber is re quired", "Phone Number must be 10 digits"]
//   }
// }
export function parseMultiErrorMessage(errorObject) {
  const keys = Object.keys(errorObject);
  // Loops through "errors" object and creates headers from the keys
  const errorHtml = keys.reduce((acc, key, idx) => {
    // Loops through each key's array and creates bullets from the strings
    const listItems = errorObject[key].reduce((itemAcc, itemKey, itemIdx) => {
      itemAcc.push(<li key={itemIdx}>{itemKey}</li>);
      return itemAcc;
    }, []);
    // Creates a new div containing the headers & messages for each of the errors
    acc.push(
      <div key={idx}>
        <span>{key}</span>
        <ul>{listItems}</ul>
      </div>,
    );
    return acc;
  }, []);
  return <div>{errorHtml}</div>;
}

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MemberConnectionForm from './MemberConnectionForm/MemberConnectionForm';
import { Alert, Grid } from '@mui/material';
import useAxiosSwrFetch from 'Tasking_Hooks/useAxiosSwrFetch';
import urlcat from 'urlcat';
import usePrint from 'Helpers/printHelper';
import PrintableMemberConnections from './PrintMemberConnectionForm/PrintableMemberConnections';
import { useParams } from 'react-router-dom';

const requestPath = '/v1/memberconnections/:orgId::memberConnectionGuid';

const MemberConnectionHistory = ({ memberInfo, ngm }) => {
  const { memberConnectionGuid } = useParams();
  const orgId = ngm ? memberInfo.organizationId : memberInfo?.activeOrganizationId;

  const { handlePrint, renderPrint } = usePrint();
  const {
    response: memberConnectionData,
    error: getMemberConnectionError,
    isLoading: isGetPending,
    fetch,
  } = useAxiosSwrFetch({ fetchRequired: false });

  useEffect(() => {
    if (memberConnectionGuid && orgId) {
      fetch(urlcat(process.env.API_BASE_URL, requestPath, { orgId: orgId, memberConnectionGuid }));
    }
  }, []);

  const cancelButtonCallback = () => {};
  const printButtonCallback = handlePrint;

  return (
    <>
      {memberConnectionGuid && !memberConnectionData ? (
        <Grid item xs={12}>
          <Alert severity="info" variant="filled">
            loading member connection...
          </Alert>
        </Grid>
      ) : !isGetPending && getMemberConnectionError ? (
        <Grid item xs={12}>
          <Alert severity="error" variant="filled">
            {getMemberConnectionError.message ?? 'Error: Something went wrong.'}
          </Alert>
        </Grid>
      ) : (
        <MemberConnectionForm
          memberInfo={memberInfo}
          memberConnectionGuid={memberConnectionGuid}
          memberConnectionData={memberConnectionData}
          cancelButtonCallback={cancelButtonCallback}
          printButtonCallback={printButtonCallback}
          displayStatus={true}
          ngm={ngm}
          orgId={orgId}
        />
      )}
      {renderPrint(PrintableMemberConnections, { memberConnectionGuid, orgId })}
    </>
  );
};

MemberConnectionHistory.propTypes = {
  memberInfo: PropTypes.object,
  memberConnectionGuid: PropTypes.string,
  ngm: PropTypes.bool,
};

export default MemberConnectionHistory;

import styled from 'styled-components';

export const GreetingCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GreetingCardMessageContainer = styled.div`
  flex-direction: column;
  align-items: center;
  text-align: center;
  top: 3%;
  border: 1px solid #f3f1f4;
  position: relative;
  background: white;
  box-shadow: 5px 5px 5px #b6b8b8;
  padding-top: 1.49%;
  width: ${(props) => props.maxSize};
  max-width: ${(props) => props.maxSize};
  height: ${(props) => props.maxSize};

  &::after {
    z-index: -1;
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    left: 5px;
    top: 0;
    background-color: #f3f1f4;
    box-shadow: 0 0 0 0 #f3f1f4, 8px 9px 11px #b6b8b8;
    transform: rotate(10deg) scale(1.01);
  }
`;

export const GreetingCardImage = styled.img`
  margin: 10px;
  max-width: 150px;
  max-height: 150px;
  height: 50%;
`;

export const GreetingCardTextContainer = styled.div`
  display: flex;
  flex-flow: column;
  height: calc(${(props) => props.maxSize} - 51.49% - 20px);

  &:hover {
    height: auto;
    min-height: calc(${(props) => props.maxSize} - 51.49% - 20px);
    background-color: white;
    box-shadow: 0px -30px 0px 0px white, 5px 4px 10px 4px #b6b8b8;
  }
`;

export const CardText = styled.p`
  flex: 1 1 auto;
  align-self: center;
  padding: 0 4% 0 4%;
`;

export const GreetingCardMessage = styled(CardText)`
  word-break: break-all;
  font-size: ${(props) => props.fontSize};
  margin-top: 0;
  overflow: clip;
  color: #4a4f7f;
  margin: 0;
  display: inline-block;

  &:hover {
    overflow: visible;
  }
`;

export const GreetingCardErrorMessage = styled.p`
  color: red;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
`;

export const GreetingCardFrom = styled(CardText)`
  line-height: 1.7rem;
  margin: 10px 0 10px 0;
  font-size: ${(props) => props.fontSize};
  color: #4a4f7f;
  font-family: Noteworthy, cursive;
`;

export const SpinnerButton = styled.div`
  color: white;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

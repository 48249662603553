import styled from 'styled-components';
import { TableContainer } from '@mui/material';

export const StyledTableContainer = styled(TableContainer)`
  margin: 20px 0 0 0;

  th {
    font-weight: bold;
    white-space: nowrap;
  }
`;

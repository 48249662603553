import {
  YouthNeedsScreeningModuleStore,
  YouthPHQ2ScreeningModuleStore,
  YouthUCLA3ScreeningModuleStore,
  AdultPHQ4ScreeningModuleStore,
  AdultUCLA3ScreeningModuleStore,
  AdultPRAPAREScreeningModuleStore,
  AdultWellBeingScreeningModuleStore,
  AdultNeedsScreeningModuleStore,
  CAHPSPyxPlusScreeningModuleStore,
} from './ScreeningImplementations';
import { Screenings } from './enumerations/enums';

type FactoryInstance<T> = T extends new () => infer R ? R : undefined;
type FactoryMapValues<T extends object> = T[keyof T];
type FactoryMap<T extends object> = FactoryInstance<FactoryMapValues<T>>;

const ScreeningModuleStores = {
  [Screenings.YOUTH_UCLA3]: YouthUCLA3ScreeningModuleStore,
  [Screenings.YOUTH_PHQ2]: YouthPHQ2ScreeningModuleStore,
  [Screenings.YOUTH_NEEDS]: YouthNeedsScreeningModuleStore,
  [Screenings.ADULT_UCLA3]: AdultUCLA3ScreeningModuleStore,
  [Screenings.ADULT_PHQ4]: AdultPHQ4ScreeningModuleStore,
  [Screenings.ADULT_NEEDS]: AdultNeedsScreeningModuleStore,
  [Screenings.ADULT_PRAPARE]: AdultPRAPAREScreeningModuleStore,
  [Screenings.ADULT_WELLBEING]: AdultWellBeingScreeningModuleStore,
  [Screenings.CAHPS_PYXPLUS]: CAHPSPyxPlusScreeningModuleStore,
};

type ScreeningModuleStoreTypes = typeof ScreeningModuleStores;

export class ScreeningFormModuleStoreFactory {
  static get(k: string): FactoryMap<ScreeningModuleStoreTypes> {
    const FormStore = ScreeningModuleStores[k];
    if (!FormStore) return;
    return new FormStore();
  }
}

import React from 'react';
import { OidcSecure } from '@axa-fr/react-oidc';
import 'regenerator-runtime/runtime';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import theme from '../shared/styles/theme';
import NavBar from 'Components/Navbar/NavBar';
import Routes from './Routes/Routes';
import { BrowserRouter } from 'react-router-dom';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <OidcSecure>
          <BrowserRouter basename="">
            <NavBar />
            <Routes />
          </BrowserRouter>
        </OidcSecure>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export default App;

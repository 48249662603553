import { useEffect, useState } from 'react';
import useSWR from 'swr';
import urlcat from 'urlcat';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

const requestPath = 'v1/thrive/access/:userId/history';

const retrieveApiHistory = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

function useThriveAccessHistory() {
  const [isPending, setIsPending] = useState(true);
  const [history, setHistory] = useState();
  const [url, setUrl] = useState();

  const {
    data: response,
    error,
    mutate,
  } = useSWR(() => url, retrieveApiHistory, {
    revalidateOnFocus: false,
  });

  const getThriveAccessHistory = (userId) => {
    setUrl(urlcat(process.env.API_BASE_URL, requestPath, { userId }));
    mutate(response);
  };

  useEffect(() => {
    if (response) {
      setHistory(response.data);
    }

    setIsPending(!response && !error);
  }, [response, error]);

  return { history, error, isPending, getThriveAccessHistory };
}

export default useThriveAccessHistory;

import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';
import urlcat from 'urlcat';

const requestPath = 'v1/PositivePsychology/:userId/Interaction';

const useLogPsychologyContent = () => {
  const [logPsychologyContentData, setLogPsychologyContentData] = useState();
  const [url, setUrl] = useState();
  const { response, isLoading, hasErrored, errorData } = useAxiosSubmit(url, logPsychologyContentData);

  const logPsychologyContent = (request, userId) => {
    setUrl(urlcat(process.env.API_BASE_URL, requestPath, { userId }));
    setLogPsychologyContentData(request);
  };

  return {
    logResponse: response,
    logContentErrorMessage: errorData,
    logContentError: hasErrored,
    isLogPending: isLoading,
    logPsychologyContent,
  };
};

export default useLogPsychologyContent;

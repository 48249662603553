import { Button } from '@mui/material';
import styled from 'styled-components';

export const CreateTaskDataContainer = styled.div`
  margin-top: 35px;
  height: 120px;
  width: 100%;
  font-family: Karla;
`;

export const HeaderContainer = styled.div`
  display: flex;
`;

export const TextHeader = styled.div`
  margin-top: 31px;
  margin-left: 90px;
  font-family: karla;
  color: #015b5b;
  font-size: 30px;
  font-weight: bold;
`;

export const CardPreviewContainer = styled.div`
  position: absolute;
  margin-left: 1000px;
  margin-top: -50px;
`;

export const ActivitiesTextHeader = styled.div`
  position: absolute;
  margin-top: 265px;
  margin-left: 90px;
  font-family: karla;
  color: #015b5b;
  font-size: 18px;
  font-weight: bold;
`;

export const SectionHeader = styled.div`
  font-family: Lato;
  color: #0f0f0f;
  font-size: 14px;
  margin-bottom: 10px;
`;

export const DataColumn = styled.div`
  display: flex;
  margin-left: 85px;
  margin-top: ${(props) => (props.top ? '' : '30px')};
`;

export const SelectStyle = styled.select`
  width: 225px;
  height: 32px;
  border: 2px solid #e9e7e7;
  margin-top: -5px;
`;

export const TextAreaStyle = styled.textarea`
  border-radius: 10px;
  width: 1239px;
  height: 92px;
  border: 2px solid #e9e7e7;
  color: #0f145b;
  margin-top: 6px;
  margin-right: 20px;
`;

export const SubmitButtonContainer = styled.div`
  display: flex;
  padding: 25px 0;
`;

export const SaveButton = styled.div`
  background-color: #c0d7d8;
  color: #015353;
  width: 125px;
  height: 27px;
  border-radius: 25px;
  margin: 0 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: karla;
  margin-left: 85px;
  display: ${(props) => (props.showSave ? '' : 'none')};
`;

export const CompleteButtonTooltipContainer = styled.div`
  height: 27px;
  border-radius: 25px;
  margin-left: ${(props) => (props.finished ? '1200px' : '25px')};
  margin-top: ${(props) => (props.finished ? '25px' : '0')};
  display: ${(props) => (props.showcomplete ? '' : 'none')};
`;

export const CompleteButton = styled(Button)`
  color: white;
  min-width: 125px;
  height: 27px;
  border-radius: 25px;
  margin: 0 7px;
  font-family: karla;
  font-size: 16px;
  display: ${(props) => (props.showcomplete ? '' : 'none')};

  &.Mui-disabled {
    background-color: #c8c8da;
    color: #ffffff;
  }
`;

export const SubmitButtonTooltipContainer = styled.div`
  height: 27px;
  margin-left: ${(props) => (props.showcomplete ? '700px' : '850px')};
  border-radius: 25px;
`;

export const SubmitButton = styled(Button)`
  color: white;
  min-width: 125px;
  height: 27px;
  border-radius: 25px;
  margin: 0 7px;
  font-family: karla;

  &.Mui-disabled {
    background-color: #c8c8da;
    color: #ffffff;
  }
`;

export const CancelButton = styled.div`
  background-color: white;
  color: #4a4f7f;
  width: 125px;
  height: 27px;
  border: 1px solid #4a4f7f;
  border-radius: 25px;
  margin: 0 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: karla;
`;

export const InputStyle = styled.input`
  width: 145px;
  height: 32px;
  margin-right: 72px;
  margin-top: -10px;
  border-radius: 9px;
  border: ${(props) => (props.error ? '1px solid red' : '1px solid #e9e7e7')};

  &:invalid {
    border: 1px solid red;
  }
`;

export const SpinnerButton = styled.div`
  background-color: #c0d7d8;
  color: white;
  width: 125px;
  height: 30px;
  border: 1px solid #4a4f7f;
  border-radius: 25px;
  font-family: Karla, sans-serif;
  outline: none;
  margin-left: ${(props) => (props.saveButtonSpinner ? '85px' : '850px')};
`;

export const ErrorMessage = styled.div`
  color: red;
  font-family: Karla;
  margin-left: ${(props) => (props.savedTaskClicked ? '390px' : '87px')};
  margin-top: -50px;
`;

export const HiFriendModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  height: 200px;
  border-radius: 24px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: white;
`;

export const HiFriendContent = styled.div`
  width: 80%;
  height: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: karla;
  padding: 15px;
`;

export const HiFriendDismissButton = styled.button`
  background-color: white;
  color: #4a4f7f;
  width: 125px;
  height: 27px;
  border: 1px solid #4a4f7f;
  border-radius: 25px;
  margin: 0 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: karla;
  margin: auto;
`;

export const CallContainer = styled.div`
  position: absolute;
  margin-top: -75px;
  margin-left: 864px;

  :only-child {
    margin-top: 7px;
  }
`;

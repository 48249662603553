export const getEndOfUrl = (str) => {
  str = str.split('/');
  return str[str.length - 1];
};

export const getEndOfUrlForNgm = (str) => {
  str = str.split('/');
  return [str[str.length - 1], str[str.length - 2]];
};

export const lowerCaseAllWordsExceptFirstLetters = (str) => {
  return str.replace(/\S*/g, function (word) {
    return word.charAt(0) + word.slice(1).toLowerCase();
  });
};

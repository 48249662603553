import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';

const useCompleteActivity = () => {
  const [completeActivityData, setCompleteActivityData] = useState();
  const [url, setUrl] = useState();
  const { response, isLoading, hasErrored, requestCompleted, errorData } = useAxiosSubmit(
    url,
    completeActivityData,
    'post',
  );

  const completeActivity = async (request, orgId, taskGuid) => {
    setUrl(`${process.env.API_BASE_URL}/v5/Tasks/${orgId}:${taskGuid}/activity`);
    setCompleteActivityData(request);
  };

  return {
    activityCompletedRequestCompleted: requestCompleted,
    completedActivity: response,
    error: hasErrored,
    errorMessage: errorData,
    isCompleteActivityPending: isLoading,
    completeActivity,
  };
};

export default useCompleteActivity;

import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';

const useCreateDoNotCall = () => {
  const createDoNotCallUrl = '/v1/DoNotCall';

  const [createDoNotCallData, setCreateDoNotCallData] = useState();
  const [url, setUrl] = useState();
  const { response, isLoading, hasErrored, errorData } = useAxiosSubmit(url, createDoNotCallData);

  const createDoNotCall = async (request) => {
    setUrl(`${process.env.API_BASE_URL}${createDoNotCallUrl}`);
    setCreateDoNotCallData(request);
  };

  return {
    createDoNotCallErrorMessage: errorData,
    doNotCallGuid: response,
    createDoNotCallError: hasErrored,
    isCreateDoNotCallPending: isLoading,
    createDoNotCall,
  };
};

export default useCreateDoNotCall;

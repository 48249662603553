import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { TextField } from '@mui/material';

const PhoneInput = forwardRef(({ onChange, error, errorMessage, ...props }, ref) => {
  return (
    <NumberFormat
      getInputRef={ref}
      type={'tel'}
      customInput={TextField}
      placeholder="+1 (___) ___-____"
      format="+1 (###) ###-####"
      mask="_"
      helperText={error && errorMessage}
      error={error}
      onValueChange={(v) => onChange(v.value)}
      {...props}
    />
  );
});

PhoneInput.displayName = 'PhoneInput';

PhoneInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  error: PropTypes.bool,
};

export default PhoneInput;

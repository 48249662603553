import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';

const useSubmitSelfReportedOutcomes = () => {
  const url = `${process.env.API_BASE_URL}/v1/sro/response`;
  const [request, setRequest] = useState();
  const { response, isLoading, hasErrored, errorData } = useAxiosSubmit(url, request, 0, 1);

  const submitSelfReportedOutcome = async (request) => setRequest(request);

  return { response, hasErrored, isLoading, selfReportedOutcomeError: errorData, submitSelfReportedOutcome };
};

export default useSubmitSelfReportedOutcomes;

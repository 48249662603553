import React, { useEffect, useState } from 'react';
import { MigrationRequestModuleStore } from '../MigrationRequestModuleStore';
import LoadingWrapper from 'Components/LoadingWrapper/LoadingWrapper';

export default function withInformation(SearchComponent) {
  const withInformationStore = (props) => {
    const migrationStore = MigrationRequestModuleStore.Instance().Use();
    const [loading, setLoading] = useState(true);
    const isLoading = loading || migrationStore.IsLoading;

    useEffect(() => {
      migrationStore.InitializeSearch().then(() => setLoading(false));
    }, []);

    return (
      <LoadingWrapper loading={isLoading}>
        <SearchComponent {...props} />
      </LoadingWrapper>
    );
  };

  return withInformationStore;
}

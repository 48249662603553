import React from 'react';
import PropTypes from 'prop-types';
import { GroupContainer, Heading, Text } from '../../../ScreeningHistoryItem/styles.js';
import { questions } from '../config.js';

const YouthUCLA3 = ({ answers, scoreDetail }) => {
  return (
    <div>
      <GroupContainer>
        <Heading>Type</Heading>
        <Text data-testid={'ScreeningName'}>Youth - UCLA3</Text>
      </GroupContainer>

      <GroupContainer>
        <Heading>Score</Heading>
        <Text>{scoreDetail[0]?.score}</Text>
      </GroupContainer>

      {questions.map((question, index) => {
        const questionAnswers = question.options.filter((o) => answers.find((x) => x.answerGuid === o.name));
        const answer = questionAnswers?.length ? questionAnswers[0] : questionAnswers;
        const answerData = answers.find((x) => x.answerGuid === answer.name);
        return (
          <GroupContainer key={`${index}`}>
            <Heading>{question.text}</Heading>
            <Text>{answerData?.value ?? answer?.text}</Text>
          </GroupContainer>
        );
      })}
    </div>
  );
};

YouthUCLA3.propTypes = {
  answers: PropTypes.array,
  scoreDetail: PropTypes.array,
};

export default YouthUCLA3;

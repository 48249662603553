import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

// filter out style-only attributes
// eslint-disable-next-line no-unused-vars
function NavButtonWrapper({ active, ...props }) {
  return <Button {...props} />;
}
NavButtonWrapper.propTypes = {
  active: PropTypes.bool.isRequired,
};
export default NavButtonWrapper;

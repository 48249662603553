import styled from '@emotion/styled';
import { Paper } from '@mui/material';

export const Question = styled(Paper)`
  width: 100%;
  padding: 15px;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  border-radius: 20px;

  pointer-events: ${(props) => (props.disabled ? 'none' : '')};
  background-color: ${(props) => (props.disabled ? '#F8F8F8' : '')};
  color: ${(props) => (props.disabled ? '#8A8A8A' : '')};

  .MuiCheckbox-root {
    color: ${(props) => (props.disabled ? '#8A8A8A' : '')};
  }
  .MuiRadio-root {
    color: ${(props) => (props.disabled ? '#8A8A8A' : '')};
  }
`;

export const SecondaryQuestion = styled(Question)`
  background-color: #fafafa;
  margin: 15px 8px;
  padding: 15px 20px;
  width: 96%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  border: 0;
`;

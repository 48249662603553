import { Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { SectionHeader, StyledPaper } from './styles';
import MemberConnectionField from './MemberConnectionField';

function MemberConnectionSection({ control, section, onUpdate, isDisabled, values }) {
  return (
    <Grid item xs={section.gridSize}>
      <StyledPaper>
        <Grid container spacing={1}>
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', paddingBottom: '4px' }}>
            <SectionHeader fontSize={16} fontWeight={400}>
              {section.title}
            </SectionHeader>
          </Grid>
          {section.formFields.map((formField, index) => (
            <MemberConnectionField
              formField={formField}
              key={index}
              control={control}
              onUpdate={onUpdate}
              isDisabled={isDisabled}
              values={values}
            />
          ))}
        </Grid>
      </StyledPaper>
    </Grid>
  );
}

MemberConnectionSection.propTypes = {
  control: PropTypes.object,
  section: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  values: PropTypes.object,
};

export default MemberConnectionSection;

export const screeningConfig = {
  scoreMap: { 1: 'Never', 2: 'Rarely', 3: 'Sometimes', 4: 'Usually' },
  questions: [
    {
      key: '1',
      text: 'I have someone to talk to and feel supported and respected.',
      dimension: 'SOCIAL',
    },
    {
      key: '2',
      text: 'I am satisfied with the quantity and quality of my social activities.',
      dimension: 'SOCIAL',
    },
    {
      key: '3',
      text: 'I can manage stress in a way that works for my lifestyle.',
      dimension: 'EMOTIONAL',
    },
    {
      key: '4',
      text: 'I have a person or group of people I can reach out to when I am stressed.',
      dimension: 'EMOTIONAL',
    },
    {
      key: '5',
      text: 'I engage in an adequate amount of physical exercise.',
      dimension: 'PHYSICAL',
    },
    {
      key: '6',
      text: 'I typically get an adequate amount of sleep each night.',
      dimension: 'PHYSICAL',
    },
    {
      key: '7',
      text: 'I eat a balanced nutritional diet.',
      dimension: 'PHYSICAL',
    },
    {
      key: '8',
      text: 'I search for learning opportunities.',
      dimension: 'INTELLECTUAL',
    },
    {
      key: '9',
      text: 'I engage in stimulating mental activities.',
      dimension: 'INTELLECTUAL',
    },
  ],
};

import { IStateStore } from 'Helpers/IStateStore';
import { Singleton } from 'Helpers/Singleton';
import { IScreeningTakerModuleStore } from './IScreeningTakerModuleStore';
import { atom, getDefaultStore, useAtom } from 'jotai';
import { ScreeningResponseApiDataService } from './services/ScreeningResponseApiDataService';
import { ScreeningFormModuleStoreFactory } from './ScreeningModuleStoreFactory';
import { AvailableScreeningsApiService } from './services/AvailableScreeningsApiService';

export class ScreeningModuleStore extends Singleton implements IStateStore {
  private screeningResponseApiDataService: ScreeningResponseApiDataService;
  private availableScreeningService: AvailableScreeningsApiService;
  private screeningGuidAtom;
  private screeningResponseGuidAtom;
  private errorAtom;
  private availableScreeningsAtom;
  private loadingAtom;
  private screeningDoneAtom;
  private screeningForm: IScreeningTakerModuleStore;
  private atomStore = getDefaultStore();
  private userId;
  private taskGuid;
  private defaultScreeningGuid;
  private completeCallback: (screeningResponseGuid) => void;

  constructor() {
    super();

    this.screeningResponseApiDataService = new ScreeningResponseApiDataService();
    this.availableScreeningService = new AvailableScreeningsApiService();
    this.taskGuid = null;
    this.defaultScreeningGuid = null;
    this.availableScreeningsAtom = atom([]);
    this.screeningGuidAtom = atom(null);
    this.screeningResponseGuidAtom = atom(null);
    this.errorAtom = atom(null);
    this.screeningDoneAtom = atom(false);
    this.loadingAtom = atom(false);
  }

  public Use = () => {
    useAtom(this.screeningGuidAtom);
    useAtom(this.availableScreeningsAtom);
    useAtom(this.screeningResponseGuidAtom);
    useAtom(this.errorAtom);
    useAtom(this.loadingAtom);
    useAtom(this.screeningDoneAtom);

    return this;
  };

  public get ScreeningGuid(): string {
    return this.atomStore.get(this.screeningGuidAtom);
  }

  public get ScreeningResponseGuid(): string {
    return this.atomStore.get(this.screeningResponseGuidAtom);
  }

  public get Error() {
    return this.atomStore.get(this.errorAtom);
  }

  // When Task & Activity pages do not require prop-drilling, this can be removed/replaced
  public get ScreeningIsDone() {
    return this.atomStore.get(this.screeningDoneAtom);
  }

  public get IsLoading() {
    return this.atomStore.get(this.loadingAtom);
  }

  public set IsLoading(loading: boolean) {
    this.atomStore.set(this.loadingAtom, loading);
  }

  /**
   *  When we add more screenings, abstract this to its own factory (guid => module store)
   */
  public get ScreeningForm(): IScreeningTakerModuleStore {
    if (this.screeningForm) return this.screeningForm;

    const FormStore = ScreeningFormModuleStoreFactory.get(this.ScreeningGuid);

    if (!FormStore) return null;

    this.screeningForm = FormStore.create(this.complete);

    return this.screeningForm;
  }

  /**
   *  When we add more screenings, abstract this to its own factory
   */
  public get AvailableScreenings() {
    return this.atomStore.get(this.availableScreeningsAtom);
  }

  private updateAvailableScreenings = async () => {
    this.IsLoading = true;

    const { response: screenings, error } = await this.availableScreeningService.getAvailableScreenings(this.userId);

    if (error) {
      this.atomStore.set(this.errorAtom, { hasError: true, error });
      this.IsLoading = false;
      return;
    }

    this.atomStore.set(this.availableScreeningsAtom, screenings);
    this.defaultScreeningGuid = screenings.length > 0 ? screenings[0] : null;

    if (!this.ScreeningGuid) {
      this.atomStore.set(this.screeningGuidAtom, this.defaultScreeningGuid);
    }

    this.IsLoading = false;
  };

  private resetScreening = () => {
    if (this.screeningForm) {
      delete this.screeningForm;
      this.screeningForm = null;
    }
  };

  private reset = () => {
    this.resetScreening();
    this.IsLoading = false;
    this.atomStore.set(this.errorAtom, null);
    this.atomStore.set(this.screeningGuidAtom, null);
    this.atomStore.set(this.screeningResponseGuidAtom, null);
  };

  public startScreening = async () => {
    this.IsLoading = true;
    if (this.ScreeningResponseGuid) {
      this.IsLoading = false;
      return;
    }

    const { response: screeningResponseGuid, error } =
      await this.screeningResponseApiDataService.startScreeningResponse({
        userId: this.userId,
        screeningGuid: this.ScreeningGuid,
      });

    if (!screeningResponseGuid) {
      this.atomStore.set(this.errorAtom, { hasError: true, error });
      this.IsLoading = false;
      return;
    }

    this.atomStore.set(this.screeningResponseGuidAtom, screeningResponseGuid);
    this.IsLoading = false;
  };

  public initialize = async ({ userId, taskGuid, completeCallback }) => {
    this.atomStore.set(this.screeningDoneAtom, false);

    if (this.userId !== userId || this.taskGuid !== taskGuid) {
      this.reset();
      this.userId = userId;
      this.taskGuid = taskGuid;
      await this.updateAvailableScreenings();
      this.completeCallback = completeCallback;
    }

    if (!this.ScreeningGuid) {
      this.atomStore.set(this.screeningGuidAtom, this.defaultScreeningGuid);
    }
  };

  public chooseScreening = (screeningGuid) => {
    this.resetScreening();
    this.atomStore.set(this.screeningGuidAtom, screeningGuid);
    this.atomStore.set(this.screeningResponseGuidAtom, null);
  };

  public cancel = () => {
    this.reset();
    this.atomStore.set(this.screeningDoneAtom, true);
  };

  public complete = async (answers) => {
    this.IsLoading = true;
    const { success: hasCompletedScreening, error } =
      await this.screeningResponseApiDataService.completeScreeningResponse({
        userId: this.userId,
        screeningResponseGuid: this.ScreeningResponseGuid,
        isCompletedByAgent: true,
        answers,
      });

    this.atomStore.set(this.errorAtom, error);

    if (!hasCompletedScreening) {
      this.atomStore.set(this.errorAtom, { hasError: true, error });
      this.IsLoading = false;
      return;
    }

    this.atomStore.set(this.screeningDoneAtom, true);
    this.completeCallback(this.ScreeningResponseGuid);
    this.reset();
    this.IsLoading = false;
  };
}

import { formatDate } from 'Tasking_Helpers/formatDate';

export const printFieldTypes = {
  TEXT: 'TEXT',
  RADIOGROUP: 'RADIOGROUP',
  CHECKBOX: 'CHECKBOX',
};

export const getMemberConnectionPrintConfig = (memberConnectionData) => {
  return {
    sections: [
      {
        title: 'Member Information',
        containerWidth: '100.25%',
        items: [
          {
            label: 'Full Name:',
            inputType: printFieldTypes.TEXT,
            value: memberConnectionData?.memberName,
            fullWidth: true,
            gridSize: 4,
          },
          {
            label: 'Network Name:',
            inputType: printFieldTypes.TEXT,
            value: memberConnectionData?.networkName,
            fullWidth: true,
            gridSize: 4,
          },
          {
            label: 'Date of Birth:',
            inputType: printFieldTypes.TEXT,
            value: formatDate(memberConnectionData?.memberDOB),
            fullWidth: true,
            gridSize: 2,
          },
          {
            gridSize: 2,
          },
          {
            label: 'HealthPlan ID:',
            inputType: printFieldTypes.TEXT,
            value: memberConnectionData?.healthPlanId,
            fullWidth: true,
            gridSize: 4,
          },
          {
            label: 'Group Name:',
            inputType: printFieldTypes.TEXT,
            value: memberConnectionData?.groupName,
            fullWidth: true,
            gridSize: 4,
          },
          {
            label: 'Language:',
            inputType: printFieldTypes.TEXT,
            value: memberConnectionData?.memberLanguage,
            fullWidth: true,
            gridSize: 2,
          },
          {
            label: 'Phone Number:',
            inputType: printFieldTypes.TEXT,
            value: memberConnectionData?.memberPhoneNumber,
            fullWidth: true,
            gridSize: 2,
          },
        ],
      },
      {
        title: 'Connection Contact Information',
        gridSize: 6,
        items: [
          {
            inputType: printFieldTypes.RADIOGROUP,
            value: memberConnectionData?.isUrgent,
            gridSize: 12,
            options: [
              { name: 'Urgent Member Connection', value: true },
              { name: 'Non-Urgent Member Connection', value: false },
            ],
          },
          {
            label: 'Primary Member Connection Reason',
            inputType: printFieldTypes.TEXT,
            value: memberConnectionData?.primaryReason,
            gridSize: 12,
            fullWidth: true,
            multiline: true,
            rows: 4,
            inputProps: {
              maxLength: 300,
            },
          },
          {
            label: 'Secondary Member Connection Reason',
            inputType: printFieldTypes.TEXT,
            value: memberConnectionData?.secondaryReason,
            gridSize: 12,
            fullWidth: true,
            multiline: true,
            rows: 4,
            margin: 'none',
            inputProps: {
              maxLength: 300,
            },
          },
        ],
      },
      {
        title: 'Connection Destination',
        gridSize: 6,
        items: [
          {
            inputType: printFieldTypes.CHECKBOX,
            name: 'Member Services',
            checked: memberConnectionData?.destinationMemberServices,
            gridSize: 6,
          },
          {
            inputType: printFieldTypes.CHECKBOX,
            name: 'Community-Based Org',
            checked: memberConnectionData?.destinationCommunityOrg,
            gridSize: 6,
          },
          {
            inputType: printFieldTypes.CHECKBOX,
            name: 'Crisis Line',
            checked: memberConnectionData?.destinationCrisisLine,
            gridSize: 12,
          },
          {
            inputType: printFieldTypes.CHECKBOX,
            name: 'Nurse Line',
            checked: memberConnectionData?.destinationNurseLine,
            gridSize: 12,
          },
        ],
      },
      {
        title: 'Medical',
        gridSize: 6,
        items: [
          {
            inputType: printFieldTypes.CHECKBOX,
            name: 'Access to Care',
            checked: memberConnectionData?.medicalAccessToCare,
            gridSize: 7,
            options: [
              { name: 'Behavioral Health', checked: memberConnectionData?.medicalAccessToBehaviorHealth },
              { name: 'MD/Physician', checked: memberConnectionData?.medicalAccessToPhysician },
            ],
          },
          {
            inputType: printFieldTypes.CHECKBOX,
            name: 'Pharmacy',
            checked: memberConnectionData?.medicalPharmacy,
            gridSize: 5,
            options: [
              {
                name: 'Side Effects',
                checked: memberConnectionData?.medicalPharmacySideEffects,
              },
              { name: 'Refill', checked: memberConnectionData?.medicalPharmacyRefill },
              { name: 'Others', checked: memberConnectionData?.medicalPharmacyOther },
            ],
          },
          {
            inputType: printFieldTypes.CHECKBOX,
            name: 'Dentistry',
            checked: memberConnectionData?.medicalDentistry,
            gridSize: 6,
          },
          {
            inputType: printFieldTypes.CHECKBOX,
            name: 'In-Home Care Assistance',
            checked: memberConnectionData?.medicalInHomeCare,
            gridSize: 6,
          },
          {
            inputType: printFieldTypes.CHECKBOX,
            name: 'Durable Medical Equipment (DME)',
            checked: memberConnectionData?.medicalDME,
            gridSize: 6,
          },
          {
            inputType: printFieldTypes.CHECKBOX,
            name: 'Activities for Daily Living',
            checked: memberConnectionData?.medicalActivities,
            gridSize: 12,
          },
        ],
      },
      {
        title: 'Behavioral Health',
        gridSize: 6,
        columns: 6,
        items: [
          {
            inputType: printFieldTypes.CHECKBOX,
            name: 'Find a Provider',
            checked: memberConnectionData?.behaviorFindProvider,
            gridSize: 12,
          },
          {
            inputType: printFieldTypes.CHECKBOX,
            name: 'Welfare Check',
            checked: memberConnectionData?.behaviorWelfareCheck,
            gridSize: 12,
          },
        ],
      },
      {
        title: 'SDOH',
        gridSize: 6,
        columns: 6,
        items: [
          {
            inputType: printFieldTypes.CHECKBOX,
            name: 'Food',
            checked: memberConnectionData?.sdohFood,
            gridSize: 5,
          },
          {
            inputType: printFieldTypes.CHECKBOX,
            name: 'Employment',
            checked: memberConnectionData?.sdohEmployment,
            gridSize: 5,
          },
          {
            inputType: printFieldTypes.CHECKBOX,
            name: 'Housing',
            checked: memberConnectionData?.sdohHousing,
            gridSize: 5,
            options: [
              { name: 'HUD', checked: memberConnectionData?.sdohHousingHUD },
              {
                name: 'Urgent Shelter',
                checked: memberConnectionData?.sdohHousingUrgentShelter,
              },
            ],
          },
          {
            inputType: printFieldTypes.CHECKBOX,
            name: 'Safety',
            checked: memberConnectionData?.sdohSafety,
            gridSize: 5,
            options: [
              { name: 'Environment', checked: memberConnectionData?.sdohSafetyEnvironment },
              { name: 'Physical', checked: memberConnectionData?.sdohSafetyPhysical },
            ],
          },
          {
            inputType: printFieldTypes.CHECKBOX,
            name: 'Transportation',
            checked: memberConnectionData?.sdohTransportation,
            gridSize: 5,
          },
          {
            inputType: printFieldTypes.CHECKBOX,
            name: 'Utility',
            checked: memberConnectionData?.sdohUtility,
            gridSize: 5,
          },
          {
            inputType: printFieldTypes.CHECKBOX,
            name: 'Income',
            checked: memberConnectionData?.sdohIncome,
            gridSize: 12,
          },
        ],
      },
      {
        title: 'Benefits',
        gridSize: 6,
        columns: 6,
        items: [
          {
            inputType: printFieldTypes.CHECKBOX,
            name: 'Covered Services',
            checked: memberConnectionData?.benefitsCoveredServices,
            gridSize: 12,
          },
          {
            inputType: printFieldTypes.CHECKBOX,
            name: 'Need a Card',
            checked: memberConnectionData?.benefitsNeedACard,
            gridSize: 12,
          },
          {
            inputType: printFieldTypes.CHECKBOX,
            name: 'Prior Authorization',
            checked: memberConnectionData?.benefitsPriorAuthorization,
            gridSize: 12,
          },
        ],
      },
      {
        gridSize: 6,
      },
      {
        title: 'Transfer to Plan Recommended',
        gridSize: 6,
        columns: 6,
        items: [
          {
            inputType: printFieldTypes.RADIOGROUP,
            value: memberConnectionData?.transferStatus,
            gridSize: 12,
            options: [
              { name: 'No', value: 'NO' },
              { name: 'Declined Transfer', value: 'DECLINED' },
              { name: 'Successful Transfer', value: 'SUCCESSFUL' },
            ],
          },
        ],
      },
      {
        gridSize: 6,
      },
      {
        title: 'Details / Additional Information',
        gridSize: 6,
        columns: 6,
        items: [
          {
            label: 'Action Taken:',
            inputType: printFieldTypes.TEXT,
            value: memberConnectionData?.actionTaken,
            multiline: true,
            fullWidth: true,
            rows: 5,
            gridSize: 12,
          },
          {
            label: 'Follow Up:',
            inputType: printFieldTypes.TEXT,
            value: memberConnectionData?.followUp,
            multiline: true,
            fullWidth: true,
            rows: 5,
            gridSize: 12,
          },
          {
            label: 'Additional Notes:',
            inputType: printFieldTypes.TEXT,
            value: memberConnectionData?.additionalNotes,
            multiline: true,
            fullWidth: true,
            rows: 5,
            gridSize: 12,
          },
        ],
      },
    ],
  };
};

import React, { useState } from 'react';
import { Navigate, Route, Routes as Switch } from 'react-router-dom';
import HomePage from '../../tasking/pages/HomePage/HomePage';
import MemberInfo from '../../tasking/pages/MemberInfo/MemberInfo';
import NgmInfo from '../../tasking/pages/NgmInfo/NgmInfo';
import MemberMigration from 'Administrator_Pages/MigrationRequests/MemberMigration';
import { ActionsContext } from 'Tasking_Contexts/ActionsContext';
import { MainIconBarContext } from 'Tasking_Contexts/MainIconBarContext';
import { TaskSelectedContext } from 'Tasking_Contexts/TaskSelectedContext';
import { ActivitySelectedContext } from 'Tasking_Contexts/ActivitySelectedContext';
import { ThriveProvider } from 'Tasking_Contexts/ThriveContext';

const Routes = () => {
  const [actions, setActions] = useState();
  const [showMainIcons, setShowMainIcons] = useState(true);
  const [selectedTask, setSelectedTask] = useState();
  const [selectedActivity, setSelectedActivity] = useState();

  return (
    <Switch>
      <Route
        path={'user/:userId/*'}
        element={
          <MainIconBarContext.Provider value={{ showMainIcons, setShowMainIcons }}>
            <TaskSelectedContext.Provider value={{ selectedTask, setSelectedTask }}>
              <ActivitySelectedContext.Provider value={{ selectedActivity, setSelectedActivity }}>
                <ActionsContext.Provider value={{ actions, setActions }}>
                  <ThriveProvider>
                    <MemberInfo />
                  </ThriveProvider>
                </ActionsContext.Provider>
              </ActivitySelectedContext.Provider>
            </TaskSelectedContext.Provider>
          </MainIconBarContext.Provider>
        }
      />
      <Route
        path={'ngm/:orgId/:ngmId/*'}
        element={
          <MainIconBarContext.Provider value={{ showMainIcons, setShowMainIcons }}>
            <TaskSelectedContext.Provider value={{ selectedTask, setSelectedTask }}>
              <ActivitySelectedContext.Provider value={{ selectedActivity, setSelectedActivity }}>
                <ActionsContext.Provider value={{ actions, setActions }}>
                  <NgmInfo />
                </ActionsContext.Provider>
              </ActivitySelectedContext.Provider>
            </TaskSelectedContext.Provider>
          </MainIconBarContext.Provider>
        }
      />
      <Route
        path={'/migrationrequests/*'}
        element={
          <MainIconBarContext.Provider value={{ showMainIcons, setShowMainIcons }}>
            <MemberMigration />
          </MainIconBarContext.Provider>
        }
      />
      <Route
        index
        element={
          <MainIconBarContext.Provider value={{ showMainIcons, setShowMainIcons }}>
            <TaskSelectedContext.Provider value={{ selectedTask, setSelectedTask }}>
              <ActivitySelectedContext.Provider value={{ selectedActivity, setSelectedActivity }}>
                <HomePage />
              </ActivitySelectedContext.Provider>
            </TaskSelectedContext.Provider>
          </MainIconBarContext.Provider>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Switch>
  );
};

export default Routes;

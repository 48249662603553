import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

const screeningQuestionUrl = 'v5/screening';
const options = {
  revalidateOnFocus: false,
};

const getMemberScreening = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useGetPastScreening = (userId, screeningId) => {
  const [isGetPending, setIsPending] = useState(true);
  const [memberScreeningDetail, setMemberScreening] = useState();

  if (!userId || !screeningId) {
    return { memberScreeningDetail, isGetPending };
  }

  const { data: memberScreeningResult, error } = useSWR(
    () => `${process.env.API_BASE_URL}/${screeningQuestionUrl}/${userId}:${screeningId}`,
    getMemberScreening,
    {
      ...options,
    },
  );

  useEffect(() => {
    if (memberScreeningResult) {
      setMemberScreening(memberScreeningResult.data);
    }

    setIsPending(!memberScreeningResult && !error);
  }, [memberScreeningResult, error]);

  return { memberScreeningDetail, error, isGetPending };
};

export default useGetPastScreening;

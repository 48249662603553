export const questionTypes = {
  CLOSED: 'CLOSED',
  RATING: 'RATING',
  FREETEXT: 'FREETEXT',
  CHECKBOX: 'CHECKBOX',
};

export const userTypes = {
  ALL: 'ALL',
  APP: 'APP',
  NONAPP: 'NONAPP',
};

export const populationTypes = {
  CAREGIVER: 'CAREGIVER',
  GENERAL: 'GENERAL',
};

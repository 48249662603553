import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { FormLabel, Radio } from '@mui/material';
import { StyledFormControl, StyledFormLabelContainer, StyledRadioGroup, StyledFormControlLabel } from './styles';

const ScreeningQuestion = ({ name, control, questionText, disabled }) => {
  return (
    <StyledFormControl component="fieldset" variant="standard" disabled={disabled}>
      <StyledFormLabelContainer>
        <FormLabel component="legend">{questionText}</FormLabel>
      </StyledFormLabelContainer>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value, ...field } }, ...fields) => (
          <StyledRadioGroup {...field} {...fields} row value={value} onChange={onChange}>
            <StyledFormControlLabel value="1" control={<Radio />} label="Never" />
            <StyledFormControlLabel value="2" control={<Radio />} label="Rarely" />
            <StyledFormControlLabel value="3" control={<Radio />} label="Sometimes" />
            <StyledFormControlLabel value="4" control={<Radio />} label="Usually" />
          </StyledRadioGroup>
        )}
      />
    </StyledFormControl>
  );
};

ScreeningQuestion.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  questionText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
ScreeningQuestion.defaultProps = {
  disabled: false,
};

export default ScreeningQuestion;

import { printFieldTypes } from '../MemberConnectionPrintConfig';
import PrintableCheckBox from './CheckBox/PrintableCheckBox';
import FreeText from './FreeText/FreeText';
import PrintableRadioGroup from './RadioGroup/PrintableRadioGroup';

export const inputConfig = {
  [printFieldTypes.TEXT]: FreeText,
  [printFieldTypes.RADIOGROUP]: PrintableRadioGroup,
  [printFieldTypes.CHECKBOX]: PrintableCheckBox,
};

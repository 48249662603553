import React, { useEffect, useState } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import ThriveNavigation from './ThriveNavigation/ThriveNavigation';
import { ACTIONS, useThriveDispatch } from 'Tasking_Contexts/ThriveContext';
import useThriveStatus from 'Tasking_Hooks/useThriveStatus';
import useThriveProgress from 'Tasking_Hooks/useThriveProgress';
import ThriveMemberFunctions from './ThriveMemberFunctions/ThriveMemberFunctions';
import ThriveScreening from './Screening/Screening';
import PsychologyContent from './PsychologyContent/PsychologyContent';
import ThriveMetrics from './ThriveMetrics/ThriveMetrics';
import { StyledContentContainer, StyledErrorMessage, StyledProgressContainer } from './styles';
import ThriveStatus from 'Tasking_Components/ThriveStatus/ThriveStatus';
import PropTypes from 'prop-types';
import CenteredCircularProgress from 'Components/CenteredCircularProgress/CenteredCircularProgress';

function Thrive({ memberInfo }) {
  const dispatch = useThriveDispatch();
  const { userId } = useParams();
  const [error, setError] = useState();
  const { status: thriveStatus, getThriveStatus, error: statusError, isPending: statusPending } = useThriveStatus();
  const {
    progress: thriveProgress,
    getThriveProgress,
    error: progressError,
    isPending: progressPending,
  } = useThriveProgress();

  useEffect(() => {
    updateThriveStatus();
  }, []);

  const updateThriveStatus = () => {
    getThriveStatus(userId);
    getThriveProgress(userId);
  };

  useEffect(() => {
    if (thriveStatus !== null && thriveStatus !== undefined) {
      dispatch({ type: ACTIONS.SET_THRIVE_STATUS, payload: thriveStatus });
    }
  }, [thriveStatus]);

  useEffect(() => {
    if (thriveProgress !== null && thriveProgress !== undefined) {
      dispatch({ type: ACTIONS.SET_THRIVE_PROGRESS, payload: thriveProgress });
    }
  }, [thriveProgress]);

  useEffect(() => {
    if (statusError || progressError) {
      setError(statusError?.message ?? progressError?.message);
    }
  }, [statusError, progressError]);

  return (
    <section>
      <ThriveNavigation />
      <StyledContentContainer>
        {statusPending || progressPending ? (
          <StyledProgressContainer>
            <CenteredCircularProgress />
          </StyledProgressContainer>
        ) : error ? (
          <StyledErrorMessage>{error}</StyledErrorMessage>
        ) : (
          <>
            <ThriveStatus />
            <Routes>
              <Route path={'screening'} element={<ThriveScreening />} />
              <Route path={'psychology-content'} element={<PsychologyContent />} />
              <Route path={'metrics'} element={<ThriveMetrics />} />
              <Route
                index
                element={<ThriveMemberFunctions updateThriveStatus={updateThriveStatus} memberInfo={memberInfo} />}
              />
            </Routes>
          </>
        )}
      </StyledContentContainer>
    </section>
  );
}

Thrive.propTypes = {
  memberInfo: PropTypes.object.isRequired,
};

export default Thrive;

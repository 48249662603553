import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  StyledTextHeader,
  StyledTextSubHeader,
  StyledInputStyle,
  StyledLabel,
  StyledSelectStyle,
  StyledMemberDataContainer,
  StyledDataRowContainer,
  StyledIcon,
  StyledIconContainer,
  StyledTextAreaStyle,
  StyledSubmitButtonContainer,
  StyledCancelButton,
  StyledHeaderContainer,
  StyledCheckBoxContainer,
  StyledPaper,
  StyledNoteLabel,
  StyledErrorMessage,
  CheckboxGridItem,
  StyledInputCheckboxStyle,
  StyledInputCheckboxLabel,
} from './styles';
import { useForm } from 'react-hook-form';
import useMemberInfo from 'Tasking_Hooks/useMemberInfo';
import useNgmMemberInfo from 'Tasking_Hooks/useNgmMemberInfo';
import useAxiosPatch from 'Tasking_Hooks/useAxiosPatch';
import states from 'Assets/mock-data/states.json';
import MemberPhoneData from './MemberPhoneData';
import Button from 'Components/Button';
import useCreateDoNotCall from 'Tasking_Hooks/useCreateDoNotCall';
import useAxiosDelete from 'Tasking_Hooks/useAxiosDelete';
import urlcat from 'urlcat';
import { phoneNumberTypes } from './enums';
import { Grid } from '@mui/material';
import { getNow } from 'Helpers/timeWarpHelper';
import { convertLocalDateToUtcStr } from 'Util/dateFunctions';

const MemberInfo = ({ memberInfo, ngm }) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      DoNotCall: memberInfo?.doNotCall,
      Declined: memberInfo?.declined,
      Deceased: memberInfo?.deceased,
      IsNonAppUser: memberInfo?.isNonAppUser,
      MemberHighlight: memberInfo?.memberHighlight,
    },
  });
  const [editing, setEditing] = useState(false);
  const [patchData, setPatchData] = useState();
  const [ngmUpdateData, setNgmUpdateData] = useState();
  const { isLoading: isMemberPending, hasErrored: memberError } = useAxiosPatch(
    `${process.env.API_BASE_URL}/v5/members/${memberInfo?.userId}`,
    patchData,
  );
  //Revisit this when api messages are more fluent
  const { isLoading: isNgmPending, hasErrored: ngmError } = useAxiosPatch(
    `${process.env.API_BASE_URL}/v5/ngms/${memberInfo?.organizationId}:${memberInfo?.id}`,
    ngmUpdateData,
  );
  const { isCreatePending, createDoNotCallErrorMessage, createDoNotCall } = useCreateDoNotCall();
  const { isLoading: isDoNotDeletePending, error: deleteDoNotCallError, executeDelete } = useAxiosDelete();
  const { retrieveMemberInfo } = useMemberInfo();
  const { retrieveNgmMemberInfo } = useNgmMemberInfo();
  const [patchErrored, setPatchErrored] = useState(false);
  const [phoneNumberMissing, setPhoneNumberMissing] = useState(false);
  const doNotCallDeletePath = '/v1/DoNotCall/:guid';

  useEffect(() => {
    if (
      !isNgmPending &&
      !isMemberPending &&
      !memberError &&
      !ngmError &&
      !createDoNotCallErrorMessage &&
      !deleteDoNotCallError &&
      !phoneNumberMissing
    ) {
      setEditing(false);
      if (memberInfo) {
        ngm
          ? retrieveNgmMemberInfo([memberInfo?.id, memberInfo?.organizationId])
          : retrieveMemberInfo(memberInfo?.userId);
      }
    } else {
      setPatchErrored(memberError || ngmError);
    }
  }, [
    isNgmPending,
    isMemberPending,
    ngmError,
    memberError,
    createDoNotCallErrorMessage,
    deleteDoNotCallError,
    isCreatePending,
    isDoNotDeletePending,
  ]);

  const submit = (e) => {
    const currentUtcDateTime = convertLocalDateToUtcStr(getNow());
    const memberHighlightDateHasChanged =
      currentUtcDateTime.split('T')[0] !== memberInfo?.memberHighlightCheckedOnUtc?.split('T')[0];

    const data = {
      firstName: e.PreferredFirstName,
      lastName: e.PreferredLastName,
      address: e.StreetName,
      city: e.City,
      state: e.State,
      zip: e.Zip,
      notes: e.Notes,
      deceased: e.Deceased,
      declined: e.Declined,
      isNonAppUser: e.IsNonAppUser,
      phoneNumberPreference: e.phoneNumberPreference,
      language: e.Language,
      memberHighlight: e.MemberHighlight,
      memberHighlightCheckedOnUtc:
        e.MemberHighlight === true && memberHighlightDateHasChanged
          ? currentUtcDateTime
          : e.MemberHighlight === false
          ? null
          : memberInfo.memberHighlightCheckedOnUtc,
    };
    const getPreferredPhoneNumber = {
      [phoneNumberTypes.HEALTHPLANPROVIDEDPHONENUMBER]: e.HPPhoneNumberInput,
      [phoneNumberTypes.PHONENUMBER]: ngm ? e.PreferredPhoneNumber : e.MemberProvidedMobilePhone,
      [phoneNumberTypes.LANDLINEPHONENUMBER]: e.MemberProvidedLandlinePhone,
    };
    // DoNotCall - if form value is dirty, use it, otherwise use existing value
    const doNotCallFormValue = e.DoNotCall !== undefined ? e.DoNotCall : memberInfo?.doNotCall;
    const doNotCallValueHasChanged = doNotCallFormValue !== memberInfo?.doNotCall;
    const doNotCallPhoneNumber = getPreferredPhoneNumber[e.phoneNumberPreference ?? memberInfo.phoneNumberPreference];

    if (ngm) {
      // udpate ngm info
      if (doNotCallValueHasChanged) {
        handleDoNotCall(ngm, doNotCallFormValue, doNotCallPhoneNumber, memberInfo);
      }

      const ngmdata = {
        ...data,
        healthPlanProvidedPhoneNumberNotWorking: e.HPPhoneNumberNotWorking,
        phoneNumberNotWorking: e.PreferredPhoneNumberNotWorking,
        phoneNumber: e.PreferredPhoneNumber,
      };

      const patchItems = formatNGMPatchData(ngmdata);
      setNgmUpdateData(patchItems);
    } else {
      // update member info
      if (doNotCallValueHasChanged) {
        handleDoNotCall(ngm, doNotCallFormValue, doNotCallPhoneNumber, memberInfo);
      }

      const memberData = {
        ...data,
        landlinePhoneNumber: e.MemberProvidedLandlinePhone,
        phoneNumberNotWorking: e.MobilePhoneNumberNotWorking,
        landlinePhoneNumberNotWorking: e.LandlinePhoneNumberNotWorking,
      };

      const patchItems = formatMemberPatchData(memberData);
      if (Object.keys(patchItems).length > 0) {
        setPatchData(patchItems);
      }
    }
  };

  const handleDoNotCall = (ngm, doNotCallFormValue, doNotCallPhoneNumber, memberInfo) => {
    const shouldCreateDoNotCall = doNotCallFormValue === true && memberInfo?.doNotCall === false;
    const shouldDeleteDoNotCall = doNotCallFormValue === false && memberInfo?.doNotCall === true;

    if (shouldCreateDoNotCall) {
      if (!doNotCallPhoneNumber) {
        setPhoneNumberMissing(true);
        return;
      } else {
        createDoNotCall({
          organizationId: ngm ? memberInfo?.organizationId : memberInfo?.activeOrganizationId,
          profileId: ngm ? null : memberInfo?.activeProfileId,
          networkGroupMemberId: ngm ? memberInfo?.id : null,
          phoneNumber: doNotCallPhoneNumber,
        });
        setPhoneNumberMissing(false);
      }
    }
    if (shouldDeleteDoNotCall) {
      executeDelete(urlcat(process.env.API_BASE_URL, doNotCallDeletePath, { guid: memberInfo.doNotCallGuid }), {
        organizationId: ngm ? memberInfo?.organizationId : memberInfo?.activeOrganizationId,
      });
    }
  };

  const formatMemberPatchData = (data) => {
    let transformedObject = {
      memberPatchItems: {},
    };

    let changeArray = Object.entries(data).filter(([key, value]) =>
      value ? value != memberInfo[key] : !!value != !!memberInfo[key],
    );

    for (let i = 0; i < changeArray.length; i++) {
      //there is one pesky field that is named differently on the result object, so we'll rename it here
      let key = changeArray[i][0] == 'language' ? 'locale' : changeArray[i][0];
      transformedObject.memberPatchItems[key] = changeArray[i][1];
    }

    return transformedObject;
  };

  const formatNGMPatchData = (data) => {
    let transformedObject = {
      ngmPatchItems: {},
    };

    let changeArray = Object.entries(data).filter(([key, value]) => {
      if (key === 'phoneNumber') {
        return value
          ? value != memberInfo['preferredPhone']?.slice(-10)
          : !!value != !!memberInfo['preferredPhone']?.slice(-10);
      }
      return value ? value != memberInfo[key] : !!value != !!memberInfo[key];
    });

    for (let i = 0; i < changeArray.length; i++) {
      //there is one pesky field that is named differently on the result object, so we'll rename it here
      let key = changeArray[i][0] == 'language' ? 'locale' : changeArray[i][0];
      transformedObject.ngmPatchItems[key] = changeArray[i][1];
    }

    return transformedObject;
  };

  const cancel = () => {
    setEditing(false);
    setPatchErrored(false);
    reset();
  };

  const displayHeaderOptions = () => {
    let onClickHandler = () => {
      if (memberInfo?.deleted) {
        return;
      }
      setEditing(true);
    };

    if (!editing) {
      return (
        <StyledIconContainer data-testid="EditIcon" onClick={onClickHandler} edit={!memberInfo?.deleted}>
          <StyledIcon edit={!memberInfo?.deleted} className="fas fa-pen fa-2x"></StyledIcon>
        </StyledIconContainer>
      );
    } else {
      return (
        <StyledSubmitButtonContainer data-testid="ButtonContainer">
          <StyledCancelButton clear value="Clear" type="reset" data-testid="CancelButton" onClick={cancel}>
            Cancel
          </StyledCancelButton>
          <Button
            data-testid="MemberInfoSubmitButton"
            variant="contained"
            fullWidth
            type="submit"
            disabled={!editing}
            loading={isMemberPending || isNgmPending}
            sx={{ margin: '25px 0' }}
            onClick={handleSubmit(submit)}
          >
            Save
          </Button>
        </StyledSubmitButtonContainer>
      );
    }
  };

  return (
    <>
      <StyledHeaderContainer>
        <div>
          <StyledTextHeader>Member Information </StyledTextHeader>
          <StyledErrorMessage>
            {patchErrored ? <p>Error saving form (be sure that area codes are valid)</p> : null}
            {phoneNumberMissing ? <p>Error saving form (be sure that a valid preferred phone number exists)</p> : null}
            {createDoNotCallErrorMessage ? (
              <p>Error creating Do Not Call indicator. Please refresh the page and try again.</p>
            ) : null}
            {deleteDoNotCallError ? (
              <p>Error deleting Do Not Call indicator. Please refresh the page and try again.</p>
            ) : null}
          </StyledErrorMessage>
        </div>
        {displayHeaderOptions()}
      </StyledHeaderContainer>
      <StyledMemberDataContainer>
        <StyledPaper elevation={3}>
          <form data-testid="MemberDataForm" onSubmit={handleSubmit(submit)} style={{ marginTop: '-10px' }}>
            <StyledDataRowContainer>
              <StyledTextSubHeader>Member Information</StyledTextSubHeader>
              <StyledCheckBoxContainer>
                <Grid container>
                  <CheckboxGridItem item xs={4}>
                    <StyledInputCheckboxStyle
                      {...register('DoNotCall')}
                      type="checkBox"
                      data-testid="DoNotCall"
                      id="DoNotCall"
                      disabled={!editing}
                    />
                    <StyledInputCheckboxLabel htmlFor="DoNotCall">Do Not Call</StyledInputCheckboxLabel>
                  </CheckboxGridItem>

                  <CheckboxGridItem item xs={4}>
                    <StyledInputCheckboxStyle
                      {...register('Declined')}
                      type="checkBox"
                      data-testid="Declined"
                      id="Declined"
                      disabled={!editing}
                    />
                    <StyledInputCheckboxLabel htmlFor="Declined">Declined</StyledInputCheckboxLabel>
                  </CheckboxGridItem>

                  <CheckboxGridItem item xs={4}>
                    <StyledInputCheckboxStyle
                      {...register('Deceased')}
                      type="checkBox"
                      data-testid="Deceased"
                      id="Deceased"
                      disabled={!editing}
                    />
                    <StyledInputCheckboxLabel htmlFor="Deceased">Deceased</StyledInputCheckboxLabel>
                  </CheckboxGridItem>

                  <CheckboxGridItem item xs={4}>
                    <StyledInputCheckboxStyle
                      {...register('IsNonAppUser')}
                      type="checkBox"
                      data-testid="IsNonAppUser"
                      id="IsNonAppUser"
                      disabled={!editing}
                    />
                    <StyledInputCheckboxLabel htmlFor="IsNonAppUser">Non-App User</StyledInputCheckboxLabel>
                  </CheckboxGridItem>

                  <CheckboxGridItem item xs={4}>
                    <StyledInputCheckboxStyle
                      {...register('MemberHighlight')}
                      type="checkBox"
                      data-testid="MemberHighlight"
                      id="MemberHighlight"
                      disabled={!editing}
                    />
                    <StyledInputCheckboxLabel htmlFor="MemberHighlight">Member Highlight</StyledInputCheckboxLabel>
                  </CheckboxGridItem>
                </Grid>
              </StyledCheckBoxContainer>
            </StyledDataRowContainer>
            <StyledDataRowContainer>
              <div>
                <StyledLabel htmlFor="StreetName">Address:</StyledLabel>
                <br />
                <StyledInputStyle
                  {...register('StreetName')}
                  type="text"
                  id="StreetName"
                  data-testid="StreetNameInput"
                  placeholder="Address"
                  defaultValue={memberInfo?.address}
                  disabled={!editing}
                />
              </div>
              <div>
                <StyledLabel htmlFor="City">City:</StyledLabel>
                <br />
                <StyledInputStyle
                  {...register('City')}
                  type="text"
                  id="City"
                  data-testid="CityInput"
                  placeholder={'City'}
                  defaultValue={memberInfo?.city}
                  disabled={!editing}
                />
              </div>
              <StyledLabel htmlFor="State">State:</StyledLabel>
              <br />
              <StyledSelectStyle
                {...register('State')}
                disabled={!editing}
                type="text"
                id="State"
                data-testid="StateInput"
                defaultValue={memberInfo?.state}
              >
                {states.map(({ abbreviation }, i) => (
                  <option key={i} value={abbreviation}>
                    {abbreviation}
                  </option>
                ))}
              </StyledSelectStyle>
              <div>
                <StyledLabel type={'Zip'} htmlFor="Zip">
                  Zip:
                </StyledLabel>
                <br />
                <StyledInputStyle
                  {...register('Zip')}
                  type="text"
                  id="Zip"
                  data-testid="ZipInput"
                  placeholder={'00000'}
                  defaultValue={memberInfo?.zip}
                  disabled={!editing}
                />
              </div>
            </StyledDataRowContainer>
            <StyledDataRowContainer>
              <div>
                <StyledLabel htmlFor="PreferredFirstName">Preferred First Name:</StyledLabel>
                <br />
                <StyledInputStyle
                  {...register('PreferredFirstName')}
                  type="text"
                  id="PreferredFirstName"
                  data-testid="PreferredFirstNameInput"
                  placeholder={'First Name'}
                  defaultValue={memberInfo?.firstName}
                  disabled={!editing}
                />
              </div>

              <div>
                <StyledLabel htmlFor="PreferredLastName">Preferred Last Name:</StyledLabel>
                <br />
                <StyledInputStyle
                  {...register('PreferredLastName')}
                  type="text"
                  id="PreferredLastName"
                  data-testid="PreferredLastNameInput"
                  placeholder={'Last Name'}
                  defaultValue={memberInfo?.lastName}
                  disabled={!editing}
                />
              </div>
              <StyledLabel type={'Language'} htmlFor="State">
                Preferred Language:
              </StyledLabel>
              <StyledSelectStyle
                {...register('Language')}
                disabled={!editing}
                type="text"
                id="Language"
                data-testid="LanguageInput"
                defaultValue={memberInfo?.language}
              >
                <option value="en">English</option>
                <option value="es">Spanish</option>
              </StyledSelectStyle>
            </StyledDataRowContainer>
            <StyledDataRowContainer>
              <MemberPhoneData
                memberInfo={memberInfo}
                editing={editing}
                register={register}
                control={control}
                ngm={ngm}
                errors={errors}
              />
            </StyledDataRowContainer>
            <StyledDataRowContainer>
              <div>
                <StyledNoteLabel htmlFor="Notes">Notes:</StyledNoteLabel>
                <br />
                <StyledTextAreaStyle
                  {...register('Notes')}
                  type="textarea"
                  id="Notes"
                  data-testid="NotesInput"
                  placeholder={'Notes'}
                  defaultValue={memberInfo?.notes}
                  disabled={!editing}
                />
              </div>
            </StyledDataRowContainer>
          </form>
        </StyledPaper>
      </StyledMemberDataContainer>
    </>
  );
};

MemberInfo.propTypes = {
  memberInfo: PropTypes.object,
  ngm: PropTypes.bool,
};

export default MemberInfo;

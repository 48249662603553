import { AnswerTypes } from './enums';

export const questions = [
  {
    name: '1d148bcd-92f2-403f-b532-b2919060f27b',
    text: 'In the last 6 months, how often did you get an appointment for a checkup or routine care as soon as you needed?',
    options: [
      {
        name: 'b258d613-7559-4b30-a701-f55fc5a8b479',
        text: 'Always',
      },
      {
        name: '1649ba14-8fc6-499c-905e-018d4d471f73',
        text: 'Usually',
      },
      {
        name: 'e78611fb-5a12-469e-9113-e371e828874d',
        text: 'Sometimes',
      },
      {
        name: '2308b868-87c5-46fa-a1e3-593e0d2c5ded',
        text: 'Never',
      },
      {
        name: '33257dd5-8ea9-46b4-8563-bb4c78d3a1e0',
        text: 'N/A',
      },
    ],
  },
  {
    name: 'ede6e5c1-43cf-473c-848e-25e5a78bacd4',
    text: '[If Never/Sometimes/Usually] - What made it difficult for you to get a needed appointment in a timely manner?',
    dependencies: [
      '2308b868-87c5-46fa-a1e3-593e0d2c5ded',
      'e78611fb-5a12-469e-9113-e371e828874d',
      '1649ba14-8fc6-499c-905e-018d4d471f73',
    ],
    answerType: AnswerTypes.TEXT,
    options: [
      {
        name: '5a92b840-df79-4d8f-9caf-1d84c0e5fe8a',
        text: 'Free text',
      },
      {
        name: '0f4593bb-6cfe-4467-b812-41f4f1b373ed',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
  {
    name: '4160918f-9826-407d-855d-7798f9384924',
    text: 'In the last 6 months, how often was it easy to get the care, tests or treatment you needed with your Primary Care Physician or Specialist?',
    options: [
      {
        name: 'afd377cd-8dae-4b4d-81d1-88f59f8eae67',
        text: 'Always',
      },
      {
        name: '27d4cb66-5d42-47f3-b95b-3fec60f3f416',
        text: 'Usually',
      },
      {
        name: 'f824fdbd-69c4-46a9-8fbc-78e8c4cf0ea7',
        text: 'Sometimes',
      },
      {
        name: '4c480f78-f52e-4ac0-9ab5-2e44d16c5139',
        text: 'Never',
      },
      {
        name: '172b93f2-d93a-4a36-a224-d1d6470c3070',
        text: 'N/A',
      },
    ],
  },
  {
    name: 'a95d6965-3d4d-4f6c-800c-3f58e4383b5c',
    text: '[If Never/Sometimes/Usually] - What made it difficult for you to get the care, tests or treatment that you needed?',
    dependencies: [
      '4c480f78-f52e-4ac0-9ab5-2e44d16c5139',
      'f824fdbd-69c4-46a9-8fbc-78e8c4cf0ea7',
      '27d4cb66-5d42-47f3-b95b-3fec60f3f416',
    ],
    answerType: AnswerTypes.TEXT,
    options: [
      {
        name: 'c2b5936c-2568-49a8-a5ff-dfb507ff568e',
        text: 'Free text',
      },
      {
        name: 'f0dbbdbb-8a99-4a3f-badb-765812ec8af7',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
  {
    name: '6eb52ddb-44ea-4ccf-abac-4b79418cb234',
    text: 'In the last 6 months, how often was it easy to use your prescription drug plan to get the medicines your doctor prescribed?',
    options: [
      {
        name: '9851228e-2dd3-452b-b38a-dba17a26ae41',
        text: 'Always',
      },
      {
        name: '64a1587b-017c-44c2-afce-4455e8128fbe',
        text: 'Usually',
      },
      {
        name: '8bdca42c-54a4-4a1d-863d-427e29d1a518',
        text: 'Sometimes',
      },
      {
        name: 'a04b0f7c-e551-4076-a93a-447f80458fb9',
        text: 'Never',
      },
      {
        name: '80f150de-a400-46c7-a472-87bdf2d13758',
        text: 'N/A',
      },
    ],
  },
  {
    name: 'f9979ff3-41ea-4e68-b0f7-3dd4dddc291c',
    text: 'In the last 6 months, did you delay or not fill a prescription because you felt you could not afford it?',
    options: [
      {
        name: '5df21e7f-ea4f-45d7-a437-b103b19523c1',
        text: 'Yes',
      },
      {
        name: 'e526e8db-7e67-4915-b956-fd4170c2b70a',
        text: 'No',
      },
    ],
  },
  {
    name: '3dcbcc09-594b-4148-b141-c030ac455570',
    text: '[If Yes] - What made it difficult for you to get the prescribed medications that you needed?',
    dependencies: ['5df21e7f-ea4f-45d7-a437-b103b19523c1'],
    answerType: AnswerTypes.TEXT,
    options: [
      {
        name: 'ee2845c0-c6c7-4658-a28d-15e40fc27a08',
        text: 'Free text',
      },
      {
        name: '5bc186fc-3b6c-46af-b986-83b71dda41a4',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
  {
    name: '46f41b2e-9265-4506-aaec-56eefc6a2b70',
    text: 'What challenges (if any) have you been facing with your primary care physician and/or specialists?',
    answerType: AnswerTypes.CHECKBOX,
    options: [
      {
        name: 'd9ea2618-a4f6-406b-8c90-05bdd858d263',
        text: 'Needs new PCP/Specialist',
      },
      {
        name: '58025b2c-c8fa-43a0-8c0b-503d5b15c8e4',
        text: 'Not satisfied with current PCP/Specialist',
      },
      {
        name: 'bacccc1e-0b4e-4fe3-9494-f85b9dd513ac',
        text: 'Unable/Difficult to get appointments with PCP/Specialist',
      },
      {
        name: '6f40c4e2-fff7-4d12-888f-fc2e07958dee',
        text: 'Needs transportation to appointments',
      },
      {
        name: 'c7d67227-8e6b-4bc9-b744-24a0229d72f8',
        text: 'Cost of appointments',
      },
      {
        name: '20c3f3f7-9f08-4533-880a-aea289ddb8eb',
        text: 'Not getting enough out of appointments',
      },
      {
        name: '9735169a-4d2c-4aef-b723-1f70da8d14e9',
        text: 'Missing regular PCP appointments',
      },
      {
        name: 'a0b6f3c3-539b-4766-9dc4-05b73c99135a',
        text: 'Needs a dentist',
      },
      {
        name: '9478016b-3644-4cc7-9c4f-5979e6799e2a',
        text: 'Needs a vision specialist',
      },
      {
        name: '7a5562c2-2e89-466c-a7d7-88b5ddc95f7d',
        text: 'Not an issue',
      },
    ],
  },
  {
    name: 'bfc7b669-076a-4832-8ea1-4321f062666b',
    text: 'Is there anything getting in the way of you taking your medications as prescribed?',
    answerType: AnswerTypes.CHECKBOX,
    options: [
      {
        name: '46b466ac-3f2e-4c71-ac34-1b2ae149f737',
        text: 'Out or almost out of medication',
      },
      {
        name: '51423569-3e1d-4772-b53a-6c3421e70142',
        text: 'Needs medication refill',
      },
      {
        name: '22610f33-e580-4b25-8b96-a7833b1545f9',
        text: 'Experiencing side effects',
      },
      {
        name: '219ecad8-43ea-4c32-a976-8560c8548fa0',
        text: 'Cost of medication',
      },
      {
        name: 'b1db471b-5c70-4210-95c5-7653c7405fc8',
        text: 'Forgetting to take medication',
      },
      {
        name: '22672dc0-42ef-4229-a391-15860f07acc7',
        text: 'Doesn’t feel the need to take medications',
      },
      {
        name: '5faf6d74-cd64-444a-9f14-f62a38c7e580',
        text: 'Medications Not Working',
      },
      {
        name: '30ed368f-e2da-4826-8cdd-dee7f821b9d4',
        text: 'Not an issue',
      },
    ],
  },
  {
    name: '1755ca62-8f4a-445c-b747-d9e527422a43',
    text: 'Do you have any issues with insurance coverage?',
    answerType: AnswerTypes.CHECKBOX,
    options: [
      {
        name: 'ee0fd593-fa43-472d-bb18-52b5c7660127',
        text: 'No insurance /uninsured or coverage ending within 30 days',
      },
      {
        name: 'dee9017f-a0a9-48c5-a649-ab9df2311379',
        text: 'Needs to renew insurance coverage/plan',
      },
      {
        name: '39e05202-1859-4b23-a8ac-8b0a3087f057',
        text: 'Needs additional health insurance coverage (health, dental, vision)',
      },
      {
        name: '6baa4724-0c2d-4e6b-ae41-6a51199bf4ff',
        text: 'Cost of procedure/test too high or is not covered',
      },
      {
        name: '2ec4e502-cbff-47ec-a75f-0d78a99908e7',
        text: 'Needs help understanding benefits',
      },
      {
        name: '13f9c703-4e4a-4e47-8bf6-6fb77fffb050',
        text: 'Cost of appointments is too high',
      },
      {
        name: 'aa166e71-1dce-4031-bc16-69486c6edf5f',
        text: 'Cost of medications is too high',
      },
      {
        name: '428b97cc-dad7-49b2-a413-a9bc87b3f6e2',
        text: 'No issues with insurance',
      },
    ],
  },
  {
    name: 'a1a9829b-5034-4f69-943c-173242e49da1',
    text: 'Do you routinely need assistance in the home with making meals, bathing, dressing, or reminders to take medications?',
    options: [
      {
        name: '9cdbaa85-a16f-4489-9fd2-e7cbc368543b',
        text: 'Yes',
      },
      {
        name: '39d9e642-0b7c-4ed3-999e-1e42199013cc',
        text: 'No',
      },
    ],
  },
  {
    name: 'f166e146-3427-4af5-bf39-cf8cbf75e397',
    text: 'Do you currently receive any help from friends, family, or an in-home service provider to complete these tasks?',
    options: [
      {
        name: '8510fca4-5bf2-4d91-8581-29d9429ba039',
        text: 'Yes',
      },
      {
        name: 'a62c8422-886a-409e-aeda-a09eea1c1761',
        text: 'No',
      },
    ],
  },
  {
    name: '368b9d38-fc94-4c34-bd4e-86bb74e6f301',
    text: '[If Yes] - Is the care you are receiving meeting your level of needs?',
    dependencies: ['8510fca4-5bf2-4d91-8581-29d9429ba039'],
    options: [
      {
        name: 'c6653a88-572f-4b8f-a30a-47411f5c0fee',
        text: 'Yes',
      },
      {
        name: '5210dc74-1e8c-49f0-bfc8-dbce4cf00be9',
        text: 'No',
      },
    ],
  },
  {
    name: '1c0af857-29fe-43c8-aab1-c7fc1c3b72ba',
    text: '[If No] - Do you need help applying for in-home services?',
    dependencies: ['a62c8422-886a-409e-aeda-a09eea1c1761'],
    options: [
      {
        name: 'b3087942-6fb6-4496-8ae4-53a5d52b63a6',
        text: 'Yes',
      },
      {
        name: '5fea19c2-f555-4134-a516-7e11c0131eaf',
        text: 'No',
      },
    ],
  },
  {
    name: '6c9e8f52-54f2-40ce-ac10-d8754f1c93c0',
    text: 'Are you in need of any durable medical equipment or medical supplies that you have been unable to obtain?',
    options: [
      {
        name: '76b7598e-b163-4dbf-a5d9-4e86276a15dd',
        text: 'Yes',
      },
      {
        name: '26942033-3d45-4fe7-896d-0af37eaaaedd',
        text: 'No',
      },
    ],
  },
  {
    name: '37839b1d-f6b0-4ac2-a8fd-09ab4d1b5974',
    text: '[If Yes] - What type of equipment or supplies are you needing help obtaining?',
    dependencies: ['76b7598e-b163-4dbf-a5d9-4e86276a15dd'],
    answerType: AnswerTypes.CHECKBOX,
    options: [
      {
        name: '1c0c1a0b-9e8c-4189-88e2-530f8557ef74',
        text: 'Walking Aides-(Cane, walker, scooter, rollator, wheelchair, etc.)',
      },
      {
        name: '31e85174-f79b-4e87-9e4b-515505c48c3e',
        text: 'Shower/Bathroom safety-(shower chair, raised toilet seat, grab bars, etc.)',
      },
      {
        name: 'fe053fe9-c224-4cb0-924c-5a5526e34e83',
        text: 'Incontinence supplies (chux pads, pull-ups, bed pans, urinals, etc.)',
      },
      {
        name: 'c4b8eb59-70a9-4d3f-a21b-57c087fa7e11',
        text: 'Hearing aids/batteries',
      },
      {
        name: 'b1c275bf-c863-4e45-8330-6b9c9fc76ed6',
        text: 'Other hearing or sight enhancers-(magnifier, prescription label reader, etc.)',
      },
      {
        name: '2bd1fe93-4a1b-4d1f-97e8-cb6866e2f58d',
        text: 'Enteral feeding/nutrition (nutritional supplements, feeding tube supplies, etc)',
      },
      {
        name: 'e2e10fb5-a039-4d00-95c0-6f8785b60e8c',
        text: 'Post-surgery/Wound care supplies (alcohol wipes, gauze, adhesive tape, etc.)',
      },
      {
        name: '21844ae7-8e48-4eac-9167-eb3403ad13ac',
        text: 'Ostomy supplies (care for a surgical opening made to collect stool or urine)',
      },
      {
        name: '8d67ebe8-c8df-4a83-946c-3b0f537a55ed',
        text: 'Support goods (compression socks, supports for injuries, braces, etc.)',
      },
      {
        name: 'ed6dcabf-42dc-4e4a-8cbb-0e346dc46679',
        text: 'Asthma/COPD related supplies (inhaler, nebulizer, etc.)',
      },
      {
        name: '7062cb4b-7c7d-4663-871b-806aeeff50b5',
        text: 'Respiratory/tracheostomy care (treatment for breathing conditions, breathing tubes)',
      },
      {
        name: '3b25524d-843f-42a2-94c8-6bba0ee67436',
        text: 'Equipment repairs/replacement parts needed',
      },
      {
        name: '7261f487-fde9-4e7f-935d-3c3978e7e7df',
        text: 'Diabetic blood sugar meters/test strips',
      },
      {
        name: 'e2b4414a-de87-4987-ad1c-4b47b82d4262',
        text: 'Blood pressure monitor',
      },
      {
        name: 'b4655194-58c1-4cbd-97d2-b1a3fbf48e81',
        text: 'Other',
      },
      {
        name: '11792300-a24f-4317-87b3-5e5919379556',
        answerType: AnswerTypes.SKIP,
        text: 'Skip',
      },
    ],
  },
  {
    name: 'e8d7ed86-ae14-4a6d-9753-3bf66ee63ed8',
    text: 'In the last year have you ever had to worry that your food will run out before you were able to buy more?',
    options: [
      {
        name: 'f29e15d2-1212-4a91-bc0e-3e644c0eae88',
        text: 'Yes',
      },
      {
        name: 'f5ff534d-c5d5-43ef-ad23-aacf329eb92e',
        text: 'No',
      },
    ],
  },
  {
    name: 'bd93542e-e835-4ed6-9d93-cd2808d61e8c',
    text: 'Do you have any of the following illness, injuries or chronic conditions?',
    answerType: AnswerTypes.CHECKBOX,
    options: [
      {
        name: 'f7f5429e-1c4e-4c11-91ff-53ea5502c512',
        text: 'Diabetes',
      },
      {
        name: '5b4f8c39-1de9-473d-91c5-acae41c95d53',
        text: 'Hypertension',
      },
      {
        name: '49da1a63-fe3e-4a21-a925-21e8bdbf2b93',
        text: 'Asthma',
      },
      {
        name: 'b1ac3b5c-a385-4825-b457-6a3829b7b968',
        text: 'Child with Asthma',
      },
      {
        name: '71f672b5-c56b-408b-9894-454f1be1db58',
        text: 'Lyme Disease',
      },
      {
        name: '6757c8ac-2e15-4569-ad37-db8d71c169d0',
        text: "Crohn's/Colitis",
      },
      {
        name: 'e525d525-e0b8-4d83-a418-18e19e4ec6c1',
        text: 'Kidney Disease',
      },
      {
        name: '57875706-c122-409c-91e9-38580f95d59c',
        text: 'Prediabetes',
      },
      {
        name: '0fb5774d-96a9-4409-b5b1-1edc3419c9e6',
        text: 'Cancer/history of cancer',
      },
      {
        name: '68787f3b-ba68-41b4-be2e-e6b01e329ff1',
        text: 'Mental health concern (major depression, schizophrenia, obsessive-compulsive disorder, or severe anxiety)',
      },
      {
        name: '8aedbe4a-52d4-484b-92b2-e9b7c69a030c',
        text: 'H.I.V./AIDS',
      },
      {
        name: '99e0376c-cd46-47b4-a7e5-37cf0023df1e',
        text: 'Lupus',
      },
      {
        name: '36305148-bea0-4796-835d-4b8015ca5138',
        text: 'Sickle cell disease',
      },
      {
        name: '23ec7812-cf11-4632-b34b-f376f5aa47bb',
        text: 'Low vision or legal blindness',
      },
      {
        name: '027f7f92-ae57-4945-a924-8c8b2076c56e',
        text: 'Memory problems',
      },
      {
        name: '62cb75a1-9e33-48d0-9e91-e02060fb8ba6',
        text: 'Arthritis',
      },
      {
        name: 'fbe172fb-669c-4e1e-8cca-eb7d831d4698',
        text: 'Osteoporosis/low bones density/rheumatoid arthritis',
      },
      {
        name: '05a67ee3-00bb-4f18-a915-7e742de26a44',
        text: 'Incontinence (accidental leaking of urine or stool)',
      },
      {
        name: 'aff02aa1-919d-40cb-bdcf-83afb128b132',
        text: 'None of the above',
      },
    ],
  },
];

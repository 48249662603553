
export enum InformationTypes {
  MEMBER = 'member',
  ELIGIBILITY = 'eligibility',
  CODE = 'code',
  HIFRIEND = 'hifriend',
}

export enum MigrationStatusType {
  PENDING,
  FAILED
}
 
export enum ProfileMigrationType {
    NEWPROFILE,
    MOVEPROFILE
};

export enum DataMigrationType {
    NONE,
    MOVEDATA
}

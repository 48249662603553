import styled from 'styled-components';

export const RadioWrapper = styled.div`
  display: inline-block;

  &.pad-top-five {
    padding-top: 2px;
  }
`;

export const Mark = styled.span`
  display: inline-block;
  border: 1px solid #e9e7e7;
  width: 14px;
  height: 14px;
  left: 0;
  border-radius: 50%;
  margin-right: 5px;
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: #0f145b;
    opacity: 0;
    transition: all 110ms;
    margin: 15%;
  }
`;

export const Input = styled.input`
  position: absolute;
  visibility: hidden;
  display: none;
  &:checked + ${Mark} {
    &::after {
      width: 10px;
      height: 10px;
      opacity: 1;
      left: 12%;
      top: 12%;
    }
  }

  &.pad-top-five {
    &:checked + ${Mark} {
      &::after {
        top: 15%;
      }
    }
  }
`;

export const Label = styled.label`
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  font-size: 14px;
  font-family: lato;
  padding: 5px 20px 5px 0;
  color: #0f145b;
  > .break {
    width: 100%;
    padding-left: 22px;
    box-sizing: border-box;
  }

  &.not-other {
    width: 112px;
  }
`;

import styled from 'styled-components';
import { TableContainer } from '@mui/material';

export const StyledTableContainer = styled(TableContainer)`
  margin: -8px 0 20px;
  width: auto;

  th {
    font-weight: bold;
    white-space: nowrap;
  }
`;

export const StyledActionsContainer = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

export const StyledProgressContainer = styled.span`
  display: flex;
  justify-content: center;
  padding: 100px;
`;

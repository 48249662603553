import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import Button from 'Components/Button';

export const NewMigrationFormContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 90%;
  margin: 0 auto;
  margin-top: 40px;
  box-shadow: rgba(0, 0, 0, 0.25) 1px 2px 11px 0px;

  h1 {
    background-color: rgb(1, 83, 83);
    padding: 15px;
    color: white;
  }
`;

export const DestinationContainer = styled(Grid)`
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin-left: 20px;
`;

export const ContentContainer = styled(Grid)`
  padding: 20px;
  row-gap: 40px;
  margin-top: 35px;
`;

export const Footer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: right;
  column-gap: 20px;
  padding: 15px;

  .MuiButton-root {
    width: 150px;
    height: 35px;
  }
`;

export const MemberTypeChooseButton = styled(Button)`
  height: 30px;
`;

export const DetectedMigrationTypesContainer = styled.div`
  row-gap: 15px;
  display: flex;
  flex-flow: wrap;
  margin-top: 25px;
`;

export const DetectedMigrationTypeItem = styled(Grid)`
  text-align: right;
  padding-right: 30px;
`;

export const ErrorMessage = styled.div`
  color: red;
  font-family: Karla;
  padding: 20px;
`;

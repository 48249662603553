import styled from 'styled-components';
import { Dialog, TextField, DialogActions, Button } from '@mui/material';

export const DropDownContainer = styled.div`
  width: fit-content;
  height: auto;
  background-color: white;
  color: black;
  text-align: left;
  position: absolute;
  padding-left: 5px;
  padding-bottom: 10px;
  padding-right: 5px;
  box-shadow: 1px 2px 11px 0px rgba(0, 0, 0, 0.25);
  right: ${(props) => (props.user ? '85px' : '175px')};
  z-index: 1;
`;

export const Arrow = styled.div`
  position: absolute;
  right: ${(props) => (props.user ? '1px' : '80px')};
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid white;
`;

export const MoneyDropDownTitle = styled.div`
  font-family: Karla;
  font-size: 20px;
  font-weight: bold;
  color: #494e83;
  margin-right: 20px;
  margin-top: 17.5px;
`;

export const MoneyDropDownAmount = styled.div`
  font-family: Karla;
  font-size: 20px;
  font-weight: bold;
  color: #494e83;
  margin-right: 35px;
  margin-top: 7.5px;
  font-size: 28px;
`;

export const MoneyDropDownDate = styled.div`
  font-family: Lato;
  font-size: 14px;
  font-weight: 300;
  color: #606060;
  margin-right: 40px;
  margin-top: 7.5px;
  font-size: 14px;
`;

export const UserDropDownOption = styled.div`
  font-family: Karla;
  font-size: 18px;
  font-weight: bold;
  color: #484d87;
  margin-right: 120px;
  margin-top: 10px;
  cursor: pointer;
`;

export const UserDropDownInfoContainer = styled.div`
  display: flex;
`;

export const UserDropDownInfoColumn = styled.div`
  display: flex;
  margin-left: 15px;
  flex-direction: column;
  margin-top: 10px;
`;

export const UserDropDownInfoTitle = styled.div`
  font-family: Karla;
  font-size: 18px;
  font-weight: bold;
`;

export const UserDropDownInfoText = styled.div`
  font-family: Karla;
  font-size: 13px;
  margin-top: 3px;
`;

export const Divider = styled.div`
  background-color: #f2f2f2;
  height: 2px;
  width: 100%;
  margin-top: 5px;
`;

export const UserDropDownDefaultIcon = styled.i`
  height: 45px;
  width: 45px;
  display: flex;
  border-radius: 50px;
  border: 2px solid #d9d9d9;
  margin-top: 10px;
  align-items: flex-end;
  justify-content: center;
  font-size: 40px;
  color: #b3b6ba;
  background-color: #d9d9d9;
  overflow: hidden;
`;

export const StyledDialog = styled(Dialog)`
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiDialog-container {
    width: 100%;
  }

  .MuiPaper-root {
    height: 20%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledDateTimePicker = styled(TextField)`
  margin: 8px;
`;

export const StyledButtonContainer = styled(DialogActions)`
  padding-right: 12px;
  width: 100%;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  height: 36px;

  .MuiButton-label {
    line-height: 12px;
  }
`;

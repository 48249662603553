import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import urlcat from 'urlcat';
import { useThriveState } from 'Tasking_Contexts/ThriveContext';
import { formatDate } from 'Tasking_Helpers/formatDate';
import useMemberInfo from 'Tasking_Hooks/useMemberInfo';
import useThriveAssessmentScores from 'Tasking_Hooks/useThriveAssessmentScores';
import { Button, CircularProgress } from '@mui/material';
import ScoreOverviewTable from './ScoreOverviewTable';
import ScoreDetailTable from './ScoreDetailTable';
import { StyledActionsContainer, StyledProgressContainer } from './styles';

const ScreeningScore = () => {
  const [showDetailedScore, setShowDetailedScore] = useState(false);

  const { userId } = useParams();
  const thriveState = useThriveState();
  const { id: thriveAccessId } = thriveState.thriveStatus;
  const { response: scoresResult, isLoading } = useThriveAssessmentScores(userId, thriveAccessId);
  const { memberInfo } = useMemberInfo(userId);

  const mapScores = (dimension) => {
    function getDimensionScore(scoreResult) {
      let score;
      if (dimension === 'overall') {
        score =
          [
            scoreResult.emotionalScore,
            scoreResult.intellectualScore,
            scoreResult.physicalScore,
            scoreResult.socialScore,
          ].reduce((arr, curr) => arr + curr) / 4;
      } else {
        score = scoreResult[`${dimension}Score`];
      }
      return Math.round(score);
    }

    return scoresResult.map((r) => ({ term: r.term, score: getDimensionScore(r), date: formatDate(r.createdOnUtc) }));
  };
  const getTermScoreId = (term) => scoresResult.find((r) => r.term === term)?.id;

  function toggleDetailView() {
    setShowDetailedScore((prevState) => !prevState);
  }

  function openScreeningHistoryTab() {
    const path = 'user/:userId/history';
    const target = urlcat(process.env.BASE_URL, path, { userId: userId, filter: 'screening' });

    window.open(target);
  }

  if (isLoading) {
    return (
      <StyledProgressContainer>
        <CircularProgress size={100} />
      </StyledProgressContainer>
    );
  }

  if (showDetailedScore) {
    return (
      <div data-testid="detail-view">
        <ScoreDetailTable
          orgId={memberInfo.activeOrganizationId}
          week1ScoreId={getTermScoreId('WEEK1')}
          week8ScoreId={getTermScoreId('WEEK8')}
        />
        <StyledActionsContainer>
          <Button variant="contained" color="primary" onClick={toggleDetailView}>
            Back
          </Button>
        </StyledActionsContainer>
      </div>
    );
  }

  return (
    <div data-testid="overview-view">
      <section>
        <h2>Overall Screening Weighted Average Score</h2>
        <ScoreOverviewTable name="overall" scores={mapScores('overall')} />
      </section>
      <section>
        <h2>Social Dimension Score</h2>
        <ScoreOverviewTable name="social" scores={mapScores('social')} />
      </section>
      <section>
        <h2>Emotional Dimension Score</h2>
        <ScoreOverviewTable name="emotional" scores={mapScores('emotional')} />
      </section>
      <section>
        <h2>Intellectual Dimension Score</h2>
        <ScoreOverviewTable name="intellectual" scores={mapScores('intellectual')} />
      </section>
      <section>
        <h2>Physical Dimension Score</h2>
        <ScoreOverviewTable name="physical" scores={mapScores('physical')} />
      </section>
      <StyledActionsContainer>
        <Button variant="contained" color="primary" onClick={toggleDetailView}>
          View Detailed Scores by Question
        </Button>
        <Button variant="contained" color="primary" onClick={openScreeningHistoryTab}>
          View History of Other Screenings
        </Button>
      </StyledActionsContainer>
    </div>
  );
};

export default ScreeningScore;

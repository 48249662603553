import { ApiService } from 'Administrator_Helpers/ApiService';

export class NgmsApiDataService {
  private static _instance: NgmsApiDataService = new NgmsApiDataService();
  private api: ApiService;

  constructor() {
    // Make sure we don't create more than one instance of this service
    if (NgmsApiDataService._instance) {
      return NgmsApiDataService._instance;
    }
    this.api = new ApiService();
  }

  public getNgm = async (request: { organizationId: number, ngmId: number }) => {
    const { response, error } = await this.api.get(`/v5/ngms/${request.organizationId}:${request.ngmId}`, null);
    return { response, error };
  };

  public searchNgms = async (request: { birthDay: string, phoneNumber: string, firstName: string, lastName: string, organizationId: number }) => {
    const { response, error } = await this.api.post(`/v5/ngms/search`, null, request);
    return { response, error };
  }
}

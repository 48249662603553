import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';

const useCreateMemberConnection = () => {
  const url = `${process.env.API_BASE_URL}/v1/memberconnections/`;
  const [request, setRequest] = useState();
  const { response, isLoading, hasErrored, errorData } = useAxiosSubmit(url, request, 0, 1);

  const createMemberConnection = async (request) => setRequest(request);

  return { response, hasErrored, isLoading, createMemberConnectionError: errorData, createMemberConnection };
};

export default useCreateMemberConnection;

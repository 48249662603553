import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import useSWRImmutable from 'swr/immutable';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

async function fetcher(url, token) {
  const authorizedApiClient = await getAuthClient();
  if (!url) return;
  if (authorizedApiClient) {
    return await authorizedApiClient.get(url, { cancelToken: token }).catch(function (thrown) {
      if (!axios.isCancel(thrown)) {
        throw thrown;
      }
    });
  }
}

const useAxiosSwrFetch = ({ swrOptions = {}, fetchRequired = true } = {}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState();
  const [url, setUrl] = useState();

  const source = useMemo(() => axios.CancelToken.source(), []);

  const { data, error, isValidating, mutate } = useSWRImmutable([url, source.token], fetcher, {
    ...swrOptions,
  });

  async function fetch(url) {
    setUrl(url);
    await mutate(response);
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (data) {
        setResponse(data.data);
      }

      const validResponse = !!data || !fetchRequired;
      setIsLoading(!validResponse && !error);
    }

    return () => {
      source.cancel();
      isMounted = false;
    };
  }, [data, error]);

  return { response, error, isLoading: isLoading || isValidating, fetch };
};

export default useAxiosSwrFetch;

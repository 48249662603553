import styled from 'styled-components';

export const NavBarContainer = styled.div`
  background-color: #f2f2f2;
  display: flex;
  height: 77px;
  align-items: center;
  margin-top: -10px;
`;

export const Left = styled.div`
  flex: 1;
  margin-left: 85px;
`;
export const Right = styled.div`
  flex: 1;
  text-align: right;
`;

export const IconContainer = styled.i`
  color: #494e83;
  color: ${(props) => (props.picked ? '#62A942' : '#494e83')};
  margin: 25px;
  cursor: pointer;
  margin-right: ${(props) => (props.user ? '75px' : '')};
  padding-top: ${(props) => (props.home ? '10px' : '')};
  position: relative;
  font-size: ${(props) => (props.home ? '40px' : '35px')};
`;

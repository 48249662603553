import { useEffect, useState } from 'react';
import useSWR from 'swr';
import urlcat from 'urlcat';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

const requestPath = 'v1/PositivePsychology/:userId';

const retrievePsychology = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

function useGetPositivePsychology() {
  const [isPending, setIsPending] = useState(true);
  const [positivePsychology, setPositivePsychology] = useState();
  const [url, setUrl] = useState();

  const {
    data: response,
    error,
    mutate,
  } = useSWR(() => url, retrievePsychology, {
    revalidateOnFocus: false,
  });

  const getPositivePsychology = (userId) => {
    setUrl(urlcat(process.env.API_BASE_URL, requestPath, { userId }));
    mutate(response);
  };

  useEffect(() => {
    if (response) {
      setPositivePsychology(response.data);
    }

    setIsPending(!response && !error);
  }, [response, error]);

  return { positivePsychology, error, isPending, getPositivePsychology };
}

export default useGetPositivePsychology;

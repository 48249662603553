import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Tasking_Helpers/getAuthClient';
import urlcat from 'urlcat';

const getCardUrl = 'v1/cards/:cardId';
const options = {
  revalidateOnFocus: false,
};

const getCard = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useGetCard = (requestData) => {
  const [isPending, setIsPending] = useState(true);
  const [cardResult, setCardResult] = useState();
  const { data: cardResultData, error } = useSWR(
    () => urlcat(process.env.API_BASE_URL, getCardUrl, { cardId: requestData }),
    getCard,
    {
      ...options,
    },
  );

  useEffect(() => {
    if (cardResultData) {
      setCardResult(cardResultData.data);
    }

    setIsPending(!cardResultData && !error);
  }, [cardResultData, error]);

  return { card: cardResult, getCardError: error, isGetCardPending: isPending };
};

export default useGetCard;

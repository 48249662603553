import styled from '@emotion/styled';
import { Grid, Skeleton } from '@mui/material';

export const NoResultsFound = styled.div`
  color: #c80000;
  font-weight: 700;
  font-size: 30px;
  margin: 0 auto;
  padding: 200px;
`;

export const DataGridTitle = styled(Grid)`
  font-weight: 700;
  border-bottom: 1px solid black;
  padding: 10px 0;
  background-color: rgb(242, 242, 242);
  color: rgb(1, 83, 83);
`;

export const LoadingContainer = styled(Skeleton)`
  width: 100%;
  border-bottom: 5px solid white;
`;

import React from 'react';
import { AnswerTypes } from '../../enums';
import { answerMap } from './answerTypes';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

const RequiredFormAnswer = ({ name, control, answerType, options, ...props }) => {
  const Component = answerMap[answerType];

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={''}
      render={({ field }) => {
        return <Component {...field} options={options} {...props} />;
      }}
    />
  );
};

RequiredFormAnswer.displayName = 'RequiredFormAnswer';

RequiredFormAnswer.propTypes = {
  answerType: PropTypes.oneOf(Object.values(AnswerTypes)),
  control: PropTypes.object.isRequired,
  name: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default RequiredFormAnswer;

import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';
import urlcat from 'urlcat';

const requestPath = 'v1/PositivePsychology/:userId';

const useUpdatePsychologyContent = () => {
  const [updatePsychologyContentData, setUpdatePsychologyContentData] = useState();
  const [url, setUrl] = useState();
  const { response, isLoading, hasErrored, errorData } = useAxiosSubmit(url, updatePsychologyContentData);

  const updatePsychologyContent = (request, userId) => {
    setUrl(urlcat(process.env.API_BASE_URL, requestPath, { userId }));
    setUpdatePsychologyContentData(request);
  };

  return {
    updateResponse: response,
    updateContentErrorMessage: errorData,
    updateContentError: hasErrored,
    isUpdatePending: isLoading,
    updatePsychologyContent,
  };
};

export default useUpdatePsychologyContent;

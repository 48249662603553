import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

const metaUrl = 'v1/usermeta';
const options = {
  revalidateOnFocus: false,
};

const getUserMeta = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useUserMeta = (userId) => {
  const [isPending, setIsPending] = useState(true);
  const [userMeta, setUserMeta] = useState();
  const { data: metaResult, error } = useSWR(() => `${process.env.API_BASE_URL}/${metaUrl}/${userId}`, getUserMeta, {
    ...options,
  });

  useEffect(() => {
    if (metaResult) {
      setUserMeta(metaResult.data);
    }

    setIsPending(!metaResult && !error);
  }, [metaResult, error]);

  return { userMeta, error, isPending };
};

export default useUserMeta;

//backend api response returns YYYYMMDD, change to MMDDYYYY
export const formatApiDate = (date) => {
  if (!date) {
    return null;
  }

  const year = date.substring(0, 4);
  const month = date.substring(5, 7);
  const day = date.substring(8, 10);
  return `${month}/${day}/${year}`;
};
// Supports MMDDYYYY or dates separated with /
export const birthDayReformat = (date) => {
  if (date.includes('/')) {
    return date;
  }
  function addStr(str, index, stringToAdd) {
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  }
  if (date.length === 8) {
    let modifyDate = addStr(date, 2, '/');
    modifyDate = addStr(modifyDate, 5, '/');
    return modifyDate;
  }

  return null;
};

export const convertLocalDateToUtcStr = (localizedTime) => {
  if (!localizedTime) return localizedTime;
  const os = new Date().getTimezoneOffset();
  const converted = new Date(new Date(localizedTime) + os * 60 * 1000);
  return converted.toISOString();
};

export const convertUtcDateToLocalStr = (utcTime) => {
  if (!utcTime) return utcTime;
  const os = new Date().getTimezoneOffset();
  const converted = new Date(new Date(utcTime) - os * 60 * 1000);
  return converted.toISOString();
};

export const removeTimeZoneStr = (zonedTimeStr) => {
  return zonedTimeStr?.replace(/Z$/, '');
};

export const calculateAge = (dob) => {
  if (!dob) {
    return;
  }
  const diff_ms = Date.now() - new Date(dob).getTime();
  const age_dt = new Date(diff_ms);
  return Math.abs(age_dt.getUTCFullYear() - 1970);
};

import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  TextHeader,
  CreateTaskDataContainer,
  DataColumn,
  SectionHeader,
  HeaderContainer,
  TextAreaStyle,
  SubmitButtonContainer,
  SubmitButton,
  CancelButton,
  CrumbBar,
  CrumbBarData,
  SpinnerButton,
  ErrorMessage,
  InfoColumn,
  InfoContainer,
  InfoHeading,
  InfoText,
  InfoGroup,
  SelectStyle,
  CallContainer,
} from './styles';

import { ActionsContext } from 'Tasking_Contexts/ActionsContext';
import { MainIconBarContext } from 'Tasking_Contexts/MainIconBarContext';
import { parseErrorMessage } from 'Helpers/parseApiErrorMessage';

import ActivityViewOnlyText from './ActivityViewOnlyText';
import Radio from 'Tasking_Components/Radio/Radio';

import Modal from 'Tasking_Components/AbandonActivityModal/Modal';
import useTaskDetails from 'Tasking_Hooks/useTaskDetails';
import useCompleteActivity from 'Tasking_Hooks/useCompleteActivity';
import ActivityTabs from './Tabs/ActivityTabs';
import Spinner from 'Components/Spinner/Spinner';

import { Controller, useForm } from 'react-hook-form';
import { formatDate } from 'Tasking_Helpers/formatDate';
import { ActivitySelectedContext } from 'Tasking_Contexts/ActivitySelectedContext';
import { useNavigate, useParams } from 'react-router-dom';
import CallLog from 'Tasking_Components/CallLog/CallLog';
import { getDisplayableActions } from './activityConfig';
import { activityTypes } from './Tabs/ActivityTabOptions';
import { ENROLLED_TASKS_EDIT, NONENROLLED_TASKS_EDIT } from '../../../initialization/Routes/RoutesConfig';
import urlcat from 'urlcat';
import PeersCallButton from 'Tasking_Components/PeersCall/PeersCallButton';

const contactOutcomesTransform = {
  UnableToSpeakToMember: 'Unable to Speak to Member',
  SpokeToMember: 'Spoke to Member',
};

const AddActivity = ({
  setViewActivity,
  viewActivity,
  taskSaved,
  memberTaskInfo,
  memberInfo,
  ngm,
  savedTaskData,
  enums,
  thriveStatus,
  activitySelected,
  setSelectedTask,
}) => {
  const navigate = useNavigate();
  const { userId, ngmId, taskGuid, orgId } = useParams();
  const [actionToDisplay, setActionToDisplay] = useState();
  const [currentTab, setCurrentTab] = useState();
  const [modal, setModal] = useState(false);
  const { setShowMainIcons } = useContext(MainIconBarContext);
  const { actions, setActions } = useContext(ActionsContext);
  const { selectedActivity, setSelectedActivity } = useContext(ActivitySelectedContext);
  const { completedActivity, isCompleteActivityPending, completeActivity, errorMessage } = useCompleteActivity();
  const { taskDetail, retrieveTask } = ngm
    ? useTaskDetails(memberTaskInfo?.organizationId, taskSaved ? taskSaved : savedTaskData?.taskGuid)
    : useTaskDetails(memberTaskInfo?.activeOrganizationId, taskSaved ? taskSaved : savedTaskData?.taskGuid);
  const organizationId = ngm ? memberTaskInfo?.organizationId : memberTaskInfo?.activeOrganizationId;
  const { register, handleSubmit, control } = useForm();
  const [customError, setCustomError] = useState();
  const [callLogInfo, setCallLogInfo] = useState();

  const notesEdit = (e) => {
    setSelectedActivity({ ...selectedActivity, notes: e.target.value });
  };

  const needFollowupEdit = (e) => {
    setSelectedActivity({ ...selectedActivity, needFollowup: e.target.value });
  };

  const contactOutcomeEdit = (e) => {
    setSelectedActivity({ ...selectedActivity, contactOutcomeOption: undefined, contactOutcome: e.target.value });
  };

  const contactOutcomeOptionEdit = (actionType) => {
    setSelectedActivity({ ...selectedActivity, contactOutcomeOption: actionType.code });
  };

  const actionsEdit = (actionType) => {
    let actionTypes = selectedActivity?.actionTypes ? selectedActivity?.actionTypes : [];
    let index = actionTypes.indexOf(actionType.code);

    if (index >= 0) {
      actionTypes.splice(index, 1);
    } else {
      actionTypes.push(actionType.code);
    }
    setSelectedActivity({ ...selectedActivity, actionTypes: actionTypes });
  };

  const parseError = (errorMessage) => {
    if (errorMessage.length > 0) {
      return parseErrorMessage(errorMessage);
    }
  };

  useEffect(() => {
    if (actionToDisplay !== selectedActivity?.actionToDisplay || currentTab !== selectedActivity?.currentTab) {
      setSelectedActivity({ ...selectedActivity, currentTab: currentTab, actionToDisplay: actionToDisplay });
    }
  }, [actionToDisplay, currentTab]);

  useEffect(() => {
    if (selectedActivity) {
      setActionToDisplay(selectedActivity?.actionToDisplay ? selectedActivity?.actionToDisplay : actionToDisplay);
      setCurrentTab(selectedActivity?.currentTab ? selectedActivity?.currentTab : currentTab);
      setSelectedActivity({ ...selectedActivity, currentTab: currentTab, actionToDisplay: actionToDisplay });
    }
  }, [selectedActivity]);

  useEffect(() => {
    if (activitySelected) {
      setSelectedActivity(activitySelected);
    }
  }, [activitySelected]);

  useEffect(() => {
    setSelectedTask({ ...taskDetail, taskGuid: taskSaved ? taskSaved : savedTaskData?.taskGuid });
  }, [taskDetail]);

  useEffect(() => {
    if (completedActivity) {
      retrieveTask();
      setSelectedTask({ ...taskDetail, taskGuid: taskSaved ? taskSaved : savedTaskData?.taskGuid });
      setViewActivity(false);
      setSelectedActivity();
      setActions({});
      navigate(
        ngm
          ? urlcat(NONENROLLED_TASKS_EDIT, { orgId: orgId, ngmId: ngmId, taskGuid: taskGuid })
          : urlcat(ENROLLED_TASKS_EDIT, { userId: userId, taskGuid: taskGuid }),
      );
    }
  }, [completedActivity]);

  // Validate that the required form fields are entered prior to navigating
  // to the 'Non-App Enroll' page
  useEffect(() => {
    if (actionToDisplay === activityTypes.NONAPPENROLL && !formIsValid(selectedActivity)) {
      setActionToDisplay('');
      setCurrentTab('');
    }
  }, [actionToDisplay]);

  const showActionScreen = () => {
    const currentTaskGuid = taskSaved ? taskSaved : savedTaskData.taskGuid;
    const actionParams = {
      actions,
      setActions,
      setCurrentTab,
      setActionToDisplay,
      values: selectedActivity,
      setValues: setSelectedActivity,
    };
    const completedFeedback = !!taskDetail?.activities?.some((x) => !!x.selfReportedOutcomeGuid);
    const displayableActionParams = {
      memberTaskInfo,
      ngm,
      memberInfo,
      currentTaskGuid,
      setSelectedTask,
      completedFeedback,
    };

    const { Component, params } = getDisplayableActions(displayableActionParams)[actionToDisplay];

    return <Component {...actionParams} {...params} />;
  };

  const formIsValid = (e) => {
    // Since the outcome may be null but the API accepts null, and the field is still required, we need
    // to make sure that the user fills this out
    const errorMessage =
      !e?.contactOutcome || e?.contactOutcome?.length < 1
        ? 'Missing Required Parameter: Contact Outcome'
        : e?.contactOutcome === 'UnableToSpeakToMember' &&
          (e?.contactOutcomeOption === undefined || e?.contactOutcomeOption?.length < 1)
        ? 'Missing Required Parameter: Contact Outcome Options'
        : e?.needFollowup === undefined
        ? 'Missing Required Parameter: Does Member Require Followup'
        : '';
    setCustomError(errorMessage);
    return errorMessage.length < 1;
  };

  const onSubmit = () => {
    const currentTaskGuid = taskSaved ? taskSaved : savedTaskData.taskGuid ?? taskGuid;
    if (formIsValid(selectedActivity)) {
      let valueForCompleteActivity = {
        Notes: selectedActivity.notes,
        AssistanceProvidedGuid: actions?.assistanceProvidedGuid,
        MemberConcernsGuid: actions?.concernGuid,
        MemberConnectionGuid: actions?.memberConnectionGuid,
        SelfReportedOutcomeGuid: actions?.outcomeGuid,
        CardId: actions?.greetingCardId,
        EncounterHistoryIds: actions?.encounterHistoryIds,
        ContactOutcome: selectedActivity?.contactOutcomeOption,
        NeedFollowup: selectedActivity?.needFollowup,
        Actions: selectedActivity?.actionTypes,
      };

      completeActivity(valueForCompleteActivity, organizationId, currentTaskGuid);
      setShowMainIcons(true);
    }
  };

  const cancelSelected = () => {
    if (actions?.isActive) {
      setModal(true);
    } else {
      setSelectedActivity();
      setViewActivity(false);
      setShowMainIcons(true);
      navigate(
        ngm
          ? urlcat(NONENROLLED_TASKS_EDIT, { orgId: orgId, ngmId: ngmId, taskGuid: taskGuid })
          : urlcat(ENROLLED_TASKS_EDIT, { userId: userId, taskGuid: taskGuid }),
        {
          showTaskScreen: true,
        },
      );
    }
  };

  const onModalClick = () => {
    setActions(false);
    setModal(false);
    setSelectedActivity();
    navigate(
      ngm
        ? urlcat(NONENROLLED_TASKS_EDIT, { orgId: orgId, ngmId: ngmId, taskGuid: taskGuid })
        : urlcat(ENROLLED_TASKS_EDIT, { userId: userId, taskGuid: taskGuid }),
      {
        showTaskScreen: true,
      },
    );
  };

  const displayButtons = () => {
    return (
      <SubmitButtonContainer>
        {isCompleteActivityPending ? (
          <SpinnerButton saveButtonSpinner>
            <Spinner />
          </SpinnerButton>
        ) : (
          <SubmitButton
            disabled={callLogInfo?.callLogState?.disableActivityTabs}
            variant="contained"
            disableElevation
            data-testid="SubmitButton"
            name={'submit'}
            type="submit"
          >
            Complete
          </SubmitButton>
        )}

        <CancelButton onClick={cancelSelected} value="Clear" type="reset" data-testid="CancelButton">
          Cancel
        </CancelButton>
      </SubmitButtonContainer>
    );
  };

  const checkIfShouldReturnActivityForm = () => {
    if (viewActivity) {
      return returnViewOnlyData();
    } else {
      const contactOutcomeOptions = Object.keys(contactOutcomesTransform);
      return (
        <CreateTaskDataContainer data-testid="CreateTaskDataContainer">
          <form data-testid="TaskForm" onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '-10px' }}>
            <div style={{ marginTop: '20px' }} />
            <ErrorMessage data-testid="ErrorMessage">
              {customError ? customError : errorMessage ? parseError(errorMessage) : ''}
            </ErrorMessage>
            <DataColumn>
              <div>
                <SectionHeader>Contact Outcome:</SectionHeader>
                <SelectStyle
                  {...register('contactOutcome')}
                  type="text"
                  id="contactOutcome"
                  data-testid={'ContactOutcomeSelect'}
                  onChange={contactOutcomeEdit}
                  value={selectedActivity?.contactOutcome || ''}
                >
                  <option value="">Please Select an Outcome</option>
                  {contactOutcomeOptions?.map((key) => {
                    return (
                      <option value={key} key={key} data-testid={key}>
                        {contactOutcomesTransform[key]}
                      </option>
                    );
                  })}
                </SelectStyle>
              </div>
            </DataColumn>
            <DataColumn>
              {selectedActivity?.contactOutcome === 'UnableToSpeakToMember'
                ? enums.TaskActivityContactOutcome.map((actionType, index) => (
                    <Controller
                      key={`contactOutcomeOptionController${actionType.code}`}
                      id={`contactOutcomeOptionController${actionType.code}`}
                      control={control}
                      name="contactOutcomeOption"
                      defaultValue={null}
                      render={() => (
                        <Radio
                          onChange={() => contactOutcomeOptionEdit(actionType)}
                          value={actionType.code}
                          key={`Outcome${actionType.code}`}
                          id={`Outcome${actionType.code}`}
                          name="contactOutcomeOption"
                          dataTestId={`OutcomeOption${index}`}
                          checked={selectedActivity?.contactOutcomeOption === actionType.code}
                        >
                          {actionType.displayName}
                        </Radio>
                      )}
                    />
                  ))
                : null}
            </DataColumn>
            <DataColumn>
              <div>
                <SectionHeader>Contact Actions:</SectionHeader>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {enums.TaskActivityActionType.map((actionType, index) => (
                    <div key={`Action${actionType.code}CheckContainer`}>
                      <label htmlFor={`Action${actionType.code}Check`}>
                        <input
                          id={`Action${actionType.code}Check`}
                          data-testid={`ActionCheckBox_${index}`}
                          type="checkbox"
                          checked={selectedActivity?.actionTypes?.indexOf(actionType.code) >= 0}
                          onChange={() => actionsEdit(actionType)}
                        />
                        {actionType.displayName}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </DataColumn>
            <DataColumn>
              <div>
                <SectionHeader>Does Member Require Followup?</SectionHeader>
                <Controller
                  key="needFollowUpControllerYes"
                  id="needFollowUpControllerYes"
                  control={control}
                  name="needFollowup"
                  defaultValue={null}
                  render={({ field: { onChange } }) => (
                    <Radio
                      onChange={(e) => {
                        onChange(e);
                        needFollowupEdit(e);
                      }}
                      key="needFollowUpControllerYes"
                      id="needFollowUpControllerYes"
                      dataTestId="NeedFollowUp_Yes"
                      name="needFollowup"
                      value={'true'}
                      checked={selectedActivity?.needFollowup === 'true'}
                    >
                      Yes
                    </Radio>
                  )}
                />
                <Controller
                  key="needFollowUpControllerNo"
                  id="needFollowUpControllerNo"
                  control={control}
                  name="needFollowup"
                  defaultValue={null}
                  render={({ field: { onChange } }) => (
                    <Radio
                      onChange={(e) => {
                        onChange(e);
                        needFollowupEdit(e);
                      }}
                      value="false"
                      key="needFollowUpControllerNo"
                      id="needFollowUpControllerNo"
                      dataTestId="NeedFollowUp_No"
                      name="needFollowup"
                      checked={selectedActivity?.needFollowup === 'false'}
                    >
                      No
                    </Radio>
                  )}
                />
              </div>
            </DataColumn>
            <DataColumn>
              <div>
                <SectionHeader>Notes:</SectionHeader>
                <TextAreaStyle
                  {...register('notes')}
                  onChange={notesEdit}
                  type="textarea"
                  id="notes"
                  data-testid="NotesInput"
                  value={
                    selectedActivity?.notes
                      ? selectedActivity.notes
                      : `What is the purpose of this call?${'\n'}What needs came up in the call?${'\n'}How did we help them?${'\n'}Are there any plans beyond this call?`
                  }
                />
              </div>
            </DataColumn>
            {displayButtons()}
          </form>
        </CreateTaskDataContainer>
      );
    }
  };

  const returnViewOnlyData = () => {
    if (viewActivity) {
      return (
        <>
          <ActivityViewOnlyText selectedActivity={selectedActivity} />
          <div style={{ padding: '35px 60px' }}>
            <CancelButton
              finsihed
              style={{ color: 'white', backgroundColor: '#4a4f7f' }}
              onClick={cancelSelected}
              value="Done"
              type="reset"
              data-testid="FinishedButton"
            >
              Close
            </CancelButton>
          </div>
        </>
      );
    }
  };

  const returnTaskOrActivity = () => {
    if (actionToDisplay) {
      return (
        <>
          <CrumbBar>
            <CrumbBarData>
              <ActivityTabs
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                setActionToDisplay={setActionToDisplay}
                taskType={taskDetail?.taskType}
                thriveStatus={thriveStatus}
                ngm={ngm}
                disabled={callLogInfo?.callLogState?.disableActivityTabs}
              ></ActivityTabs>
            </CrumbBarData>
          </CrumbBar>
          {showActionScreen()}
        </>
      );
    } else {
      return (
        <>
          {modal ? <Modal setActions={setActions} display={modal} setModal={setModal} onClick={onModalClick} /> : ''}
          <CrumbBar>
            <CrumbBarData>
              {viewActivity ? (
                ''
              ) : (
                <ActivityTabs
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                  setActionToDisplay={setActionToDisplay}
                  taskType={taskDetail?.taskType}
                  thriveStatus={thriveStatus}
                  ngm={ngm}
                  disabled={callLogInfo?.callLogState?.disableActivityTabs}
                ></ActivityTabs>
              )}
            </CrumbBarData>
          </CrumbBar>
          <HeaderContainer>
            <TextHeader data-testid="ActivityHeader">{viewActivity ? 'View Activity' : 'New Activity'}</TextHeader>
          </HeaderContainer>
          <div style={{ display: 'flex', marginLeft: '90px', flexDirection: 'column' }}>
            <div style={{ width: '50%' }}>
              <InfoContainer style={{ backgroundColor: '#f8f7fa' }}>
                <InfoColumn>
                  <InfoGroup>
                    <InfoHeading>Task Type:</InfoHeading>
                    <InfoText>{taskDetail?.taskType}</InfoText>
                  </InfoGroup>
                  <InfoGroup>
                    <InfoHeading>Task Subtype:</InfoHeading>
                    <InfoText>{taskDetail?.taskSubType}</InfoText>
                  </InfoGroup>
                  <InfoGroup>
                    <InfoHeading>Task Notes:</InfoHeading>
                    <InfoText>{taskDetail?.notes}</InfoText>
                  </InfoGroup>
                </InfoColumn>
                <InfoColumn>
                  <InfoGroup>
                    <InfoHeading>Assigned To:</InfoHeading>
                    <InfoText>{taskDetail?.assignedToName}</InfoText>
                  </InfoGroup>
                  <InfoGroup>
                    <InfoHeading>Due Date:</InfoHeading>
                    <InfoText>{formatDate(taskDetail?.dueDateUtc)}</InfoText>
                  </InfoGroup>
                </InfoColumn>
                <InfoColumn>
                  <InfoGroup>
                    <InfoHeading>Task Status:</InfoHeading>
                    <InfoText>{taskDetail?.status}</InfoText>
                  </InfoGroup>
                  <InfoGroup>
                    <InfoHeading>Priority:</InfoHeading>
                    <InfoText>{taskDetail?.priority}</InfoText>
                  </InfoGroup>
                </InfoColumn>
              </InfoContainer>
            </div>
            <CallContainer>
              {!ngm && !!taskDetail && (
                <CallLog
                  taskDetail={taskDetail}
                  selectedTask={savedTaskData}
                  userId={userId}
                  ngmId={ngmId}
                  callLogInfo={callLogInfo}
                  organizationId={organizationId}
                  taskSaved={taskSaved}
                  activityStarted={true}
                  viewActivity={viewActivity}
                  setSelectedTask={setSelectedTask}
                  setCallLogInfo={setCallLogInfo}
                />
              )}
              <PeersCallButton userId={userId} ngmId={ngmId} organizationId={organizationId} />
            </CallContainer>
          </div>
          {checkIfShouldReturnActivityForm()}
        </>
      );
    }
  };
  return <>{returnTaskOrActivity()}</>;
};

AddActivity.propTypes = {
  selectedActivity: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setViewActivity: PropTypes.func,
  viewActivity: PropTypes.bool,
  taskSaved: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  memberTaskInfo: PropTypes.object,
  memberInfo: PropTypes.object,
  ngm: PropTypes.bool,
  savedTaskData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  enums: PropTypes.object,
  activitySelected: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setSelectedTask: PropTypes.func,
  thriveStatus: PropTypes.object,
  onChange: PropTypes.func,
};

export default AddActivity;

import { Typography } from '@mui/material';
import React from 'react';
import { Question, SecondaryQuestion } from './styles';
import PropTypes from 'prop-types';

function OutcomeQuestion({ title, type, disabled, children }) {
  const questionTypeConfig = {
    [OutcomeQuestion.type.SECONDARY]: {
      Component: SecondaryQuestion,
      variant: 'outlined',
    },
    [OutcomeQuestion.type.PRIMARY]: {
      Component: Question,
    },
  };
  const { Component, variant } = questionTypeConfig[type];
  return (
    <Component disabled={disabled} variant={variant}>
      <Typography fontSize={18} padding={'0px 0px 5px'}>
        {title}
      </Typography>
      {children}
    </Component>
  );
}

OutcomeQuestion.type = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
};

OutcomeQuestion.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

OutcomeQuestion.defaultProps = {
  type: OutcomeQuestion.type.SECONDARY,
};

export default OutcomeQuestion;

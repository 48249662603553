import styled from 'styled-components';
import { Button } from '@mui/material';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  grid-gap: 25px;

  div {
    max-width: 1024px;
  }
`;

export const StyledMessageContainer = styled.div`
  margin: 20px 0;
`;

export const StyledErrorMessage = styled.div`
  color: red;
`;

export const StyledSubmitButton = styled(Button)`
  white-space: nowrap;
`;

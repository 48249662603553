import React, { useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const ACTIONS = {
  SET_THRIVE_STATUS: 'set-thrive-status',
  SET_THRIVE_PROGRESS: 'set-thrive-progress',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_THRIVE_STATUS:
      return { ...state, thriveStatus: action.payload };
    case ACTIONS.SET_THRIVE_PROGRESS:
      return { ...state, thriveProgress: action.payload };
    default:
      return state;
  }
}

const ThriveContext = React.createContext();
const ThriveUpdateContext = React.createContext();

export const useThriveState = () => useContext(ThriveContext);
export const useThriveDispatch = () => useContext(ThriveUpdateContext);

export function ThriveProvider({ children }) {
  const [state, dispatch] = useReducer(reducer);

  return (
    <ThriveContext.Provider value={state}>
      <ThriveUpdateContext.Provider value={dispatch}>{children}</ThriveUpdateContext.Provider>
    </ThriveContext.Provider>
  );
}
ThriveProvider.propTypes = {
  children: PropTypes.node,
};

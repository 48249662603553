import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';

const useLogRedeterminationAttempt = () => {
  const url = `${process.env.API_BASE_URL}/v1/redetermination/logattempt`;
  const [request, setRequest] = useState();
  const { response, isLoading, hasErrored, errorData } = useAxiosSubmit(url, request, 0, 1);

  const logAttempt = async (request) => setRequest(request);

  return { response, hasErrored, isLoading, attemptError: errorData, logAttempt };
};

export default useLogRedeterminationAttempt;

import React from 'react';
import { AnswerTypes } from '../../enums';
import PropTypes from 'prop-types';
import SkippableFormAnswer from './SkippableFormAnswer';
import RequiredFormAnswer from './RequiredFormAnswer';
import { FormLabel } from '@mui/material';

const Question = ({ text, control, options, index, ...props }) => {
  const isSkippable = !!options?.filter((x) => x.answerType === AnswerTypes.SKIP)?.length;
  const FormComponent = isSkippable ? SkippableFormAnswer : RequiredFormAnswer;

  return (
    <>
      <FormLabel id={`radiogroup-label-${index}`}>{text}</FormLabel>
      <FormComponent control={control} options={options} aria-labelledby={`radiogroup-label-${index}`} {...props} />
    </>
  );
};

Question.displayName = 'Question';

Question.propTypes = {
  control: PropTypes.object.isRequired,
  text: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  index: PropTypes.string,
  value: PropTypes.any,
};

export default Question;

import React from 'react';
import { questionTypes } from '../Configuration/enums';
import { Controller } from 'react-hook-form';
import CheckboxGroup from 'Components/CheckboxGroup/CheckboxGroup';
import { CheckedRadio, StyledTextBox, UncheckedRadio } from './styles';
import OutcomeRadioGroup from '../OutcomeRadioGroup/OutcomeRadioGroup';
import PropTypes from 'prop-types';
import { displayTypes, questionDisplayTypes } from './outcomeAnswerConfig';

function OutcomeAnswer({ control, name, type, options, disabled, onUpdate }) {
  const defaultValue = type === questionTypes.FREETEXT ? '' : undefined;
  const displayType = questionDisplayTypes[type];
  const displayStyle = type === questionTypes.RATING ? { gap: '32px' } : {};

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, ...field } }, ...fields) => {
        const onAnswerChange = (e) => {
          const value = type === questionTypes.CHECKBOX ? e : e.target.value;
          onChange(e);
          onUpdate({ name, value });
        };
        return displayType === displayTypes.RADIO ? (
          <OutcomeRadioGroup
            {...field}
            {...fields}
            onChange={onAnswerChange}
            icon={<UncheckedRadio disabled={disabled} />}
            checkedIcon={<CheckedRadio disabled={disabled} />}
            row={type === questionTypes.RATING}
            disabled={disabled}
            sx={displayStyle}
            options={options}
          />
        ) : displayType === displayTypes.CHECKBOX ? (
          <CheckboxGroup {...field} options={options} onChange={onAnswerChange} />
        ) : displayType === displayTypes.FREETEXT ? (
          <StyledTextBox
            {...field}
            {...fields}
            disabled={disabled}
            multiline
            fullWidth
            rows={3}
            type="text"
            placeholder="Max 500 characters..."
            inputProps={{
              maxLength: 500,
            }}
            onChange={onAnswerChange}
          />
        ) : (
          ''
        );
      }}
    />
  );
}

OutcomeAnswer.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
  onUpdate: PropTypes.func,
};

export default OutcomeAnswer;

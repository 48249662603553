import React, { useEffect } from 'react';
import { MIGRATIONREQUESTS_NEW } from '../../../../../initialization/Routes/RoutesConfig';
import { useNavigate } from 'react-router-dom';
import withInformation from '../withInformation';
import LoadingWrapper from 'Components/LoadingWrapper/LoadingWrapper';
import { MigrationRequestModuleStore } from 'Administrator_Pages/MigrationRequests/MigrationRequestModuleStore';

const HiFriend = () => {
  const migrationStore = MigrationRequestModuleStore.Instance().Use();
  const infoStore = migrationStore.Information.Use();
  const memberStore = migrationStore.Member.Use();
  const memberInfo = memberStore.Info;
  const navigate = useNavigate();

  const isLoading = migrationStore.isLoading || infoStore.IsLoading || !infoStore.Info;

  useEffect(() => {
    infoStore.addInfo(memberInfo?.birthDay);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      navigate(MIGRATIONREQUESTS_NEW);
    }
  }, [isLoading]);

  return (
    <LoadingWrapper loading={isLoading}>
      <div>Redirecting...</div>
    </LoadingWrapper>
  );
};

export default withInformation(HiFriend);

import { userMetaCategories } from 'Tasking_Helpers/userMetaHelpers';

export const psychologyContentConfig = {
  [userMetaCategories.GENERAL]: [
    {
      dimension: 'Social/Build Relationship',
      items: [
        {
          contentTitle: 'Small Talk To Connect',
          contentId: 1,
          lastAccessed: null,
        },
        {
          contentTitle: 'Social Support Network',
          contentId: 2,
          lastAccessed: null,
        },
        {
          contentTitle: '3 Caring Connections',
          contentId: 3,
          lastAccessed: null,
        },
      ],
    },
    {
      dimension: 'Emotional/Good Feelings',
      items: [
        {
          contentTitle: 'Gratitude Letter',
          contentId: 4,
          lastAccessed: null,
        },
        {
          contentTitle: '3 Good Things',
          contentId: 5,
          lastAccessed: null,
        },
        {
          contentTitle: 'Stress Reduction',
          contentId: 6,
          lastAccessed: null,
        },
      ],
    },
    {
      dimension: 'Intellectual/Brain Boosters',
      items: [
        {
          contentTitle: 'Habit Tracker',
          contentId: 7,
          lastAccessed: null,
        },
        {
          contentTitle: 'Celebrate Your Progress',
          contentId: 8,
          lastAccessed: null,
        },
        {
          contentTitle: 'Reframe Judgement',
          contentId: 9,
          lastAccessed: null,
        },
        {
          contentTitle: "Things I Can and Can't Control",
          contentId: 15,
          lastAccessed: null,
        },
      ],
    },
    {
      dimension: 'Physical/Body Health',
      items: [
        {
          contentTitle: 'Calm Your Mind',
          contentId: 10,
          lastAccessed: null,
        },
        {
          contentTitle: 'Increase Movement',
          contentId: 11,
          lastAccessed: null,
        },
        {
          contentTitle: 'Body Awareness',
          contentId: 12,
          lastAccessed: null,
        },
        {
          contentTitle: 'Mindful Eating',
          contentId: 13,
          lastAccessed: null,
        },
        {
          contentTitle: 'Loneliness in Pregnancy',
          contentId: 14,
          lastAccessed: null,
        },
      ],
    },
  ],
  [userMetaCategories.YOUTH]: [
    {
      dimension: 'Social/Build Relationship',
      items: [
        {
          contentTitle: 'What To Do About Bullies',
          contentId: 18,
          lastAccessed: null,
        },
        {
          contentTitle: 'Ingredients of Friendship',
          contentId: 22,
          lastAccessed: null,
        },
      ],
    },
    {
      dimension: 'Emotional/Good Feelings',
      items: [
        {
          contentTitle: 'Keeping a Safe Space on LGBTQIA Social Media',
          contentId: 19,
          lastAccessed: null,
        },
        {
          contentTitle: 'Things I Like About Me',
          contentId: 20,
          lastAccessed: null,
        },
        {
          contentTitle: 'Creating Your Own Affirmations',
          contentId: 21,
          lastAccessed: null,
        },
        {
          contentTitle: 'Try Mindfulness for Emotional Balance',
          contentId: 23,
          lastAccessed: null,
        },
      ],
    },
    {
      dimension: 'Intellectual/Brain Boosters',
      items: [
        {
          contentTitle: 'Vision Board',
          contentId: 16,
          lastAccessed: null,
        },
        {
          contentTitle: 'Reframe Negative Thoughts To Feel Better',
          contentId: 17,
          lastAccessed: null,
        },
      ],
    },
    {
      dimension: 'Physical/Body Health',
      items: [
        {
          contentTitle: 'How To Stay Balanced Using Your 5 Senses',
          contentId: 24,
          lastAccessed: null,
        },
      ],
    },
  ],
  [userMetaCategories.CAREGIVER]: [
    {
      dimension: 'Caregiver',
      items: [
        {
          contentTitle: 'Activities to Engage Those with Memory Loss',
          contentId: 25,
          lastAccessed: null,
        },
        {
          contentTitle: 'Administering Medications',
          contentId: 26,
          lastAccessed: null,
        },
        {
          contentTitle: 'Bathing and Toileting How-To',
          contentId: 27,
          lastAccessed: null,
        },
        {
          contentTitle: 'Building a Support System',
          contentId: 28,
          lastAccessed: null,
        },
        {
          contentTitle: 'Building Confidence Developing I am Statements',
          contentId: 29,
          lastAccessed: null,
        },
        {
          contentTitle: 'Caregiver Burnout',
          contentId: 30,
          lastAccessed: null,
        },
        {
          contentTitle: 'Caring for yourself',
          contentId: 31,
          lastAccessed: null,
        },
        {
          contentTitle: 'Completing Daily Tasks in the Home',
          contentId: 32,
          lastAccessed: null,
        },
        {
          contentTitle: 'Deciding Which Healthcare Option is Best',
          contentId: 33,
          lastAccessed: null,
        },
        {
          contentTitle: 'Experiencing Grief and Loss',
          contentId: 34,
          lastAccessed: null,
        },
        {
          contentTitle: 'Knowing When You Need Help',
          contentId: 35,
          lastAccessed: null,
        },
        {
          contentTitle: 'Maintaining a Sense of Self',
          contentId: 36,
          lastAccessed: null,
        },
        {
          contentTitle: 'Navigating this Challenging Health Care System',
          contentId: 37,
          lastAccessed: null,
        },
        {
          contentTitle: 'Relaxation Activities',
          contentId: 38,
          lastAccessed: null,
        },
        {
          contentTitle: 'Rephrasing my Thoughts',
          contentId: 39,
          lastAccessed: null,
        },
        {
          contentTitle: 'Supporting Individuals with Mental Health Needs',
          contentId: 40,
          lastAccessed: null,
        },
        {
          contentTitle: 'Understanding How to Care for Someone with Cognitive Disabilities',
          contentId: 41,
          lastAccessed: null,
        },
      ],
    },
  ],
  [userMetaCategories.GUARDIAN]: [
    {
      dimension: 'Guardian',
      items: [
        {
          contentTitle: 'Building a Support System',
          contentId: 42,
          lastAccessed: null,
        },
        {
          contentTitle: 'Building Confidence Developing I am Statements',
          contentId: 43,
          lastAccessed: null,
        },
        {
          contentTitle: 'Caregiver Burnout',
          contentId: 44,
          lastAccessed: null,
        },
        {
          contentTitle: 'Caring for Yourself',
          contentId: 45,
          lastAccessed: null,
        },
        {
          contentTitle: 'Experiencing Grief and Loss During Transitions',
          contentId: 46,
          lastAccessed: null,
        },
        {
          contentTitle: 'Foster Parenting Tips',
          contentId: 47,
          lastAccessed: null,
        },
        {
          contentTitle: 'Knowing When You Need Help',
          contentId: 48,
          lastAccessed: null,
        },
        {
          contentTitle: 'Learning Empathy',
          contentId: 49,
          lastAccessed: null,
        },
        {
          contentTitle: 'My Child is Acting Out',
          contentId: 50,
          lastAccessed: null,
        },
        {
          contentTitle: 'Relaxation Activities',
          contentId: 51,
          lastAccessed: null,
        },
        {
          contentTitle: 'Rephrasing my Thoughts',
          contentId: 52,
          lastAccessed: null,
        },
        {
          contentTitle: 'Supporting My Child With Their Trauma',
          contentId: 53,
          lastAccessed: null,
        },
      ],
    },
  ],
};

import urlcat from 'urlcat';
import useAxiosSwrFetch from 'Tasking_Hooks/useAxiosSwrFetch';
import { useEffect } from 'react';

const requestPath = 'v1/thrive/assessments/scores';

const useThriveAssessmentScores = (userId, thriveAccessId) => {
  const { response, error, isLoading, fetch } = useAxiosSwrFetch();

  useEffect(() => {
    if (!!userId && !!thriveAccessId) {
      fetch(urlcat(process.env.API_BASE_URL, requestPath, { userId, thriveAccessId }));
    }
  }, [userId, thriveAccessId]);

  return { response, error, isLoading };
};

export default useThriveAssessmentScores;

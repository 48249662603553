import { IStateStore } from 'Helpers/IStateStore';
import { Singleton } from 'Helpers/Singleton';
import { atom, getDefaultStore, useAtom } from 'jotai';
import { CallLogApiService } from './CallLogApiService';
import { convertLocalDateToUtcStr } from 'Util/dateFunctions';
import { getNow } from 'Helpers/timeWarpHelper';
import CallLog from './CallLog';
import { AssociateCallLogApiService } from './AssociateCallLogApiService';

export class CallLogModuleStore extends Singleton implements IStateStore {
  private callLogApiService: CallLogApiService;
  private associateCallLogApiService: AssociateCallLogApiService;

  private taskGuidAtom = null;
  private atomStore = getDefaultStore();
  private callLogGuidAtom;
  private callLogDataAtom;
  private getCallLogErrorAtom;
  private isLoadingAtom;
  private updateCallLogErrorAtom;
  private isUpdatePendingAtom;
  private isCreatePendingAtom;
  private createCallLogErrorAtom;
  private callLogStatusAtom;

  constructor() {
    super();

    this.callLogApiService = new CallLogApiService();
    this.associateCallLogApiService = new AssociateCallLogApiService();

    this.callLogGuidAtom = atom(null);
    this.callLogDataAtom = atom(null);
    this.getCallLogErrorAtom = atom(null);
    this.isLoadingAtom = atom(false);
    this.updateCallLogErrorAtom = atom(null);
    this.isUpdatePendingAtom = atom(false);
    this.createCallLogErrorAtom = atom(null);
    this.isCreatePendingAtom = atom(false);
    this.taskGuidAtom = atom(null);
    this.callLogStatusAtom = atom(CallLog.status.NEW);
  }

  public Use = () => {
    useAtom(this.callLogGuidAtom);
    useAtom(this.callLogDataAtom);
    useAtom(this.getCallLogErrorAtom);
    useAtom(this.isLoadingAtom);
    useAtom(this.updateCallLogErrorAtom);
    useAtom(this.isUpdatePendingAtom);
    useAtom(this.createCallLogErrorAtom);
    useAtom(this.isCreatePendingAtom);
    useAtom(this.callLogStatusAtom);
    useAtom(this.taskGuidAtom);

    return this;
  };

  public get TaskGuid() {
    return this.atomStore.get(this.taskGuidAtom);
  }

  public get CallLogStatus() {
    return this.atomStore.get(this.callLogStatusAtom);
  }

  public get IsCreatePending() {
    return this.atomStore.get(this.isCreatePendingAtom);
  }

  public get IsLoading() {
    return this.atomStore.get(this.isLoadingAtom);
  }

  public get IsUpdatePending() {
    return this.atomStore.get(this.isUpdatePendingAtom);
  }

  public get CreateCallLogError() {
    return this.atomStore.get(this.createCallLogErrorAtom);
  }

  public get GetCallLogError() {
    return this.atomStore.get(this.getCallLogErrorAtom);
  }

  public get UpdateCallLogError() {
    return this.atomStore.get(this.updateCallLogErrorAtom);
  }

  public get CallLogData() {
    return this.atomStore.get(this.callLogDataAtom);
  }

  public get CallLogGuid(): string {
    return this.atomStore.get(this.callLogGuidAtom);
  }

  public createCallLog = async (agentUserId, organizationId, taskGuid) => {
    this.reset();
    this.atomStore.set(this.isCreatePendingAtom, true);

    const startedOnUtc = convertLocalDateToUtcStr(getNow());

    const request = { AgentUserId: agentUserId, StartedOnUtc: startedOnUtc, OrganizationId: organizationId };
    const { response: callLogGuid, error: createCallLogError } = await this.callLogApiService.createCallLog(request);

    if (createCallLogError) {
      this.atomStore.set(this.createCallLogErrorAtom, { hasError: true, error: createCallLogError });
      this.atomStore.set(this.isCreatePendingAtom, false);
      return;
    }

    const { response: associateCallLogResponse, error: associateCallLogError } =
      await this.associateCallLogApiService.associateCallLog(organizationId, taskGuid, callLogGuid);

    if (associateCallLogError) {
      this.atomStore.set(this.createCallLogErrorAtom, { hasError: true, error: associateCallLogError });
      return;
    }

    this.atomStore.set(this.callLogGuidAtom, callLogGuid);
    this.atomStore.set(this.callLogDataAtom, request);
    this.atomStore.set(this.isCreatePendingAtom, false);

    await this.getCallLog(callLogGuid, organizationId, taskGuid);
  };

  public getCallLog = async (callLogGuid, organizationId, taskGuid) => {
    if (this.TaskGuid != null && taskGuid != this.TaskGuid) {
      this.reset();
      return;
    }

    this.atomStore.set(this.taskGuidAtom, taskGuid);

    if (callLogGuid != this.CallLogGuid) {
      this.reset();
    }

    this.atomStore.set(this.isLoadingAtom, true);

    const { response: callLogData, error } = await this.callLogApiService.getCallLog(organizationId, callLogGuid);

    if (!callLogData) {
      this.atomStore.set(this.getCallLogErrorAtom, { hasError: true, error });
      this.atomStore.set(this.isLoadingAtom, false);
      return;
    }

    this.atomStore.set(this.callLogGuidAtom, callLogGuid);
    this.atomStore.set(this.callLogDataAtom, callLogData);
    this.atomStore.set(this.isLoadingAtom, false);
    this.atomStore.set(this.callLogStatusAtom, this.getCallLogStatus());
  };

  public updateCallLog = async (
    callLogGuid,
    organizationId,
    taskGuid,
    request: { AgentUserId; StartedOnUtc; OrganizationId; EndedOnUtc },
  ) => {
    this.atomStore.set(this.isUpdatePendingAtom, true);

    const { response: callLogData, error } = await this.callLogApiService.updateCallLog(
      organizationId,
      callLogGuid,
      request,
    );

    if (error) {
      this.atomStore.set(this.updateCallLogErrorAtom, { hasError: true, error });
      this.atomStore.set(this.isUpdatePendingAtom, false);
      return false;
    }

    this.atomStore.set(this.isUpdatePendingAtom, false);
    this.atomStore.set(this.callLogStatusAtom, this.getCallLogStatus());

    this.getCallLog(callLogGuid, organizationId, taskGuid);
    return true;
  };

  public reset = () => {
    this.atomStore.set(this.isLoadingAtom, false);
    this.atomStore.set(this.isCreatePendingAtom, false);
    this.atomStore.set(this.isUpdatePendingAtom, false);

    this.atomStore.set(this.createCallLogErrorAtom, null);
    this.atomStore.set(this.updateCallLogErrorAtom, null);
    this.atomStore.set(this.getCallLogErrorAtom, null);

    this.atomStore.set(this.callLogDataAtom, null);
    this.atomStore.set(this.callLogGuidAtom, null);
    this.atomStore.set(this.callLogStatusAtom, this.getCallLogStatus());
    this.atomStore.set(this.taskGuidAtom, null);
  };

  private getCallLogStatus = () => {
    const callLogData = this.atomStore.get(this.callLogDataAtom) as any;

    if (!callLogData || !callLogData.startedOnUtc) {
      return CallLog.status.NEW;
    } else if (!callLogData.endedOnUtc) {
      return CallLog.status.STARTED;
    } else {
      return CallLog.status.ENDED;
    }
  };
}

import styled from '@emotion/styled';
import { FormControl } from '@mui/material';

export const StyledSelect = styled(FormControl)`
  width: 100%;
  max-width: 400px;

  .MuiSelect-select {
    padding: 4px 10px;
    font-size: 14px;
  }

  .MuiInputLabel-root {
    position: relative;
    font-size: 19px;
    color: black;
    margin-bottom: -7px;
  }
`;

// Legacy Styles
export const ScreenMemberDataContainer = styled.div`
  margin-top: 35px;
  border-top: 1px solid #f5f5f5;
  width: 100%;
  padding-left: 85px;
  padding-right: 95px;
  padding-top: 16px;
  box-sizing: border-box;
  font-family: lato;
  display: flex;
  flex-flow: column;
  row-gap: 25px;
`;

// Legacy Styles
export const TextHeader = styled.h2`
  margin: 0;
  margin-top: 31px;
  margin-left: 85px;
  font-family: karla;
  color: #015b5b;
  font-size: 32px;
  font-weight: bold;
  width: fit-content;
  padding: 0;
`;

import { Grid, Modal, Typography } from '@mui/material';
import React from 'react';
import { StyledLoadingButton } from '../styles';
import { StyledModalBox, StyledModalHeader } from './styles';
import PropTypes from 'prop-types';

const SubmissionModal = ({ open, onClose, onSubmit }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <StyledModalBox>
        <StyledModalHeader>
          <Typography fontSize={20} fontWeight={700}>
            Confirm Submission?
          </Typography>
        </StyledModalHeader>
        <Grid container spacing={1} rowSpacing={3} sx={{ justifyContent: 'space-evenly', padding: '10px 20px 20px' }}>
          <Grid item xs={12}>
            <Typography>You cannot edit if you save now. Are you sure you want to save?</Typography>
          </Grid>
          <Grid item xs={5}>
            <StyledLoadingButton variant="outlined" fullWidth onClick={onClose}>
              Cancel
            </StyledLoadingButton>
          </Grid>
          <Grid item xs={5}>
            <StyledLoadingButton variant="contained" fullWidth onClick={onSubmit}>
              Yes
            </StyledLoadingButton>
          </Grid>
        </Grid>
      </StyledModalBox>
    </Modal>
  );
};

SubmissionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SubmissionModal;

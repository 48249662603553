import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';

const useUpdateMemberConnection = (guid, orgId) => {
  const [request, setRequest] = useState();
  const url = `${process.env.API_BASE_URL}/v1/memberconnections/${orgId}:${guid}`;
  const { response, isLoading, hasErrored, errorData } = useAxiosSubmit(url, request, 'put');

  const updateMemberConnection = async (request) => {
    setRequest(request);
  };

  return { response, hasErrored, isLoading, updateMemberConnectionError: errorData, updateMemberConnection };
};

export default useUpdateMemberConnection;

// this is done because the npm package vanilla-oidc
// does not use the same "instance" as the react-oidc
import { Oidc } from '@axa-fr/react-oidc/dist/vanilla/oidc';
import { DefaultBrowser } from './DefaultBrowser';
import urlcat from 'urlcat';

const defaultConfigurationName = 'default';
const USERINFO_RESPONSE_KEY = 'userinfo_response';

const accessTokenInitialState = { accessToken: null, accessTokenPayload: null };

export class AuthService {
  constructor() {}

  init = async () => {
    this.browser = new DefaultBrowser();
    this.storage = localStorage;
  };

  getAccessToken = (configurationName = defaultConfigurationName) => {
    // this is basically the same stuff in oidc for useOidcAccessToken but without the useState or useEffects.
    // this is because it is not allowing us to get the access token in getAuthClient
    const oidc = Oidc.get(configurationName);

    let accessToken;

    if (oidc) {
      if (oidc.tokens) {
        const tokens = oidc.tokens;
        accessToken = tokens.accessToken;
      }

      const newSubscriptionId = oidc.subscriveEvents((name) => {
        if (name == Oidc.eventNames.token_renewed || name == Oidc.eventNames.token_aquired) {
          const tokens = oidc.tokens;
          const accessTokenData =
            tokens != null
              ? { accessToken: tokens.accessToken, accessTokenPayload: tokens.accessTokenPayload }
              : accessTokenInitialState;
          accessToken = accessTokenData.accessToken;
        }
      });
      oidc.removeEventSubscription(newSubscriptionId);
    }

    return accessToken;
  };

  getIdToken = (configurationName = defaultConfigurationName) => {
    const oidc = Oidc.get(configurationName);

    let idToken;
    if (oidc) {
      if (oidc.tokens) {
        const tokens = oidc.tokens;
        idToken = tokens.idToken;
      }
    }

    return idToken;
  };

  completeLogout = async (configurationName = defaultConfigurationName) => {
    localStorage.removeItem(USERINFO_RESPONSE_KEY);

    const idToken = this.getIdToken();

    this.storage.clear();

    if (idToken) {
      // destroy oidc client to reset (unauthenticate)
      const oidc = Oidc.get(configurationName);
      await oidc.destroyAsync();

      this.browser.showWindow(
        urlcat(`${process.env.AUTHORITY}/connect/endsession`, {
          id_token_hint: idToken,
          post_logout_redirect_uri: window.location.origin,
        }),
      );

      if (this.browser.loaded) {
        window.location.reload();
      }
    } else {
      window.location.reload();
    }
  };
}

import styled from 'styled-components';

export const RowContainer = styled.div`
  display: flex;
  font-family: karla;
  margin-top: 15px;
  align-items: ${(props) => (props.baseline ? 'baseline' : 'inherit')};
`;

export const TitleText = styled.div`
  font-weight: bold;
`;

export const ContentText = styled.div`
  font-family: open Sans;
  margin-top: 5px;
  margin-left: 80px;
  white-space: ${(props) => (props.notes ? 'pre-line' : 'normal')};
`;

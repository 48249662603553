import React, { useEffect } from 'react';
import {
  CancelButton,
  ModalBody,
  ModalButtonsContainer,
  ModalContainer,
  ModalContent,
  ModalHeader,
  SelectStyle,
  SubmitButton,
  SpinnerButton,
} from './styles';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import useAssignUser from 'Tasking_Hooks/useAssignUser';
import Spinner from 'Components/Spinner/Spinner';

const AssignUserModal = ({ setModal, assignTaskInfo, assignOptions }) => {
  const { register, handleSubmit } = useForm();
  const { userAssigned, isSubmitPending, submitAssignedUser } = useAssignUser();

  useEffect(() => {
    if (!isSubmitPending && userAssigned != null) {
      setModal(false);
    }
  }, [isSubmitPending]);

  const renderUserList = () => {
    if (assignOptions?.length) {
      return assignOptions.map((user, index) => {
        const { name, userId } = user;
        return (
          <option data-testid={`AssignedUserOption-${index}`} key={`${userId}-${index}`} value={userId}>
            {name}
          </option>
        );
      });
    }
  };

  const onSubmit = (e) => {
    const valuesForSubmit = {
      AssignedToUserId: e.AssignedInput,
    };

    submitAssignedUser(valuesForSubmit, assignTaskInfo.organizationId, assignTaskInfo.taskGuid);
  };

  return (
    <ModalContainer data-testid="AssignUserContainer">
      <ModalContent>
        <ModalHeader>Assign Task</ModalHeader>
        <form data-testid="AssignUserForm" onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <div htmlFor="AssignTo">Assign to:</div>
            <br />
            <SelectStyle {...register('AssignedInput')} type="text" id="AssignedInput" data-testid="AssignedInput">
              {renderUserList()}
            </SelectStyle>
          </ModalBody>
          <ModalButtonsContainer>
            {isSubmitPending ? (
              <SpinnerButton data-testid="SpinnerButton">
                <Spinner />
              </SpinnerButton>
            ) : (
              <SubmitButton data-testid="SubmitButton" value={'Save'} type="submit" />
            )}
            <CancelButton
              onClick={() => {
                setModal(false);
              }}
              value={'Cancel'}
              type="button"
              data-testid="CancelButton"
            />
          </ModalButtonsContainer>
        </form>
      </ModalContent>
    </ModalContainer>
  );
};

AssignUserModal.propTypes = {
  setModal: PropTypes.func,
  assignTaskInfo: PropTypes.object,
  assignOptions: PropTypes.array,
};

export default AssignUserModal;

import { atom, getDefaultStore, useAtom } from 'jotai';
import { DataMigrationType, InformationTypes, ProfileMigrationType } from './enumerations/enums';
import { Singleton } from 'Helpers/Singleton';
import { UserResourcesApiDataService } from './services/UserResourcesApiDataService';
import datediff from 'Administrator_Helpers/getDateDiff';
import { MigrationRequestsApiDataService } from './services/MigrationRequestsApiDataService';
import { IMigrationInformationModuleStore } from './IMigrationInformationModuleStore';
import { MemberMigrationInformationModuleStore } from './InformationImplementations/MemberSearch/MemberMigrationInformationModuleStore';
import { CodeMigrationInformationModuleStore } from './InformationImplementations/CodeSearch/CodeMigrationInformationModuleStore';
import { EligibilityMigrationInformationModuleStore } from './InformationImplementations/EligibilitySearch/EligibilityMigrationInformationModuleStore';
import { matchPath } from 'react-router';
import { HiFriendMigrationInformationModuleStore } from './InformationImplementations/HiFriend/HiFriendMigrationInformationModuleStore';
import { MIGRATIONREQUESTS_NEW_INFO } from '../../../initialization/Routes/RoutesConfig';
import { OnboardingApiDataService } from './services/OnboardingApiDataService';

export class MigrationRequestModuleStore extends Singleton {
  private atomStore = getDefaultStore();
  private userResourcesApiDataService: UserResourcesApiDataService;
  private migrationRequestsApiDataService: MigrationRequestsApiDataService;
  private onboardingApiService: OnboardingApiDataService;
  private hasPermissionsAtom;
  private destinationTypeAtom;
  private profileMigrationTypeAtom;
  private dataMigrationTypeAtom;
  private detectedRuleAtom;
  private errorAtom;
  private migrationResultsAtom;
  private migrationEnumsAtom;
  private codeGroupsAtom;
  private informationStore: IMigrationInformationModuleStore;
  private memberStore: IMigrationInformationModuleStore;
  private loadingAtom;
  private lastMigrationSearchRequest;

  constructor() {
    super();

    this.userResourcesApiDataService = new UserResourcesApiDataService();
    this.migrationRequestsApiDataService = new MigrationRequestsApiDataService();
    this.onboardingApiService = new OnboardingApiDataService();
    this.memberStore = new MemberMigrationInformationModuleStore();
    this.hasPermissionsAtom = atom(null);
    this.loadingAtom = atom(false);
    this.errorAtom = atom(null);
    this.destinationTypeAtom = atom(null);
    this.profileMigrationTypeAtom = atom(null);
    this.dataMigrationTypeAtom = atom(null);
    this.detectedRuleAtom = atom(null);
    this.migrationResultsAtom = atom(null);
    this.migrationEnumsAtom = atom(null);
    this.codeGroupsAtom = atom([]);
    this.lastMigrationSearchRequest = null;
  }

  public Use = () => {
    useAtom(this.hasPermissionsAtom);
    useAtom(this.errorAtom);
    useAtom(this.destinationTypeAtom);
    useAtom(this.profileMigrationTypeAtom);
    useAtom(this.dataMigrationTypeAtom);
    useAtom(this.detectedRuleAtom);
    useAtom(this.loadingAtom);
    useAtom(this.migrationResultsAtom);
    useAtom(this.migrationEnumsAtom);
    useAtom(this.codeGroupsAtom);

    return this;
  };

  public get Error() {
    return this.atomStore.get(this.errorAtom);
  }

  public set Error(message: string | object) {
    this.atomStore.set(this.errorAtom, message);
  }

  public get Member(): IMigrationInformationModuleStore {
    return this.memberStore;
  }

  public get DestinationType(): InformationTypes {
    return this.atomStore.get(this.destinationTypeAtom);
  }

  public get HasPermissions(): boolean {
    return this.atomStore.get(this.hasPermissionsAtom);
  }

  public get ProfileMigrationType(): ProfileMigrationType {
    return this.atomStore.get(this.profileMigrationTypeAtom);
  }

  public get DataMigrationType(): DataMigrationType {
    return this.atomStore.get(this.dataMigrationTypeAtom);
  }

  public get DetectedRule() {
    return this.atomStore.get(this.detectedRuleAtom);
  }

  public get IsLoading() {
    return this.atomStore.get(this.loadingAtom);
  }

  public set IsLoading(loading: boolean) {
    this.atomStore.set(this.loadingAtom, loading);
  }

  public get MigrationResults() {
    return this.atomStore.get(this.migrationResultsAtom);
  }

  public get MigrationEnums() {
    return this.atomStore.get(this.migrationEnumsAtom);
  }

  public get CodeGroups(): Array<any> {
    return this.atomStore.get(this.codeGroupsAtom);
  }

  public get Information(): IMigrationInformationModuleStore {
    if (this.informationStore) return this.informationStore;

    const InfoStore = {
      [InformationTypes.CODE]: CodeMigrationInformationModuleStore,
      [InformationTypes.ELIGIBILITY]: EligibilityMigrationInformationModuleStore,
      [InformationTypes.HIFRIEND]: HiFriendMigrationInformationModuleStore,
    }[this.DestinationType];

    if (!InfoStore) return null;

    this.informationStore = new InfoStore().create(this.ClearInformation, this.updateDestination);

    return this.informationStore;
  }

  private updateDestination = (destinationType: InformationTypes) => {
    this.atomStore.set(this.destinationTypeAtom, destinationType);
  };

  public initialize = async ({ userId }) => {
    this.IsLoading = true;
    const userResources = await this.userResourcesApiDataService.getUserResources({ userId });

    if (userResources) {
      this.atomStore.set(
        this.hasPermissionsAtom,
        userResources?.response?.resources?.includes('Pyx.PyxIQ.MigrateMembers'),
      );
    }

    if (this.HasPermissions) {
      const response = await this.migrationRequestsApiDataService.getMigrationEnums();
      this.atomStore.set(this.migrationEnumsAtom, response?.response);

      await this.onboardingApiService.getCodes().then((response) => {
        this.atomStore.set(this.codeGroupsAtom, response?.response);
      });
    }

    this.IsLoading = false;
  };

  public InitializeSearch = async () => {
    this.Error = null;
    this.IsLoading = true;

    const match = matchPath({ path: MIGRATIONREQUESTS_NEW_INFO }, window.location.pathname);
    const infoType = <InformationTypes>match?.params?.informationSearchType;

    if ([InformationTypes.CODE, InformationTypes.ELIGIBILITY, InformationTypes.HIFRIEND].includes(infoType)) {
      this.atomStore.set(this.destinationTypeAtom, infoType);
    }

    this.IsLoading = false;
  };

  public ClearRules = () => {
    this.atomStore.set(this.profileMigrationTypeAtom, null);
    this.atomStore.set(this.dataMigrationTypeAtom, null);
    this.atomStore.set(this.detectedRuleAtom, null);
    this.Error = null;
  };

  public ClearInformation = () => {
    this.Information?.clear();
    this.ClearRules();
    this.atomStore.set(this.destinationTypeAtom, null);

    if (this.informationStore) {
      delete this.informationStore;
      this.informationStore = null;
    }
  };

  public ClearMigrationRequest = () => {
    this.Error = null;
    this.ClearInformation();
    this.Member.clear();
    this.IsLoading = false;
  };

  public SearchMigrations = async (request: { status; fetch; offset }) => {
    this.IsLoading = true;

    if (request) {
      this.lastMigrationSearchRequest = request;
    }

    const response = await this.migrationRequestsApiDataService.searchMigrationRequests(
      this.lastMigrationSearchRequest,
    );

    if (response?.error) {
      this.Error = response.error;
    } else {
      this.atomStore.set(this.migrationResultsAtom, response?.response);
    }

    this.IsLoading = false;
  };

  public SubmitMigrationRequest = async (): Promise<boolean> => {
    this.IsLoading = true;
    this.Error = null;

    if (
      !this.Member ||
      !this.Information?.Info ||
      this.ProfileMigrationType === null ||
      this.DataMigrationType === null
    ) {
      this.Error = 'Please select a member and destination.';
      this.IsLoading = false;
      return;
    }

    const response = await this.migrationRequestsApiDataService.createMigrationRequest({
      userId: this.Member.Info?.userId,
      sourceOrganizationId: this.Member.Info?.activeOrganizationId,
      sourceGroupId: this.Member.Info?.groupId,
      sourceNgmId: this.Member.Info?.ngmId,
      destinationOrganizationId: this.Information.Info?.organizationId,
      destinationGroupId: this.Information.Info?.groupId,
      destinationNgmId: this.Information.Info?.id,
      profileMigrationType: ProfileMigrationType[this.ProfileMigrationType],
      dataMigrationType: DataMigrationType[this.DataMigrationType],
    });

    if (response?.error) {
      this.Error = response?.error;
    } else if (response?.success) {
      this.ClearMigrationRequest();
    }

    this.IsLoading = false;
    return !!response?.success;
  };

  public DeleteMigration = async ({ migrationGuid }): Promise<boolean> => {
    this.IsLoading = true;
    const response = await this.migrationRequestsApiDataService.deleteMigrationRequest({
      migrationGuid,
    });

    if (response?.error) {
      this.Error = response?.error;
      this.IsLoading = false;
      return;
    } else {
      this.SearchMigrations(null);
    }

    return !response?.error;
  };

  public RetryMigration = async ({ migrationGuid }): Promise<boolean> => {
    this.IsLoading = true;
    const response = await this.migrationRequestsApiDataService.retryMigrationRequest({
      migrationGuid,
    });

    if (response?.error) {
      this.Error = response?.error;
      this.IsLoading = false;
    } else {
      this.SearchMigrations(null);
    }

    return !response?.error;
  };

  // The logic defining each of these is described in the following confluence doc
  // https://techmodgroup.atlassian.net/wiki/spaces/PYX/pages/2540437505/Migrating+Users#Migration-Scenarios
  public DetectMigrationTypes = () => {
    this.Error = null;
    const isSameOrg = this.Member.Info?.activeOrganizationId === this.Information.Info?.organizationId;
    const isNewAccount = datediff(new Date(this.Member.Info?.enrolledOn)) >= -14;
    const isHiFriend = this.Member.Info?.activeOrganizationId === 2001;
    const isCodeGroup = !!this.CodeGroups.find(x => x.groupId === this.Information.Info?.groupId);
    const isNewEligibility =
      this.Member.Info?.ngmId !== this.Information.Info?.id && this.DestinationType === InformationTypes.ELIGIBILITY;

    if (!isCodeGroup && isNewAccount && isHiFriend && !isSameOrg && this.DestinationType === InformationTypes.ELIGIBILITY) {
      this.atomStore.set(this.profileMigrationTypeAtom, ProfileMigrationType.MOVEPROFILE);
      this.atomStore.set(this.dataMigrationTypeAtom, DataMigrationType.MOVEDATA);
      this.atomStore.set(this.detectedRuleAtom, 'Member missed their plan.');
    } else if (isNewAccount && isHiFriend && !isSameOrg && this.DestinationType === InformationTypes.CODE) {
      this.atomStore.set(this.profileMigrationTypeAtom, ProfileMigrationType.MOVEPROFILE);
      this.atomStore.set(this.dataMigrationTypeAtom, DataMigrationType.NONE);
      this.atomStore.set(this.detectedRuleAtom, 'Member missed their code.');
    } else if (!isNewAccount && this.DestinationType === InformationTypes.CODE) {
      this.atomStore.set(this.profileMigrationTypeAtom, ProfileMigrationType.NEWPROFILE);
      this.atomStore.set(this.dataMigrationTypeAtom, DataMigrationType.NONE);
      this.atomStore.set(this.detectedRuleAtom, 'Member had a plan change.');
    } else if (!isNewAccount && isNewEligibility && this.DestinationType === InformationTypes.ELIGIBILITY) {
      this.atomStore.set(this.profileMigrationTypeAtom, ProfileMigrationType.NEWPROFILE);
      this.atomStore.set(this.dataMigrationTypeAtom, DataMigrationType.NONE);
      this.atomStore.set(this.detectedRuleAtom, 'Member had a plan change.');
    } else if (!isHiFriend && this.DestinationType === InformationTypes.HIFRIEND) {
      this.atomStore.set(this.profileMigrationTypeAtom, ProfileMigrationType.NEWPROFILE);
      this.atomStore.set(this.dataMigrationTypeAtom, DataMigrationType.NONE);
      this.atomStore.set(this.detectedRuleAtom, 'Member had a plan change.');
    } else if (isSameOrg) {
      this.Error = 'Error: Cannot migrate the selected member to the same destination organization.';
    } else {
      this.Error =
        'Error: Could not find an applicable rule for the selected member and destination. Please, verify the selected member and destination are correct.';
    }
  };
}

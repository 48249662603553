import { purple } from 'Styles/colors';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const TextHeader = styled.h2`
  margin: 31px 85px 10px;
  color: #015b5b;
  font-size: 32px;
  font-weight: bold;
`;

export const PageContentContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${(props) => (props.preview ? '36px 0 0' : '20px')};
`;

export const GalleryContainer = styled.div`
  height: 100%;
  width: 100%;
  min-height: 300px;
  outline: 2px solid grey;
  min-width: 600px;
  display: flex;
  align-items: stretch;
  flex: 1;
`;
export const GalleryOptionContainer = styled.div`
  align-self: center;
`;

export const CardGalleryContainer = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  min-height: 200px;
  display: flex;
  width: auto;
  justify-content: space-evenly;
  padding: 5% 0% 6%;
`;

export const GalleryPreviewContainer = styled.button`
  border: none;
  background: transparent;
`;

export const GalleryHeader = styled.h2`
  margin: 0 auto;
  text-align: center;
  padding: 14px 0px 7px;
`;

export const NavButton = styled.button`
  margin: 10px;
  background: transparent;
  color: ${purple._900};
  :disabled {
    color: grey;
  }
`;

export const NavIcon = styled.i`
  font-size: 2rem;
`;

export const CancelButton = styled.button`
  background-color: white;
  color: #4a4f7f;
  width: 125px;
  height: 27px;
  border: 1px solid #4a4f7f;
  border-radius: 25px;
  cursor: pointer;
`;

export const SubmitButton = styled.button`
  background-color: #4a4f7f;
  color: white;
  width: 125px;
  height: 27px;
  border: 1px solid white;
  border-radius: 25px;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  padding: ${(props) => (props.preview ? '16px 0 15px' : '')};
  justify-content: ${(props) => (props.preview ? 'space-evenly' : '')};
`;

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 30%;
  text-align: center;
  padding: 20px;
  text-align: center;
`;

export const ErrorMessageContainer = styled.div`
  color: red;
  display: flex;
  flex-flow: row-reverse;
  text-align: ${(props) => (props.preview ? 'center' : 'right')};
  flex: 1;
`;

export const FooterContainer = styled.div`
  display: ${(props) => (props.preview ? 'block' : 'flex')};
  padding: ${(props) => (props.preview ? '65px' : '20px')};
`;

import React from 'react';
import PropTypes from 'prop-types';
import { PrintTextField, PrintTextFieldLabel } from '../../styles';

function FreeText({ value, label, ...props }) {
  return (
    <>
      <PrintTextFieldLabel>{label}</PrintTextFieldLabel>
      <PrintTextField
        {...props}
        variant="standard"
        focused
        InputProps={{
          readOnly: true,
        }}
        sx={{ wordBreak: 'break-word' }}
      >
        {value}
      </PrintTextField>
    </>
  );
}

FreeText.displayName = 'FreeText';

FreeText.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
};

export default FreeText;

import { atom, getDefaultStore, useAtom } from 'jotai';
import { InformationTypes } from 'Administrator_Pages/MigrationRequests/enumerations/enums';
import { IMigrationInformationModuleStore } from 'Administrator_Pages/MigrationRequests/IMigrationInformationModuleStore';
import { MembersApiDataService } from 'Administrator_Pages/MigrationRequests/services/MembersApiDataService';
import { birthDayReformat } from 'Util/dateFunctions';

export class MemberMigrationInformationModuleStore implements IMigrationInformationModuleStore {
  private atomStore = getDefaultStore();
  private apiService: MembersApiDataService;
  private infoAtom;
  private loadingAtom;
  private errorAtom;
  private searchResultsAtom;
  private clearCallback: () => void;
  private updateDestinationCallback: (destinationType) => void;

  constructor() {
    this.apiService = new MembersApiDataService();
    this.loadingAtom = atom(false);
    this.infoAtom = atom(null);
    this.errorAtom = atom(null);
    this.searchResultsAtom = atom(null);
  }

  public get IsLoading(): boolean {
    return this.atomStore.get(this.loadingAtom);
  }

  private set IsLoading(isLoading: boolean) {
    this.atomStore.set(this.loadingAtom, isLoading);
  }

  public get Info(): any {
    return this.atomStore.get(this.infoAtom);
  }

  public get Error(): string {
    return this.atomStore.get(this.errorAtom);
  }

  private set Error(message: any) {
    this.atomStore.set(this.errorAtom, message);
  }

  public get SearchResults(): boolean {
    return this.atomStore.get(this.searchResultsAtom);
  }

  public Use = () => {
    useAtom(this.loadingAtom);
    useAtom(this.infoAtom);
    useAtom(this.errorAtom);
    useAtom(this.searchResultsAtom);

    return this;
  };

  private fixBirthdayFormat = (birthday: string) => {
    if (!birthday) {
      return null;
    }

    let birthdayResult = birthDayReformat(birthday);
    if (!Date.parse(birthdayResult)) {
      return null;
    }
    birthdayResult = new Date(birthdayResult);
    birthdayResult.setUTCHours(0, 0, 0, 0);

    return birthdayResult;
  };

  private searchFieldsWithoutOrg = (request): Array<any> => {
    const fieldsWithoutOrganization = Object.values(request);
    fieldsWithoutOrganization.splice(Object.keys(request).indexOf('organizationId'), 1);
    return fieldsWithoutOrganization.filter((x) => !!x && x !== '');
  };

  private validateSearch = (request): boolean => {
    this.Error = null;
    const fieldsNoOrg = this.searchFieldsWithoutOrg(request);
    const fieldsFilled = fieldsNoOrg.filter((x) => !!x && x !== '')?.length;
    const fieldsLessThanMinimumCharacterCount =
      fieldsNoOrg.filter((x: any) => typeof x === 'string' && x?.length < 3)?.length > 0;

    if (fieldsLessThanMinimumCharacterCount) {
      this.Error = 'Please include at least 3 character in your chosen search fields';
    } else if (fieldsFilled < 1) {
      this.Error = 'Search must include at least one criterion (a selected organization does not count)';
    } else if (!request?.organizationId && request?.organizationId !== '') {
      this.Error = 'Please select an organization';
    } else if (request?.birthDay) {
      const reformattedDate = birthDayReformat(request.birthDay);
      if (!Date.parse(reformattedDate)) {
        this.Error = 'Invalid Date Format';
      }
    }

    return !this.Error;
  };

  private cleanRequest = (request) => {
    const req = {};
    Object.keys(request)
      .filter((x) => !!request[x] && request[x] !== '')
      .forEach((x) => {
        req[x] = request[x];
      });
    return req;
  };

  create(clearCallback: () => void, updateDestinationCallback: (destinationType) => void): this {
    this.clearCallback = clearCallback;
    this.updateDestinationCallback = updateDestinationCallback;
    this.clear();
    return this;
  }

  clear() {
    this.atomStore.set(this.infoAtom, null);
    this.atomStore.set(this.searchResultsAtom, null);
    this.atomStore.set(this.errorAtom, null);
    this.atomStore.set(this.loadingAtom, null);
  }

  cancel() {
    this.atomStore.set(this.searchResultsAtom, null);
    this.atomStore.set(this.errorAtom, null);
    this.atomStore.set(this.loadingAtom, null);
  }

  search = async (request) => {
    this.IsLoading = true;
    this.Error = null;
    const isValid = this.validateSearch(request);

    if (!isValid) {
      this.IsLoading = false;
      return;
    }

    request.birthDay = this.fixBirthdayFormat(request?.birthDay);
    request = this.cleanRequest(request);

    await this.apiService.searchMembers(request).then((response) => {
      this.atomStore.set(this.searchResultsAtom, response?.response);
      this.Error = response?.error;
      this.IsLoading = false;
    });
  };

  async addInfo(data: any): Promise<boolean> {
    this.IsLoading = true;

    if (!data?.userId) {
      this.Error = 'User is invalid.';
      this.IsLoading = false;
      return;
    }

    return await this.apiService.getMember({ userId: data.userId }).then((response) => {
      this.atomStore.set(this.infoAtom, response.response);
      this.atomStore.set(this.searchResultsAtom, null);
      this.IsLoading = false;
      return !response?.error;
    });
  }
}

import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';

const useAssignUser = () => {
  const [assignedUserData, setAssignedUserData] = useState();
  const [url, setUrl] = useState();
  const { response, isLoading, hasErrored } = useAxiosSubmit(url, assignedUserData, 'put');

  const submitAssignedUser = async (request, orgId, taskGuid) => {
    setUrl(`${process.env.API_BASE_URL}/v5/Tasks/${orgId}:${taskGuid}/assign`);
    setAssignedUserData(request);
  };

  return { userAssigned: response, submitError: hasErrored, isSubmitPending: isLoading, submitAssignedUser };
};

export default useAssignUser;

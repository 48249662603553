import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

const getScreeningScoreSearchUrl = 'v1/screeningscore/search';
const options = {
  revalidateOnFocus: false,
};

const getScreeningScoreSearchInternal = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useSearchScreeningScore = () => {
  const [screeningScore, setScreeningScore] = useState();
  const [url, setUrl] = useState();
  const [isGetPending, setIsGetPending] = useState(true);
  const {
    data: screeningScoreSearchResult,
    errorData,
    error: getError,
    mutate,
  } = useSWR(url, getScreeningScoreSearchInternal, options);

  const searchScreeningScore = (userId, screeningResponseGuid) => {
    setUrl(
      `${process.env.API_BASE_URL}/${getScreeningScoreSearchUrl}?UserId=${userId}&ScreeningResponseGuids=${screeningResponseGuid}`,
    );
    mutate(screeningScoreSearchResult);
  };

  useEffect(() => {
    if (screeningScoreSearchResult) {
      setScreeningScore(screeningScoreSearchResult.data);
    }

    setIsGetPending(!screeningScoreSearchResult && !getError);
  }, [screeningScoreSearchResult, getError]);

  return {
    screeningScore,
    getError,
    screeningScoreErrorMessage: errorData,
    isGetPending,
    searchScreeningScore,
  };
};

export default useSearchScreeningScore;

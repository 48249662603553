import { Grid } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import { CallTimerField, StyledTextField, StyledGridTimerPicker } from '../styles';
import PropTypes from 'prop-types';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { convertUtcDateToLocalStr } from 'Util/dateFunctions';

function TimerEntry({ label, timeUtc, disabled, editTimeAllowed, onTimeChange, onInvalidDateEntry, error }) {
  const [timeOpen, setTimeOpen] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const dateUtc = convertUtcDateToLocalStr(timeUtc)?.slice(0, 10).replace(/-/g, '-');
  const [date, setDate] = useState(dateUtc);
  const [time, setTime] = useState(timeUtc);
  const timeRef = useRef(null);

  const onTimeClick = () => {
    if (editTimeAllowed) {
      setTimeOpen(!timeOpen);
    }
  };

  const onTimeEdit = (e) => {
    setTime(e);

    if (onTimeChange && !invalid) {
      onTimeChange(e);
    }
  };

  const convertDateWithTime = (dateInputData, timeInputData) => {
    if (!dateInputData || new Date(dateInputData).getFullYear() < 1900) return;

    const timeData = new Date(timeInputData);
    const dateData = new Date(dateInputData);

    return new Date(
      dateData.getUTCFullYear(),
      dateData.getUTCMonth(),
      dateData.getUTCDate(),
      timeData.getHours(),
      timeData.getMinutes(),
      timeData.getSeconds(),
      timeData.getMilliseconds(),
    );
  };

  const onDateEdit = (e) => {
    if (!editTimeAllowed) return;

    const val = e.target.value;
    const convertedDate = convertDateWithTime(val, time);
    const isValidDate = !isNaN(convertedDate);

    if (!isValidDate && onInvalidDateEntry) {
      onInvalidDateEntry('Invalid Date');
    } else if (isValidDate && onTimeChange) {
      onTimeChange(convertedDate);
    }

    setDate(val);
    setInvalid(!isValidDate);
    setTimeOpen(false);
  };

  useEffect(() => {
    setTime(timeUtc);
    setDate(dateUtc);
    setInvalid(false);
  }, [timeUtc]);

  return (
    <>
      <Grid item xs={12}>
        {label}
      </Grid>
      <StyledGridTimerPicker item xs={5}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            clearable
            ampm={true}
            mask={time ? '__:__:__ _M' : '__:__:__'}
            placeholder={'00:00:00'}
            inputFormat={time ? 'hh:mm:ss a' : 'HH:mm:ss'}
            value={time ?? new Date(new Date().setHours(0, 0, 0, 0))}
            disableOpenPicker={true}
            disabled={disabled}
            readonly={!editTimeAllowed}
            onChange={onTimeEdit}
            error={error}
            onAccept={() => setTimeOpen(false)}
            open={timeOpen}
            orientation="portrait"
            PopperProps={{ disablePortal: true }}
            showToolbar
            toolbarTitle={''}
            ampmInClock={false}
            renderInput={(params) => (
              <StyledTextField
                onClick={onTimeClick}
                {...params}
                ref={timeRef}
                error={error}
                inputProps={{ ...params?.inputProps, readOnly: !editTimeAllowed }}
              />
            )}
          />
        </LocalizationProvider>
      </StyledGridTimerPicker>
      <Grid item xs={7}>
        <CallTimerField
          disabled={disabled}
          readonly={!editTimeAllowed}
          id="datepicker"
          type="date"
          inputProps={{ max: '9999-12-31', min: '0001-01-01' }}
          value={date}
          defaultValue={date}
          onChange={onDateEdit}
          error={error}
        />
      </Grid>
    </>
  );
}

TimerEntry.propTypes = {
  label: PropTypes.string,
  timeUtc: PropTypes.string,
  disabled: PropTypes.bool,
  editTimeAllowed: PropTypes.bool,
  onTimeChange: PropTypes.func,
  onInvalidDateEntry: PropTypes.func,
  error: PropTypes.bool,
};

export default TimerEntry;

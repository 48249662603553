export const questions = [
  {
    name: 'ba671ac9-7196-4e88-903b-6cc855137696',
    text: "In the past 6 months, did you have to stay overnight somewhere because you didn't have a home to stay in?",
    options: [
      {
        name: '78e48dd1-258c-4fbe-bd40-f579119b06fc',
        text: 'No',
      },
      {
        name: 'cb0639c9-ed37-49ad-834b-f55320216cc8',
        text: 'Yes',
      },
    ],
  },
  {
    name: '6524a0b6-1b20-44ac-8a29-3b9a42b533f8',
    text: 'In the past 12 months, did you ever feel hungry because there was not enough food to eat where you live?',
    options: [
      {
        name: '0c5b8e44-5fd3-4533-95a5-df1067cc4a4c',
        text: 'No',
      },
      {
        name: '7cb64579-abe1-46d4-af72-4d9b38848ec3',
        text: 'Yes',
      },
    ],
  },
  {
    name: '40769842-8115-45d3-94e6-5d708131d8cc',
    text: 'In the past 12 months, have you or the people you live with been unable to get utilities (heat, electricity, water)?',
    options: [
      {
        name: '07778e8f-6a1c-49bb-938f-9c3083421bdf',
        text: 'No',
      },
      {
        name: 'f8162c46-9e70-4bc8-95af-d461c13c2f59',
        text: 'Yes',
      },
    ],
  },
  {
    name: 'a41161f7-553a-4852-bba8-988f9d5bd3ac',
    text: 'In the past 12 months, have you been unable to get transportation to your medical visits, school, job, or other important events?',
    options: [
      {
        name: 'ed439c3f-5e76-4819-a904-87292da41c5d',
        text: 'No',
      },
      {
        name: '6572bb1b-0027-48ac-b152-009b36709f53',
        text: 'Yes',
      },
    ],
  },
  {
    name: 'a153dfc1-40e9-49d1-94b0-cd9db4da0023',
    text: 'In the past 12 months have you been threatened, teased, or hurt by someone (on the Internet, text or in person)?',
    options: [
      {
        name: '5849d8f0-fa5c-4128-818f-a6550d9d049c',
        text: 'No',
      },
      {
        name: '41eab832-9f3e-4ed2-9384-d38f83231f46',
        text: 'Yes',
      },
    ],
  },
  {
    name: '644c5538-00fc-45d8-adde-433dd27c3acc',
    text: 'In your everyday life have you felt stressed because someone treated you differently because of your race, ethnicity, gender identity, or sexual orientation?',
    options: [
      {
        name: '1143668c-cb65-48cc-9525-2733447d53a8',
        text: 'No',
      },
      {
        name: '1681cd18-c2db-47a0-b372-5635d7f01d40',
        text: 'Yes',
      },
    ],
  },
  {
    name: '0262bd99-25ee-4e06-9bd2-11666328005a',
    text: 'Do you ever feel that you spend too much time online and that it is negatively affecting your mood or well-being?',
    options: [
      {
        name: '2de62de8-d114-43ba-9f4b-b40eeba73990',
        text: 'No',
      },
      {
        name: '71e26680-4682-471b-9c3e-25f7b7419fc0',
        text: 'Yes',
      },
    ],
  },
  {
    name: '3ec39b3a-e4f2-4ac9-8dab-3de9f09a7e27',
    text: 'In the past 12 months, have your parents or guardians gotten separated or divorced?',
    options: [
      {
        name: 'e1fc2990-79cc-4ba8-b92d-0c1e31814f3c',
        text: 'No',
      },
      {
        name: '2608f03d-82a9-496d-b770-dff6a52fe354',
        text: 'Yes',
      },
    ],
  },
  {
    name: '61d2b911-7a98-46c7-89c4-c909f5550518',
    text: 'Is someone living in your home experiencing depression, mental illness, or attempted suicide?',
    options: [
      {
        name: '379a519e-4a18-4b82-857c-4a0e77215697',
        text: 'No',
      },
      {
        name: '257935fe-13b1-478e-8850-d1ab41214948',
        text: 'Yes',
      },
    ],
  },
  {
    name: 'ce2f0425-1bd7-481b-a335-2f77ff162596',
    text: 'Are any of these needs urgent?',
    options: [
      {
        name: 'ec2372db-6460-4007-b72f-2d134ce258ca',
        text: 'No',
      },
      {
        name: '53426981-153a-4653-8940-519d9ce4b0a7',
        text: 'Yes',
      },
    ],
  },
];

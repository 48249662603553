import styled from 'styled-components';

export const TabsContainer = styled.div`
  display: flex;
  font-size: 22px;
`;

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.clicked ? 'white' : '#c0d7d8')};
  color: ${(props) => (props.disabled ? 'rgba(255, 255, 255, 0.5)' : props.clicked ? '#c0d7d8' : 'white')};
  border-width: 2px;
  border-style: solid;
  border-color: ${(props) => (props.disabled ? 'rgba(255, 255, 255, 0.5)' : 'white')};
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  padding: 10px;
`;

export const TabDescription = styled.div`
  color: ${(props) => (props.disabled ? 'rgba(0, 92, 179, 0.4)' : '#005cb3')};
  font-size: 14px;
  margin-top: 15px;
  margin-left: 10px;
  font-family: karla;
`;

export const Container = styled.div`
  display: flex;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  margin-right: 30px;
`;

import styled from '@emotion/styled';
import { Button, FormControl, Grid, Select } from '@mui/material';

export const SearchResultsGrid = styled(Grid)`
  width: 90%;
  display: flex;
  margin: 0 auto;
  margin-top: 40px;
  box-shadow: rgba(0, 0, 0, 0.25) 1px 2px 11px 0px;
  background-color: rgb(1, 83, 83);
  color: white;
`;

export const HeaderContainer = styled.div`
  padding: 15px;
  width: 100%;
  display: flex;
  flex-flow: wrap;
`;

export const DataGridFooter = styled(Grid)`
  font-weight: 700;
  padding: 5px;
  background-color: rgb(242, 242, 242);
  color: rgb(1, 83, 83);
  text-align: right;
`;

export const StyledSelect = styled(Select)`
  .MuiSelect-select {
    padding: 5px 15px;
    width: 175px;
    background-color: white;
  }
`;

export const ActionButton = styled(Button)`
  color: white;
  border-color: white;
  border-radius: 5px;

  :hover,
  :focus {
    border-color: white;
    color: white;
  }
`;

export const StyledFormControl = styled(FormControl)`
  flex-direction: row;
  column-gap: 15px;
  display: flex;
  flex-flow: wrap;

  .MuiFormLabel-root {
    transform: none;
    position: relative;
    align-self: center;
    color: white;

    &.Mui-focused {
      color: white;
    }
  }
`;

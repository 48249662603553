import urlcat from 'urlcat';
import useAxiosSwrFetch from 'Tasking_Hooks/useAxiosSwrFetch';
import { useEffect } from 'react';

const requestPath = 'v1/thrive/assessments/scores/:orgId::thriveAssessmentScoreId';

const useThriveAssessmentDetails = (orgId, thriveAssessmentScoreId) => {
  const { response, error, isLoading, fetch } = useAxiosSwrFetch({ fetchRequired: false });

  useEffect(() => {
    if (!!thriveAssessmentScoreId) {
      fetch(urlcat(process.env.API_BASE_URL, requestPath, { orgId, thriveAssessmentScoreId }));
    }
  }, [thriveAssessmentScoreId]);

  return { response, error, isLoading };
};

export default useThriveAssessmentDetails;

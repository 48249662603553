import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';

const useEnrollUser = () => {
  const [enrollUserData, setEnrollUserData] = useState();
  const [url, setUrl] = useState();
  const { response, isLoading, hasErrored, errorData } = useAxiosSubmit(url, enrollUserData);

  const enrollUser = async (request) => {
    setUrl(`${process.env.API_BASE_URL}/v5/onboarding/enrollUser`);
    setEnrollUserData(request);
  };

  return { response, error: hasErrored, isPending: isLoading, enrollmentErrorMessage: errorData, enrollUser };
};

export default useEnrollUser;

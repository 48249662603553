import React, { useEffect, useState, Fragment } from 'react';
import useGetPositivePsychology from 'Tasking_Hooks/useGetPositivePsychology';
import { useNavigate, useParams } from 'react-router-dom';
import { psychologyContentConfig } from '../ThriveMetrics/PositivePsychologyContent/psychologyContentConfig';
import {
  StyledButton,
  StyledDimensionContainer,
  StyledContentColumn,
  StyledOptionColumn,
  StyledOptionContainer,
  StyledDimensionSubheader,
  StyledCheck,
  StyledSaveButton,
  StyledInputContainer,
} from './styles';
import { CircularProgress, TextField } from '@mui/material';
import useLogPsychologyContent from 'Tasking_Hooks/useLogPsychologyContent';
import useUpdatePsychologyContent from 'Tasking_Hooks/useUpdatePsychologyContent';
import RouteLeavingGuard from 'Tasking_Components/RouteLeavingGuard/RouteLeavingGuard';
import CenteredCircularProgress from 'Components/CenteredCircularProgress/CenteredCircularProgress';
import useUserMeta from 'Tasking_Hooks/useUserMeta';
import { retrieveUserMetaCategories } from 'Tasking_Helpers/userMetaHelpers';
import { mergeObjectsByMetaCategory } from '../ThriveMetrics/PositivePsychologyContent/psychologyContentUtils';

const PsychologyContent = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [isDirty, setIsDirty] = useState(false);
  const [currentContentId, setCurrentContentId] = useState(null);
  const { positivePsychology, getPositivePsychology } = useGetPositivePsychology();
  const { logResponse, isLogPending, logPsychologyContent } = useLogPsychologyContent();
  const [noteValue, setNoteValue] = useState('');
  const { updateResponse, isUpdatePending, updatePsychologyContent } = useUpdatePsychologyContent();
  const { userMeta, isPending: isMetaPending } = useUserMeta(userId);
  const [metaPsychologyContent, setPsychContent] = useState(null);

  useEffect(() => {
    getPositivePsychology(userId);
  }, [updateResponse]);

  useEffect(() => {
    if (positivePsychology?.notes) {
      setIsDirty(false);
      setNoteValue(positivePsychology.notes);
    }
  }, [positivePsychology]);

  useEffect(() => {
    if (
      (noteValue !== positivePsychology?.notes && noteValue.length > 0) ||
      (positivePsychology?.notes && noteValue.length == 0)
    ) {
      setIsDirty(true);
    }
  }, [noteValue]);

  useEffect(() => {
    if (!isMetaPending && metaPsychologyContent == null) {
      const categories = retrieveUserMetaCategories(userMeta);
      setPsychContent(mergeObjectsByMetaCategory(psychologyContentConfig, categories));
    }
  }, [isMetaPending]);

  const save = () => {
    updatePsychologyContent({ notes: noteValue }, userId);
    setIsDirty(false);
  };

  const logInteraction = (contentId) => {
    setCurrentContentId(contentId);
    logPsychologyContent({ positivePsychologyContentId: contentId }, userId);
  };

  const getDimensionOptions = () => {
    return metaPsychologyContent?.map((dimension, i) => {
      return (
        <div key={i}>
          <h1 data-testid={`header_${dimension.dimension}`}>{dimension.dimension}</h1>
          <StyledDimensionContainer>
            <StyledDimensionSubheader>Content</StyledDimensionSubheader>
            <StyledDimensionSubheader>Complete the activity via Andy call?</StyledDimensionSubheader>
            {dimension.items.map((item, j) => {
              return (
                <Fragment key={j + i}>
                  <StyledContentColumn key={`Column${j + i}`} data-testid={`columnTitle_${item.contentTitle}`}>
                    {item.contentTitle}
                  </StyledContentColumn>
                  <StyledOptionColumn key={`Option${j + i}`}>
                    {currentContentId === item.contentId ? (
                      <StyledOptionContainer>
                        {isLogPending || logResponse === null ? (
                          <CircularProgress />
                        ) : (
                          <StyledCheck data-testid={`check_${item.contentId}`} />
                        )}
                      </StyledOptionContainer>
                    ) : (
                      <StyledButton
                        data-testid={`logButton_${item.contentId}`}
                        onClick={() => {
                          logInteraction(item.contentId);
                        }}
                        variant="outlined"
                        disabled={isLogPending}
                      >
                        Mark as Complete
                      </StyledButton>
                    )}
                  </StyledOptionColumn>
                </Fragment>
              );
            })}
          </StyledDimensionContainer>
        </div>
      );
    });
  };

  const getNoteInput = () => {
    return (
      <>
        {isDirty ? <p style={{ color: 'red', fontSize: '20px' }}>Remember to save your notes!</p> : null}
        <h1 id="psychologynotes">Positive Psychology Notes</h1>
        <StyledInputContainer>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={5}
            fullWidth
            variant="outlined"
            value={noteValue}
            inputProps={{ 'data-testid': 'inputField', 'aria-labelledby': 'psychologynotes' }}
            onChange={(e) => {
              setNoteValue(e.target.value);
            }}
          />
          {isUpdatePending ? (
            <CenteredCircularProgress />
          ) : (
            <StyledSaveButton data-testid="saveButton" variant="outlined" onClick={() => save()}>
              Save Notes
            </StyledSaveButton>
          )}
        </StyledInputContainer>
      </>
    );
  };

  const getRouteGuard = () => {
    return (
      <RouteLeavingGuard when={isDirty} navigate={(path) => navigate(path)} shouldBlockNavigation={() => isDirty} />
    );
  };

  return (
    <>
      {!isMetaPending && getDimensionOptions()}
      {getNoteInput()}
      {getRouteGuard()}
    </>
  );
};

export default PsychologyContent;

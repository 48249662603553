export const questions = [
  {
    name: 'f025847c-4e1a-4e53-957f-f3ede6d37f48',
    text: 'In the last two weeks, did you ever eat less than you felt you should because there was not enough money for food?',
    options: [
      {
        name: 'e1dd0376-bf3e-45af-800e-f683de402041',
        text: 'Yes',
      },
      {
        name: '6d503c95-c98c-4dc8-b1ea-94ba3cbc07a1',
        text: 'No',
      },
    ],
  },
  {
    name: 'cc827323-e8f8-457b-9dd2-d0434fe19b51',
    text: 'In the last two weeks, has your utility company shut off your service for not paying your bills?',
    options: [
      {
        name: 'b39bd9fe-46d1-480a-92e3-2a460436aa5f',
        text: 'Yes',
      },
      {
        name: '2d26fe63-9c11-45ed-9ab6-1d5a678f3dcd',
        text: 'No',
      },
    ],
  },
  {
    name: 'e58f9b78-48e0-4c33-8d23-a5abf18597b8',
    text: 'Are you worried that in the next two months you may not have stable housing?',
    options: [
      {
        name: '60b53a44-4101-44b2-8d6f-851b46bb92c7',
        text: 'Yes',
      },
      {
        name: 'b788512d-8911-412e-8bab-5d608cc7282f',
        text: 'No',
      },
    ],
  },
  {
    name: 'a3b75ad5-d4b6-49d6-90ee-7ee3545b56b6',
    text: 'Are problems finding day care making it difficult for you to work?',
    options: [
      {
        name: '3d5240e5-bc7d-4f51-bcc1-14017e5c082a',
        text: 'Yes',
      },
      {
        name: 'cde88494-9053-4c00-91c4-7bbba8c9f875',
        text: 'No',
      },
    ],
  },
  {
    name: 'ac9c91cf-74dd-420e-9f5f-36d27cc3c60a',
    text: 'People sometimes miss taking their medications for reasons other than forgetting.  Thinking over the past 2 weeks, were there any days when you did not take your medicine?',
    options: [
      {
        name: 'a09b696b-d3ae-4065-af0a-45ba10142e51',
        text: 'Yes',
      },
      {
        name: 'cf836f8f-1a9d-4f49-9d9f-ea0147da7ebd',
        text: 'No',
      },
    ],
  },
  {
    name: 'd055b2af-b9e5-4894-93fa-7ee82e9ec91a',
    text: "In the last two weeks, have you been left without medical care because you didn't have a way to get there?",
    options: [
      {
        name: 'b5d10b3e-9c88-4f23-aef4-169ed19a16bf',
        text: 'Yes',
      },
      {
        name: '4f93c079-dd58-47a3-9758-f4830371c435',
        text: 'No',
      },
    ],
  },
  {
    name: 'b1770566-cf3f-45ed-9887-b43321f20be2',
    text: 'Do you ever need help reading doctor or healthcare materials?',
    options: [
      {
        name: 'fbcecb52-205a-4f67-a81b-6d02489cc25d',
        text: 'Yes',
      },
      {
        name: '13a2db41-05fa-4573-82e1-86b75fa001d2',
        text: 'No',
      },
    ],
  },
  {
    name: 'ae3e27e0-fea3-4983-b30a-51d9379e4d0f',
    text: 'Are you afraid you might be hurt in your building or home?',
    options: [
      {
        name: 'dc17d5a5-832f-45d5-a895-9e23c6d8ccda',
        text: 'Yes',
      },
      {
        name: 'fe760321-b570-4102-93a4-3eb7ef0e3afb',
        text: 'No',
      },
    ],
  },
  {
    name: 'b6389276-8f89-4b77-bbe8-c477a537af64',
    text: 'Would you like to receive assistance with any of these needs?',
    options: [
      {
        text: 'Yes',
        name: 'a012ca43-0b17-4f49-958f-add68ac4dc14',
      },
      {
        text: 'No',
        name: 'a7c5fe54-7241-4826-abd6-a5884cf68921',
      },
    ],
  },
  {
    name: 'eec3c056-3f68-4079-8297-5c5323c9cc0a',
    text: 'Are any of these needs urgent?',
    options: [
      {
        text: 'Yes',
        name: 'b22eceee-d6de-4af6-b1c6-4b5c2dc55d2b',
      },
      {
        text: 'No',
        name: '7557e7de-614b-4216-8a2e-2e9f218c254a',
      },
    ],
  },
];

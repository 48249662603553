export const thriveMemberFunctionConfig = {
  ACTIVE: {
    message:
      'This member is currently enrolled in Thrive. Select a dropout reason and click "Deactivate" to drop them out of Thrive.',
    optionType: 'DROPDOWN',
    header: 'Select Drop Out Reason',
    options: [
      'The member wanted to drop out from Thrive',
      'The member was unresponsive to calls and not actively using the app',
    ],
    buttonValue: 'Deactivate',
  },
  DROPOUT: {
    message:
      'This member has previously dropped out from Thrive. Select a re-enrollment reason and click "Re-Enroll" to re-enroll them back into Thrive.',
    optionType: 'DROPDOWN',
    header: 'Select Re-Enrollment Reason',
    options: [
      'The member wanted to re-enroll into Thrive since they are back from the hospital',
      'The member wanted to continue Thrive - Personal Reason',
    ],
    buttonValue: 'Re-Enroll',
  },
  GRADUATE: {
    message: 'This member has completed Thrive Pathways. No additional actions can be taken.',
    optionType: 'NONE',
  },
  NOTENROLLED: {
    message: 'This member has never been enrolled in Thrive. Click "Enroll into Thrive" to enroll them into Thrive.',
    optionType: 'ENROLLBUTTON',
    buttonValue: 'Enroll into Thrive',
    reason: 'Member wanted to enroll in Thrive',
  },
};

import React, { useState, useRef, useCallback } from 'react';
import { useReactToPrint } from 'react-to-print';

const usePrint = () => {
  const [showPrint, setShowPrint] = useState(false);
  const printableRef = useRef(null);

  const printPDF = useReactToPrint({
    content: () => printableRef?.current,
    removeAfterPrint: true,
    onAfterPrint: () => {
      setShowPrint(false);
    },
  });

  const handlePrint = () => {
    setShowPrint(true);
  };

  const printRef = useCallback((node) => {
    if (node !== null) {
      printableRef.current = node;
      printPDF();
    }
  }, []);

  const renderPrint = (Component, props) => {
    return <div style={{ display: 'none' }}>{showPrint && <Component ref={printRef} {...props} />}</div>;
  };

  return { handlePrint, renderPrint };
};

export default usePrint;

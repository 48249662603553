import React, { useEffect, useState } from 'react';
// import { Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';
import UnsavedChangesModal from 'Tasking_Components/UnsavedChangesModal/UnsavedChangesModal';

const RouteLeavingGuard = ({ navigate, shouldBlockNavigation }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = (command) => {
    if (command === 'Yes') {
      setConfirmedNavigation(true);
    }
    setModalVisible(false);
  };
  // eslint-disable-next-line no-unused-vars
  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      {/* <Prompt when={when} message={handleBlockedNavigation} /> */}
      <UnsavedChangesModal open={modalVisible} handleClose={closeModal} />
    </>
  );
};

RouteLeavingGuard.propTypes = {
  when: PropTypes.bool,
  navigate: PropTypes.func,
  shouldBlockNavigation: PropTypes.func,
};

export default RouteLeavingGuard;

import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

const getTaskUrl = 'v5/Tasks';
const options = {
  revalidateOnFocus: false,
};

const getTaskDetail = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useGetTaskDetails = (orgId, taskGuid) => {
  const [isTaskDetailsPending, setIsTaskDetailsPending] = useState(true);
  const [taskDetail, setTaskDetail] = useState();
  const [url, setUrl] = useState();
  const {
    data: taskDetailResult,
    error,
    mutate,
  } = useSWR(() => url, getTaskDetail, {
    ...options,
  });

  useEffect(() => {
    if (taskGuid && orgId) {
      setUrl(taskGuid && orgId ? `${process.env.API_BASE_URL}/${getTaskUrl}/${orgId}:${taskGuid}` : '');
      mutate();
    }
  }, [taskGuid, orgId]);

  useEffect(() => {
    if (taskDetailResult) {
      setTaskDetail(taskDetailResult.data);
    }
    setIsTaskDetailsPending(!taskDetailResult && !error);
  }, [taskDetailResult, error]);

  const retrieveTask = (orgId, taskGuid) => {
    setUrl(taskGuid && orgId ? `${process.env.API_BASE_URL}/${getTaskUrl}/${orgId}:${taskGuid}` : '');
    if (taskDetailResult) {
      setTaskDetail(taskDetailResult.data);
    }
    setIsTaskDetailsPending(!taskDetailResult && !error);
    mutate();
  };

  return { taskDetail, error, isTaskDetailsPending, retrieveTask };
};

export default useGetTaskDetails;

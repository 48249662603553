import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import Button from 'Components/Button';

export const InputFieldContainer = styled(Grid)`
  display: flex;
  flex-flow: column;

  .MuiInputBase-input {
    padding: 5px;
  }

  .MuiInputBase-root {
    width: 90%;
  }
`;

export const SearchFormContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-top: 25px;
  box-shadow: rgba(0, 0, 0, 0.25) 1px 2px 11px 0px;
  background-color: rgb(1, 83, 83);
  color: white;

  h1 {
    padding: 15px;
  }
`;

export const HeaderContainer = styled(Grid)`
  padding: 15px;
  width: 100%;
  display: flex;
  flex-flow: wrap;
  background-color: white;
  row-gap: 20px;
`;

export const ButtonContainer = styled(Grid)`
  display: flex;
  justify-content: right;
  column-gap: 20px;
  align-items: end;
  padding-right: 24px;
`;

export const StyledButton = styled(Button)`
  width: 125px;
  height: 35px;
`;

export const Footer = styled.div`
  font-weight: 700;
  padding: 5px;
  background-color: rgb(242, 242, 242);
  color: rgb(1, 83, 83);
  text-align: end;
  min-height: 25px;
`;

export const SearchHeaderContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-top: 25px;
`;

export const BackButton = styled(Button)`
  font-size: 25px;
  text-decoration: underline;
  color: blue;
  width: auto;
  height: 35px;
`;

export const ErrorMessage = styled.div`
  color: red;
  font-family: Karla;
  padding: 20px;
`;

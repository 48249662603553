import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

const ngmMemberHistoryUrl = 'v5/Ngms';
const options = {
  revalidateOnFocus: false,
};

const getNgmHistory = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useNgmHistory = (data) => {
  const [ngmIsPending, setNgmIsPending] = useState(true);
  const [ngmHistory, setNgmHistory] = useState();
  const [ngmIdData, setNgmIdData] = useState(data);
  const {
    data: ngmHistoryResult,
    error,
    mutate,
  } = useSWR(
    () => `${process.env.API_BASE_URL}/${ngmMemberHistoryUrl}/${ngmIdData[1]}:${ngmIdData[0]}/history`,
    getNgmHistory,
    {
      ...options,
    },
  );

  useEffect(() => {
    if (ngmHistoryResult) {
      setNgmHistory(ngmHistoryResult.data);
    }

    setNgmIsPending(!ngmHistoryResult && !error);
  }, [ngmHistoryResult, error]);

  const retrieveNgmHistory = (newNgmIdData) => {
    if (!newNgmIdData) return;
    setNgmIdData(newNgmIdData);
    mutate(ngmIdData);
  };

  return { ngmHistory, error, ngmIsPending, retrieveNgmHistory };
};

export default useNgmHistory;

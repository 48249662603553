import React from 'react';
import { useForm } from 'react-hook-form';
import ScreeningQuestion from './ScreeningQuestion/ScreeningQuestion';
import { StyledDimensionHeader, StyledForm, StyledSubmitButton, StyledSaveSpinner } from './styles';
import { screeningConfig } from './ScreeningConfig';
import { useParams } from 'react-router-dom';
import { Alert } from '@mui/lab';
import { CircularProgress } from '@mui/material';
import { StyledProgressContainer } from 'Tasking_Components/Thrive/styles';
import usePendingAssessment from 'Tasking_Hooks/usePendingAssessment';
import useSaveAssessment from 'Tasking_Hooks/useSaveAssessment';

const ThriveScreening = () => {
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm({ mode: 'onChange' });
  const { userId } = useParams();
  const { result: pendingAssessment, error: loadError, isPending: isLoading } = usePendingAssessment(userId);
  const { response: saveResponse, hasErrored: saveError, isLoading: isSaving, save } = useSaveAssessment();

  async function onSubmit(data) {
    const answers = Object.entries(data).map((e) => {
      const [questionKey, answerValue] = e;
      const question = screeningConfig.questions.find((q) => q.key === questionKey);

      return {
        dimension: question.dimension,
        questionText: question.text,
        answerText: screeningConfig.scoreMap[answerValue],
        answerValue,
      };
    });

    const request = {
      userId,
      term: pendingAssessment.thriveAssessmentTerm,
      answers,
    };

    await save(request);
  }

  function getQuestionsForDimension(dimension) {
    return screeningConfig.questions
      .filter((q) => q.dimension === dimension)
      .map((q) => (
        <ScreeningQuestion
          key={q.key}
          name={q.key}
          control={control}
          questionText={q.text}
          disabled={!pendingAssessment}
        />
      ));
  }

  if (isLoading) {
    return (
      <StyledProgressContainer data-testid="MainLoadingSpinner">
        <CircularProgress size="100px" />
      </StyledProgressContainer>
    );
  }

  return (
    <>
      {!pendingAssessment && <Alert severity="warning">Member has already completed screening for current term.</Alert>}
      {loadError && <Alert severity="error">An error has occurred loading screening data.</Alert>}
      {saveError && <Alert severity="error">An error has occurred saving screening data.</Alert>}
      {saveResponse && <Alert severity="success">Screening saved!</Alert>}
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledDimensionHeader>Social / Build Relationship</StyledDimensionHeader>
        {getQuestionsForDimension('SOCIAL')}

        <StyledDimensionHeader>Emotional / Good Feelings</StyledDimensionHeader>
        {getQuestionsForDimension('EMOTIONAL')}

        <StyledDimensionHeader>Intellectual / Brain Boosters</StyledDimensionHeader>
        {getQuestionsForDimension('INTELLECTUAL')}

        <StyledDimensionHeader>Physical / Body Health</StyledDimensionHeader>
        {getQuestionsForDimension('PHYSICAL')}

        <StyledSubmitButton variant="contained" color="primary" type="submit" disabled={!isValid || !!saveResponse}>
          Save / Done
          {isSaving && <StyledSaveSpinner data-testid="SavingSpinner" size={14} />}
        </StyledSubmitButton>
      </StyledForm>
    </>
  );
};

export default ThriveScreening;

import { searchTypes } from './SearchSelect/selectConfig';

export const PAGE_SIZE = 10;
export const LAST_ORG_SELECTION_KEY = 'last-org-selected';

export const HeaderTransform = {
  dueDateStatus: 'Status',
  priority: 'Priority',
  status: 'Task Status',
  taskType: 'Task Type',
  taskSubType: 'Task Subtype',
  firstName: 'First Name',
  lastName: 'Last Name',
  dueDateUtc: 'Due Date',
  organizationName: 'Organization',
  assignedToName: 'Assigned To',
  createdByName: 'Created By',
};

export const TaskStatusFilters = {
  OPEN: 'OPEN',
  ALL: 'ALL',
};

export const TABS = [
  { name: 'New Member Enrollment', id: 'ENROLLMENT' },
  { name: 'Andy Calls', id: 'ANDY' },
  { name: 'Other', id: 'ALL' },
];

export const SearchFilterTypes = {
  ORGANIZATION: 'ORGANIZATION',
  NETWORK: 'NETWORK',
  TASKSTATUS: 'TASKSTATUS',
  ASSIGNEDUSER: 'ASSIGNEDUSER',
  TASKSUBTYPES: 'TASKSUBTYPES',
};

export const defaultSearchFilters = {
  [SearchFilterTypes.ORGANIZATION]: '',
  [SearchFilterTypes.NETWORK]: [],
  [SearchFilterTypes.TASKSTATUS]: TaskStatusFilters.OPEN,
  [SearchFilterTypes.ASSIGNEDUSER]: '',
  [SearchFilterTypes.TASKSUBTYPES]: '',
};

export const getSelectConfig = ({
  assignedUserPending,
  searchState,
  organizations,
  enums,
  assignOptions,
  currentTab,
}) => {
  return [
    {
      title: 'Organization',
      filter: SearchFilterTypes.ORGANIZATION,
      value: searchState[SearchFilterTypes.ORGANIZATION],
      options: [{ id: '', name: 'All Organizations' }].concat(organizations ?? []),
    },
    {
      title: 'Network',
      filter: SearchFilterTypes.NETWORK,
      disabled: !searchState[SearchFilterTypes.ORGANIZATION],
      type: searchTypes.MULTIPLE,
      value: searchState[SearchFilterTypes.NETWORK],
      options: alphabeticalSort(
        organizations?.find((x) => x.id == searchState[SearchFilterTypes.ORGANIZATION])?.networks,
      ),
    },
    {
      title: 'Task Status Filter',
      filter: SearchFilterTypes.TASKSTATUS,
      optionProps: {
        name: 'code',
        text: 'displayName',
      },
      value: searchState[SearchFilterTypes.TASKSTATUS],
      options: enums?.TaskStatusFilter,
    },
    {
      title: 'Assigned User',
      filter: SearchFilterTypes.ASSIGNEDUSER,
      optionProps: {
        name: 'userId',
        text: 'name',
      },
      loading: assignedUserPending,
      value: searchState[SearchFilterTypes.ASSIGNEDUSER],
      options: [{ userId: '', name: 'All Assigned' }]
        .concat(assignOptions ?? [])
        .concat([{ userId: 0, name: 'Unassigned' }]),
    },
    {
      title: 'Task Sub Type',
      filter: SearchFilterTypes.TASKSUBTYPES,
      optionProps: {
        name: 'code',
        text: 'displayName',
      },
      show: currentTab === 'ANDY',
      value: searchState[SearchFilterTypes.TASKSUBTYPES],
      options: [{ code: '', displayName: 'All Subtypes' }].concat(enums?.TaskSubType),
    },
  ];
};

const alphabeticalSort = (options) => {
  return options?.sort((a, b) => a.name.localeCompare(b.name));
};

import styled from 'styled-components';

export const MemberDataContainer = styled.div`
  background-color: ${(props) =>
    props.deceased
      ? '#000000'
      : props.deleted
      ? '#ECECEC'
      : props.doNotCall || props.declined
      ? '#DE2B1B'
      : props.underage
      ? '#ff5900'
      : props.ngm
      ? '#378788'
      : '#62a942'};
  color: ${(props) => (props.deleted && !props.deceased ? '#5F5F5F' : 'white')};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  height: 120px;
`;

export const DataColumn = styled.div`
  font-size: 16px;
  margin-left: ${(props) => (props.colOne ? '85px' : props.colTwo ? '85px' : '')};
`;

export const TextInfo = styled.div`
  margin-top: ${(props) => (props.nameCol ? '7.5px' : '12.5px')};
  font-size: ${(props) => (props.isName ? '28px' : '18px')};
  font-weight: ${(props) => (props.isName ? 'bold' : '')};
  font-family: karla;
  width: fit-content;
`;

export const TextLink = styled.div`
  margin-top: 12.5px;
  color: white;
  font-size: 18px;
  font-family: karla;
  width: fit-content;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: #494e83;
  }
`;

export const OtpCodeButtonContainer = styled.div`
  display: inline-block;
  margin-top: 12px;
`;

import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';

const useSaveAssessment = () => {
  const url = `${process.env.API_BASE_URL}/v1/thrive/assessments/scores`;
  const [request, setRequest] = useState();
  const { response, isLoading, hasErrored } = useAxiosSubmit(url, request, 0, 1);

  const save = async (request) => setRequest(request);

  return { response, hasErrored, isLoading, save };
};

export default useSaveAssessment;

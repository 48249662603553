import React, { useEffect, useState } from 'react';
import {
  StyledClearFilterButton,
  StyledCustomerData,
  StyledCustomers,
  StyledCustomerTableSections,
  StyledExpandedInfo,
  StyledHeaderContainer,
  StyledIcon,
  StyledMailOutline,
  StyledNoDataMessage,
  StyledSearchBar,
  StyledSelect,
  StyledTextHeader,
} from './styles';
import PropTypes from 'prop-types';
import DropDownInfo from 'Tasking_Components/DropDownInfo/DropDownInfo';
import useMemberHistory from 'Tasking_Hooks/useMemberHistory';
import useNgmHistory from 'Tasking_Hooks/useNgmHistory';
import useMemberHistoryTypeEnums from 'Tasking_Hooks/useMemberHistoryTypeEnums';
import { formatDate, formatTime } from 'Tasking_Helpers/formatDate';
import useQuery from 'Tasking_Hooks/useQuery';
import { historyTypeFilterConfig } from 'Tasking_Components/MemberHistoryData/historyTypeFilterConfig';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';

const MemberHistoryData = ({ memberInfo, ngm }) => {
  const { enums } = useMemberHistoryTypeEnums();
  const [expand, setExpand] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [historyTypeToDisplay, setHistoryTypeToDisplay] = useState('');
  const { memberHistory, isPending, retrieveMemberHistory } = useMemberHistory();
  const { ngmHistory, ngmIsPending, retrieveNgmHistory } = useNgmHistory();
  const consolidatedEnums = enums?.MemberHistoryType.filter((e) => e.displayName !== 'Legacy Screening');
  const query = useQuery();

  useEffect(() => {
    ngm ? retrieveNgmHistory([memberInfo?.id, memberInfo?.organizationId]) : retrieveMemberHistory(memberInfo?.userId);
  }, []);

  useEffect(() => {
    if (memberHistory) {
      setHistoryData(memberHistory.items);
    }
    if (ngmHistory) {
      setHistoryData(ngmHistory);
    }
    if (query.get('filter')) {
      setHistoryTypeToDisplay(historyTypeFilterConfig[query?.get('filter')]);
    } else {
      setHistoryTypeToDisplay(historyTypeFilterConfig.all);
    }
  }, [memberHistory, ngmHistory]);

  function filterData(type) {
    if (type === historyTypeFilterConfig.all) {
      return historyData;
    } else if (type === historyTypeFilterConfig.greetingCard) {
      return historyData?.filter((historyItem) => {
        return historyItem.description?.includes('CARD') || historyItem.type.includes(historyTypeToDisplay);
      });
    } else if (type === historyTypeFilterConfig.task) {
      return historyData?.filter((historyItem) => {
        return historyItem.type.includes(historyTypeToDisplay) && !historyItem.description.includes('CARD');
      });
    } else if (type.includes(historyTypeFilterConfig.screening)) {
      return historyData?.filter((historyItem) => {
        return historyItem.type.includes(historyTypeToDisplay) || historyItem.type.includes('Legacy');
      });
    } else {
      return historyData?.filter((historyItem) => {
        return historyItem.type.includes(historyTypeToDisplay);
      });
    }
  }

  const returnIcon = (type, description) => {
    switch (type) {
      case enums?.MemberHistoryType.find((t) => t.displayName === historyTypeFilterConfig.assistanceProvided)
        ?.displayName:
        return <StyledIcon className={`far fa-parachute-box`} />;
      case enums?.MemberHistoryType.find((t) => t.displayName === historyTypeFilterConfig.memberConcerns)?.displayName:
        return <StyledIcon style={{ color: '#028A8A' }} className={`fal fa-comment-alt-lines`} />;
      case enums?.MemberHistoryType.find((t) => t.displayName === historyTypeFilterConfig.notification)?.displayName:
        return <StyledIcon style={{ color: '#028A8A' }} className={`fal fa-comment-alt-lines`} />;
      case enums?.MemberHistoryType.find((t) => t.displayName === historyTypeFilterConfig.task)?.displayName:
        return description === 'CARD - ' ? <StyledMailOutline /> : <StyledIcon className={`fal fa-tasks`} />;
      case enums?.MemberHistoryType.find((t) => t.displayName === historyTypeFilterConfig.memberConnection)
        ?.displayName:
        return <StyledIcon className={`fal fa-chart-bar`} />;
      case enums?.MemberHistoryType.find((t) => t.displayName === historyTypeFilterConfig.greetingCard)?.displayName:
        return <StyledMailOutline />;
      case enums?.MemberHistoryType.find((t) => t.displayName === historyTypeFilterConfig.memberMigration)?.displayName:
        return <FlightTakeoffIcon />;
      default:
        if (
          type.includes(
            enums?.MemberHistoryType.find((t) => t.displayName === historyTypeFilterConfig.screening)?.displayName,
          )
        ) {
          return <StyledIcon className={`far fa-clipboard-check`} />;
        } else {
          return <StyledIcon className={`fal fa-chart-bar`} />;
        }
    }
  };

  const addToExpansionList = (i) => {
    if (expand.includes(i)) {
      const index = expand.indexOf(i);
      expand.splice(index, 1);
      setExpand([...expand]);
    } else {
      setExpand([...expand, i]);
    }
  };

  const showExpandedInfo = (i, type, data) => {
    if (expand.includes(i)) {
      return (
        <StyledExpandedInfo Type={type}>
          <DropDownInfo ngm={ngm} memberInfo={memberInfo} data={data} type={type} />
        </StyledExpandedInfo>
      );
    }
  };

  const renderTableData = () => {
    return filterData(historyTypeToDisplay)?.map((data, i) => {
      const { timestampUtc, type, byUserName, description } = data;
      return (
        <React.Fragment key={i}>
          <StyledCustomerTableSections key={`section-${i}`}>
            <StyledCustomerData return num={i}>
              {returnIcon(type, description)}
            </StyledCustomerData>
            <StyledCustomerData date num={i}>
              {formatDate(timestampUtc)}
            </StyledCustomerData>
            <StyledCustomerData time num={i}>
              {formatTime(timestampUtc)}
            </StyledCustomerData>
            <StyledCustomerData taskType altType={type === 'Text'} num={i}>
              {displayType(type, description)}
            </StyledCustomerData>
            <StyledCustomerData num={i}>{byUserName}</StyledCustomerData>
            <StyledCustomerData sort num={i}>
              <StyledIcon
                data-testid={`Click ${type}`}
                onClick={() => {
                  addToExpansionList(i);
                }}
                className={expand.includes(i) ? 'fas fa-sort-down' : 'fas fa-sort-up'}
              />
            </StyledCustomerData>
          </StyledCustomerTableSections>
          {showExpandedInfo(i, type, data)}
        </React.Fragment>
      );
    });
  };

  const checkIfTableShouldRender = () => {
    if (historyData?.length > 0) {
      return (
        <StyledCustomers>
          <tbody>{renderTableData()}</tbody>
        </StyledCustomers>
      );
    }
    if (historyTypeToDisplay && historyData?.length > 0) {
      return (
        <StyledNoDataMessage data-testid={'EmptyHistoryTypeMessage'}>
          {`There is currently no ${historyTypeToDisplay.toLowerCase()} history for this member`}
        </StyledNoDataMessage>
      );
    }
    if (!ngmIsPending || !isPending) {
      return (
        <StyledNoDataMessage data-testid={'EmptyHistoryMessage'}>
          There is currently no history for this member
        </StyledNoDataMessage>
      );
    } else {
      return <StyledNoDataMessage>Loading</StyledNoDataMessage>;
    }
  };

  const applyFilterSelection = (e) => {
    setHistoryTypeToDisplay(e.target.value);
  };

  const handleClearFilter = () => {
    setExpand([]);
    setHistoryTypeToDisplay(historyTypeFilterConfig.all);
  };

  function displayType(type, description) {
    if (type.startsWith('Legacy')) {
      return type.split('Legacy ').pop();
    }
    if (type === 'Task' && description === 'CARD - ') {
      return 'Received Greeting Card';
    } else if (
      type === enums?.MemberHistoryType.find((t) => t.displayName === historyTypeFilterConfig.greetingCard)?.displayName
    ) {
      return 'Card Reply';
    } else {
      return type;
    }
  }

  return (
    <div id="Dashboard">
      <StyledHeaderContainer>
        <StyledTextHeader>Member History</StyledTextHeader>
        <StyledSearchBar>
          <StyledSelect
            onChange={(e) => {
              applyFilterSelection(e);
            }}
            id="historyTaskFilter"
            name="historyTaskFilter"
            value={historyTypeToDisplay}
          >
            <option value={historyTypeFilterConfig.all}>{'All Task Types'}</option>;
            {consolidatedEnums ? (
              consolidatedEnums.map((data) => {
                return <option key={data.code} value={`${data.displayName}`}>{`${data.displayName}`}</option>;
              })
            ) : (
              <option data-testid="TaskSubType" value={'Loading'}>
                Loading
              </option>
            )}
          </StyledSelect>
          <StyledClearFilterButton onClick={handleClearFilter}>Clear Filter</StyledClearFilterButton>
        </StyledSearchBar>
        {checkIfTableShouldRender()}
      </StyledHeaderContainer>
    </div>
  );
};

MemberHistoryData.propTypes = {
  memberInfo: PropTypes.object,
  ngm: PropTypes.bool,
};

export default MemberHistoryData;

import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
`;

export const TextHeader = styled.h2`
  margin: 0;
  margin-top: 31px;
  margin-left: 85px;
  font-family: karla;
  color: #015b5b;
  font-size: 32px;
  font-weight: bold;
  width: fit-content;
  padding: 0;
`;

export const DataRow = styled.div`
  display: flex;
  font-size: 16px;
  flex-flow: wrap;

  &.top-form {
    padding-top: 15px;
    padding-left: 87px;
    padding-bottom: 30px;
  }

  &.text-template-row {
    border-bottom: 1px solid #d9d9d9;
  }
`;

export const DataColumn = styled.div`
  &.language {
    width: 280px;
  }

  &.flex-box {
    display: flex;
    flex-wrap: wrap;
  }

  &.text-list {
    width: 34%;
    max-width: 477px;
    border-right: 1px solid #;
  }

  &.text-preview {
    flex: 1;
  }
`;

export const SelectStyle = styled.select`
  height: 32px;
  border: 2px solid #e9e7e7;
  border-radius: 9px;
  display: block;
  width: 100%;
  max-width: 400px;
  margin-top: 2px;
  color: #0f145b;

  &.push-down {
    margin-bottom: 23px;
  }

  ${DataColumn}.language & {
    width: 185px;
  }
`;

export const Label = styled.label`
  color: #0f0f0f;
  font-size: 14px;
  font-family: lato;
  display: block;
  width: 100%;
  padding-bottom: 7px;
`;

export const MemberTextContainer = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: lato;
`;

export const InputStyle = styled.input`
  border: 1px solid #e9e7e7;
  border-radius: 9px;
  color: #0f145b;
  font-size: 14px;
  line-height: 32px;
  height: 32px;
  vertical-align: middle;
  padding-left: 11px;
  box-sizing: border-box;
  width: 185px;
`;

export const HeaderStyle = styled.h3`
  background-color: #8483aa;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  font-family: karla;
  font-weight: bold;
  padding-left: 86px;
  padding-top: 9px;
  padding-bottom: 5px;
  height: 42px;
  box-sizing: border-box;
  margin: 0;

  &.preview-header {
    padding-left: 43px;
  }
`;

export const TextTemplateList = styled.ul`
  margin: 0;
  padding: 0;

  > li {
    border-bottom: 1px solid #d9d9d9;
    margin: 0;
    padding: 0;
    list-style: none;
    font-family: 'Open Sans';
    font-weight: 600;

    > button {
      display: block;
      width: 100%;
      background: transparent;
      padding: 18px 0;
      padding-left: 86px;
      margin: 0;
      border: 0;
      appearance: none;
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
      color: #0f145b;
      text-decoration: none;
      font-family: 'Open Sans';
      cursor: pointer;
      text-align: left;
      padding-left: 86px;

      &:hover,
      &.active {
        color: #378788;
      }

      &:focus {
        outline: 0;
      }
    }

    &:last-child {
      border-bottom: 0;
    }
  }
`;

export const PreviewContainer = styled.div`
  padding-right: 95px;
  padding-left: 43px;
  border-left: 1px solid #d9d9d9;
  height: calc(100% - 42px);
  box-sizing: border-box;

  > h4 {
    font-size: 18px;
    line-height: 22px;
    color: #0f145b;
    font-weight: 600;
    font-family: 'Open Sans';
    padding: 21px 0 34px 0;
    margin: 0;
  }

  > p {
    font-size: 16px;
    line-height: 20px;
    color: #0f145b;
    font-family: 'Open Sans';
    padding: 0 0 10px 0;
    margin: 0;
  }
`;

export const SubmitButtonContainer = styled.div`
  display: flex;
  padding: 14px 0;
`;

export const SubmitButton = styled.input`
  background-color: #4a4f7f;
  color: white;
  width: 125px;
  height: 27px;
  border: 1px solid #4a4f7f;
  border-radius: 25px;
  margin: 0;
  margin-right: 14px;
  cursor: pointer;
  font-family: karla;
  margin-left: auto;
`;

export const CancelButton = styled.div`
  background-color: white;
  color: #4a4f7f;
  width: 125px;
  height: 27px;
  border: 1px solid #4a4f7f;
  border-radius: 25px;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: karla;
`;

export const SpinnerButton = styled.div`
  background-color: #4a4f7f;
  color: white;
  width: 123px;
  height: 25px;
  border: 1px solid #4a4f7f;
  border-radius: 25px;
  margin: 0;
  margin-right: 14px;
  cursor: pointer;
  font-family: karla;
  margin-left: auto;
`;

export const SubmissionRow = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  padding: 28px 95px 28px 0;
`;

export const SubmissionContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const ErrorMessage = styled.span`
  margin-top: 5px;
  font-size: 14px;
  margin-left: 10px;
  color: #de2b1b;
`;

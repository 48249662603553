export const fields = [
  {
    name: 'dob',
    label: 'DOB:',
  },
  {
    name: 'phone',
    label: 'Phone:',
  },
  {
    name: 'firstname',
    label: 'First Name:',
  },
  {
    name: 'lastname',
    label: 'Last Name:',
  },
];

export const headers = [
  { header: 'Organization', name: 'organizationName' },
  { header: 'DOB', name: 'birthDay' },
  { header: 'First Name', name: 'firstName' },
  { header: 'Last Name', name: 'lastName' },
  { header: 'Phone', name: 'phoneNumber' },
];

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import {
  ActionTooltip,
  DataLinkButton,
  ErrorContainer,
  Header,
  InfoData,
  InfoLabel,
  MigrationActionButton,
  MigrationActionsContainer,
  MigrationErrorMessage,
  MigrationInfoContainer,
  StyledDialog,
} from './styles';
import { MigrationRequestModuleStore } from 'Administrator_Pages/MigrationRequests/MigrationRequestModuleStore';
import { getViewMigrationFields } from '../config';
import { MigrationStatusType } from 'Administrator_Pages/MigrationRequests/enumerations/enums';
import { ENROLLED } from '../../../../../initialization/Routes/RoutesConfig';
import urlcat from 'urlcat';

const ViewMigration = ({ open, onClose, onReset, request }) => {
  const navigate = useNavigate();
  const migrationStore = MigrationRequestModuleStore.Instance().Use();
  const [modalOpen, setModalOpen] = useState(open);
  const isLoading = migrationStore.IsLoading;
  const viewMigrationFields = getViewMigrationFields(request);
  const lastErrorAttemptPresent =
    !!request?.lastMigrationAttemptErrorMessage && !!request?.lastMigrationAttemptEndedOnUtc;
  const retryEnabled = MigrationStatusType[request?.migrationStatus] === MigrationStatusType.FAILED;
  const deleteEnabled = [MigrationStatusType.PENDING, MigrationStatusType.FAILED].includes(
    MigrationStatusType[request?.migrationStatus],
  );
  const navigateToProfileMessage = "Navigate to the member's profile";
  const retryTooltipMessage = retryEnabled
    ? 'When a migration fails and all details look correct, the migration can be re-attempted by clicking "Retry".'
    : 'Only migration requests that are failed may be retried.';
  const deleteTooltipMessage = deleteEnabled
    ? 'When a member either no longer requires a migration or if any details here are incorrect and requires a new migration request to be created, the migration can be deleted by clicking "Delete".'
    : 'Only migration requests that are pending or failed may be deleted.';

  const onModalClose = () => {
    setModalOpen(false);

    if (onClose) {
      onClose();
    }
  };

  const onCloseReset = () => {
    if (onReset) {
      onReset();
    }

    onModalClose();
  };

  const onOpenMember = () => navigate(urlcat(ENROLLED, { userId: request?.userId }));
  const onClickRetry = () =>
    migrationStore.RetryMigration({ migrationGuid: request?.guid }).then((success) => {
      if (success) {
        onCloseReset();
      }
    });
  const onClickDelete = () =>
    migrationStore.DeleteMigration({ migrationGuid: request?.guid }).then((success) => {
      if (success) {
        onCloseReset();
      }
    });

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  return (
    <>
      <StyledDialog open={modalOpen} aria-labelledby="migration-title">
        <Header>
          <Typography id="migration-title" fontSize={24} margin="0 auto">
            View Migration
          </Typography>
          <IconButton onClick={onModalClose}>
            <CloseIcon />
          </IconButton>
        </Header>
        <MigrationInfoContainer container>
          <Grid item xs={7}>
            <Typography fontSize={20} fontWeight={700}>
              Status: {request?.migrationStatus}
            </Typography>
            {lastErrorAttemptPresent && (
              <>
                <ErrorContainer>
                  <MigrationErrorMessage fontSize={12}>
                    Error: {request?.lastMigrationAttemptErrorMessage}
                  </MigrationErrorMessage>
                </ErrorContainer>
                <Typography fontSize={12}>Migration Date: {request?.lastMigrationAttemptEndedOnUtc}</Typography>
              </>
            )}
          </Grid>
          <MigrationActionsContainer item xs={5}>
            <ActionTooltip title={deleteTooltipMessage}>
              <div>
                <MigrationActionButton
                  variant="contained"
                  disabled={!deleteEnabled}
                  onClick={onClickDelete}
                  loading={deleteEnabled && isLoading}
                >
                  Delete
                </MigrationActionButton>
              </div>
            </ActionTooltip>
            <ActionTooltip title={retryTooltipMessage}>
              <div>
                <MigrationActionButton
                  variant="contained"
                  disabled={!retryEnabled}
                  onClick={onClickRetry}
                  loading={retryEnabled && isLoading}
                >
                  Retry
                </MigrationActionButton>
              </div>
            </ActionTooltip>
          </MigrationActionsContainer>
          <InfoLabel item xs={4}>
            {`Member's Name`}
          </InfoLabel>
          <InfoData item xs={7}>
            <Tooltip title={navigateToProfileMessage}>
              <div>
                <DataLinkButton variant="text" onClick={onOpenMember}>
                  {request?.userFullName}
                </DataLinkButton>
              </div>
            </Tooltip>
          </InfoData>
          <InfoLabel item xs={4}>
            User Id
          </InfoLabel>
          <InfoData item xs={7}>
            <Tooltip title={navigateToProfileMessage}>
              <div>
                <DataLinkButton variant="text" onClick={onOpenMember}>
                  {request?.userId}
                </DataLinkButton>
              </div>
            </Tooltip>
          </InfoData>
          {viewMigrationFields?.map(({ title, value }, key) => (
            <React.Fragment key={key}>
              <InfoLabel item xs={4}>
                {title}
              </InfoLabel>
              <InfoData item xs={7}>
                {value}
              </InfoData>
            </React.Fragment>
          ))}
        </MigrationInfoContainer>
      </StyledDialog>
    </>
  );
};

ViewMigration.propTypes = {
  request: PropTypes.object,
  onClose: PropTypes.func,
  onReset: PropTypes.func,
  open: PropTypes.bool,
};

export default ViewMigration;

import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'Components/Spinner/Spinner';
import useMemberOtpCode from 'Tasking_Hooks/useMemberOtpCode';
import { OtpCodeButton } from './styles';

const MemberOtpCodeButton = ({ userId, otpType }) => {
  const { otpCode, isPending, error, retrieveMemberOtpCode } = useMemberOtpCode();

  const handleClick = () => retrieveMemberOtpCode(userId, otpType);

  function buttonContent() {
    if (error) {
      return <span data-testid="ErrorMessage">Failed to retrieve OTP Code</span>;
    }

    if (isPending) {
      return (
        <>
          <span>OTP Code</span>
          <Spinner
            data-testid="Spinner"
            height="10px"
            width="10px"
            marginLeft="10px"
            marginTop="0"
            marginBottom="-1px"
          />
        </>
      );
    }

    if (otpCode) return <span data-testid="Code">OTP Code: {otpCode.code}</span>;

    return <span data-testid="InitialButtonText">Get OTP Code</span>;
  }

  return (
    <OtpCodeButton data-testid="OtpCodeButton" onClick={handleClick}>
      {buttonContent()}
    </OtpCodeButton>
  );
};
MemberOtpCodeButton.propTypes = {
  userId: PropTypes.number,
  otpType: PropTypes.string,
};

export default MemberOtpCodeButton;

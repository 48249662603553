import axios from 'axios';
import 'regenerator-runtime/runtime.js';
import { useOidc } from '@axa-fr/react-oidc';
import { useAuthentication } from './useAuthentication';

const getAuthClient = async () => {
  const { isAuthenticated } = useOidc();
  const { getAccessToken } = useAuthentication();

  const accessToken = await getAccessToken();
  let authorizedApi;

  if (isAuthenticated && accessToken) {
    authorizedApi = axios.create();

    const dateTimeOverride = localStorage.getItem('dateTimeOverride');
    if (dateTimeOverride && process.env.ENVIRONMENT !== 'production') {
      authorizedApi.defaults.headers.common['x-datetime-override'] = dateTimeOverride;
    }
    authorizedApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }

  return authorizedApi;
};

export default getAuthClient;

export function formatDate(d) {
  if (!d) return '';

  const date = new Date(d);
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }

  return mm + '/' + dd + '/' + yyyy;
}

export function formatDateEditTask(d) {
  const date = new Date(d);
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }

  return yyyy + '-' + mm + '-' + dd;
}

export function formatTime(d) {
  if (typeof d === 'undefined') return '';

  return new Date(d).toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' });
}

import { useEffect, useState } from 'react';
import useSWR from 'swr';
import getAuthClient from 'Tasking_Helpers/getAuthClient';

const getScreeningResponseUrl = 'v1/screeningresponse/';
const options = {
  revalidateOnFocus: false,
};

const getScreeningResponseInternal = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useGetScreeningResponse = () => {
  const [screeningResponse, setScreeningResponse] = useState();
  const [url, setUrl] = useState();
  const [isGetPending, setIsGetPending] = useState(true);
  const {
    data: screeningResponseResult,
    errorData,
    error: getError,
    mutate,
  } = useSWR(url, getScreeningResponseInternal, options);

  const getScreeningResponse = (userId, screeningResponseGuid) => {
    setUrl(`${process.env.API_BASE_URL}/${getScreeningResponseUrl}${userId}:${screeningResponseGuid}`);
    mutate(screeningResponseResult);
  };

  useEffect(() => {
    if (screeningResponseResult) {
      setScreeningResponse(screeningResponseResult.data);
    }

    setIsGetPending(!screeningResponseResult && !getError);
  }, [screeningResponseResult, getError]);

  return {
    screeningResponse,
    getError,
    screeningScoreErrorMessage: errorData,
    isGetPending,
    getScreeningResponse,
  };
};

export default useGetScreeningResponse;

import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { BackButton, Footer, SearchFormContainer, SearchHeaderContainer } from './styles';
import ResultsGrid from 'Components/ResultsGrid/ResultsGrid';
import { MigrationRequestModuleStore } from 'Administrator_Pages/MigrationRequests/MigrationRequestModuleStore';
import Button from 'Components/Button';
import { MIGRATIONREQUESTS_NEW } from '../../../../../initialization/Routes/RoutesConfig';
import { useNavigate } from 'react-router-dom';
import withInformation from '../withInformation';
import { headers } from './config';

const CodeSearch = () => {
  const navigate = useNavigate();
  const migrationStore = MigrationRequestModuleStore.Instance().Use();
  const infoStore = migrationStore.Information.Use();
  const searchResults = infoStore.SearchResults;
  const isLoading = migrationStore.isLoading || infoStore.IsLoading;

  const onDataClick = (item) =>
    infoStore.addInfo(item).then((response) => {
      if (response) {
        navigate(MIGRATIONREQUESTS_NEW);
      }
    });

  const onBack = () => {
    navigate(MIGRATIONREQUESTS_NEW);
    infoStore.cancel();
  };

  useEffect(() => {
    infoStore.search();
  }, []);

  return (
    <>
      <SearchHeaderContainer>
        <BackButton variant={Button.variant.TEXT} onClick={onBack}>
          {'< Back'}
        </BackButton>
      </SearchHeaderContainer>
      <SearchFormContainer>
        <Typography component="h1" fontSize={24} fontWeight={700}>
          Choose Code
        </Typography>
        <ResultsGrid
          fetchNumber={10}
          loading={isLoading}
          headerMap={headers}
          data={searchResults}
          onDataClick={onDataClick}
        />
        <Footer />
      </SearchFormContainer>
    </>
  );
};

export default withInformation(CodeSearch);

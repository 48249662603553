import React from 'react';
import { FormLabel, MenuItem, Select } from '@mui/material';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

const SelectInput = ({ control, name, label, placeholder, options }) => {
  return (
    <>
      <FormLabel id={`${name}-label`}>{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        defaultValue={0}
        render={({ field }) => (
          <Select
            {...field}
            id={`${name}-select`}
            aria-labelledby={`${name}-label`}
            labelId={`${name}-label`}
            displayEmpty
          >
            <MenuItem disabled value={0}>
              {placeholder}
            </MenuItem>
            {options?.map(({ name, text }, index) => (
              <MenuItem key={index} name={name} value={name}>
                {text}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </>
  );
};

SelectInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string,
    }),
  ),
};

export default SelectInput;

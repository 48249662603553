import React from 'react';
import {
  ModalContainer,
  ModalContent,
  AbandoButton,
  CancelButton,
  ModalButtonsContainer,
  ModalInnerContent,
  ModalTextContent,
} from './styles';
import PropTypes from 'prop-types';

const Modal = ({ display, setModal, onClick }) => {
  const displayData = () => {
    if (display)
      return (
        <ModalContent data-testid="ModalContent">
          <ModalInnerContent>
            <span style={{ color: 'white' }}>Abandon Activity</span>
          </ModalInnerContent>
          <ModalTextContent>
            <b>Warning!</b>
            <div style={{ marginTop: '10px', width: '80%' }}>
              If you abandon this activity it will not be saved and it will not be added to the task.
            </div>
            <ModalButtonsContainer style={{ marginTop: '-3px' }}>
              <AbandoButton onClick={onClick} value="Abandon" data-testid="AbandonButton">
                Abandon
              </AbandoButton>
              <CancelButton
                onClick={() => {
                  setModal(false);
                }}
                value="Clear"
                type="reset"
                data-testid="CancelButton"
              >
                Cancel
              </CancelButton>
            </ModalButtonsContainer>
          </ModalTextContent>
        </ModalContent>
      );
  };

  return <ModalContainer data-testid="ModalContainer">{displayData()}</ModalContainer>;
};

Modal.propTypes = {
  onClick: PropTypes.func,
  display: PropTypes.bool,
  setModal: PropTypes.func,
};

export default Modal;

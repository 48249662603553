import React, { useEffect, useState } from 'react';
import {
  TextHeader,
  HeaderContainer,
  InputStyle,
  Label,
  SubmitButton,
  SubmitButtonContainer,
  CancelButton,
  DataColumn,
  NonAppEnrollmentContainer,
  SpinnerButton,
  CustomError,
} from './styles';
import { useForm } from 'react-hook-form';
import useEnrollUser from 'Tasking_Hooks/useEnrollUser';
import { useNavigate } from 'react-router-dom';
import useCompleteActivity from 'Tasking_Hooks/useCompleteActivity';
import PropTypes from 'prop-types';
import Spinner from 'Components/Spinner/Spinner';
import { parseErrorMessage, parseMultiErrorMessage } from 'Helpers/parseApiErrorMessage';
import { ENROLLED_TASKS_EDIT } from '../../../initialization/Routes/RoutesConfig';
import urlcat from 'urlcat';

const NonAppEnrollment = ({
  setActionToDisplay,
  setCurrentTab,
  memberInfo,
  taskGuid,
  values,
  setValues,
  setSelectedTask,
}) => {
  const { register, handleSubmit } = useForm();
  const [userId, setUserId] = useState();
  const [customError, setCustomError] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const { response, isPending: enrollUserIsPending, enrollmentErrorMessage, enrollUser } = useEnrollUser();
  const {
    completedActivity,
    isCompleteActivityPending,
    completeActivity,
    errorMessage: completeActivityErrorMessage,
  } = useCompleteActivity();
  const navigate = useNavigate();

  const navigateToCustomerPage = (userId, taskGuid) => {
    setValues();
    setSelectedTask();
    navigate(urlcat(ENROLLED_TASKS_EDIT, { userId: userId, taskGuid: taskGuid }), { replace: true });
  };

  useEffect(() => {
    setIsPending(enrollUserIsPending || isCompleteActivityPending);
  }, [enrollUserIsPending, isCompleteActivityPending]);

  useEffect(() => {
    if (enrollmentErrorMessage) {
      setCustomError(parseError(enrollmentErrorMessage));
    } else if (completeActivityErrorMessage) {
      setCustomError(parseError(completeActivityErrorMessage));
    }
  }, [enrollmentErrorMessage, completeActivityErrorMessage]);

  const parseError = (errorMessage) => {
    if (errorMessage.length > 0) {
      return parseErrorMessage(errorMessage);
    } else if (Object.keys(errorMessage.errors)?.length > 0) {
      return parseMultiErrorMessage(errorMessage.errors);
    }
  };

  useEffect(() => {
    if (response) {
      setUserId(response);
      let valueForCompleteActivity = {
        NeedFollowUp: values?.needFollowup,
        ContactOutcome: values?.contactOutcomeOption,
        Actions: values?.actionTypes,
        Notes: values?.notes,
      };
      completeActivity(valueForCompleteActivity, memberInfo?.organizationId, taskGuid);
    }
  }, [response]);

  useEffect(() => {
    if (completedActivity && userId) {
      navigateToCustomerPage(userId, taskGuid);
    }
  }, [completedActivity]);

  const onSubmit = (e) => {
    setCustomError();
    const enrollmentData = {
      ngmId: memberInfo?.id,
      organizationId: memberInfo?.organizationId,
      userName: values?.nonAppEnrollment?.userName,
      firstName: memberInfo?.firstName,
      lastName: memberInfo?.lastName,
      phoneNumber: values?.nonAppEnrollment?.phoneNumber ?? e.phoneNumber,
      isNonAppUser: true,
      locale: memberInfo?.locale,
    };

    if (!enrollmentData?.userName || enrollmentData?.userName.length < 6) {
      setCustomError('Username must be at least 6 letters.');
      return null;
    }

    if (!enrollmentData?.phoneNumber || enrollmentData?.phoneNumber.length < 10) {
      setCustomError('Please ensure you have entered a valid phone number.');
      return null;
    }

    if (!values || !values.contactOutcome || values.needFollowup === undefined) {
      setCustomError('Please ensure you have filled out the activity form before attempting to enroll a member.');
      return null;
    }
    enrollUser(enrollmentData);
  };

  const cancelSelected = () => {
    setValues({ ...values, currentTab: '', actionToDisplay: '', nonAppEnrollment: undefined });
    setActionToDisplay('');
    setCurrentTab('');
  };

  const updateValue = (name, value) => {
    setValues({ ...values, nonAppEnrollment: { ...values?.nonAppEnrollment, [name]: value } });
  };

  return (
    <>
      <CustomError data-testid="CustomError">{customError}</CustomError>

      <HeaderContainer>
        <TextHeader>Non-App Enrollment</TextHeader>
      </HeaderContainer>
      <NonAppEnrollmentContainer data-testid="NonAppEnrollmentContainer">
        <form data-testid="NonAppEnrollForm" onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '-10px' }}>
          <DataColumn>
            <div>
              <Label htmlFor="phoneNumber">Phone Number</Label>
              <div style={{ margin: '10px 0' }} />
              <InputStyle
                {...register('phoneNumber')}
                type="text"
                id="phoneNumber"
                data-testid="PhoneNumberInput"
                onChange={(e) => {
                  updateValue('phoneNumber', e.target.value);
                }}
                defaultValue={
                  values?.nonAppEnrollment?.phoneNumber
                    ? values?.nonAppEnrollment?.phoneNumber
                    : memberInfo?.normalizedPhone
                    ? memberInfo?.normalizedPhone
                    : ''
                }
              />
            </div>

            <div>
              <Label htmlFor="userName">User Name (must be at least 6 letters)</Label>
              <div style={{ margin: '10px 0' }} />
              <InputStyle
                type="text"
                name="userName"
                id="userName"
                data-testid="UserNameInput"
                onChange={(e) => {
                  updateValue('userName', e.target.value);
                }}
                defaultValue={values?.nonAppEnrollment?.userName}
              />
            </div>
          </DataColumn>

          <SubmitButtonContainer>
            {isPending ? (
              <SpinnerButton data-testid="SpinnerButton">
                <Spinner />
              </SpinnerButton>
            ) : (
              <SubmitButton
                data-testid="SubmitButton"
                name={'submit'}
                value={'Enroll & Close Activity'}
                type="submit"
              />
            )}
            <CancelButton onClick={cancelSelected} value="Clear" type="reset" data-testid="CancelButton">
              Cancel
            </CancelButton>
          </SubmitButtonContainer>
        </form>
      </NonAppEnrollmentContainer>
    </>
  );
};

NonAppEnrollment.propTypes = {
  setActionToDisplay: PropTypes.func,
  setCurrentTab: PropTypes.func,
  onChange: PropTypes.func,
  setActions: PropTypes.func,
  memberInfo: PropTypes.object,
  activityFormData: PropTypes.object,
  taskGuid: PropTypes.number,
  values: PropTypes.object,
  setValues: PropTypes.func,
  setSelectedTask: PropTypes.func,
};

export default NonAppEnrollment;

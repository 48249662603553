import styled from 'styled-components';
import { MailOutline } from '@mui/icons-material';

export const StyledCustomers = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 1274px;
  margin-top: 38px;
  margin-left: 85px;
  table-layout: fixed;
  font-family: Karla, sans-serif;
`;

export const StyledCustomerData = styled.td`
  white-space: nowrap;
  border-bottom: 0.75px solid #e4e6e3;
  color: ${(props) => (props.taskType ? '#8483AA' : 'black')};
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  cursor: ${(props) => (props.pointer ? 'pointer' : null)};
  font-weight: bold;
  font-family: Open Sans, sans-serif;
  width: ${(props) => (props.sort ? '20px' : 'auto')};
`;

export const StyledCustomerTableSections = styled.tr``;

export const StyledIcon = styled.i`
  color: #0f145b;
  height: 14px;
  width: 14px;
  cursor: pointer;
  font-size: 22px;
  margin-top: 12px;
`;

export const StyledHeaderContainer = styled.div``;

export const StyledTextHeader = styled.div`
  margin-top: 28px;
  margin-left: 85px;
  font-family: Karla, sans-serif;
  color: #015b5b;
  font-size: 34px;
  font-weight: bold;
`;

export const StyledExpandedInfo = styled.div`
  width: 1274px;
  height: auto;
  background-color: #f8f7fa;
`;

export const StyledNoDataMessage = styled.div`
  margin-top: 38px;
  margin-left: 85px;
  font-family: Karla, sans-serif;
  font-size: 18px;
  color: red;
  margin-bottom: -20px;
`;

export const StyledSearchBar = styled.div`
  display: flex;
  align-items: center;
  background-color: #015353;
  min-height: 65px;
  width: 1274px;
  margin-top: 38px;
  margin-left: 85px;
`;

export const StyledSelect = styled.select`
  width: 190px;
  height: 32px;
  margin-left: 740px;
  border-radius: 9px;
  color: #0f145b;
`;

export const StyledClearFilterButton = styled.div`
  background-color: white;
  color: #4a4f7f;
  width: 125px;
  height: 27px;
  border: 1px solid #4a4f7f;
  border-radius: 25px;
  margin-left: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Karla, sans-serif;
`;

export const StyledMailOutline = styled(MailOutline)`
  color: #0f145b;
`;

const configuration = {
  client_id: 'pyx.iq',
  redirect_uri: `${process.env.BASE_URL}${process.env.REDIRECT_URI}`,
  response_type: 'code',
  post_logout_redirect_uri: process.env.BASE_URL,
  scope: 'openid pyx.api.readwrite offline_access',
  authority: process.env.AUTHORITY,
  silent_redirect_uri: `${process.env.BASE_URL}${process.env.SILENT_REDIRECT_URI}`,
  automaticSilentRenew: true,
  loadUserInfo: true,
};

export default configuration;

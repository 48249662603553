import React from 'react';
import PropTypes from 'prop-types';
import { Slider as MuiSlider } from '@mui/material';

const Slider = ({ index, options, onChange, ...props }) => {
  const extraInputProps = { 'aria-labelledby': props['aria-labelledby'] };
  const { name: sliderName, ...sliderOptions } = options[0];

  return (
    <>
      <MuiSlider
        {...props}
        aria-labelledby={props['aria-labelledby']}
        name={`slider-${index}`}
        onChange={(e) => onChange(e, { name: sliderName, value: e.target.value })}
        defaultValue={0}
        value={props?.value?.value}
        valueLabelDisplay="auto"
        {...extraInputProps}
        {...sliderOptions}
      />
    </>
  );
};

Slider.displayName = `Slider`;

Slider.propTypes = {
  index: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  ['aria-labelledby']: PropTypes.string,
};

export default Slider;

import 'regenerator-runtime/runtime.js';
import { useState } from 'react';
import useAxiosSubmit from './useAxiosSubmit';

const useCreateMemberConcern = () => {
  const [createMemberConcernData, setMemberConcernData] = useState();
  const [url, setUrl] = useState();
  const { response, isLoading, hasErrored, errorData } = useAxiosSubmit(url, createMemberConcernData);

  const createMemberConcern = async (request) => {
    setUrl(`${process.env.API_BASE_URL}/v5/memberconcerns/`);
    setMemberConcernData(request);
  };

  return {
    memberConcernGuid: response,
    memberConcernErrorMessage: errorData,
    error: hasErrored,
    isCreatePending: isLoading,
    createMemberConcern,
  };
};

export default useCreateMemberConcern;

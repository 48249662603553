import React, { useState, useEffect, useContext } from 'react';
import { useOidcUser } from '@axa-fr/react-oidc';
import {
  CustomersContainer,
  Customers,
  CustomerData,
  CustomerDataHeader,
  StatusBox,
  CustomerTableSections,
  SearchBar,
  PaginationContent,
  NoDataMessageTabs,
  Tabs,
  Tab,
  ArrowButton,
  ScrollableContent,
  DashboardContainer,
} from './styles';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { TaskSelectedContext } from 'Tasking_Contexts/TaskSelectedContext';
import AssignUserModal from 'Tasking_Components/AssignUserModal/AssignUserModal';
import useEnumLists from 'Tasking_Hooks/useEnumLists';
import useGetTaskList from 'Tasking_Hooks/useGetTaskList';
import useAssignOptions from 'Tasking_Hooks/useAssignOptions';
import datediff from 'Tasking_Helpers/getDateDiff';
import useGetOrganizations from 'Tasking_Hooks/useGetOrganizations';
import { formatApiDate } from 'Util/dateFunctions';
import {
  HeaderTransform,
  LAST_ORG_SELECTION_KEY,
  TABS,
  PAGE_SIZE,
  SearchFilterTypes,
  defaultSearchFilters,
  getSelectConfig,
} from './dashboardConfig';
import SearchSelect from './SearchSelect/SearchSelect';
import { ENROLLED_TASKS_EDIT, NONENROLLED_TASKS_EDIT } from '../../../initialization/Routes/RoutesConfig';
import urlcat from 'urlcat';

const Dashboard = ({ specificOrg, specificProfile, ngm, profileSearch }) => {
  const { enums } = useEnumLists();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState(TABS[0].id);
  const [modal, setModal] = useState(false);
  const { oidcUser } = useOidcUser();
  const [triedToMatchAssignedUser, setTriedToMatchAssignedUser] = useState(false);
  const { setSelectedTask } = useContext(TaskSelectedContext);
  const { getTaskList, taskList, isTaskListPending } = useGetTaskList();
  const { organizations } = useGetOrganizations();
  const [assignTaskInfo, setAssignTaskInfo] = useState();
  const { assignOptions, isAssignableUsersPending } = useAssignOptions();
  const [searchState, setSearchState] = useState(defaultSearchFilters);
  const [searchOptions, setSearchOptions] = useState();

  useEffect(() => {
    const org = localStorage.getItem(LAST_ORG_SELECTION_KEY);
    if (specificOrg) {
      updateFilterState(SearchFilterTypes.ORGANIZATION, specificOrg);
    } else if (org && org !== '') {
      updateFilterState(SearchFilterTypes.ORGANIZATION, org);
    }
  }, []);

  useEffect(() => {
    if (assignOptions && oidcUser) {
      const foundUserId = assignOptions?.find((u) => u.userId == oidcUser?.sub)?.userId;
      if (foundUserId) {
        updateFilterState(SearchFilterTypes.ASSIGNEDUSER, foundUserId);
      }
      setTriedToMatchAssignedUser(true);
    }
  }, [assignOptions, oidcUser]);

  useEffect(() => {
    if (triedToMatchAssignedUser) {
      const searchType = profileSearch ? 'PROFILE' : 'ASSIGNED';
      getTaskList(createTaskRequest(searchType), currentTab);
    }
  }, [currentPage, currentTab, triedToMatchAssignedUser]);

  useEffect(() => {
    updateSearchFilters(searchState);
  }, [triedToMatchAssignedUser, currentTab]);

  useEffect(() => {
    if (!modal && triedToMatchAssignedUser) {
      updateProfile();
    }
  }, [triedToMatchAssignedUser, modal]);

  const updateProfile = () => {
    setCurrentPage(1);
    const searchType = profileSearch ? 'PROFILE' : 'ASSIGNED';
    getTaskList(createTaskRequest(searchType), currentTab);
  };

  const createTaskRequest = (searchType) => {
    return {
      OrganizationId: searchState[SearchFilterTypes.ORGANIZATION] ?? undefined,
      networkGroupMemberId: ngm && searchType !== 'ASSIGNED' ? specificProfile : undefined,
      profileId: ngm && searchType !== 'ASSIGNED' ? undefined : specificProfile,
      networkIds: searchState[SearchFilterTypes.NETWORK],
      AssignedToUserId: searchState[SearchFilterTypes.ASSIGNEDUSER],
      TaskStatusFilter: searchState[SearchFilterTypes.TASKSTATUS],
      taskSubType: searchState[SearchFilterTypes.TASKSUBTYPES],
      Offset: (currentPage - 1) * PAGE_SIZE,
      Fetch: PAGE_SIZE,
    };
  };

  const taskClicked = (task) => {
    setSelectedTask(task);
    if (task.networkGroupMemberId) {
      navigate(
        urlcat(NONENROLLED_TASKS_EDIT, {
          orgId: task.organizationId,
          ngmId: task.networkGroupMemberId,
          taskGuid: task?.taskGuid,
        }),
        {
          state: {
            showTaskScreen: true,
          },
        },
      );
    } else {
      navigate(urlcat(ENROLLED_TASKS_EDIT, { userId: task.userId, taskGuid: task?.taskGuid }), {
        state: {
          showTaskScreen: true,
        },
      });
    }
  };

  const renderTableData = () => {
    if (taskList.length > 0) {
      return taskList.map((customers, i) => {
        const {
          status,
          taskType,
          taskSubType,
          priority,
          firstName,
          lastName,
          dueDateUtc,
          organizationName,
          assignedToName,
          createdByName,
        } = customers;

        return (
          <CustomerTableSections
            onClick={() => {
              taskClicked(customers);
            }}
            data-testid={`TableSections${[i]}`}
            key={i}
          >
            <CustomerData data-testid={`StatusBox${[i]}`}>
              <StatusBox
                complete={status === 'COMPLETE'}
                current={datediff(new Date(dueDateUtc))}
                overdueOneDay={datediff(new Date(dueDateUtc)) < -1}
                overdue={datediff(new Date(dueDateUtc)) <= -2}
              >
                {status === 'COMPLETE' ? 'Complete' : datediff(new Date(dueDateUtc)) >= -1 ? 'Current' : `Overdue`}
              </StatusBox>
            </CustomerData>
            <CustomerData data-testid={`DataPriority${[i]}`}>{priority === 'NORMAL' ? '' : priority}</CustomerData>
            <CustomerData data-testid={`Status${[i]}`}>{status}</CustomerData>
            <CustomerData data-testid={`TaskType${[i]}`}>{taskType}</CustomerData>
            <CustomerData data-testid={`TaskSubType${[i]}`}>{taskSubType}</CustomerData>
            <CustomerData data-testid={`FirstName${[i]}`}>{firstName}</CustomerData>
            <CustomerData data-testid={`LastName${[i]}`}>{lastName}</CustomerData>
            <CustomerData data-testid={`DueDateUtc${[i]}`}>{formatApiDate(dueDateUtc.substring(0, 10))}</CustomerData>
            <CustomerData data-testid={`OrganizationName${[i]}`}>{organizationName}</CustomerData>
            <CustomerData
              data-testid="AssignedUserClick"
              onClick={(event) => onAssignedClick(customers, event)}
              assignedTo
            >
              <div>{assignedToName}</div>
            </CustomerData>
            <CustomerData>{createdByName}</CustomerData>
          </CustomerTableSections>
        );
      });
    } else {
      return <NoDataMessageTabs>There are currently no tasks available</NoDataMessageTabs>;
    }
  };

  const renderTableHeader = () => {
    const header = Object.keys(taskList[0]);

    const sortArray = Object.keys(HeaderTransform);
    let headerOrder = [];

    sortArray.forEach((key) => {
      let found = false;
      header.filter((item) => {
        if (!found && item === key) {
          headerOrder.push(item);
          found = true;
          return false;
        } else {
          return true;
        }
      });
    });

    if (headerOrder.length > 0) {
      headerOrder = ['dueDateStatus', ...headerOrder];
      return headerOrder.map((key, index) => (
        <CustomerDataHeader key={index} colSpan={key === 'AssignedTo' ? 2 : null}>
          {HeaderTransform[key]}
        </CustomerDataHeader>
      ));
    } else {
      return null;
    }
  };

  const backwardPageButton = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const forwardPageButton = () => {
    if (taskList.length >= PAGE_SIZE) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderTableFooter = () => {
    return (
      <PaginationContent currentTab={currentTab} data-testid="TableFooter">
        <ArrowButton data-testid="BackwardPageButton" onClick={() => backwardPageButton()}>
          {'<'}
        </ArrowButton>
        <span data-testid="page">{`Page ${currentPage}`}</span>
        <ArrowButton data-testid="ForwardPageButton" onClick={() => forwardPageButton()}>
          {'>'}
        </ArrowButton>
      </PaginationContent>
    );
  };

  const tabClicked = (selectedTab) => {
    setCurrentPage(1);
    setCurrentTab(selectedTab);
    setSearchState({
      ...searchState,
      [SearchFilterTypes.TASKSUBTYPES]: defaultSearchFilters[SearchFilterTypes.TASKSUBTYPES],
    });
    updateSearchFilters(searchState, selectedTab);
  };

  const renderData = () => {
    if (taskList?.length > 0 && !isTaskListPending) {
      return (
        <>
          <ScrollableContent>
            <Customers>
              <tbody>
                <tr data-testid="TableHeader">{renderTableHeader()}</tr>
                {renderTableData()}
              </tbody>
            </Customers>
          </ScrollableContent>
          {renderTableFooter()}
        </>
      );
    } else {
      if (isTaskListPending) {
        return generateNoDataMessageTable('Loading');
      } else {
        return generateNoDataMessageTable('There are currently no tasks available');
      }
    }
  };

  const generateNoDataMessageTable = (value) => {
    return (
      <Customers>
        <tbody>
          <tr>
            <td>
              <NoDataMessageTabs>{value}</NoDataMessageTabs>
            </td>
          </tr>
        </tbody>
      </Customers>
    );
  };

  const onAssignedClick = (taskInfo, event) => {
    event.stopPropagation();
    setAssignTaskInfo(taskInfo);
    setModal(true);
  };

  function updateSearchFilters(searchData, selectedTab) {
    const configProps = {
      assignedUserPending,
      searchState: searchData,
      organizations,
      enums,
      assignOptions,
      currentTab: selectedTab ?? currentTab,
    };
    setSearchOptions(getSelectConfig(configProps));
  }

  const updateFilterState = (filter, filterValue) => {
    let filterState = searchState;

    if (filter === SearchFilterTypes.ORGANIZATION) {
      if (!specificOrg) {
        // This should store the org and use that when they return to this screen
        localStorage.setItem(LAST_ORG_SELECTION_KEY, filterValue);
      }
      filterState[SearchFilterTypes.NETWORK] = [];
    }

    filterState[filter] = filterValue;
    setSearchState(filterState);
    return filterState;
  };

  function onFilterChange(filter, filterValue) {
    const filterState = updateFilterState(filter, filterValue);
    updateSearchFilters(filterState);

    if (!modal && triedToMatchAssignedUser) {
      updateProfile();
    }
  }

  const assignedUserPending = isAssignableUsersPending || !assignOptions?.length || !triedToMatchAssignedUser;

  return (
    <DashboardContainer data-testid="Dashboard">
      <Tabs role="tablist">
        {TABS.map((tab, index) => {
          return (
            <Tab
              data-testid={`Tab${tab.id}`}
              key={`Tab_${index}`}
              role="tab"
              aria-controls="nav-home"
              selected={currentTab === tab.id}
              aria-selected="true"
              onClick={() => tabClicked(tab.id)}
            >
              <span>{tab.name}</span>
            </Tab>
          );
        })}
      </Tabs>
      <SearchBar>
        {searchOptions?.map(({ filter, ...props }, index) => (
          <SearchSelect key={index} name={filter} onChange={(e) => onFilterChange(filter, e.target.value)} {...props} />
        ))}
      </SearchBar>
      <CustomersContainer>{renderData()}</CustomersContainer>
      {modal && <AssignUserModal setModal={setModal} assignTaskInfo={assignTaskInfo} assignOptions={assignOptions} />}
    </DashboardContainer>
  );
};

Dashboard.propTypes = {
  specificOrg: PropTypes.number,
  specificProfile: PropTypes.number,
  ngm: PropTypes.bool,
  profileSearch: PropTypes.bool,
};

export default Dashboard;

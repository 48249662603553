import React from 'react';
import { AnswerTypes } from '../../enums';
import { answerMap } from './answerTypes';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import { StyledAnswerGroup } from './styles';

const SkippableFormAnswer = ({ name, control, answerType, options, ...props }) => {
  const answerOptions = options?.filter((x) => x.answerType !== AnswerTypes.SKIP);
  const skipOption = options?.filter((x) => x.answerType === AnswerTypes.SKIP)[0];
  const { name: skipName, text: skipText, ...skipOptions } = skipOption;
  const { value: answerValue, onChange } = props;
  const skipSelected = skipName === answerValue;

  const onClickSkip = (e) => {
    if (onChange) {
      onChange(e, skipName);
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={''}
      render={({ field }) => {
        const Component = answerMap[answerType];
        return (
          <StyledAnswerGroup className="SkippableAnswerGroup">
            <Component {...field} options={answerOptions} {...props} />
            <Chip
              label={skipText}
              onClick={onClickSkip}
              name={skipName}
              value={skipName}
              className="SkipAnswer"
              icon={skipSelected && <DoneIcon />}
              color={skipSelected ? 'success' : 'primary'}
              variant={skipSelected ? 'filled' : 'outlined'}
              {...skipOptions}
            />
          </StyledAnswerGroup>
        );
      }}
    />
  );
};

SkippableFormAnswer.displayName = 'SkippableFormAnswer';

SkippableFormAnswer.propTypes = {
  answerType: PropTypes.oneOf(Object.values(AnswerTypes)),
  control: PropTypes.object.isRequired,
  name: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default SkippableFormAnswer;

import styled from '@emotion/styled';
import { Dialog, FormControl } from '@mui/material';
import Button from 'Components/Button/Button';

export const StyledForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 15px;
  font-size: 14px;
  row-gap: 50px;
`;

export const StyledButton = styled(Button)`
  width: 125px;
  height: 27px;
  border-radius: 25px;
  font-size: 16px;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  column-gap: 15px;
`;

export const StyledRadioGroup = styled(FormControl)`
  row-gap: 25px;
  font-size: 14px;
  color: black;

  .MuiFormGroup-root {
    flex-direction: row;
    column-gap: 15px;
  }

  .MuiFormControlLabel-label {
    font-size: 14px;
    color: rgb(15, 20, 91);
  }

  .MuiFormLabel-root {
    font-size: 14px;
    color: black;
    white-space: pre-wrap;

    &.Mui-focused {
      color: black;
    }
  }

  .MuiSvgIcon-root {
    font-size: 16px;
    color: rgb(15, 20, 91);
    font-size: 16px;
  }

  .MuiRadio-root {
    padding: 5px;
  }
`;

export const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: transparent;
  }

  .MuiPaper-root {
    max-width: 350px;
    min-height: 250px;
    border-radius: 20px;
  }

  .MuiDialogContent-root {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 25px;
  }

  .MuiDialogTitle-root {
    background-color: rgb(5, 139, 139);
    color: white;
    font-size: 24px;
  }

  .MuiDialogActions-root {
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 20px;

    .MuiButton-root {
      font-size: 14px;
    }
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  font-family: Karla;
  margin-top: -50px;
`;

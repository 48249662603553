import MemberConcerns from 'Tasking_Components/MemberConcerns/MemberConcerns';
import ProvideAssistance from 'Tasking_Components/ProvideAssistance/ProvideAssistance';
import MemberOtpCodeData from 'Tasking_Components/MemberOtpCodeData/MemberOtpCodeData';
import MemberTextData from 'Tasking_Components/MemberTextData/MemberTextData';
import NonAppEnrollment from 'Tasking_Components/NonAppEnrollment/NonAppEnrollment';
import CardGallery from 'Tasking_Components/CardGallery/CardGallery';
import SelfReportedOutcome from 'Tasking_Components/SelfReportedOutcome/SelfReportedOutcome';
import { activityTypes } from './Tabs/ActivityTabOptions';
import MemberConnectionActivity from 'Tasking_Components/MemberConnections/MemberConnectionActivity';
import ScreeningForm from 'Tasking_Components/Screenings/ScreeningForm/ScreeningForm';

export const getDisplayableActions = ({
  memberTaskInfo,
  ngm,
  memberInfo,
  currentTaskGuid,
  setSelectedTask,
  completedFeedback,
}) => {
  return {
    [activityTypes.ASSISTANCE]: {
      Component: ProvideAssistance,
      params: { memberInfo: memberTaskInfo },
    },
    [activityTypes.OTP]: {
      Component: MemberOtpCodeData,
      params: { memberInfo: memberTaskInfo, ngm },
    },
    [activityTypes.CONCERNS]: {
      Component: MemberConcerns,
      params: { memberTaskInfo },
    },
    [activityTypes.TEXT]: {
      Component: MemberTextData,
      params: { memberInfo, memberTaskInfo, ngm },
    },
    [activityTypes.SCREEN]: {
      Component: ScreeningForm,
      params: { memberTaskInfo },
    },
    [activityTypes.NONAPPENROLL]: {
      Component: NonAppEnrollment,
      params: { memberInfo: memberTaskInfo, taskGuid: currentTaskGuid, setSelectedTask },
    },
    [activityTypes.FEEDBACK]: {
      Component: SelfReportedOutcome,
      params: { memberInfo: memberTaskInfo, completedFeedback },
    },
    [activityTypes.CARD]: {
      Component: CardGallery,
      params: { memberTaskInfo, memberInfo },
    },
    [activityTypes.MEMBERCONNECTION]: {
      Component: MemberConnectionActivity,
      params: { memberInfo: memberTaskInfo, ngm },
    },
  };
};

import { ApiService } from 'Tasking_Helpers/ApiService';

export class AssociateCallLogApiService {
  private static _instance: AssociateCallLogApiService = new AssociateCallLogApiService();
  private api: ApiService;

  constructor() {
    // Make sure we don't create more than one instance of this service
    if (AssociateCallLogApiService._instance) {
      return AssociateCallLogApiService._instance;
    }

    this.api = new ApiService();
  }

  public associateCallLog = async (organizationId: number, taskGuid, callLogGuid) => {
    const { response, error } = await this.api.post(
      '/v5/tasks/:organizationId::taskGuid/calllog',
      { organizationId, taskGuid },
      {
        callLogGuid: callLogGuid,
      },
    );
    return { response, error };
  };
}

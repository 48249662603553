import styled from 'styled-components';

export const Customers = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 1274px;
  margin-top: 300px;
  margin-left: 85px;
  table-layout: fixed;
  border: 1px solid #f2f2f2;
  font-family: Karla;
`;

export const CustomerData = styled.td`
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 0.75px solid #e4e6e3;
  color: #0f145b;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
  font-family: karla;
  padding-left: ${(props) => (props.date ? '30px' : '0px')};
`;

export const CustomerDataHeader = styled.th`
  font-family: Karla;
  letter-spacing: 0.25px;
  text-align: left;
  color: #028a8a;
  background-color: #f2f2f2;
  padding-top: 12px;
  padding-bottom: 12px;
  white-space: nowrap;
  border: none;
  padding-left: ${(props) => (props.CreationDate ? '30px' : '0px')};
`;

export const CustomerTableSections = styled.tr`
  &:hover {
    background-color: #d9f7f7;
  }
`;

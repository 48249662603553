import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { StyledTextField } from './styles';

const Textbox = forwardRef(({ value, options, onChange, inputProps, ...props }, ref) => {
  const extraInputProps = { 'aria-labelledby': props['aria-labelledby'] };
  const val = value?.value ?? '';
  const { name } = options[0];

  const onValueChange = (e) => {
    const val = { name: name, value: e.target.value };

    if (onChange) {
      onChange(e, val);
    }
  };
  return (
    <StyledTextField
      multiline
      className="FormAnswer-answer-textbox"
      {...props}
      inputProps={{ maxLength: 500, ...inputProps, ...extraInputProps }}
      ref={ref}
      onChange={onValueChange}
      value={val}
    />
  );
});

Textbox.displayName = 'Textbox';

Textbox.propTypes = {
  inputProps: PropTypes.object,
  ref: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ['aria-labelledby']: PropTypes.string,
};

export default Textbox;

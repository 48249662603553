import { AnswerTypes } from './enums';

export const questions = [
  {
    name: 'b9cbd7e5-b5f7-4c49-b399-30a7acbee231',
    text: 'How often do you need to have someone help you when you read instructions, pamphlets, or other written material from your doctor or pharmacist?',
    options: [
      {
        name: '9727cc89-ba4f-4c18-bafc-6aceaf083962',
        text: 'Never',
      },
      {
        name: 'e4328855-c073-476d-aeaa-80a2dae13045',
        text: 'Rarely',
      },
      {
        name: '9cea49e6-46e5-4ae4-b002-2058b09a6e29',
        text: 'Sometimes',
      },
      {
        name: 'c33fde79-7208-4fc8-9b31-55f06436de98',
        text: 'Often',
      },
      {
        name: '6c289e0c-cf61-4e6a-8158-cd3ae5bd92eb',
        text: 'Always',
      },
    ],
  },
  {
    name: '694278c9-58e2-481a-aa90-8ea16da082c5',
    text: 'People sometimes miss taking their medications for reasons other than forgetting. Thinking over the past 2 weeks, were there any days when you did not take your medicine?',
    options: [
      {
        name: 'a565fd2f-78e5-41ca-b9cc-409a2d66ff6d',
        text: 'Yes',
      },
      {
        name: 'b474c057-0874-4407-b210-0f818f5105ba',
        text: 'No',
      },
    ],
  },
  {
    name: '8f1ab826-a8b0-4659-bcb3-2795eab19188',
    text: 'Was there a time in the past 12 months when you needed to see a doctor but could not because you could not afford it?',
    options: [
      {
        name: '58c84a56-e6a6-456e-9f44-eceb8611d8e9',
        text: 'Yes',
      },
      {
        name: '65a0e9ef-a2f1-415f-8572-0cff23fced2d',
        text: 'No',
      },
      {
        name: '59205fc5-19e9-4aa3-ac2c-0ea044e8de7c',
        text: "Don't know / not sure",
      },
      {
        name: 'f8ea1b1c-17c8-4723-a0f8-92d2df847d6d',
        text: 'Prefer not to answer',
      },
    ],
  },
  {
    name: 'cec7648e-0a79-4601-9c33-dfa81fe47c00',
    text: 'About how long has it been since you last visited a doctor for a routine checkup?',
    options: [
      {
        name: 'f65d30e5-1b5c-47e4-84bb-90f61800ad3b',
        text: 'Within the past year (anytime less than 12 months ago)',
      },
      {
        name: '0dda67a7-c3b1-4cf7-8d73-04cff12db592',
        text: 'Within the past 2 years (1 year but less than 2 years ago)',
      },
      {
        name: 'a1fa0811-a7d0-4e79-9108-6cf29b42980b',
        text: 'Within the past 5 years (2 years but less than 5 years ago)',
      },
      {
        name: '75a53fb3-6ee4-4aca-8d72-f12a5b68bd89',
        text: '5 or more years ago',
      },
    ],
  },
  {
    name: 'fbd56006-7aaa-407b-a192-35a77dceccfc',
    text: 'During the past 30 days, for about how many days did poor physical or mental health keep you from doing your usual activities, such as self-care, work, or recreation?',
    answerType: AnswerTypes.NULLABLETEXTBOX,
    options: [
      {
        name: '3d39aed6-05a2-4339-a99b-459f3ddd01c3',
        text: 'Variable amount between 1 and 30 days',
      },
      {
        name: '22cc38ed-2a0d-47f9-a424-f65dbba5f37c',
        text: 'None',
      },
    ],
  },
];

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  StyledMemberDataContainer,
  StyledPaper,
  StyledLabel,
  StyledDataRowContainer,
  StyledInputCheckboxLabel,
  StyledInputCheckboxStyle,
  StyledPhoneInput,
  StyledCodeInput,
  StyledCheckBoxContainer,
  StyledMobileInputContainer,
  StyledMobileUpdateInputLabel,
  StyledResendButtonContainer,
  StyledBackButtonContainer,
} from './styles';
import { Controller } from 'react-hook-form';
import Button from 'Components/Button';
import useAxiosSubmit from 'Tasking_Hooks/useAxiosSubmit';
import { Tooltip } from '@mui/material';
import { CheckCircle, ArrowBackIos } from '@mui/icons-material';
import { phoneNumberTypes } from './enums';

const MemberPhoneData = ({ memberInfo, ngm, editing, register, control, errors }) => {
  const mobileUpdateStates = {
    enterPhone: 'ENTERPHONE',
    enterCode: 'ENTERCODE',
    confirmation: 'CONFIRMATION',
  };

  const [mobileUpdateState, setMobileUpdateState] = useState(mobileUpdateStates.enterPhone);
  const [mobileNumber, setMobileNumber] = useState();
  const [code, setCode] = useState();
  const [submittedMobileNumber, setSubmittedMobileNumber] = useState();
  const [submittedCode, setSubmittedCode] = useState();
  const phoneNumberPreferenceRef = register('phoneNumberPreference');

  const { isLoading: isPhonePending, hasErrored: hasSubmitPhoneErrored } = useAxiosSubmit(
    `${process.env.API_BASE_URL}/v5/members/${memberInfo?.userId}/phone`,
    submittedMobileNumber,
  );

  const { isLoading: isCodePending, hasErrored: hasSubmitCodeErrored } = useAxiosSubmit(
    `${process.env.API_BASE_URL}/v5/members/${memberInfo?.userId}/phone-verify`,
    submittedCode,
  );

  const submitNewMobileNumber = () => {
    setSubmittedMobileNumber({ phoneNumber: mobileNumber });
  };

  const submitCode = () => {
    setSubmittedCode({ code: code });
  };

  const onBackToMobileInput = () => {
    setMobileUpdateState(mobileUpdateStates.enterPhone);
  };

  useEffect(() => {
    if (!hasSubmitPhoneErrored && !isPhonePending && submittedMobileNumber) {
      setMobileUpdateState(mobileUpdateStates.enterCode);
    }
  }, [isPhonePending]);

  useEffect(() => {
    if (!hasSubmitCodeErrored && !isCodePending && submittedCode) {
      setMobileUpdateState(mobileUpdateStates.confirmation);
    }
  }, [isCodePending]);

  return (
    <>
      <StyledPaper elevation={4}>
        <StyledDataRowContainer>
          <div>
            <StyledLabel htmlFor="HPProvidedPhone">HP Provided Phone:</StyledLabel>
            <br />
            <Controller
              name="HPPhoneNumberInput"
              control={control}
              defaultValue={
                ngm ? memberInfo?.normalizedPhone?.slice(-10) : memberInfo?.healthPlanProvidedPhoneNumber?.slice(-10)
              }
              render={({ field }) => <StyledPhoneInput {...field} disabled={true} id="HPPhoneNumberInput" />}
            />
          </div>
          <div>
            <br />
            <br />
            <Controller
              id="phoneNumberPreference"
              name="phoneNumberPreference"
              control={control}
              render={({ field }) => (
                <StyledInputCheckboxStyle
                  {...field}
                  {...phoneNumberPreferenceRef}
                  type="radio"
                  value={phoneNumberTypes.HEALTHPLANPROVIDEDPHONENUMBER}
                  defaultChecked={memberInfo?.phoneNumberPreference === phoneNumberTypes.HEALTHPLANPROVIDEDPHONENUMBER}
                  disabled={!editing}
                />
              )}
            />
            <StyledInputCheckboxLabel htmlFor="PreferredPhoneNumber_HP">
              Preferred Phone Number
            </StyledInputCheckboxLabel>
          </div>
          <div>
            <br />
            <br />
            <Tooltip arrow placement="top" title="This field must be updated in this person's non-member record.">
              <StyledCheckBoxContainer>
                <StyledInputCheckboxStyle
                  {...register('HPPhoneNumberNotWorking')}
                  type="checkBox"
                  data-testid="HPPhoneNumberNotWorking"
                  id="HPPhoneNumberNotWorking"
                  disabled={!ngm || !editing}
                  defaultChecked={memberInfo?.healthPlanProvidedPhoneNumberNotWorking}
                />
                <StyledInputCheckboxLabel htmlFor="HPPhoneNumberNotWorking">
                  Non-Working Number
                </StyledInputCheckboxLabel>
              </StyledCheckBoxContainer>
            </Tooltip>
          </div>
        </StyledDataRowContainer>
        {!ngm ? (
          <>
            <StyledDataRowContainer>
              <div>
                <StyledLabel htmlFor="MemberProvidedMobilePhone">Member Provided Mobile Phone:</StyledLabel>
                <br />
                <Controller
                  id="MemberProvidedMobilePhone"
                  name="MemberProvidedMobilePhone"
                  control={control}
                  defaultValue={memberInfo?.phoneNumber?.slice(-10)}
                  render={({ field }) => <StyledPhoneInput {...field} disabled={true} id="MemberProvidedMobilePhone" />}
                />
              </div>
              <div>
                <br />
                <br />
                <Controller
                  id="phoneNumberPreference"
                  name="phoneNumberPreference"
                  control={control}
                  render={({ field }) => (
                    <StyledInputCheckboxStyle
                      {...field}
                      {...phoneNumberPreferenceRef}
                      type="radio"
                      value={phoneNumberTypes.PHONENUMBER}
                      defaultChecked={memberInfo?.phoneNumberPreference === phoneNumberTypes.PHONENUMBER}
                      disabled={!editing}
                    />
                  )}
                />
                <StyledInputCheckboxLabel htmlFor="PreferredPhoneNumber_Mobile">
                  Preferred Phone Number
                </StyledInputCheckboxLabel>
              </div>
              <div>
                <br />
                <br />
                <StyledCheckBoxContainer>
                  <StyledInputCheckboxStyle
                    {...register('MobilePhoneNumberNotWorking')}
                    type="checkBox"
                    data-testid="MobilePhoneNumberNotWorking"
                    id="MobilePhoneNumberNotWorking"
                    disabled={!editing}
                    defaultChecked={memberInfo?.phoneNumberNotWorking}
                  />
                  <StyledInputCheckboxLabel htmlFor="MobilePhoneNumberNotWorking">
                    Non-Working Number
                  </StyledInputCheckboxLabel>
                </StyledCheckBoxContainer>
              </div>
            </StyledDataRowContainer>
            <StyledDataRowContainer>
              <div>
                <StyledLabel htmlFor="MemberProvidedLandlinePhone">Member Provided Landline Phone:</StyledLabel>
                <br />
                <Controller
                  name="MemberProvidedLandlinePhone"
                  control={control}
                  rules={{
                    validate: (v) => v?.length === 10 || v?.length === 0 || v == null,
                  }}
                  defaultValue={memberInfo?.landlinePhoneNumber?.slice(-10)}
                  render={({ field }) => (
                    <StyledPhoneInput
                      {...field}
                      disabled={!editing}
                      error={errors?.MemberProvidedLandlinePhone}
                      errorMessage={'Error: Phone number is too short.'}
                      id="MemberProvidedLandlinePhone"
                    />
                  )}
                />
              </div>
              <div>
                <br />
                <br />
                <Controller
                  name="phoneNumberPreference"
                  control={control}
                  render={({ field }) => (
                    <StyledInputCheckboxStyle
                      {...field}
                      {...phoneNumberPreferenceRef}
                      type="radio"
                      value={phoneNumberTypes.LANDLINEPHONENUMBER}
                      defaultChecked={memberInfo?.phoneNumberPreference === phoneNumberTypes.LANDLINEPHONENUMBER}
                      disabled={!editing}
                    />
                  )}
                />
                <StyledInputCheckboxLabel htmlFor="PreferredPhoneNumber_Landline">
                  Preferred Phone Number
                </StyledInputCheckboxLabel>
              </div>
              <div>
                <br />
                <br />
                <StyledCheckBoxContainer>
                  <StyledInputCheckboxStyle
                    {...register('LandlinePhoneNumberNotWorking')}
                    type="checkBox"
                    data-testid="LandlinePhoneNumberNotWorking"
                    id="LandlinePhoneNumberNotWorking"
                    disabled={!editing}
                    defaultChecked={memberInfo?.landlinePhoneNumberNotWorking}
                  />
                  <StyledInputCheckboxLabel htmlFor="LandlinePhoneNumberNotWorking">
                    Non-Working Number
                  </StyledInputCheckboxLabel>
                </StyledCheckBoxContainer>
              </div>
            </StyledDataRowContainer>
          </>
        ) : (
          <StyledDataRowContainer>
            <div>
              <StyledLabel htmlFor="PreferredPhoneNumber">Preferred Phone:</StyledLabel>
              <br />
              <Controller
                name="PreferredPhoneNumber"
                control={control}
                defaultValue={memberInfo?.preferredPhone?.slice(-10)}
                rules={{
                  validate: (v) => v?.length === 10 || v?.length === 0 || v == null,
                }}
                render={({ field }) => (
                  <StyledPhoneInput
                    {...field}
                    error={!!errors?.PreferredPhoneNumber}
                    errorMessage={'Invalid Input'}
                    disabled={!editing}
                    id="PreferredPhoneNumber"
                  />
                )}
              />
            </div>
            <div>
              <br />
              <br />
              <Controller
                name="phoneNumberPreference"
                control={control}
                render={({ field }) => (
                  <StyledInputCheckboxStyle
                    {...field}
                    {...phoneNumberPreferenceRef}
                    type="radio"
                    id="phoneNumberPreference"
                    value={phoneNumberTypes.PHONENUMBER}
                    defaultChecked={memberInfo?.phoneNumberPreference === phoneNumberTypes.PHONENUMBER}
                    disabled={!editing}
                  />
                )}
              />
              <StyledInputCheckboxLabel htmlFor="PreferredPhoneNumber_Mobile">
                Preferred Phone Number
              </StyledInputCheckboxLabel>
            </div>
            <div>
              <br />
              <br />
              <StyledCheckBoxContainer>
                <StyledInputCheckboxStyle
                  {...register('PreferredPhoneNumberNotWorking')}
                  type="checkBox"
                  data-testid="PreferredPhoneNumberNotWorking"
                  id="PreferredPhoneNumberNotWorking"
                  disabled={!editing}
                  defaultChecked={memberInfo?.phoneNumberNotWorking}
                />
                <StyledInputCheckboxLabel htmlFor="PreferredPhoneNumberNotWorking">
                  Non-Working Number
                </StyledInputCheckboxLabel>
              </StyledCheckBoxContainer>
            </div>
          </StyledDataRowContainer>
        )}
      </StyledPaper>
      {!ngm && (
        <StyledMemberDataContainer>
          {mobileUpdateState === mobileUpdateStates.enterPhone ? (
            <StyledMobileInputContainer elevation={4}>
              <StyledMobileUpdateInputLabel>New Mobile Number</StyledMobileUpdateInputLabel>
              <Controller
                name="NewMobileNumber"
                control={control}
                render={({ field }) => (
                  <StyledPhoneInput
                    {...field}
                    variant="filled"
                    placeholder="(___) ___-____"
                    format="(###) ###-####"
                    disabled={!editing}
                    error={hasSubmitPhoneErrored}
                    errorMessage={'Error: Invalid Input'}
                    name="NewMobileNumber"
                    data-testid="NewMobileNumber"
                    onChange={setMobileNumber}
                  />
                )}
              />
              <Button
                data-testid="MobilePhoneSubmitButton"
                variant="contained"
                fullWidth={true}
                disabled={!editing}
                loading={isPhonePending}
                sx={{ margin: '25px 0' }}
                onClick={submitNewMobileNumber}
              >
                Submit
              </Button>
            </StyledMobileInputContainer>
          ) : mobileUpdateState === mobileUpdateStates.enterCode ? (
            <StyledMobileInputContainer elevation={4}>
              <StyledMobileUpdateInputLabel>
                <StyledBackButtonContainer>
                  <ArrowBackIos onClick={onBackToMobileInput} aria-label={'Back'} />
                </StyledBackButtonContainer>
                Verify Mobile Number
              </StyledMobileUpdateInputLabel>
              <Controller
                name="code"
                control={control}
                render={({ field }) => (
                  <StyledCodeInput
                    {...field}
                    error={hasSubmitCodeErrored}
                    errorMessage={'Error: Invalid Input'}
                    disabled={!editing}
                    id="CodeInput"
                    data-testid="CodeInput"
                    onChange={setCode}
                  />
                )}
              />
              <Button
                data-testid="CodeSubmitButton"
                variant="contained"
                fullWidth={true}
                disabled={!editing}
                loading={isCodePending}
                sx={{ margin: '25px 0' }}
                onClick={submitCode}
              >
                Confirm
              </Button>
              <StyledResendButtonContainer>
                <Button
                  data-testid="resend"
                  variant="text"
                  fullWidth={false}
                  disabled={!editing}
                  loading={isPhonePending}
                  onClick={submitNewMobileNumber}
                >
                  Resend
                </Button>
              </StyledResendButtonContainer>
            </StyledMobileInputContainer>
          ) : (
            <StyledMobileInputContainer elevation={4}>
              <StyledMobileUpdateInputLabel>Mobile Verification</StyledMobileUpdateInputLabel>
              <StyledMobileUpdateInputLabel>
                <CheckCircle style={{ color: '#048600', fontSize: '70' }} />
              </StyledMobileUpdateInputLabel>
              <StyledMobileUpdateInputLabel>Mobile Successfully Verified</StyledMobileUpdateInputLabel>
            </StyledMobileInputContainer>
          )}
        </StyledMemberDataContainer>
      )}
    </>
  );
};

MemberPhoneData.propTypes = {
  memberInfo: PropTypes.object,
  editing: PropTypes.bool,
  register: PropTypes.any.isRequired,
  control: PropTypes.any.isRequired,
  ngm: PropTypes.bool,
  errors: PropTypes.object,
};

export default MemberPhoneData;

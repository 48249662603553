import styled from '@emotion/styled';
import Button from 'Components/Button';

export const GroupContainer = styled.div`
  padding-top: 21px;
  margin-left: 25px;
  font-size: 14px;
  margin-bottom: 10px;
  width: 300px;
`;

export const GreetingCardContainer = styled.div`
  padding: 3%;
  position: relative;
  left: -35%;
`;

export const Heading = styled.div`
  font-family: lato;
  color: #0f0f0f;
`;

export const Text = styled.div`
  font-family: Open Sans;
  color: #0f145b;
  padding-top: 11px;
  white-space: ${(props) => (props.notes ? 'pre-line' : 'normal')};
`;

export const DropDownInfoContainer = styled.div`
  display: flex;
`;

export const Icon = styled.i`
  color: #0f145b;
  height: 14px;
  width: 14px;
  cursor: pointer;
  font-size: 22px;
  margin-top: 12px;
`;

export const StyledButton = styled(Button)`
  width: 126px;
  height: 27px;
  border-radius: 20px;
  font-size: 16px;
`;

import React from 'react';
import { RadioWrapper, Mark, Label, Input } from './styles';
import PropTypes from 'prop-types';

const Radio = ({ defaultChecked, id, name, value, children, className, dataTestId, checked, disabled, onChange }) => (
  <RadioWrapper className={className}>
    <Label className={className} htmlFor={id}>
      <Input
        data-testid={dataTestId}
        id={id}
        name={name}
        type="radio"
        checked={checked}
        value={value}
        onChange={onChange}
        className={className}
        disabled={disabled}
        defaultChecked={defaultChecked}
      />
      <Mark />
      {children}
    </Label>
  </RadioWrapper>
);

Radio.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  dataTestId: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  defaultChecked: PropTypes.bool,
};

export default Radio;

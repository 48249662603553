import React from 'react';
import PropTypes from 'prop-types';
import {
  GreetingCardContainer,
  GreetingCardImage,
  GreetingCardMessage,
  GreetingCardFrom,
  GreetingCardMessageContainer,
  GreetingCardTextContainer,
  SpinnerButton,
  GreetingCardErrorMessage,
} from './styles';
import useGetCard from 'Tasking_Hooks/useGetCard';
import Spinner from 'Components/Spinner/Spinner';

const GreetingCard = ({ cardId, cardFrom, maxSize, fontSizeTitle = '1.5625rem', fontSize = '0.875rem' }) => {
  const { card, isGetCardPending } = useGetCard(cardId);
  return (
    <>
      <GreetingCardContainer maxSize={maxSize} data-testid="greetingCardPreview">
        <GreetingCardMessageContainer maxSize={maxSize}>
          {isGetCardPending ? (
            <SpinnerButton data-testid="SpinnerButton">
              <Spinner marginLeft={'0'} height={'50%'} width={'50%'} />
            </SpinnerButton>
          ) : card ? (
            <>
              <GreetingCardImage src={card?.storagePath} />
              <GreetingCardTextContainer maxSize={maxSize}>
                <GreetingCardMessage maxSize={maxSize} fontSize={fontSize}>
                  {card?.text}
                </GreetingCardMessage>
                <GreetingCardFrom fontSize={fontSizeTitle}>{cardFrom}</GreetingCardFrom>
              </GreetingCardTextContainer>
            </>
          ) : (
            <GreetingCardErrorMessage>Card not found</GreetingCardErrorMessage>
          )}
        </GreetingCardMessageContainer>
      </GreetingCardContainer>
    </>
  );
};

GreetingCard.propTypes = {
  cardId: PropTypes.number,
  cardFrom: PropTypes.string,
  maxSize: PropTypes.string,
  fontSizeTitle: PropTypes.string,
  fontSize: PropTypes.string,
};

export default GreetingCard;

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import PropTypes from 'prop-types';

const UnsavedChangesModal = ({ open, handleClose }) => {
  return (
    <Dialog
      data-testid="UnsavedChangesModal"
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Warning! You have unsaved changes to your notes.</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">Do you still want to continue?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button data-testid="NoButton" variant="contained" color="primary" onClick={() => handleClose('No')}>
          No
        </Button>
        <Button data-testid="YesButton" variant="contained" onClick={() => handleClose('Yes')}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UnsavedChangesModal.propTypes = { open: PropTypes.bool, handleClose: PropTypes.func };

export default UnsavedChangesModal;

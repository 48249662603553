import { useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import getAuthClient from 'Tasking_Helpers/getAuthClient';
import urlcat from 'urlcat';

const searchCardsUrl = 'v1/cards/premades';
const options = {
  revalidateOnFocus: false,
};

const getPremadeCards = async (url) => {
  const authorizedApiClient = await getAuthClient();

  if (authorizedApiClient) {
    return await authorizedApiClient.get(url);
  }
};

const useGetPremadeCards = (swrOptions) => {
  const [isPending, setIsPending] = useState(true);
  const [requestData, setRequestData] = useState();
  const [cardResults, setCardResults] = useState();
  const { data: cardResultsData, error } = useSWR(
    () => urlcat(process.env.API_BASE_URL, searchCardsUrl, requestData),
    getPremadeCards,
    {
      ...options,
      ...swrOptions,
    },
  );

  useEffect(() => {
    if (cardResultsData) {
      setCardResults(cardResultsData.data);
    }

    setIsPending(!cardResultsData && !error);
  }, [cardResultsData, error]);

  const searchPremadeCards = (searchRequest) => {
    setRequestData(searchRequest);
    mutate(cardResultsData);
  };

  return { cards: cardResults, searchCardsError: error, isSearchCardsPending: isPending, searchPremadeCards };
};

export default useGetPremadeCards;

import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { Paper, Button, FormControlLabel, Grid } from '@mui/material';
import PhoneInput from 'Components/Inputs/PhoneInput/PhoneInput';
import CodeInput from 'Components/Inputs/CodeInput/CodeInput';

export const StyledBackButtonContainer = styled.span`
  position: relative;
  left: -30px;
  cursor: pointer;
`;

export const StyledMemberDataContainer = styled.div`
  padding: 0 48px;
`;

export const StyledErrorMessage = styled.div`
  color: red;
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  padding: 12px 48px;
`;

export const StyledPaper = styled(Paper)`
  padding: 32px 16px;
  border-radius: 5px;
`;

export const StyledMobileInputContainer = styled(Paper)`
  padding: 32px 16px;
  border-radius: 5px;
  height: 328px;
  width: 268px;
`;

export const StyledTextHeader = styled.h2`
  margin-top: 20px;
  font-family: karla;
  font-weight: 700;
  font-size: 32px;
  color: #484d87;
  font-size: 32px;
  width: fit-content;
  letter-spacing: 0.27px;
`;

export const StyledTextSubHeader = styled.div`
  font-family: karla;
  font-size: 24px;
  letter-spacing: 0.8px;
`;

export const StyledDataRowContainer = styled.div`
  display: flex;
  font-size: 16px;
  padding: 5px;
  width: 100%;
`;

export const StyledCheckBoxContainer = styled.div`
  margin: auto 10px auto auto;
`;

export const CheckboxGridItem = styled(Grid)`
  min-width: fit-content;
`;

export const StyledSelectStyle = styled.select`
  width: ${(props) => (props.name === 'Language' ? '155px' : '58px')};
  height: 42px;
  border: ${(props) => (props.disabled ? ' 2px none #e9e7e7;' : ' 2px solid #e9e7e7;')};
  font-size: 14px;
  font-family: lato;
  font-weight: 400;
  letter-spacing: 2px;
  margin-top: 24px;
  margin-left: ${(props) => (props.name === 'Language' ? '-135px' : '-40px')};
`;

export const StyledTextAreaStyle = styled.textarea`
  border-radius: 10px;
  width: 1239px;
  height: 92px;
  border: ${(props) => (props.disabled ? ' 2px none #e9e7e7;' : ' 2px solid #e9e7e7;')};
  font-size: 14px;
  font-family: lato;
  font-weight: 400;
  letter-spacing: 2px;
  color: #0f145b;
  margin-top: 6px;
  margin-right: 20px;
`;

export const StyledInputStyle = styled.input`
  border-radius: 10px;
  width: ${(props) =>
    props.name === 'City'
      ? '323px'
      : props.name === 'Zip'
      ? '104px'
      : props.name === 'PreferredFirstName'
      ? '268px'
      : props.name === 'PreferredLastName'
      ? '268px'
      : props.name === 'PreferredPhone'
      ? '138px'
      : props.name === 'Notes'
      ? '1239px'
      : props.name === 'HPProvidedPhone' ||
        props.name === 'MemberProvidedMobilePhone' ||
        props.name === 'MemberProvidedLandlinePhone'
      ? '268px'
      : props.name === 'Notes'
      ? '1239px'
      : '557px'};
  height: ${(props) => (props.name === 'Notes' ? '92px' : '36.26px')};
  border: ${(props) => (props.disabled ? ' 2px none #e9e7e7;' : ' 2px solid #e9e7e7;')};
  color: #000000;
  margin-top: 6px;
  margin-right: 20px;
  font-size: 14px;
  font-family: lato;
  font-weight: 400;
  letter-spacing: 2px;
  margin-left: ${(props) => (props.name === 'Zip' ? '20px' : '')};
`;

export const StyledNoteLabel = styled.label`
  font-family: karla;
  font-size: 16px;
`;

export const StyledInputCheckboxStyle = styled.input`
  width: 22px;
  height: 22px;
  border-radius: 25px;
  background: white;
  padding: 0 0 20px 40px;

  &:disabled + label {
    color: #000000;
    opacity: 0.3;
  }
`;

export const StyledInputCheckboxLabel = styled.label`
  color: #000000;
  font-size: 16px;
  font-family: karla;
  font-weight: 400;
  letter-spacing: 0.53px;
  padding: 5px 5px 5px 5px;
  vertical-align: super;
`;

export const StyledLabel = styled.label`
  color: #0f0f0f;
  font-size: 14px;
  font-family: lato;
  letter-spacing: 0.47px;
  margin-left: ${(props) => (props.type === 'Zip' ? '20px' : '')};
`;

export const StyledMobileUpdateInputLabel = styled.p`
  color: #0f0f0f;
  font-size: 16px;
  font-family: helvetica;
  font-weight: 400;
  letter-spacing: 0.53px;
  padding: 5px 5px 20px 5px;
  text-align: center;
`;

export const StyledIcon = styled.i`
  color: ${(props) => (props.edit ? '#0F145B' : 'white')};
`;

export const StyledIconContainer = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0 auto auto;
  width: 24px;
  height: 24px;
  background-color: ${(props) => (props.edit ? 'white' : '#ECECEC')};
  cursor: ${(props) => (props.edit ? 'pointer' : '')};
`;

export const StyledSubmitButtonContainer = styled.div`
  display: flex;
  margin: auto 0 auto auto;
`;

export const StyledResendButtonContainer = styled.div`
  width: 98px;
`;

export const StyledSubmitButton = styled(Button)`
  background-color: ${(props) => (props.clear ? 'white' : '#4a4f7f')};
  color: #ffffff;
  width: 178.03px;
  height: 48px;
  margin: 0 7px;
  cursor: pointer;
  font-family: karla;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 1.61px;
`;

export const StyledCancelButton = styled.div`
  background-color: white;
  color: #000000;
  width: 125px;
  height: 27px;
  margin: auto 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: karla;
  font-weight: 500;
  letter-spacing: 0.19px;
  font-size: 18px;
`;

export const StyledSpinnerButton = styled.div`
  background-color: ${(props) => (props.clear ? 'white' : '#4a4f7f')};
  color: ${(props) => (props.clear ? '#4a4f7f' : 'white')};
  width: 125px;
  height: 30px;
  border: 1px solid #4a4f7f;
  border-radius: 25px;
  font-family: Karla, sans-serif;
  outline: none;
  margin-left: 14px;
`;

export const CheckboxControl = styled(FormControlLabel)`
  padding: 0px 5px;

  .MuiCheckbox-root {
    padding: 2px;
  }

  .MuiCheckbox-root.Mui-checked {
    :not(.Mui-disabled) {
      color: #2b72f1;
    }
  }

  .MuiSvgIcon-root {
    font-size: 1.7rem;
  }
`;

const attrs = { fullWidth: false, variant: 'outlined', sx: { marginBottom: '16px', width: '268px' } };

export const StyledCodeInput = forwardRef((props, ref) => <CodeInput ref={ref} {...attrs} {...props} />);
StyledCodeInput.displayName = 'CodeInput';

export const StyledPhoneInput = forwardRef((props, ref) => <PhoneInput ref={ref} {...attrs} {...props} />);
StyledPhoneInput.displayName = 'PhoneInput';

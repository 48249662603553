import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DropDownInfoContainer } from './styles.js';
import useSearchScreeningScore from 'Tasking_Hooks/useSearchScreeningScore.js';
import useGetScreeningResponse from 'Tasking_Hooks/useGetScreeningResponse.js';
import { HistoryItems } from './historyItems.js';
import LoadingWrapper from 'Components/LoadingWrapper/LoadingWrapper.jsx';

const ScreeningHistoryItem = ({ memberInfo, data }) => {
  const { screeningScore, searchScreeningScore } = useSearchScreeningScore();
  const { screeningResponse, getScreeningResponse } = useGetScreeningResponse();
  const HistoryItemComponent = screeningScore ? HistoryItems[screeningScore[0].screeningGuid] : null;
  const isLoading = !HistoryItemComponent || !screeningResponse;

  useEffect(() => {
    searchScreeningScore(memberInfo?.userId, data?.guid);
    getScreeningResponse(memberInfo?.userId, data?.guid);
  }, []);

  return (
    <DropDownInfoContainer data-testid="ScreeningDropDownInfo">
      <LoadingWrapper loading={isLoading}>
        <HistoryItemComponent answers={screeningResponse?.answers} scoreDetail={screeningScore} />
      </LoadingWrapper>
    </DropDownInfoContainer>
  );
};

ScreeningHistoryItem.propTypes = {
  memberInfo: PropTypes.shape({
    userId: PropTypes.number,
  }),
  data: PropTypes.shape({
    guid: PropTypes.string,
  }),
};

export default ScreeningHistoryItem;

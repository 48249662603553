import { Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { NoResultsFound, DataGridTitle, LoadingContainer } from './styles';
import GridDataItem from './GridDataItem/GridDataItem';

const ResultsGrid = ({ fetchNumber, loading, data, headerMap, onDataClick, dataComponent }) => {
  const emptyBoxes = Array(fetchNumber).fill(false);
  const keys = headerMap.map((x) => x.name);
  const resultGridSize = 12 / headerMap.length;
  const dataList = data?.map((y) => {
    let temp = {};
    keys.map((x) => (temp[x] = y[x]));
    return temp;
  });
  const DataGridComponent = dataComponent ?? GridDataItem;

  const renderLoadingSkeleton = () => {
    return emptyBoxes.map((_, i) => <LoadingContainer key={i} variant="rectangle" height={43.5} />);
  };

  return (
    <>
      <Grid container item xs={12} style={{ backgroundColor: 'white' }} className="ResultsGrid">
        {headerMap.map(({ header }, index) => (
          <DataGridTitle
            item
            xs={resultGridSize}
            key={index}
            className="ResultsGrid-title"
            style={{ paddingLeft: index > 0 ? '' : '15px' }}
          >
            {header}
          </DataGridTitle>
        ))}
        {!data && loading ? (
          renderLoadingSkeleton()
        ) : data?.length > 0 ? (
          data.map((item, index) => (
            <DataGridComponent
              key={index}
              onClick={() => onDataClick(item)}
              data={item}
              loading={loading}
              className="ResultsGrid-DataItem"
            >
              {Object.keys(dataList[index])?.map((dataKey, dataIndex) => (
                <Grid item xs={resultGridSize} key={dataIndex} className={`ResultsGrid-DataItem-${index}`}>
                  {dataList[index][dataKey]}
                </Grid>
              ))}
            </DataGridComponent>
          ))
        ) : (
          <NoResultsFound>No Results Available</NoResultsFound>
        )}
      </Grid>
    </>
  );
};

ResultsGrid.displayName = 'ResultsGrid';

ResultsGrid.propTypes = {
  fetchNumber: PropTypes.number,
  data: PropTypes.array,
  headerMap: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  onDataClick: PropTypes.func,
  dataComponent: PropTypes.func,
  loading: PropTypes.bool,
};

export default ResultsGrid;

import { createTheme } from '@mui/material/styles';

// Schema for default Material theme object - https://material-ui.com/customization/default-theme/
const theme = createTheme({
  palette: {
    primary: {
      main: '#4a4f7f',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Karla',
    fontSize: 14,
    button: {
      borderRadius: '4px',
      textTransform: 'none',
    },
  },
  overrides: {
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: '#4a4f7f',
        },
      },
    },
  },
});

export default theme;

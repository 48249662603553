import styled from 'styled-components';
import NavButtonWrapper from './NavButtonWrapper';

export const StyledNavContainer = styled.nav`
  display: flex;
  flex-flow: row nowrap;
  padding: 6px 12px;
  background-color: #cde6b2;
`;

export const StyledNavButton = styled(NavButtonWrapper)`
  margin: 0 12px;
  background-color: ${(props) => (props.active ? '#72b8a7' : '#cde6b2')};
`;

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Open-Sans, Helvetica, Sans-Serif;
  }
  select {
    padding-left: 5px;
    padding-right: 5px;
    font-family: inherit;
  }
  input[type=text], input[type=date], textarea {
    padding-left: 5px;
    padding-right: 5px;
    font-family: inherit;
  }
  button {
    text-transform: capitalize;
  }
  label {
    font-size: 14px;
  }
`;

export default GlobalStyle;

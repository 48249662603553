import { ApiService } from 'Administrator_Helpers/ApiService';

export class UserResourcesApiDataService {
  private static _instance: UserResourcesApiDataService = new UserResourcesApiDataService();
  private api: ApiService;

  constructor() {
    // Make sure we don't create more than one instance of this service
    if (UserResourcesApiDataService._instance) {
      return UserResourcesApiDataService._instance;
    }
    this.api = new ApiService();
  }

  public getUserResources = async (request: { userId: number }) => {
    if (!request?.userId) return;
    const { response, error } = await this.api.get(`/v1/userresources/${request.userId}/Pyx.PyxIQ`, null);
    return { response, error };
  };
}
